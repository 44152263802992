import Translations             from '../../utils/Translations';
import React                    from 'react';
import PropTypes                from 'prop-types';
import Tracker                  from './Tracker';
import ReloadHelper             from '../../utils/ReloadHelper';
import {VXButtonLineConfig}     from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_SUCCESS}     from "../SimpleElements/VXButton/ButtonHelper";
import VXButton                 from "../SimpleElements/VXButton/VXButton";

class ProfileButton extends React.PureComponent {

	constructor(props) {
		super(props);

		this.onClick = this.onClickHandler.bind(this);
	}

	/**
	 * @param {Event} e
	 */
	onClickHandler(e) {
		Tracker.trackProfile({
			actorId: this.props.actorId,
			albumId: this.props.albumId,
		});
		ReloadHelper.reload(this.props.actorUrl, e);
	}

	render() {
		return (
			<div className="welcome-clip-rotator__profile-button-container">
				<div className="welcome-clip-rotator__profile-button-caption">{this.props.actorName}</div>
				<VXButton line1={[new VXButtonLineConfig(Translations.get('ToProfile'))]}
				          color={COLOR_TYPE_SUCCESS}
				          onClick={this.onClick}
				/>
			</div>
		);
	}
}

ProfileButton.propTypes = {
	actorId:   PropTypes.number.isRequired,
	actorName: PropTypes.string.isRequired,
	actorUrl:  PropTypes.string.isRequired,
	albumId:   PropTypes.number.isRequired,
};

export default ProfileButton;
