import React, {useState, useEffect}                              from 'react';
import PropTypes                                                 from 'prop-types';
import {exitFullScreen, getFullScreenElement, requestFullscreen} from '../../../utils/Fullscreen';
import IconButton                                                from './IconButton';

const Fullscreen = (props) => {
	const [fullscreen, setFullscreen] = useState(getFullScreenElement() === props.wrapper);

	useEffect(() => {
		const fn = () => {
			setFullscreen(getFullScreenElement() === props.wrapper);
		};

		document.addEventListener('fullscreenchange', fn);
		return () => {
			document.removeEventListener('fullscreenchange', fn);
		};
	});

	const onClick = () => {
		if (typeof props.onFullscreenClick === 'function') {
			props.onFullscreenClick();
			return;
		}

		// Apply fullscreen to wrapper instead of video element
		// Applying it to video would render default controls which cant be disabled
		if (getFullScreenElement()) {
			exitFullScreen();
		} else {
			requestFullscreen(props.wrapper, props.video, props.isMobile);
		}
	};

	return (
		<IconButton onClick={onClick} checked={fullscreen} icon='-icon-fullscreen-open' checkedIcon='-icon-fullscreen-close' />
	);
};

Fullscreen.propTypes = {
	wrapper: PropTypes.object.isRequired,
	video:   PropTypes.instanceOf(HTMLVideoElement).isRequired,
	isMobile: PropTypes.bool,
	onFullscreenClick: PropTypes.func,
};

Fullscreen.defaultProps = {
	onFullscreenClick: null,
};

export default Fullscreen;
