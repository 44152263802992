import React                                                                from 'react';
import PropTypes                                                            from 'prop-types';
import Modal                                                                from '../Modal';
import {VideoPlayer}                                                        from "../Gallery/VideoPlayer";
import VXButton                                                             from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_DISABLED, COLOR_TYPE_HIGHLIGHT_GOLD, COLOR_TYPE_SPECIAL} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}                from "../SimpleElements/VXButton/VXButtonConfig";
import {formatDuration, getCurrencySymbol} from "../../utils/CommonUtils";
import ReloadHelper                        from "../../utils/ReloadHelper";
import Translations                                                         from "../../utils/Translations";
import {VXPay}                                                              from "../../utils/VXPay";
import Flux                                                                 from "../../flux/Flux";
import AlbumBuyButtons                                                      from "../ActorProfileVideos/AlbumBuyButtons";
import UrlBuilder                                                           from "../../utils/UrlBuilder";

const REWARD_TYPE_VIDEO           = 'video';
const REWARD_TYPE_PHOTO_ALBUM     = 'photo_album';
const REWARD_TYPE_PHOTO           = 'photo';
const REWARD_TYPE_BONUSCODE       = 'bonuscode';
const REWARD_TYPE_BONUSCODE_ONTOP = 'bonuscode_ontop';
const REWARD_TYPE_VOUCHER         = 'voucher';
const REWARD_TYPE_CLIP            = 'clip';

const REWARD_INFO_PERCENT            = 'percent';
const REWARD_INFO_FLAT               = 'flat';
const REWARD_INFO_FREE               = 'free';
const REWARD_INFO_FREE_MESSAGES      = 'free_messages';
const REWARD_INFO_FREE_CHAT_DURATION = 'free_chat_duration';
const REWARD_INFO_VIDEO_VOUCHER      = 'video_voucher';
const REWARD_INFO_TICKET_SHOW        = 'ticket_show';

const REWARD_INFO_TRANSLATION_MAP = {
	[REWARD_INFO_FREE_MESSAGES]:      'Freinachrichten', // todo
	[REWARD_INFO_FREE_CHAT_DURATION]: 'Freiminuten', // todo
	[REWARD_INFO_VIDEO_VOUCHER]:      'Video Voucher',
};


class ModalBoxActorGiftCalendar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.getContent               = this.getContent.bind(this);
		this.getRewardContent         = this.getRewardContent.bind(this);
		this.getRewardVideo           = this.getRewardVideo.bind(this);
		this.getRewardPhotoAlbum      = this.getRewardPhotoAlbum.bind(this);
		this.getRewardBonuscode       = this.getRewardBonuscode.bind(this);
		this.getRewardVoucher         = this.getRewardVoucher.bind(this);
		this.getButtonColor           = this.getButtonColor.bind(this);
		this.getButtonOverwriteStyles = this.getButtonOverwriteStyles.bind(this);


		const urlParams = new URL(window.location.href);
		urlParams.searchParams.delete('openGiftCalendar');
		window.history.replaceState({}, '', urlParams.toString());
	}

	getContent() {
		const background = this.props.isMobile ? this.props.rewardBackgroundMobile : this.props.rewardBackground;
		const className  = this.props.className ? this.props.className : '';

		return (
			<div className={"agc-modal-wrapper " + className + ' ' + this.props.currentReward.type}
			     style={{backgroundImage: "url(" + background + ")"}}
			>
				<div className={"agc-modal-headline"}>{Translations.get('_event-actor-gift-calendar.' + this.props.rewardHeadline)}</div>
				{this.getRewardContent()}
				{this.getFooter()}
			</div>
		);
	}

	getButtonOverwriteStyles() {
		const styles = {margin: "auto"};

		if (this.props.actorId === 3926584) {
			styles.backgroundColor = "#C58E55";
		}

		return styles;
	}

	getButtonColor() {
		if (this.props.actorId === 3926584) {
			return COLOR_TYPE_HIGHLIGHT_GOLD;
		}

		return COLOR_TYPE_SPECIAL;
	}

	getRewardContent() {
		let content = null;

		switch (this.props.currentReward.type) {
			case REWARD_TYPE_VIDEO:
				content = this.getRewardVideo();
				break;
			case REWARD_TYPE_PHOTO_ALBUM:
				content = this.getRewardPhotoAlbum();
				break;
			case REWARD_TYPE_BONUSCODE:
			case REWARD_TYPE_BONUSCODE_ONTOP:
				content = this.getRewardBonuscode();
				break;
			case REWARD_TYPE_VOUCHER:
				content = this.getRewardVoucher();
				break;
			case REWARD_TYPE_PHOTO:
				content = this.getRewardPhoto();
				break;
			case REWARD_TYPE_CLIP:
				content = this.getRewardClip();
				break;
		}

		return (
			<div className={"agc-modal-reward " + this.props.currentReward.type}>
				{content}
			</div>
		);
	}

	getRewardVideo() {
		const onClickButton = (e) => {
			if (this.props.currentReward.needAvs) {
				VXPay.openAVS();
			} else {
				ReloadHelper.reload(this.props.currentReward.videoProfileUrl, e);
				this.props.onClose();
			}
		};

		let videoData = null;
		if (this.props.currentReward.videoUrl) {
			videoData = [
				{
					videoUrls:          [
						{
							src: this.props.currentReward.videoUrl,

						},
					],
					previewPictureUrls: [{
						pictureUrl: this.props.currentReward.previewPictureUrl,
					}],
					albumTitle:         this.props.currentReward.videoTitle,
					videoDuration:      formatDuration(this.props.currentReward.videoDuration),
					actorName:          this.props.actorName,
					actorUrl:           this.props.actorProfileUrl,
				},
			];
		}


		const button = this.props.currentReward.needBuying ?
			<div style={{paddingBottom: "10px"}}>
				<AlbumBuyButtons baseClassName={"vx_shaka--ui"}
				                 id={this.props.currentReward.videoData.albumId}
				                 onBuy={(e) => {
					                 ReloadHelper.reload(this.props.currentReward.videoData.titleUrl, e);
					                 this.props.onClose();
				                 }}
				                 actorDataId={this.props.actorId} vxbuttonAdditionalStyle={{height: "55px", lineHeight: "55px"}}
				                 price={this.props.currentReward.videoData.videoPrice} {...this.props.currentReward.videoData}
				                 sticky={false}/>
			</div> :
			<VXButton
				line1={[new VXButtonLineConfig(this.props.currentReward.needAvs ? Translations.get('common.confirm-age') : Translations.get('common.to_the_video'))]}
				color={this.getButtonColor()}
				onClick={onClickButton}
				overrideStyles={this.getButtonOverwriteStyles()}
			/>;

		return [
			this.props.currentReward.needBuying && this.props.currentReward.amount > 0 ? <div className={"agc-modal-reward_title"}
			                                           style={{fontSize: "25px"}}>{Translations.get('common.discount_on', this.props.currentReward.videoDiscount.toString())}</div> : null,
			<div className={"agc-modal-reward_title"}>„{this.props.currentReward.videoTitle}“</div>,
			<div className={"agc-modal-reward_video"}>
				{videoData && <VideoPlayer initialVideos={videoData} showBadges={false}/>}
				{this.props.currentReward.needAvs && <img className={"agc-modal-reward_video_avs"}
				                                          onClick={onClickButton}
				                                          alt={"avs"}
				                                          src={this.props.currentReward.previewPictureUrl}
				/>}
			</div>,
			button,
		];
	}

	getRewardPhotoAlbum() {
		const onClickButton = (e) => {
			ReloadHelper.reload(this.props.currentReward.albumProfileUrl, e);
			this.props.onClose();
		};

		return [
			<div className={"agc-modal-reward_title"}>„{this.props.currentReward.albumTitle}“</div>,
			<span onClick={onClickButton} className={"icon -icon-image-album-lite agc-modal-reward_photoalbum"}/>,
			<div className={"agc-modal-reward_photoalbum_info"}>{Translations.get('_event-actor-gift-calendar.free_photo_album')}</div>,
			<VXButton line1={[new VXButtonLineConfig(Translations.get('common.watch_album'))]}
			          color={this.getButtonColor()}
			          onClick={onClickButton}
			          overrideStyles={this.getButtonOverwriteStyles()}
			/>,
		];
	}

	getRewardBonuscode() {
		const onClickButton = (e) => {
			VXPay.onSuccess(() => {
				Flux.Actor.loadActorData(this.props.actorId);
				this.props.onClose();
			});

			if (this.props.currentReward.type === REWARD_TYPE_BONUSCODE_ONTOP) {
				VXPay.openPaytour({option: this.props.currentReward.value});
			} else {
				const url = UrlBuilder.buildMyVisitXBenefitsRoute() + '?code=' + this.props.currentReward.value;
				ReloadHelper.reload(url, e);
				this.props.onClose();
			}
		};

		const style = this.getButtonOverwriteStyles();
		if (this.props.currentReward.redeemed) {
			delete style.backgroundColor;
		}

		const isMoneyBonus = [REWARD_INFO_PERCENT, REWARD_INFO_FLAT].indexOf(this.props.currentReward.codeType) !== -1;

		const rewardInfo =
			      this.props.currentReward.type === REWARD_TYPE_BONUSCODE_ONTOP ? [
				      <div className={"agc-modal-reward-bonuscode_bonus " + (isMoneyBonus ? '-money-bonus' : '')}>
					      {this.buildBonusCodeReward()}
				      </div>,
				      <div className={"agc-modal-reward-bonuscode_bonus_charge"}>{Translations.get('common.on_your_next_charge', '')}</div>,

			      ] : [
				      <div className={"agc-modal-reward-bonuscode_bonus -free"}>
					      {this.buildBonusCodeReward()}
				      </div>,
			      ];

		let buttonText = Translations.get(this.props.currentReward.redeemed ? 'common.already_redeemed' : 'common.redeem_voucher_now');
		if (this.props.currentReward.isRedeemedByOther) {
			buttonText += '*';
		}

		return [
			...rewardInfo,
			<VXButton
				line1={[new VXButtonLineConfig(buttonText)]}
				color={this.props.currentReward.redeemed ? COLOR_TYPE_DISABLED : this.getButtonColor()}
				onClick={onClickButton}
				overrideStyles={style}
				disabled={this.props.currentReward.redeemed}
			/>,
		];
	}

	getRewardVoucher() {
		return [];
	}

	getRewardPhoto() {
		const onClick = (download) => {
			if (this.props.currentReward.needAvs) {
				VXPay.openAVS();
			} else {
				if (download) {
					window.open(this.props.currentReward.value + '?download=force');
				} else {
					window.open(this.props.currentReward.value, '_blank');
				}
			}
		};

		return [
			<div className={"agc-modal-reward_title"}>„{this.props.currentReward.title}“</div>,
			<div className={"agc-modal-reward-photo"}>
				<img className={"agc-modal-reward_video_avs"}
				     alt={this.props.currentReward.title}
				     src={this.props.currentReward.url}
				     onClick={() => onClick(false)}
				/>,
			</div>,
			<VXButton line1={[new VXButtonLineConfig(Translations.get(this.props.currentReward.needAvs ? 'common.confirm-age' : 'common.download'))]}
			          color={this.getButtonColor()}
			          overrideStyles={this.getButtonOverwriteStyles()}
			          onClick={() => onClick(true)}
			/>,
		];
	}

	getRewardClip() {
		const onClick = (download) => {
			if (this.props.currentReward.needAvs) {
				VXPay.openAVS();
			} else {
				if (download) {
					window.open(this.props.currentReward.value + '?download=force');
				} else {
					window.open(this.props.currentReward.value, '_blank');
				}
			}
		};

		let videoData = null;
		if (this.props.currentReward.url && !this.props.currentReward.needAvs) {
			videoData = [
				{
					videoUrls:          [
						{
							src: this.props.currentReward.url,
						},
					],
					previewPictureUrls: [],
					albumTitle:         this.props.currentReward.title,
					actorName:          this.props.actorName,
					actorUrl:           this.props.actorProfileUrl,
				},
			];
		}

		return [
			<div className={"agc-modal-reward_title"}>„{this.props.currentReward.title}“</div>,
			<div className={"agc-modal-reward_video"}>
				{videoData && <VideoPlayer initialVideos={videoData} showBadges={false}/>}
				{this.props.currentReward.needAvs && <img className={"agc-modal-reward_video_avs"}
				                                          onClick={onClick}
				                                          alt={"avs"}
				                                          src={this.props.currentReward.url}
				/>}
			</div>,
			<VXButton line1={[new VXButtonLineConfig(Translations.get(this.props.currentReward.needAvs ? 'common.confirm-age' : 'common.download'))]}
			          color={this.getButtonColor()}
			          overrideStyles={this.getButtonOverwriteStyles()}
			          onClick={() => onClick(true)}
			/>,];
	}

	buildBonusCodeReward() {
		if ([REWARD_INFO_PERCENT, REWARD_INFO_FLAT].indexOf(this.props.currentReward.codeType) !== -1) {
			return [
				<span className={"agc-modal-reward-bonuscode_bonus_amount"}>{this.props.currentReward.amount}</span>,
				<span
					className={"agc-modal-reward-bonuscode_bonus_percent"}>{this.props.currentReward.codeType === REWARD_INFO_PERCENT ? '%' : getCurrencySymbol()}</span>
			];
		}

		if (this.props.currentReward.codeType === REWARD_INFO_TICKET_SHOW) {
			return [
				<span className={"agc-modal-reward-bonuscode_bonus_ticketshow" + (this.props.isMobile ? ' -mobile' : '')}>{Translations.get('_event-actor-gift-calendar.bonuscode_ticket_show')}</span>
			];
		}

		return <span
			className={"agc-modal-reward-bonuscode" + (this.props.isMobile ? ' -mobile' : '')}>{this.props.currentReward.amount} {REWARD_INFO_TRANSLATION_MAP[this.props.currentReward.codeType]}</span>;
	}

	getFooter() {
		const footer = [];

		let isBigFooter = false;
		if (this.props.currentReward.isRedeemedByOther) {
			if(!this.props.isMobile){
				isBigFooter = true;
			}

			const bottomPx = this.props.isMobile ? '85px' : '65px';

			footer.push(<div className={"agc-modal-reward-footer"} style={{bottom: bottomPx}}>{Translations.get('_event-actor-gift-calendar.redeemed_by_other_footer')}</div>);
		}

		if (this.props.currentReward.type === REWARD_TYPE_BONUSCODE_ONTOP) {
			footer.push(<div className={"agc-modal-reward-footer" + (isBigFooter ? ' -big-footer' : '')}>{Translations.get('_event-actor-gift-calendar.bonuscode_footer_ontop')}</div>);
		} else if (this.props.currentReward.type === REWARD_TYPE_BONUSCODE) {
			footer.push(<div className={"agc-modal-reward-footer"  + (isBigFooter ? ' -big-footer' : '')}>{Translations.get('_event-actor-gift-calendar.bonuscode_footer')}</div>);
		}

		return footer;
	}

	render() {
		return (
			<Modal containerWidth={this.props.isMobile ? undefined : 900}
			       containerHeight={this.props.isMobile ? undefined : 515}
			       dynamicSize={true}
			       className={"agc-modal -theme-dialog -no-padding -relative" + (this.props.isMobile ? ' -mobile' : '')}
			       ref={this.getRef}
			       onClose={this.props.onClose}
			>
				{this.getContent()}
			</Modal>
		);
	}

}

ModalBoxActorGiftCalendar.propTypes = {
	onClose:                PropTypes.func,
	isMobile:               PropTypes.bool.isRequired,
	actorId:                PropTypes.number,
	actorName:              PropTypes.string,
	actorProfileUrl:        PropTypes.string,
	id:                     PropTypes.number,
	rewardBackground:       PropTypes.string,
	rewardBackgroundMobile: PropTypes.string,
	rewardHeadline:         PropTypes.string,
	className:              PropTypes.string,
	currentReward:          PropTypes.shape({
		type:     PropTypes.string.isRequired,
		value:    PropTypes.string.isRequired,
		amount:   PropTypes.number.isRequired,
		redeemed: PropTypes.bool.isRequired,
		// video
		videoUrl:          PropTypes.string,
		previewPictureUrl: PropTypes.string,
		videoProfileUrl:   PropTypes.string,
		videoTitle:        PropTypes.string,
		videoDuration:     PropTypes.number,
		needAvs:           PropTypes.bool,
		needBuying:        PropTypes.bool,
		videoData:         PropTypes.array,
		videoDiscount:     PropTypes.number,
		// photo_album
		albumTitle:      PropTypes.string,
		albumProfileUrl: PropTypes.string,
		// bonuscode
		code:              PropTypes.string,
		codeType:          PropTypes.string,
		isRedeemedByOther: PropTypes.bool,
		// photo / clip
		url:   PropTypes.string,
		title: PropTypes.string,
	}),
};

ModalBoxActorGiftCalendar.defaultProps = {
	onClose: () => {
	},
};

export default ModalBoxActorGiftCalendar;
