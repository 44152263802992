/* global VXConfig */
import React, {useState}                                from "react";
import PropTypes                                        from 'prop-types';
import VXButton                                         from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DISABLED} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}                             from "../SimpleElements/VXButton/VXButtonConfig";
import {
	addPreventScrolling,
	removePreventScrolling
}                                                       from '../../utils/CommonUtils';
import Translations                                     from "../../utils/Translations";
import ReloadHelper                                     from "../../utils/ReloadHelper";
import UrlBuilder                                       from "../../utils/UrlBuilder";
import LogoLoader                                       from "../Loader/LogoLoader";
import Flux                                             from "../../flux/Flux";
import FeatureFeedback                                  from '../FeatureFeedback/FeatureFeedback.js';
import FeatureFeedbackFeatureIds                        from '../FeatureFeedback/FeatureFeedbackFeatureIds.js';
import { VXPay }                                        from "../../utils/VXPay.js";

const PAGES = {
	START:  1,
	CHOOSE: 2,
	RESULT: 3,
	END:    4
};

function t(key, ...args) {
    return Translations.get('onboard-modal.' + key, args);
}

const HIDDEN_TAGS = [
    {
        id: "cupSize",
        enablerGroups: [{
            id: "gender",
            enablerTags: ["woman", "trans", "couples"]
        }]
    },
    {
        id: "penisSize",
        enablerGroups: [{
            id: "gender",
            enablerTags: ["man", "trans", "couples"]
        }]
    }
];

function getPreFilteredPreferences(tags) {
    return tags.map(group => {
        const tagGroup = HIDDEN_TAGS.find(hidden => hidden.id === group.id);
        if (tagGroup) {
            let isSelected = 0;
            tagGroup.enablerGroups.map(group => {
                group.enablerTags.map(tag => {
                    const selectedGroup = tags.find(selectedGroup => selectedGroup.id === group.id);
                    const selectedTag = selectedGroup.tags.find(selectedTag => selectedTag.id === tag);
                    if (selectedTag.isSelected) {
                        isSelected++;
                    }
                });
            });
            group.isHidden = isSelected === 0;
        }
        return group;
    });
}

const setGridRef = (ref) => {
	if (ref) {
		ref.classList.add('onboard-modal__body--grid--shadow-bottom');
		ref.addEventListener("scroll", () => {
			if (ref.scrollTop > 1) {
				ref.classList.add('onboard-modal__body--grid--shadow-top');
			} else {
				ref.classList.remove('onboard-modal__body--grid--shadow-top');
			}
		});
	}
};

export default function ModalBoxOnboarding({onClose}) {
	addPreventScrolling();

	const [currentPage, setCurrentPage] = useState(PAGES.START);
	const [tags, setTags]     = useState([...getPreFilteredPreferences(VXConfig.allGuestPreferences)]);
	const [model, setModel]   = useState({});
	const [models, setModels] = useState([]);
	const [modelIndex, setModelIndex] = useState(0);
	const [isSelected, setIsSelected] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const onClickNext = () => {
		if (currentPage === PAGES.CHOOSE) {
			setShowLoader(true);
			const preferences   = {};
			tags.map(group => {
				group.tags.map(tag => {
					if(tag.isSelected) {
						if(!preferences[group.id]) {
							preferences[group.id] = [];
						}
						preferences[group.id].push(tag.id);
					}
				});
			});

			Flux.Guest.addGuestPreferences(preferences, (res) => {
				if (res.success) {
					Flux.Vxql.getPreferencedModel({and: [{or: [{hasAvatarPicture: {eq: true}}]}]}, (models) => {
						setShowLoader(false);
						setModels(models.data.models_v3.items);
						setModel(models.data.models_v3.items[modelIndex]);
						setCurrentPage(currentPage + 1);
					});
				}
			});
		} else {
			setCurrentPage(currentPage + 1);
		}
	};

	const getSelectedTags = () => {
		const selected = [];
		tags.map(group => {
			group.tags.map(tag => {
				if(tag.isSelected) {
					selected.push(<span>{tag.value}</span>);
				}
			});
		});
		return selected;
	};

	const onClickModelProfile = (e, linkVX) => {
		beforeClose();
		ReloadHelper.reload(linkVX, e);
	};

	const onClickCams = (e) => {
		beforeClose();
		ReloadHelper.reload(UrlBuilder.buildCamsRoute(), e);
	};

	const onClickYes = () => {
		onClickNext();
	};

	const onClickNo = () => {
		if (modelIndex + 1 >= models.length) {
			setCurrentPage(PAGES.END);
		}

		const nextModelIndex = modelIndex + 1;
		setModel(models[nextModelIndex]);
		setModelIndex(nextModelIndex);
	};

	const onClickClose = (e) => {
        ReloadHelper.reload(window.location.pathname, e);
		beforeClose();
	};

    const beforeClose = () => {
		removePreventScrolling();
		onClose();
	};

	const onClickHide = () => {
        Flux.Guest.addGuestPreferences([]);
		onClickClose();
	};

    const onClickToSelection = () => {
        setModelIndex(0);
        setCurrentPage(PAGES.CHOOSE);
    };

	{/* H E A D E R */}
	const getHeaderTitle = () => {
		switch(currentPage) {
			case PAGES.START:
				return <span className="onboard-modal__header-title onboard-modal__header-title--small">{t('headline-1')}<img src="/assets/img/logo2.svg" /></span>;
			case PAGES.CHOOSE:
				return <span className="onboard-modal__header-title onboard-modal__header-title--subline">{t('headline-2')} <span>{t('headline-2-subline')}</span></span>;
			case PAGES.RESULT:
				return <span className="onboard-modal__header-title">{t('headline-3', model.name)}</span>;
			case PAGES.END:
				return <span></span>;
		}
	};

	const getHeader = () => <div className="onboard-modal__header">
			{getHeaderTitle()}
			<i className="icon -icon-close-full onboard-modal__close" onClick={onClickClose}></i>
	</div>;

	{/* B O D Y */}
	const selectedTag = (categorie, tagId) => {
		let selectedCount = 0;
		const groups = [...tags];
		groups.map(group => {
			group.tags.map(tag => {
				if(tag.id === tagId && group.id === categorie) {
					tag.isSelected = !tag.isSelected;
				}
				if (tag.isSelected) {
					selectedCount++;
				}
			});
		});
		if (selectedCount > 0) {
			setIsSelected(true);
		} else {
			setIsSelected(false);
		}
		setTags(getPreFilteredPreferences(groups));
	};

	const getPageEndWithModel = (model) => (
		model ? <>
			<div className="onboard-modal__body-subtitle">{t('end-subline', model.name)}</div>
                <div className="onboard-modal__body-profile__img--wrapper">
			        <div className="onboard-modal__body-profile">
                        <div className={"onboard-modal__body-profile__img" + (model.isOnline ? ' onboard-modal__body-profile__img--online' : '')}>
                            <img src={model.avatar.url} />
                        </div>
                        <div onClick={(e) => onClickModelProfile(e, model.linkVX)} className="onboard-modal__body-profile__name">{t('to-profile', model.name)}</div>
                    </div>
                    <VXButton
						color={COLOR_TYPE_CALL_TO_ACTION}
						line1={[new VXButtonLineConfig(t('button-to-cams'))]}
						onClick={onClickCams}
						staticMode={true}
					/>
			</div>
		</> :
        <VXButton
            color={COLOR_TYPE_CALL_TO_ACTION}
            line1={[new VXButtonLineConfig(t('button-to-cams'))]}
            onClick={onClickCams}
            staticMode={true}
		/>
	);

	const getFeatureFeedback = () => (<FeatureFeedback featureId={FeatureFeedbackFeatureIds.MODAL_ONBOARDING}
	                                                   additionalClasses={VXConfig.isVXMobilePlatform ? 'onboard-modal__feedback-mobile' : 'onboard-modal__feedback-desktop'}
	                                                   title={Translations.get('feedback-title')} />);

	const getBody = () => {
		switch(currentPage) {
			case PAGES.START:
				return <div className="onboard-modal__body">
					<div className="onboard-modal__body-img__wrapper">
						<img className="onboard-modal__body-img" src="/assets/img/modal/onboarding/htg_desktop.jpg" />
					</div>
					<div className="onboard-modal__body-title" dangerouslySetInnerHTML={{__html: t('welcome-headline', VXConfig?.guestName)}} />
					<div className="onboard-modal__body-subtitle">{t('welcome-subline')}</div>
				</div>;
			case PAGES.CHOOSE:
				return <div className={"onboard-modal__body" + (tags.length > 0 ? ' onboard-modal__body--grid' : ' onboard-modal__body--full')} ref={ref => setGridRef(ref)}>
					{tags.map((group, index) =>
						!group.isHidden && (<div className="onboard-modal__body-group" key={index}>
							<div className="onboard-modal__body-group__title">{group.category}</div>
							<div className="onboard-modal__body-group__tags">
								{group.tags.map((tag, idx) => <div onClick={() => selectedTag(group.id, tag.id)} className={"onboard-modal__body-group__tag" + (tag.isSelected ? ' onboard-modal__body-group__tag--selected' : '')} key={idx}>{tag.value}</div>)}
							</div>
						</div>)
					)}
                    {tags.length === 0 && !Flux.Guest.isLoggedIn() && <VXButton
						color={COLOR_TYPE_CALL_TO_ACTION}
						line1={[new VXButtonLineConfig(t('login'))]}
						onClick={VXPay.openSignupOrLogin}
						staticMode={true}
					/>}
				</div>;
			case PAGES.RESULT:
				return <div className="onboard-modal__body">
					<div className="onboard-modal__body-img__wrapper">
						<img className="onboard-modal__body-img" src={model.avatar.url} />
					</div>
                    <div className="onboard-modal__body-voting__wrapper">
                        <div className="onboard-modal__body-voting__name"> {model.name} <span>({model.profile.age})</span> </div>
                        <div className="onboard-modal__body-voting__tags">{getSelectedTags()}</div>
                        <div className="onboard-modal__body-voting__buttons">
                            <div onClick={onClickNo} className="onboard-modal__body-voting__button onboard-modal__body-voting__button--red"><i className="icon -icon-close-full" /></div>
                            <div onClick={onClickYes} className="onboard-modal__body-voting__button onboard-modal__body-voting__button--green"><i className="icon -icon-success-rounded-full" /></div>
                        </div>
                    </div>
				</div>;
			case PAGES.END:
				return <div className="onboard-modal__body">
					{VXConfig.isVXMobilePlatform && getFeatureFeedback()}
                    {model && <i className="icon -icon-round-success-line onboard-modal__body-icon" />}
					<div className="onboard-modal__body-title" dangerouslySetInnerHTML={{__html: model ? t('end-headline') : t('end-headline-failed')}} />
					{getPageEndWithModel(model)}
				</div>;
		}
	};

	{/* F O O T E R */}
	const getFooter = () => <div className="onboard-modal__footer">
			<div className="onboard-modal__footer-button">
				<VXButton
					color={currentPage === PAGES.CHOOSE && !isSelected ? COLOR_TYPE_DISABLED : COLOR_TYPE_CALL_TO_ACTION}
					line1={[new VXButtonLineConfig(t(currentPage === PAGES.START ? 'button-start-now' : 'button-next'))]}
					onClick={onClickNext}
					staticMode={true}
					disabled={currentPage === PAGES.CHOOSE && !isSelected}
				/>
			</div>
			{currentPage === PAGES.START && <div onClick={onClickHide} className="onboard-modal__footer-text">{t('do-not-show-again')}</div>}
	</div>;

	return <div className="onboard-modal__wrapper">
		<div className={"onboard-modal onboard-modal--" + currentPage}>
			{showLoader && <LogoLoader pulse={true} overlayClassName="-absolute" showOverlay />}
			{getHeader()}
			<div className="onboard-modal__content">
				{currentPage === PAGES.END && !VXConfig.isVXMobilePlatform && getFeatureFeedback()}
				{getBody()}
				{currentPage < PAGES.RESULT && (currentPage === PAGES.CHOOSE ? (tags.length > 0 && Flux.Guest.isLoggedIn()) : true) && getFooter()}
                {currentPage === PAGES.END && !model && <div className="onboard-modal__footer"><div onClick={onClickToSelection} className="onboard-modal__footer-text">{t('back-to-selection')}</div></div>}
			</div>
		</div>
	</div>;
}

ModalBoxOnboarding.propTypes = {
	onClose: PropTypes.func,
};

ModalBoxOnboarding.defaultProps = {
	onClose: () => {},
};
