import React               from 'react';
import PropTypes           from 'prop-types';
import Flux                from '../../flux/Flux';
import Translations        from '../../utils/Translations';
import VotingThumbs        from '../../components/VotingThumbs';
import Voting              from '../../components/Voting';
import {VXPay}             from '../../utils/VXPay';
import ReloadHelper        from '../../utils/ReloadHelper';
import PictureBoxes        from './PictureBoxes';
import VideochatButton     from '../SimpleElements/VideochatButton';
import DildocontrolOverlay from "./DildocontrolOverlay";
import Constants           from '../../flux/Constants';

class ChatTile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			chatRatingText:  (this.props.chatRating > 0) ? Translations.get('ChatTileVotedWith') : Translations.get('ChatTileVoteNow'),
			isVotingVisible: false,
		};

		this.clickFunc       = this.clickFunc.bind(this);
		this.onClick         = this.onClick.bind(this);
		this.openMessengerFn = this.openMessengerFn.bind(this);
		this.toggleVoting    = this.toggleVoting.bind(this);
	}

	clickFunc(rating, e) {

		Flux.Survey.storeChatRating(this.props.chatId, this.props.actorId, rating, '', this.props.isMobile);
		if (e.preventDefault) {
			e.preventDefault();
		}
		if (e.stopPropagation) {
			e.stopPropagation();
		}
		this.setState({
			chatRatingText:  Translations.get('ChatTileVotedWith'),
			isVotingVisible: true,
		});
		return false;
	}

	onClick(e) {
		ReloadHelper.reload(this.props.profileLink, e);
	}

	openMessengerFn(e) {
		const actorId = this.props.actorId;

		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(actorId, true);
		} else {
			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				});
			} else {
				VXPay.openSignupOrLogin();
			}
		}
		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (typeof this.props.onOpenMessengerClick === 'function') {
			this.props.onOpenMessengerClick();
		}

		return false;
	}

	toggleVoting() {
		this.setState({
			isVotingVisible: !this.state.isVotingVisible,
		});
	}

	render() {
		let responsiveMultiplier = 2;

		let contentBoxClassName = 'context-box--media-box clearfix show-on-Tablet';

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		if (this.props.isMobile || Flux.Browser.getWindowSize().width <= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg) {
			responsiveMultiplier = 1;
		}

		const outerClassName = 'context-box--media-box__picture' + (this.props.useRatio ? ' box--fixed-ratio' : '');
		const innerClassName = this.props.useRatio ? 'box--fixed-ratio__content' : '';
		const pictureBoxes   = <PictureBoxes pictures={this.props.previewPictureUrls}
		                                     useIndexForKey={true}
		                                     outerClassName={outerClassName}
		                                     innerClassName={innerClassName}
		                                     responsiveMultiplier={responsiveMultiplier}
		                                     boxRatio={4 / 3}
		                                     usePlaceholder={true}
		                       />;

		const chatRatingRefFn = (content) => {
			this.chattext = content;
		};

		const tileClassName = this.props.className ? this.props.className : 'col-xs-6 col-lg-3';

		let contactClass = '';
		if (this.props.isMobile || Flux.Browser.getWindowSize().width <= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg) {
			contactClass = this.state.isVotingVisible ? ' is-visible' : ' -semitransparent';
		}

		const profileButton = <button className="btn--icon-detailed -icon-user-full -size-sm -click-material -twobuttonsarow"
		                              data-actor-name={this.props.actorName}
		                      >
			<span className="ink animate" />
			{Translations.get('ActorGridProfileText')}
			<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridLearnMoreText')}</span>
		</button>;

		let profileButtonWrapper = profileButton;
		if (this.props.isMobile || Flux.Browser.getWindowSize().width <= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg) {
			profileButtonWrapper = <a href={this.props.profileLink} onClick={this.onClick} className="-visible-on-hover">
				{profileButton}
			</a>;
		}

		const tileContent = <div className={contentBoxClassName}>
			<div className="context-box--media-box__picture box--fixed-ratio -ratio4_3">
				<div className="box--fixed-ratio__content">
					{pictureBoxes}
					{this.props.isDildocontrolAvailable && this.props.isOnline && <DildocontrolOverlay />}
				</div>
			</div>
			<div className={'actorcontact__container' + contactClass}
			     onClick={this.toggleVoting}
			>
				<div className="actorcontact -paddingtop-small">
					<div>
						<div className="context-box--media-panel__actorchattile--text">
							<span className={'context-box--media-panel__voting--headline' + (this.props.isMobile || Flux.Browser.getWindowSize().width <= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg ? ' -visible-on-hover' : '')}
							      id="js-chat-text"
							      ref={chatRatingRefFn}
							>
								{this.state.chatRatingText}
							</span>
							<span id="js-show-voting"
							      data-voting-value={this.props.chatRating}
							      data-actor-id={this.props.actorId}
							      data-chat-id={this.props.chatId}
							>
								{this.props.useThumbsVoting || Flux.Browser.getWindowSize().width <= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg ?
									(<VotingThumbs onVoteClick={this.clickFunc}
								        initialRating={this.props.chatRating} />) : 
									(<Voting onVoteClick={this.clickFunc}
								        initialRating={this.props.chatRating}
								        frozen={false}
								        readonly={((!this.state.isVotingVisible && this.props.isMobile) || this.props.chatRating > 0)}
										onetime={true}/>)
								}
							</span>
						</div>
					</div>
					{profileButtonWrapper}
					{(this.props.isOnline || this.props.isOnlineMobileVideocall) && <VideochatButton
						multiChatPrice={this.props.multiChatPrice}
						actorId={this.props.actorId}
						isActorOnline={this.props.isOnline}
						isVoyeurMode={false}
						buttonText={Translations.get('ActorGridVideoText')}
						additionalClassNames={'-click-material -size-sm -twobuttonsarow -visible-on-hover'}
						isCurrentlyTicketShow={this.props.isCurrentlyTicketShow}
						guestHasTicket={this.props.guestHasTicket}
						isOnlineMobileVideocall={this.props.isOnlineMobileVideocall}
					                        />}
					{!this.props.isOnline && <button onClick={this.openMessengerFn}
					                                 className="btn--icon-detailed -btn-color- -icon-chat-text-full -click-material -size-sm -twobuttonsarow -visible-on-hover"
					                         >
						<span className="ink animate -visible-on-hover" />
						{Translations.get('ActorGridMessageText')}
						<span className="btn--icon-detailed__teaser -visible-on-hover">
							{Translations.get('ActorGridWriteFreeText')}
						</span>
					</button>}
				</div>
			</div>
			{this.props.children}
		</div>;

		let tileWrapper = tileContent;
		if (Flux.Browser.getWindowSize().width >= Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg) {
			tileWrapper = <a href={this.props.profileLink} onClick={this.onClick} className="-visible-on-hover">
				{tileContent}
			</a>;
		}

		return (
			<div className={tileClassName}>
				{tileWrapper}
			</div>
		);
	}
}

ChatTile.propTypes = {
	actorId:                 PropTypes.number.isRequired,
	actorName:               PropTypes.string.isRequired,
	chatId:                  PropTypes.number.isRequired,
	chatRating:              PropTypes.number.isRequired,
	className:               PropTypes.string,
	isMobile:                PropTypes.bool,
	isOnline:                PropTypes.bool.isRequired,
	isDildocontrolAvailable: PropTypes.bool,
	profileLink:             PropTypes.string.isRequired,
	multiChatPrice:          PropTypes.number.isRequired,
	usePjax:                 PropTypes.bool,
	onOpenMessengerClick:    PropTypes.func,
	contentBoxClassModifier: PropTypes.string,
	useRatio:                PropTypes.bool,
	useThumbsVoting:         PropTypes.bool,
	isCurrentlyTicketShow:   PropTypes.bool,
	guestHasTicket:          PropTypes.bool,
	isOnlineMobileVideocall: PropTypes.bool,
	previewPictureUrls:      PropTypes.arrayOf(
		PropTypes.array
	),
	children:                PropTypes.node,
};

export default ChatTile;
