import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';
import Flux         from '../../../flux/Flux';
import Vip30ButtonBadge from '../../Vip30ButtonBadge.js';

const AlbumButtonVoucher = (props) =>
	<div className={`${props.baseClassName}__button--buy ${props.baseClassName}__button--buy-voucher`} onClick={props.onClick}>
        {props.isVip30 && Flux.Guest.isLoggedIn() && Flux.Guest.isVip() && <Vip30ButtonBadge />}
		<div className={`${props.baseClassName}__icon--button icon -icon-cart-full`} />
		<div className={`${props.baseClassName}__text--price`}>{props.voucherPrice}</div>
		<div className={`${props.baseClassName}__text--button`}>{'VOUCHER ' + Translations.get('Redeem').toUpperCase()}</div>
	</div>
;

AlbumButtonVoucher.propTypes = {
	onClick:       PropTypes.func,
	baseClassName: PropTypes.string,
	voucherPrice:  PropTypes.number,
    isVip30:       PropTypes.bool,
};

export default AlbumButtonVoucher;
