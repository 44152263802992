import React     from 'react';
import PropTypes from 'prop-types';

const getImagePath = (rating, selected) => `/assets/img/feature_feedback/${rating.toLowerCase()}_${selected ? 2 : 1}.svg`;

const imageClassNameHidden  = "feature-feedback__popup__rating-button__image";
const imageClassNameVisible = "feature-feedback__popup__rating-button__image is-visible";

const FeatureFeedbackPopupRatingButton = ({isSelected, rating, onClick}) => (
	<div onClick={onClick}
	     className="feature-feedback__popup__rating-button"
	>
		<img className={isSelected ? imageClassNameHidden : imageClassNameVisible} src={getImagePath(rating, false)} />
		<img className={(isSelected ? imageClassNameVisible : imageClassNameHidden) + ' is-selected'} src={getImagePath(rating, true)} />
	</div>
);

FeatureFeedbackPopupRatingButton.propTypes = {
	isSelected: PropTypes.bool,
	rating:     PropTypes.string,
	onClick:    PropTypes.func,
};

export default FeatureFeedbackPopupRatingButton;