import React         from 'react';
import PropTypes     from 'prop-types';
import ProfileButton from './ProfileButton';
import Flux          from '../../flux/Flux';
import {VideoPlayer}   from "../Gallery/VideoPlayer";

/**
 * @link https://zinoui.com/blog/javascript-fullscreen-api
 * @link https://codepen.io/t1gor/pen/EMWyWO?editors=0010
 */
class Player extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			clicked:                 false,                                     // muted flag
			isVideoPlayerFullScreen: (window.innerHeight === screen.height),
			pauseEventReceived:      false,                                     // user clicked pause button
			videoClickEventReceived: false,                                     // indeed user clicked pause button
		};

		this.updateFullScreen     = this.updateFullScreen.bind(this);
		this.onVideoClick         = this.onVideoClickHandler.bind(this);
		this.onPauseEventReceived = this.onPauseEventReceived.bind(this);
		this.onRefChange          = this.onRefChange.bind(this);

		this.profileButtonRef = null;
		this.videoRef         = null;
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.updateFullScreen);
	}

	componentWillUnmount() {
		if (this.player) {
			this.player.dispose();
		}
		Flux.Browser.removeWindowResizeListener(this.updateFullScreen);
	}

	/**
	 * @todo move to WelcomeClipRotator (via Flux)
	 */
	updateFullScreen() {
		this.setState({
			isVideoPlayerFullScreen: window.innerHeight === screen.height ? true : null,
		});

		if (this.state.isVideoPlayerFullScreen && document.getElementById('clipRotatorVideoPlayer')) {
			document.getElementById('clipRotatorVideoPlayer').classList.add('welcome-clip-rotator__video-player--show-controls-in-full-width');
		} else if (document.getElementById('clipRotatorVideoPlayer')) {
			document.getElementById('clipRotatorVideoPlayer').classList.remove('welcome-clip-rotator__video-player--show-controls-in-full-width');
		}
	}

	onPauseEventReceived() {
		if (!this.state.videoClickEventReceived && !this.state.pauseEventReceived) {
			this.setState({
				pauseEventReceived: true,
			});
		}
	}

	onVideoClickHandler() {
		// if not pause button clicked
		
	}

	onRefChange(ref) {
		this.videoRef = ref;
		if (ref) {
			this.videoRef.shaka.video.pause();
		}
	}

	render() {
		const {showCountDown, playlistItem} = this.props;
		const videoWrapperProps    = {
			onPause: this.onPauseEventReceived,
		};

		if (!this.state.clicked) {
			videoWrapperProps.onClick = this.onVideoClick;
		}

		if (!playlistItem) {
			return null;
		}

		const videos = [
			{
				previewPictureUrls: [{pictureUrl: playlistItem.previewPictureUrl}],
				videoUrls:          playlistItem.videoSources,
			},
		];
		const buttonElement = document.querySelector('.welcome-clip-rotator__profile-button-caption');
		let additionalUIStyle = {};
		if (buttonElement) {
			additionalUIStyle = {paddingRight: buttonElement.clientWidth + 40 + 'px'};

		}
		const profileButton = <ProfileButton {...playlistItem} />;
		
		if (this.videoRef && this.props.autoplay) {
			this.videoRef.shaka.video.play();
		} else if (this.videoRef) {
			this.videoRef.shaka.video.pause();
		}

		return (
			<div className="welcome-clip-rotator__video-player-container" {...videoWrapperProps}>
				<VideoPlayer initialVideos={videos} 
					autoplay={true} 
					containerHeight={450} 
					onEnded={this.props.onEnded} 
					profileButton={profileButton}
					outerContainerClass={'h-pos-relative'} 
					additionalUIStyle={additionalUIStyle} 
					showBigPlayButton={!showCountDown}
					ref={this.onRefChange}
					         showSignupButton={false}/>
			</div>
		);
	}
}

Player.propTypes = {
	autoplay:       PropTypes.bool,
	blur:           PropTypes.bool,
	muted:          PropTypes.bool,
	onBigPlayClick: PropTypes.func,
	onError:        PropTypes.func,
	onEnded:        PropTypes.func,
	onPlaying:      PropTypes.func,
	onVolumeChange: PropTypes.func,
	onClick:        PropTypes.func,
	playlistItem:   PropTypes.object,
	showCountDown:  PropTypes.bool,
};

Player.defaultProps = {
	autoplay:     true,
	blur:         false,
	muted:        true,
	playlistItem: {},
};

export default Player;
