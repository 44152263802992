import React                from 'react';
import PropTypes            from 'prop-types';
import ReactTooltip         from 'react-tooltip';
import Flux                 from '../../../flux/Flux';
import Translations         from '../../../utils/Translations';
import {VXPay, VXPayAction} from '../../../utils/VXPay';


class Pin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isFavoriteOfGuest: this.props.initialIsFavoriteOfGuest,
		};

		this.onActorAdded    = this.onActorAdded.bind(this);
		this.onActorRemoved  = this.onActorRemoved.bind(this);
		this.onButtonClicked = this.onButtonClicked.bind(this);
		this.togglePin       = this.togglePin.bind(this);
	}

	componentDidMount() {
		Flux.Favorite.addActorPinnedListener(this.onActorAdded);
		Flux.Favorite.addActorUnpinnedListener(this.onActorRemoved);

		ReactTooltip.rebuild();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.initialIsFavoriteOfGuest !== this.props.initialIsFavoriteOfGuest) {
			this.setState({
				isFavoriteOfGuest: nextProps.initialIsFavoriteOfGuest,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (this.props.actorId !== nextProps.actorId || this.props.initialIsFavoriteOfGuest !== nextProps.initialIsFavoriteOfGuest || this.state.isFavoriteOfGuest !== nextState.isFavoriteOfGuest);
	}

	componentWillUnmount() {
		Flux.Favorite.removeActorPinnedListener(this.onActorAdded);
		Flux.Favorite.removeActorUnpinnedListener(this.onActorRemoved);
	}

	onActorAdded(actorId) {
		if (actorId === this.props.actorId) {
			this.setState({
				isFavoriteOfGuest: true,
			});
		}
	}

	onActorRemoved(actorId) {
		if (actorId === this.props.actorId) {
			this.setState({
				isFavoriteOfGuest: false,
			});
		}
	}

	togglePin() {
		if (this.state.isFavoriteOfGuest) {
			Flux.Favorite.unpinActor(this.props.actorId);
		} else {
			Flux.Favorite.pinActor(this.props.actorId);
		}
		this.setState({
			isFavoriteOfGuest: !this.state.isFavoriteOfGuest,
		});
	}

	onButtonClicked(e) {
		if (!Flux.Guest.isLoggedIn()) {
			const action = new VXPayAction(Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ACTOR, {
				targetId: this.props.actorId,
			});
			VXPay.openSignupOrLogin({
				host: this.props.actorId,
			}, action);
		} else if (typeof this.props.onClickCallback === 'function') {
			this.props.onClickCallback();
		} else {
			this.togglePin();
		}
		if (e.preventDefault) {
			e.preventDefault();
		}
		if (e.stopPropagation) {
			e.stopPropagation();
		}
	}

	render() {
		if (!this.props.actorId) {
			return null;
		}

		let classes = '';
		let title   = '';
		if (this.state.isFavoriteOfGuest) {
			classes = 'icon -icon-heart-full';
			title   = Translations.get('favPinRemoveFromFav');
		} else {
			classes = 'icon -icon-heart-line';
			title   = Translations.get('favPinAddToFav');
		}
		if (this.props.fontSize) {
			classes = classes + " " + this.props.fontSize;
		}

		const label = <i data-tip={title} className={classes}/>;

		return (
			<span className="favorite-actor-pin h-color-highlight h-cursor-pointer -txtsize" onClick={this.onButtonClicked}>{label}</span>
		);
	}

}

Pin.propTypes = {
	actorId:                  PropTypes.number.isRequired,
	initialIsFavoriteOfGuest: PropTypes.bool.isRequired,
	onClickCallback:          PropTypes.func,
	fontSize:                 PropTypes.string,
};

export default Pin;
