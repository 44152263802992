/* global VXConfig */

import React                        from 'react';
import PropTypes                    from "prop-types";
import FeatureFeedbackButton        from "./FeatureFeedbackButton";
import FeatureFeedbackPopup         from "./FeatureFeedbackPopup";
import Flux                         from "../../flux/Flux";
import FeatureFeedbackPopupBackdrop from "./FeatureFeedbackPopupBackdrop";
import Translations                 from '../../utils/Translations';

const getInitialState = () => ({
	popupOpen:             false,
	displaySuccessMessage: false,
	selectedRating:        null,
	text:                  '',
	ignoreHideCondition:   false,
	hideToggleButton:      false,
});

class FeatureFeedback extends React.Component {

	constructor(props) {
		super(props);

		this.state = getInitialState();

		this.onTogglePopup  = this.onTogglePopup.bind(this);
		this.onSelectRating = this.onSelectRating.bind(this);
		this.onChangeText   = this.onChangeText.bind(this);
		this.onSubmit       = this.onSubmit.bind(this);
		this.onSuccess      = this.onSuccess.bind(this);
		this.onGuestChange  = this.onGuestChange.bind(this);
	}

	componentDidMount() {
		Flux.Guest.addChangeListener(this.onGuestChange);
	}

	componentWillUnmount() {
		Flux.Guest.removeChangeListener(this.onGuestChange);
	}

	onGuestChange() {
		this.forceUpdate();
	}

	onTogglePopup() {
		this.setState(state => ({
			popupOpen: !state.popupOpen,
		}));
	}

	onSelectRating(selectedRating) {
		this.setState({selectedRating});
	}

	onChangeText(text) {
		this.setState({text});
	}

	onSuccess() {
		this.setState({
			displaySuccessMessage: true,
			ignoreHideCondition:   true,
		});

		setTimeout(() => {
			this.setState({
				popupOpen:        false,
				hideToggleButton: true,
			});

			setTimeout(() => this.setState(getInitialState()), 300); // Wait for the hiding animation to finish before resetting state
		}, 2000);

		Flux.Guest.removeFeatureFeedbackSentListener(this.onSuccess);
	}

	hasGuestSentFeedbackAlready() {
		const hasGuestSentFeedbackAlready = Flux.Guest.getActiveFeatureIdsWithFeedback().indexOf(this.props.featureId) !== -1;
		if (this.props.parentCallback) {
			this.props.parentCallback(hasGuestSentFeedbackAlready);
		}
		return hasGuestSentFeedbackAlready;
	}

	onSubmit() {
		Flux.Guest.addFeatureFeedbackSentListener(this.onSuccess);

		Flux.Guest.sendFeatureFeedback(this.props.featureId, this.state.selectedRating, this.state.text, window.location.href);
	}

	render() {
		if (!this.state.ignoreHideCondition && (!Flux.Guest.isLoggedIn() || this.hasGuestSentFeedbackAlready())) {
			return null;
		}

		let featureFeedbackButton;
		if (this.props.hasLabel) {
			featureFeedbackButton =
				<div className="feature-feedback__button-label" onClick={this.onTogglePopup}>
					<p>{Translations.get('HomepageFeatureFeedback')}</p>
					<FeatureFeedbackButton popupOpen={this.state.popupOpen || this.state.hideToggleButton}
					                       hide={this.state.hideToggleButton}
					                       additionalButtonClass={this.props.additionalClasses}
					/>
				</div>;
		} else {
			featureFeedbackButton = <FeatureFeedbackButton popupOpen={this.state.popupOpen || this.state.hideToggleButton}
			                                               onClick={this.onTogglePopup}
			                                               hide={this.state.hideToggleButton}
			                                               additionalButtonClass={this.props.additionalClasses}
			/>;
		}

		return (
			<div className={"feature-feedback " + this.props.additionalClasses}>
				{featureFeedbackButton}
				{VXConfig.isVXMobilePlatform ?
					<FeatureFeedbackPopupBackdrop open={this.state.popupOpen} onClick={this.onTogglePopup} />
					: null}

				<FeatureFeedbackPopup open={this.state.popupOpen}
				                      title={this.props.title}
				                      selectedRating={this.state.selectedRating}
				                      text={this.state.text}
				                      displaySuccessMessage={this.state.displaySuccessMessage}
				                      onSelectRating={this.onSelectRating}
				                      onChangeText={this.onChangeText}
				                      onSubmit={this.onSubmit}
				                      additionalPopupClass={this.props.additionalClasses}
				/>
			</div>
		);
	}

}

FeatureFeedback.propTypes = {
	featureId:         PropTypes.number,
	title:             PropTypes.string,
	additionalClasses: PropTypes.string,
	parentCallback:    PropTypes.func,
	hasLabel:          PropTypes.bool,
};

FeatureFeedback.defaultProps = {
	additionalClasses: '',
};

export default FeatureFeedback;