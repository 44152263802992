/* global VXConfig */
import React        from 'react';
import PropTypes    from 'prop-types';
import 'picturefill';
import 'lazysizes';
import Translations from '../../utils/Translations';
import Flux         from '../../flux/Flux.js';

class PictureBoxes extends React.Component {

	constructor(props) {
		super(props);

		this.imageElements = {};
	}

	componentWillMount() {
		this.getCurrentImageSource = this.getCurrentImageSource.bind(this);
		this.onImageLoad           = this.onImageLoad.bind(this);
		this.onImageRef            = this.onImageRefReady.bind(this);
	}

	getCurrentImageSource(index) {
		let currentSrc = this.imageElements[index] ? this.imageElements[index].currentSrc : null;

		if (currentSrc && currentSrc.indexOf('transparent.gif') >= 0) {
			currentSrc = null;
		}

		return currentSrc;
	}

	onImageLoad(index) {
		if (typeof this.props.onImageLoad === 'function') {
			this.props.onImageLoad(index, this.getCurrentImageSource(index));
		}
	}

	/**
	 * @param {Element|null} imgRef
	 */
	onImageRefReady(imgRef) {
		if (null === imgRef) {
			return;
		}

		this.imageElements[imgRef.getAttribute('data-idx')] = imgRef;
	}

	render() {
		const pictures = this.props.pictures;
		const length   = pictures.length;
		const contestStartDate = this.props.contestStartDate;
		const isActiveContest = this.props.isActiveContest;
		const isNextContest = this.props.isNextContest;
		const contestMediaType = this.props.contestMediaType;
		if (length === 0) {
			pictures.push([{
				format:     'w0',
				height:     0,
				id:         0,
				isPortrait: this.props.usePlaceholder,
				src:        this.props.usePlaceholder ? Flux.Constants.Placeholders.Avatar.FEMALE : '/assets/img/transparent.gif',
				width:      0,
			}]);
		} else if (length < 3 && this.props.isPhotoAlbumGridTile && !this.props.useTileWithSecondary && !this.props.isMobile) {
			for (let i = 0; i < (4 - length); i++) {
				pictures.push([{
					format:     'w0',
					height:     0,
					id:         0,
					width:      0,
					isPortrait: false,
					src:        '',
				}]);
			}
		}

		const pictureBoxes = pictures.map((pictureUrls, idx) => {
			const endIndex      = pictureUrls.length - 1;
			const reactKeyIndex = this.props.useIndexForKey ? '-' + idx : '';
			const reactKey      = 'picture-' + pictureUrls[endIndex].id + reactKeyIndex;
			let isPlaceHolder   = false;

			let outerClassName = this.props.outerClassName;
			if (this.props.useIndexForClassName) {
				outerClassName += ' -pos-' + idx;
			}

			if (pictureUrls[endIndex].src.indexOf('/assets/img/user/placeholder_') === 0) {
				outerClassName += ' -is-placeholder';
				isPlaceHolder = true;
			}

			const width         = pictureUrls[endIndex].width;
			const height        = pictureUrls[endIndex].height;
			const ratio         = height > 0 ? (width / height) : 0;
			const maximizeWidth = pictureUrls[endIndex].isPortrait || ratio && this.props.boxRatio && (ratio - this.props.boxRatio) < 0;

			let imgStyle;

			if (maximizeWidth || this.props.setMaxWidth) {
				imgStyle = {'width': '100%', 'height': 'auto'};
			} else {
				imgStyle = {'height': '100%', 'width': 'auto'};
			}

			const pictureUrlsCopy = pictureUrls.slice(0).reverse();

			const sourceElements = pictureUrlsCopy.map((pictureUrl, idx, reversedPictureUrls) => {
				const width      = pictureUrl.format.substr(1);
				let srcSetUrls;
				if(this.props.isLastContest && VXConfig.isVXMobilePlatform){
					srcSetUrls = this.props.lastContestImg;
				}
				else if(!isNextContest || !VXConfig.isVXMobilePlatform){
					srcSetUrls = (idx === 0) ? pictureUrl.src : (pictureUrl.src + ', ' + reversedPictureUrls[idx - 1].src + ' 2x');
				}
				else {
					srcSetUrls = this.props.nextContestPreview;
				}

				let minWidth   = isPlaceHolder ? 320 : this.props.responsiveMultiplier * width;
				if (pictureUrlsCopy.length === 1){
					minWidth = 0;
				}

				return (<source key={pictureUrl.id + "-" + pictureUrl.format}
				                media={"(min-width: " + minWidth + "px)"}
				                data-srcset={srcSetUrls}
				        />);
			});

			const onImageLoad = () => {
				this.onImageLoad(idx);
			};

			let picture = (
				<picture key={pictureUrls[0].src}>
					{sourceElements}
					<img
						data-idx={idx}
						className="lazyload context-box--media-box__img"
						src={'/assets/img/transparent.gif'}
						style={imgStyle}
						ref={this.onImageRef}
						onLoad={onImageLoad} // eslint-disable-line react/jsx-no-bind
						loading={this.props.lazyImageLoading ? 'lazy' : 'eager'}
					/>
				</picture>
			);

			if (this.props.innerClassName !== null) {
				picture = (
					<div className={this.props.innerClassName}>
						{picture}
					</div>
				);
			}

			return (
				<div key={reactKey} className={outerClassName}>
					{(isNextContest && !isActiveContest && VXConfig.isVXMobilePlatform) &&
					<div className="next-contest-header">
						{contestMediaType === "video" && <span>{Translations.get("ContestsNextVideoContest")}</span>}
						{contestMediaType === "photo" && <span>{Translations.get("ContestsNextPhotoContest")}</span>}
						<span> {contestStartDate}</span>
					</div>}
					{picture}
				</div>
			);
		});

		return (
			<div className={this.props.containerClassName}>
				{pictureBoxes}
			</div>
		);
	}
}

PictureBoxes.propTypes = {
	boxRatio:             PropTypes.number,
	containerClassName:   PropTypes.string,
	innerClassName:       PropTypes.string,
	onImageLoad:          PropTypes.func,
	outerClassName:       PropTypes.string,
	pictures:             PropTypes.arrayOf(
		PropTypes.array
	),
	responsiveMultiplier: PropTypes.number,
	useIndexForKey:       PropTypes.bool,
	useIndexForClassName: PropTypes.bool,
	setMaxWidth:          PropTypes.bool,
	usePlaceholder:       PropTypes.bool,
	nextContestPreview:   PropTypes.string,
	isNextContest:        PropTypes.bool,
	contestStartDate:     PropTypes.string,
	isActiveContest:      PropTypes.bool,
	contestMediaType:     PropTypes.string,
	isLastContest:        PropTypes.bool,
	lastContestImg:       PropTypes.string,
	isPhotoAlbumGridTile: PropTypes.bool,
	useTileWithSecondary: PropTypes.bool,
	isMobile:             PropTypes.bool,
	lazyImageLoading:     PropTypes.bool,
};

PictureBoxes.defaultProps = {
	pictures:             [],
	boxRatio:             0,
	containerClassName:   '',
	innerClassName:       null,
	responsiveMultiplier: 1,
	useIndexForKey:       false,
	useIndexForClassName: false,
	setMaxWidth:          false,
	usePlaceholder:       false,
	isPhotoAlbumGridTile: false,
	useTileWithSecondary: false,
	isMobile:             false,
	lazyImageLoading:     false,
};

export default PictureBoxes;