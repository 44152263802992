const LEFT  = 'left';
const RIGHT = 'right';

const STYLES = {
	BOLD:        1,
	LIGHT:       2,
	ITALIC:      3,
	UNDERLINE:   4,
	LINETHROUGH: 5,
	UPPERCASE:   6,

	COLORS: {
		WHITE: 'fg',
		MOOD1: 'm1',
		MOOD2: 'm2',
		MOOD3: 'm3',
		MOOD4: 'm4',
		MOOD5: 'm5',
		MOOD6: 'm6',
	},

	BORDER: {
		NONE:    0,
		OUTLINE: 1,
	},

	SIZE: {
		TINY:   'tiny',
		SMALL:  'small',
		MEDIUM: 'medium',
		BIG:    'big',
		FULL:   'full',
	},

	PRESET: {
		OUTLINE: 'outline',
		DEFAULT: 'default',
	},
	ICON_POSITION: {
		LEFT,
		RIGHT,
	},
	ICON_POSITIONS: [LEFT, RIGHT],
};

export default STYLES;