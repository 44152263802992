import FooterRow                   from './Base/Footer/FooterRow';
import FooterColumn                from './Base/Footer/FooterColumn';
import FooterLabel                 from './Base/Footer/FooterLabel';
import {formatDuration, mergeDeep} from '../../../utils/CommonUtils';
import FooterLikes                 from './Base/Footer/FooterLikes';
import FooterAlbumLikesButton      from './Base/Footer/FooterAlbumLikesButton';
import Footer                      from './Base/Footer/Footer';
import React                       from 'react';
import Flux                        from '../../../flux/Flux';
import {TileDisplayConfigType}     from '../Grid2';
import PropTypes                   from 'prop-types';
import ReloadHelper                from '../../../utils/ReloadHelper';
import VideoTileBuyButton          from "./VideoTileBuyButton";

const FooterLabelStyles = Flux.Constants.Grid2.Footer.Label.Styles;

const VideoTileFooter = props => {
	const data          = props.data;
	const displayConfig = props.displayConfig;
	const config        = mergeDeep({}, VideoTileFooter.defaultProps.config, props.config);
	if (!config.show.footer) {
		return null;
	}

	const showLikes     = config.show.likes;
	const showActorName = config.show.actorName && !showLikes;
	const showBuyBtn    = config.show.buyBtn;

	const actorLinkUrl     = data.model ? data.model.linkVX : '';
	const actorName        = data.model ? data.model.name : '';
	const onActorNameClick = showActorName ? e => ReloadHelper.reload(actorLinkUrl, e) : null;
	const labelFontScaling = showBuyBtn ?
		Flux.Constants.Grid2.Footer.Label.FontSizeScaling.HALF : Flux.Constants.Grid2.Footer.Label.FontSizeScaling.NONE;

	const actorNameLabel = <FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.ACTOR_NAME}>
		<a href={actorLinkUrl} onClick={onActorNameClick}>{actorName}</a>
	</FooterLabel>;

	return (
		<Footer displayConfig={displayConfig}>
			<FooterRow >
				<FooterRow shrink={true} grow={true}>
					<FooterColumn grow={true} shrink={true}>
						<FooterRow>
							<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.TITLE} headlineType={props.headlineType}>{data.title}</FooterLabel>
						</FooterRow>
						<FooterRow>
							<FooterColumn grow={!config.growActorname} shrink={true}>
								<FooterRow>
									<FooterLabel fontSizeScaling={labelFontScaling} labelStyle={FooterLabelStyles.SUBTITLE}>
										{formatDuration(data.duration)}
									</FooterLabel>
									{showActorName && showBuyBtn && actorNameLabel}
								</FooterRow>
							</FooterColumn>
							{showActorName && !showBuyBtn && <FooterColumn grow={config.growActorname} shrink={config.growActorname} paddingLeft={config.growActorname}>
								{actorNameLabel}
							</FooterColumn>}
						</FooterRow>
					</FooterColumn>
					{showLikes &&
					<FooterColumn>
						<FooterLikes count={data.rating.likes} likedByGuest={data.guestInfo && data.guestInfo.liked} />
					</FooterColumn>}
					{config.show.likeBtn &&
					<FooterColumn>
						<FooterAlbumLikesButton data={data} type={Flux.Constants.MediaTypes.VIDEO}/>
					</FooterColumn>}
				</FooterRow>
				{showBuyBtn && <VideoTileBuyButton data={data}/>}
			</FooterRow>
		</Footer>
	);
};

export const VideoTileFooterConfigType = PropTypes.shape({
	show: PropTypes.shape({
		actorName: PropTypes.bool,
		likes:     PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
		likeBtn:   PropTypes.bool,
		buyBtn:    PropTypes.bool,
		footer:    PropTypes.bool,
	}),
	growActorname:      PropTypes.bool,
});

VideoTileFooter.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL video type
	config:        VideoTileFooterConfigType,
	displayConfig: TileDisplayConfigType,
	headlineType:  PropTypes.oneOf([
		Flux.Constants.Headlines.h1,
		Flux.Constants.Headlines.h2,
		Flux.Constants.Headlines.h3,
		Flux.Constants.Headlines.h4,
	]),
};

VideoTileFooter.defaultProps = {
	config: {
		show: {
			actorName: true,
			likes:     false,
			likeBtn:   false,
			buyBtn:    false,
			footer:    true,
			isVip30:   false,
		},
		growActorname: false,
	},
	headlineType:     Flux.Constants.Headlines.h4,
};

export default VideoTileFooter;