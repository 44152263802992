import React                            from 'react';
import PropTypes                        from 'prop-types';
import FeatureFeedbackPopupRatingButton from "./FeatureFeedbackPopupRatingButton";

const RATINGS = [
	'BAD',
	'OKAY',
	'GOOD',
	'GREAT',
];

const FeatureFeedbackPopupRatingButtons = ({selectedRating, onSelectRating}) => (
	<div className="feature-feedback__popup__rating-buttons">
		{RATINGS.map((rating) => (
			<FeatureFeedbackPopupRatingButton key={rating}
			                                  rating={rating}
			                                  isSelected={selectedRating === rating}
			                                  onClick={() => onSelectRating(rating)}
			/>
		))}
	</div>
);

FeatureFeedbackPopupRatingButtons.propTypes = {
	selectedRating: PropTypes.string,
	onSelectRating: PropTypes.func,
};

export default FeatureFeedbackPopupRatingButtons;