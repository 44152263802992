/* global VXConfig */
import React                       from 'react';
import PropTypes                   from 'prop-types';

import Modal                       from '../Modal';
import Flux                        from "../../flux/Flux";
import ModalBoxVideosGrid          from "./ModalBoxVideosGrid";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_CALL_TO_ACTION,
        COLOR_TYPE_DISABLED}       from "../SimpleElements/VXButton/ButtonHelper";
import UrlBuilder                  from "../../utils/UrlBuilder";
import STYLES                      from "../SimpleElements/VXButton/EnumVXButton";
import Translations                from '../../utils/Translations';

class ModalBoxLexyValentine extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isMobile:  VXConfig.isVXMobilePlatform,
            isChecked: false,
        };

        this.toggleCheck = this.toggleCheck.bind(this);
        this.buyAlbum    = this.buyAlbum.bind(this);

        this.modalRef    = null;
    }
    
    getTranslation(key) {
        return Translations.get(`_event-lexy-valentine.${key}`);
    }

    getFilter() {
		const filter = {ids: [this.props.id]};

        return filter;
	}

    toggleCheck() {
        this.setState({isChecked: !this.state.isChecked});
    }

    getTermLink() {
       return UrlBuilder.buildLexyValentineRoute();
    }

    buyAlbum() {
        this.props.onClose();
        this.props.callback(() => {
            if (this.state.isChecked) {
                Flux.Guest.addGuestRelation(Flux.Constants.Relations.Predicates.HAS_SUBSCRIBED, Flux.Constants.Relations.Objects.LEXY_VALENTINE, 1);
            }
        });
    }

    render() {
        if (!Flux.Guest.isLoggedIn()) {
            return null;
        }

        return (
            <Modal className="modal__container--lexy-valentine -auto-size" modalClassName="modal--lexy-valentine" onClose={this.props.onClose}>
                <img className="lexy-valentine__image" src={`/assets/img/special/lexy-valentine/${this.state.isMobile ? 'mobile' : 'desktop'}.png`} alt="" />
                <div className="lexy-valentine__text">{this.getTranslation('erklaertext_popup')}</div>
                <div className="lexy-valentine__video">
                <ModalBoxVideosGrid filter={this.getFilter()} linkVX="" callback={this.props.onClose} isMobile={true} isSingle={true} />
                </div>
                    <form className="lexy-valentine__form">
                        <div className="lexy-valentine__grid">
                            <div className="lexy-valentine__checkbox">
                                <input type="checkbox"
                                id="commitparcitipation"
                                name="commitparcitipation"
                                checked={this.state.isChecked}
                                onChange={this.toggleCheck}
                                />
                                <label className="form__label" htmlFor="commitparcitipation">
                                    <span className="form__checkbox__checkbox" />
                                </label>
                            </div>
                            <div className="lexy-valentine__checkbox-text">
                            {this.getTranslation('checkbox_Lexypopup_1')} <a href={this.getTermLink()} target="_blank" className="lexy-valentine__link">{this.getTranslation('checkbox_Lexypopup_2Link')}</a> {this.getTranslation('checkbox_Lexypopup_3')}
                            </div>
                        </div>
                        <div className="lexy-valentine__button">
                            <VXButton color={this.state.isChecked ? COLOR_TYPE_CALL_TO_ACTION : COLOR_TYPE_DISABLED}
                                    line1={[new VXButtonLineConfig(this.getTranslation('button_Lexypopup_yes'))]}
                                    onClick={this.buyAlbum}
                                    size={STYLES.SIZE.SMALL}
                                    disabled={!this.state.isChecked}
                                    staticMode={true}
                            />
                        </div>
                        <div onClick={this.buyAlbum} className="lexy-valentine__decline-text">{this.getTranslation('button_Lexypopup_no')}</div>
                        <div className="lexy-valentine__disclaimer">
                            <div><span className="lexy-valentine__disclaimer-highlight">{this.getTranslation('Infotext_Lexypopup_bottom_1')} </span> {this.getTranslation('Infotext_Lexypopup_bottom_2')}</div>
                            <div>{this.getTranslation('Infotext_Lexypopup_bottom_3')} <a href={this.getTermLink()} target="_blank" className="lexy-valentine__link">{this.getTranslation('Infotext_Lexypopup_bottom_4')}</a>.</div>
                        </div>
                </form>
            </Modal>
        );
    }
}

ModalBoxLexyValentine.propTypes = {
	onClose:  PropTypes.func.isRequired,
	callback: PropTypes.func.isRequired,
    id:       PropTypes.number.isRequired,
};

export default ModalBoxLexyValentine;