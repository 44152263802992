/* global VXConfig */

const VIDEO_SEARCH_ROUTES = {
	de: '/de/suche/videos/:searchPattern/',
	en: '/en/search/videos/:searchPattern/',
	es: '/es/buscar/videos/:searchPattern/',
};

const NEW_ACTORS_ROUTES = {
	de: '/de/neue-girls/',
	en: '/en/new-girls/',
	es: '/es/nuevas-chicas/',
};

const ACTOR_PROFILE_VIDEOS_ROUTES = {
	de: '/de/amateur/:actorName/videos/',
	en: '/en/amateur/:actorName/videos/',
	es: '/es/amateur/:actorName/videos/',
};

const ACTOR_PROFILE_VIDEOS_TAG_GROUP = {
	de: '/de/amateur/:actorName/videos/tag/:tagGroup',
	en: '/en/amateur/:actorName/videos/tag/:tagGroup',
	es: '/es/amateur/:actorName/videos/tag/:tagGroup',
};

const ACTOR_PROFILE_PHOTOALBUM_DETAIL = {
	de: '/de/amateur/:actorName/fotos/:albumId/',
	en: '/en/amateur/:actorName/pictures/:albumId/',
	es: '/es/amateur/:actorName/fotos/:albumId/',
};

const ACTOR_PROFILE_LIVE_SHOW_ROUTES = {
	de: '/de/amateur/:actorName/live-show/',
	en: '/en/amateur/:actorName/live-show/',
	es: '/es/amateur/:actorName/show-en-vivo/',
};

const FREE_CHAT_ROUTES = {
	de: '/de/gratis-chat/',
	en: '/en/free-chat/',
	es: '/es/chat-gratis/',
};

const CAMS_ROUTES = {
	de: '/de/cams/',
	en: '/en/cams/',
	es: '/es/cams/',
};

const CATEGORY_ROUTES = {
	de: '/de/kategorien/:categorySlug/',
	en: '/en/categories/:categorySlug/',
	es: '/es/categorias/:categorySlug/',
};

const VIDEOS_FREE_ROUTES = {
	de: '/de/videos/gratis-clips/',
	en: '/en/videos/free-clips/',
	es: '/es/videos/clips-gratis/',
};

const VIDEOS_TRENDING_ROUTES = {
	de: '/de/videos/video-trends/',
	en: '/en/videos/video-trends/',
	es: '/es/videos/video-tendencias/',
};

const VIDEOS_SALE_ROUTES = {
	de: '/de/videos/on-sale/',
	en: '/en/videos/on-sale/',
	es: '/es/videos/en-oferta/',
};

const VIDEOS_NEW_ROUTES = {
	de: '/de/videos/neue-videos/',
	en: '/en/videos/new-videos/',
	es: '/es/videos/nuevos-videos/',
};

const MESSENGER_ROUTES = {
	de: '/de/messenger/',
	en: '/en/messenger/',
	es: '/es/mensajes/',
};

const TV_LIVESTREAM_ROUTES = {
	de: '/de/tv/livestream/',
	en: '/en/tv/livestream/',
	es: '/es/tv/livestream/',
};

const TV_PREMIUM_LIVESTREAM_ROUTES = {
	de: '/de/tv/premium/livestream/',
	en: '/en/tv/premium/livestream/',
	es: '/es/tv/premium/livestream/',
};

const DAILY_BONUS_ROUTES = {
	de: '/de/deal-des-tages/',
	en: '/en/daily-deals/',
	es: '/es/ofertas-diarias/',
};

const CONTEST_ROUTES = {
	de: '/de/wettbewerbe/',
	en: '/en/contests/',
	es: '/es/concursos/',
};

const CONTEST_ROUTES_DETAIL = {
	de: '/de/wettbewerbe/:id/',
	en: '/en/contests/:id/',
	es: '/es/concursos/:id/',
};

const HOMEPAGE_ROUTES = {
	de: '/',
	en: '/en/',
	es: '/es/',
};

const LATENIGHT_ROUTES = {
	de: '/de/tv/late-night/',
	en: '/en/tv/late-night/',
	es: '/es/tv/late-night/',
};

const MEDIA_SERIES_ROUTES = {
	de: '/de/tv/mediathek/:series/',
	en: '/en/tv/mediathek/:series/',
	es: '/es/tv/media/:series/',
};

const MEDIA_KEY_ROUTES = {
	de: '/de/tv/mediathek/:key/videos/',
	en: '/en/tv/mediathek/:key/videos/',
	es: '/es/tv/media/:key/videos/',
};

const VIDEOS_INDEX_ROUTES = {
	de: '/de/videos/',
	en: '/en/videos/',
	es: '/es/videos/',
};

const VIDEOS_ALL_ROUTES = {
	de: '/de/videos/alle-videos/',
	en: '/en/videos/all-videos/',
	es: '/es/videos/todos-los-videos/',
};

const ACTOR_PROFILE_ROUTES = {
	de: '/de/amateur/:actorName/',
	en: '/en/amateur/:actorName/',
	es: '/es/amateur/:actorName/',
};

const ACTOR_PROFILE_VIDEOS_CLASSIC_ROUTES = {
	de: '/de/amateur/:actorName/videos/classic/',
	en: '/en/amateur/:actorName/videos/classic/',
	es: '/es/amateur/:actorName/videos/classic/',
};

const VIP30_ROUTES = {
	de: '/de/videos/vip-videos/',
	en: '/en/videos/vip-videos/',
	es: '/es/videos/vip-videos/',
};

const VIPCLUB_ROUTES = {
	de: '/de/vipclub/',
	en: '/en/vipclub/',
	es: '/es/vipclub/',
};

const EASTER_URL = {
	de: '/de/magazin/ostern/:year',
	en: '/en/magazine/easter/:year',
	es: '/es/magazine/easter/:year',
};

const VIDEO_STARS_ROUTES = {
	de: '/de/videos/stars/',
	en: '/en/videos/stars/',
	es: '/es/videos/estrellas/',
};

const TV_MEDIA_SERIES_OVERVIEW_ROUTES = {
	de: '/de/tv/mediathek/serien/',
	en: '/en/tv/media/series/',
	es: '/es/tv/media/series/',
};

const WIESN_SCHIESSEN_ROUTES = {
	de: '/de/magazin/wiesn-schiessen/',
	en: '/en/magazine/shooting-gallery/',
	es: '/es/magazine/shooting-gallery/',
};

const WET_N_WILD_GAME = {
	de: '/de/magazin/wetnwildgame/',
	en: '/en/magazine/wetnwildgame/',
	es: '/es/magazine/wetnwildgame/',
};

const ADVENT_CALENDAR_ROUTES = {
	de: '/de/magazin/adventskalender/',
	en: '/en/magazine/advent-calendar/',
	es: '/es/magazine/calendario-de-adviento/',
};

const MY_VISIT_X_VIDEOS_GIFTED = {
	de: '/de/meinvisitx/videos-geschenkt/',
	en: '/en/myvisitx/videos-gift/',
	es: '/es/mivisitx/videos-regalo/',
};

const MY_VISIT_X_SPINWHEEL = {
    de: '/de/meinvisitx/gluecksrad/',
    en: '/en/myvisitx/spinwheel/',
    es: '/es/mivisitx/ruleta-de-la-fortuna/',
};

const MY_VISIT_X_PROFILE = {
	de: '/de/meinvisitx/profil/',
	en: '/en/myvisitx/profil/',
	es: '/es/mivisitx/perfil'
};

const MY_VISIT_X = {
	de: '/de/meinvisitx/',
	en: '/en/myvisitx/',
	es: '/es/mivisitx/'
};

const LEXY_VALENTINE = {
	de: '/de/magazin/lexy-roxx-gewinnspiel-user-date/',
	en: '/en/magazine/lexy-roxx-gewinnspiel-user-date/',
	es: '/en/magazine/lexy-roxx-gewinnspiel-user-date/',
};

const MY_VISIT_X_BENEFITS = {
	de: '/de/meinvisitx/benefits/',
	en: '/en/myvisitx/benefits/',
	es: '/es/mivisitx/beneficios/',
};

const CAM_SHOWS = {
	de: '/de/cam-shows/',
	en: '/en/cam-shows/',
	es: '/es/cam-shows/',
};

const HIGH_ON_ROSE = {
	de: '/de/rosenrausch/',
	en: '/en/high-on-rose/',
	es: '/es/frenesi-de-rosas/',
};

const TERMS_OF_SERVICE = {
	de: '/de/agb/',
	en: '/en/terms-of-service/',
	es: '/es/terminos-de-servicio/',
};

const DATA_PROTECTION = {
	de: '/de/datenschutz/',
	en: '/en/data-protection/',
	es: '/es/proteccion-de-datos/',
};

const FEED = {
	de: '/de/feed/',
	en: '/en/feed/',
	nl: '/es/feed/',
};

function getBaseRouteForCurrentLanguage(routesByLanguage) {
	return routesByLanguage[VXConfig.language] || routesByLanguage.en;
}

export default {
	buildVideoSearchUrl(searchTerm) {
		return getBaseRouteForCurrentLanguage(VIDEO_SEARCH_ROUTES).replace(':searchPattern', searchTerm);
	},
	buildNewActorsUrl() {
		return getBaseRouteForCurrentLanguage(NEW_ACTORS_ROUTES);
	},
	buildActorProfileVideosUrl(actorName) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_VIDEOS_ROUTES).replace(':actorName', actorName.toLowerCase());
	},
	buildActorProfileLiveShowUrl(actorName) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_LIVE_SHOW_ROUTES).replace(':actorName', actorName.toLowerCase());
	},
	buildFreeChatRoute() {
		return getBaseRouteForCurrentLanguage(FREE_CHAT_ROUTES);
	},
	buildCamsRoute() {
		return getBaseRouteForCurrentLanguage(CAMS_ROUTES);
	},
	buildCategoryRoute(category) {
		return getBaseRouteForCurrentLanguage(CATEGORY_ROUTES).replace(':categorySlug', category);
	},
	buildFreeVideosRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_FREE_ROUTES);
	},
	buildVideosTrendingRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_TRENDING_ROUTES);
	},
	buildVideosSaleRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_SALE_ROUTES);
	},
	buildVideosNewRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_NEW_ROUTES);
	},
	buildMessengerRoute() {
		return getBaseRouteForCurrentLanguage(MESSENGER_ROUTES);
	},
	buildTvLivestreamRoute() {
		return getBaseRouteForCurrentLanguage(TV_LIVESTREAM_ROUTES);
	},
	buildTvPremiumLivestreamRoute() {
		return getBaseRouteForCurrentLanguage(TV_PREMIUM_LIVESTREAM_ROUTES);
	},
	buildDailyBonusRoute() {
		return getBaseRouteForCurrentLanguage(DAILY_BONUS_ROUTES);
	},
	buildContestRoute() {
		return getBaseRouteForCurrentLanguage(CONTEST_ROUTES);
	},
	buildContestDetailRoute(id) {
		return getBaseRouteForCurrentLanguage(CONTEST_ROUTES_DETAIL).replace(':id', id);
	},
	buildHomepageRoute() {
		return getBaseRouteForCurrentLanguage(HOMEPAGE_ROUTES);
	},
	buildLatenightRoute() {
		return getBaseRouteForCurrentLanguage(LATENIGHT_ROUTES);
	},
	buildMediaSeriesRoute(series) {
		return getBaseRouteForCurrentLanguage(MEDIA_SERIES_ROUTES).replace(':series', series);
	},
	buildMediaKeyRoute(key) {
		return getBaseRouteForCurrentLanguage(MEDIA_KEY_ROUTES).replace(':key', key);
	},
	buildVideoIndexRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_INDEX_ROUTES);
	},
	buildVideoAllRoute() {
		return getBaseRouteForCurrentLanguage(VIDEOS_ALL_ROUTES);
	},
	buildActorProfileUrl(actorName) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_ROUTES).replace(':actorName', actorName.toLowerCase());
	},
	buildActorVideoClassicUrl(actorName) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_VIDEOS_CLASSIC_ROUTES).replace(':actorName', actorName.toLowerCase());
	},
	buildVIP30Url() {
		return getBaseRouteForCurrentLanguage(VIP30_ROUTES);
	},
	buildVIPClubUrl() {
		return getBaseRouteForCurrentLanguage(VIPCLUB_ROUTES);
	},
	buildEasterRoute(year) {
		return getBaseRouteForCurrentLanguage(EASTER_URL).replace(':year', year);
	},
	buildVideoStarsRoute() {
		return getBaseRouteForCurrentLanguage(VIDEO_STARS_ROUTES);
	},
	buildTVSeriesOverviewRoute() {
		return getBaseRouteForCurrentLanguage(TV_MEDIA_SERIES_OVERVIEW_ROUTES);
	},
	buildWiesnSchiessenRoute() {
		return getBaseRouteForCurrentLanguage(WIESN_SCHIESSEN_ROUTES);
	},
	buildWetNWildRoute() {
		return getBaseRouteForCurrentLanguage(WET_N_WILD_GAME);
	},
	buildAdventCalendarRoute() {
		return getBaseRouteForCurrentLanguage(ADVENT_CALENDAR_ROUTES);
	},
	buildMyVisitXGiftedVideosRoute() {
		return getBaseRouteForCurrentLanguage(MY_VISIT_X_VIDEOS_GIFTED);
	},
	buildMyVisitXProfileRoute() {
		return getBaseRouteForCurrentLanguage(MY_VISIT_X_PROFILE);
	},
	buildMyVisitXRoute() {
		return getBaseRouteForCurrentLanguage(MY_VISIT_X);
	},
    buildMyVisitXSpinwheelRoute() {
        return getBaseRouteForCurrentLanguage(MY_VISIT_X_SPINWHEEL);
    },
	buildLexyValentineRoute() {
		return getBaseRouteForCurrentLanguage(LEXY_VALENTINE);
	},
	buildMyVisitXBenefitsRoute() {
		return getBaseRouteForCurrentLanguage(MY_VISIT_X_BENEFITS);
	},
	buildCamShowsRoute() {
		return getBaseRouteForCurrentLanguage(CAM_SHOWS);
	},
	buildActorProfileVideosTagGroupRoute(actorName, tagGroup) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_VIDEOS_TAG_GROUP).replace(':actorName', actorName).replace(':tagGroup', encodeURIComponent(tagGroup));
	},
	buildActorProfilePictureDetailRoute(actorName, albumId) {
		return getBaseRouteForCurrentLanguage(ACTOR_PROFILE_PHOTOALBUM_DETAIL).replace(':actorName', actorName).replace(':albumId', albumId);
	},
	buildHighOnRoseRoute() {
		return getBaseRouteForCurrentLanguage(HIGH_ON_ROSE);
	},
	buildFeedRoute() {
		return getBaseRouteForCurrentLanguage(FEED);
	},
	buildTermsOfServiceRoute() {
		return getBaseRouteForCurrentLanguage(TERMS_OF_SERVICE);
	},
	buildDataProtectionRoute() {
		return getBaseRouteForCurrentLanguage(DATA_PROTECTION);
	},
};
