import React, {useState}                             from "react";
import PropTypes                                     from 'prop-types';
import Flux                                          from "../../flux/Flux";
import {addPreventScrolling, getCurrencyIcon, removePreventScrolling} from '../../utils/CommonUtils';
import ReloadHelper                                  from "../../utils/ReloadHelper";
import UrlBuilder                                    from "../../utils/UrlBuilder";
import Translations                                  from "../../utils/Translations";

function t(key) {
    return Translations.get('vxmodelsites-onboarding.modal__' + key);
}

export default function ModalBoxModelConversion({onClose}) {
    const {
              albumIds          = [],
              conversionAlbumId = null,
              pictureUrl        = null,
              hasPayment        = false,
              videoVouchers     = 0,
              freeSeconds       = 0,
              actorName,
          }                     = JSON.parse(new URLSearchParams(window.location.href).getAll('data'));
    const [loading, setLoading] = useState(albumIds.length > 0 || conversionAlbumId);
    const [album, setAlbum] = useState(null);
    const [currentAlbum, setCurentAlbum] = useState(0);

    if ((conversionAlbumId || albumIds.length > 0) && !album) {
        Flux.Vxql.getFilteredVideos(10, {}, (res) => {
            if (res.data.videos_v2.items.length > 0) {
                let resAlbum = res.data.videos_v2.items;
                if (conversionAlbumId) {
                    resAlbum = [...res.data.videos_v2.items.filter(video => video.id === conversionAlbumId), ...res.data.videos_v2.items.filter(video => video.id !== conversionAlbumId)];
                }
                setAlbum(resAlbum);
            }
            setLoading(false);
        }, null, null, [conversionAlbumId, ...albumIds]);
    }

    if (loading) {
        return null;
    }

    addPreventScrolling();

    const beforeClose = () => {
        const url    = new URL(window.location.href);
        const params = url.searchParams;


        params.delete('openModal');


    window.history.replaceState(window.history.state, null, '?' + params.toString());

        removePreventScrolling();
        onClose();
    };

    const reload = (url, e) => {
        beforeClose();
        ReloadHelper.reload(url, e);
    };

    const onClickAlbumLink = (e) => {
       reload(album[currentAlbum].linkVX, e);
    };

    const getHeader = () => <div className="modelsite-conversion-modal__header">
            <div className="modelsite-conversion-modal__header-logo">
                <span>{t('welcome-on')}</span>
                <img src="/assets/img/logo2.svg" />
            </div>
            <i className="icon -icon-close-full modelsite-conversion-modal__header-close" onClick={beforeClose}></i>
        </div>;

    const prevAlbum = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (currentAlbum > 0) {
            setCurentAlbum(currentAlbum - 1);
        }
    };

    const nextAlbum = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (currentAlbum < album.length - 1) {
            setCurentAlbum(currentAlbum + 1);
        }
    };

    const getTiles = () => {
        const tiles = [];
        if (hasPayment) {
            tiles.push({
                icon: getCurrencyIcon(),
                headline: t('tile-balance__headline'),
                headlineInfo: Flux.Guest.getBalanceFormatted(),
                text: t('tile-balance__text'),
            });
        }

        if (album) {
            tiles.push({
                icon: "-icon-single-play-full",
                headline: t('tile-video__headline'),
                text: t('tile-video__text'),
                linkText: t('tile-video__link'),
                link: album[currentAlbum].linkVX,
                callback: (e) => {
                    reload(album[currentAlbum].linkVX, e);
                }
            });
        }

        if (albumIds.length > 0) {
            tiles.push({
                icon: "-icon-user-full",
                iconClass: '--dark',
                headline: t('tile-gift__headline'),
                text: t('tile-gift__text'),
                linkText: t('tile-gift__link'),
                link: UrlBuilder.buildMyVisitXGiftedVideosRoute(),
                callback: (e) => {
                    reload(UrlBuilder.buildMyVisitXGiftedVideosRoute(), e);
                }
            });
        }

        if (videoVouchers) {
            tiles.push({
                icon: "-icon-voucher-icon",
                headline: videoVouchers > 1 ? t('tile-video-voucher__headline-multiple') : t('tile-video-voucher__headline'),
                text: t('tile-video-voucher__text'),
                linkText: t('tile-video-voucher__link'),
                link: UrlBuilder.buildActorProfileVideosUrl(actorName),
                callback: (e) => {
                    reload(UrlBuilder.buildActorProfileVideosUrl(actorName), e);
                }
            });
        }

        if (freeSeconds) {
            tiles.push({
                icon: "-icon-heart-full",
                picture: pictureUrl,
                iconClass: '--heart',
                headline: t('tile-free-minutes__headline'),
                text: t('tile-free-minutes__text'),
                linkText: t('tile-free-minutes__link'),
                callback: () => {
                    beforeClose();
                }
            });
        }

        if (!album && !videoVouchers && !freeSeconds) {
            tiles.push({
                icon: "-icon-heart-full",
                picture: pictureUrl,
                iconClass: '--heart',
                headline: t('tile-content__headline'),
                text: t('tile-content__text'),
                linkText: t('tile-content__link'),
                callback: () => {
                    beforeClose();
                }
            });
        }

        return tiles.map(tile =>
            <div className="modelsite-conversion-modal__tile">
                <div className={"modelsite-conversion-modal__tile-icon-wrapper " + (tile.iconClass)}> {tile.picture ? <img src={tile.picture} /> : null } {<i className={"icon " + tile.icon}></i>} </div>
                <div className="modelsite-conversion-modal__tile-headline">{tile.headline} {tile.headlineInfo && <span>({tile.headlineInfo})</span>}</div>
                <div className="modelsite-conversion-modal__tile-text">{tile.text}</div>
                {tile.linkText && <a href={tile.link} onClick={tile.callback} className="modelsite-conversion-modal__tile-link">{tile.linkText} »</a>}
            </div>);
    };

	return <div className="modelsite-conversion-modal__wrapper">
        <div className="modelsite-conversion-modal__continer">
		<div className="modelsite-conversion-modal">
            {getHeader()}
            {!album && pictureUrl && <div className="modelsite-conversion-modal__img-wrapper"><img className="modelsite-conversion-modal__img" src={pictureUrl} /></div>}
            {album && album[currentAlbum] &&
                <a onClick={onClickAlbumLink} href={album[currentAlbum].linkVX} className="modelsite-conversion-modal__img-wrapper">
                    <div className="icon -icon-play-rounded-line vx_shaka--poster-play"></div>
                    <img className="modelsite-conversion-modal__img" src={album[currentAlbum].preview.images[1].url} />
                    {currentAlbum > 0 && <div onClick={prevAlbum} className="modelsite-conversion-modal__img-arrow modelsite-conversion-modal__img-arrow--left"><i className="icon -icon-single-arrow-left-line"></i></div>}
                    {currentAlbum < album.length - 1 && <div onClick={nextAlbum} className="modelsite-conversion-modal__img-arrow modelsite-conversion-modal__img-arrow--right"><i className="icon -icon-single-arrow-right-line"></i></div>}
                </a>
            }
            <div className="modelsite-conversion-modal__bottom">
                <div className="modelsite-conversion-modal__headline">{t('headline')}</div>
                <div className="modelsite-conversion-modal__text">{t('text')}</div>
                <div className="modelsite-conversion-modal__tiles">
                    {getTiles()}
                </div>
            </div>
        </div>
        </div>
	</div>;
}

ModalBoxModelConversion.propTypes = {
	onClose: PropTypes.func,
};

ModalBoxModelConversion.defaultProps = {
	onClose: () => {},
};
