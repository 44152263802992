import React     from 'react';
import PropTypes from 'prop-types';

function withVideosGrid2Adapter(WrappedComponent) {

	class withVideosGrid2Adapter extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			return <WrappedComponent tileData={this.props.videos || []} {...this.state} {...this.props} />;
		}
	}

	withVideosGrid2Adapter.propTypes    = {
		videos: PropTypes.arrayOf(PropTypes.object).isRequired,
	};

	return withVideosGrid2Adapter;
}

export default withVideosGrid2Adapter;
