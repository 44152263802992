import React     from 'react';
import PropTypes from 'prop-types';

class Countdown extends React.PureComponent {

	static formatCountdown(countdown) {
		const arr = [];

		const hours = Math.floor(countdown / (60 * 60));
		countdown -= hours * 60 * 60;
		arr.push(("00" + hours).slice(-2));
		const min = Math.floor(countdown / 60);
		countdown -= min * 60;
		arr.push(("00" + min).slice(-2));
		arr.push(("00" + countdown).slice(-2));

		return arr;
	}

	constructor(props) {
		super(props);

		this.countdownStartTime = props.countdownStartTime ? props.countdownStartTime : new Date();
		this.countdownTimer     = null;
		this.state              = {
			countdown: props.countdown,
		};

		this.onTick = this.onTick.bind(this);
	}

	componentDidMount() {
		this.countdownTimer = setInterval(this.onTick, 995);
	}

	componentWillUnmount() {
		if (this.countdownTimer) {
			clearInterval(this.countdownTimer);
		}
	}

	onTick() {
		if (this.state.countdown <= 0) {
            this.props.onCountdownFinished();
			clearInterval(this.countdownTimer);
		} else {
			const date = new Date();
			this.setState({
				countdown: Math.max(0, this.state.countdown - Math.round((date - this.countdownStartTime) / 1000)),
			}, () => {
				this.countdownStartTime = date;
			});
		}
	}

	render() {
		const [, minutes, seconds] = Countdown.formatCountdown(this.state.countdown);

		//		const timerBoxClassName = 'feature-teaser-box__table-cell -timerbox' + (([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].indexOf(parseInt(seconds)) !== -1) ? ' -running-out-of-time' : '');
		const timerBoxClassName = 'feature-teaser-box__table-cell -timerbox -running-out-of-time';


		return (
			<table className={"feature-teaser-box__table " + this.props.className}>
				<tbody>
					<tr className="feature-teaser-box__table-row">
						<td className={timerBoxClassName}>{minutes[0]}</td>
						<td className={timerBoxClassName}>{minutes[1]}</td>
						<td className="feature-teaser-box__table-cell -timer_nobg">:</td>
						<td className={timerBoxClassName}>{seconds[0]}</td>
						<td className={timerBoxClassName}>{seconds[1]}</td>
					</tr>
					{this.props.showUnits &&
						<tr className="feature-teaser-box__table-row">
							<td colSpan={2} className="feature-teaser-box__table-cell -timername h-text-align-center -unit">Minuten</td>
							<td className="feature-teaser-box__table-cell -timer_nobg -unit"/>
							<td colSpan={2} className="feature-teaser-box__table-cell -timername h-text-align-center -unit">Sekunden</td>
						</tr>}
				</tbody>
			</table>);
	}
}

Countdown.propTypes = {
	className:          PropTypes.string,
	countdown:          PropTypes.number.isRequired,
	countdownStartTime: PropTypes.object,
	showUnits:          PropTypes.bool,
    onCountdownFinished: PropTypes.func,
};

Countdown.defaultProps = {
	className:          '',
	countdownStartTime: null,
	showUnits:          true,
    onCountdownFinished: () => {},
};

export default Countdown;
