import React        from 'react';
import PropTypes    from 'prop-types';
import Flux         from '../flux/Flux';
import Translations from '../utils/Translations';

class Like extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			isLiked: this.props.initialIsLiked,
		};

		this.onPictureRatingChange = this.onPictureRatingChange.bind(this);
		this.onGalleryRatingChange = this.onGalleryRatingChange.bind(this);
		this.toggle                = this.toggle.bind(this);
		this.updateState           = this.updateState.bind(this);
		this.onButtonClicked       = this.onButtonClicked.bind(this);
	}

	componentDidMount() {
		if (this.props.pictureId) {
			Flux.Gallery.addPictureRatingChangeListener(this.onPictureRatingChange);
		} else if (this.props.albumId) {
			Flux.Gallery.addGalleryRatingChangeListener(this.onGalleryRatingChange);
		}
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.pictureId !== this.props.pictureId) || (nextProps.albumId !== this.props.albumId)) {
			this.setState({
				isLiked: nextProps.initialIsLiked,
			});
		}
	}

	componentWillUnmount() {
		if (this.props.pictureId) {
			Flux.Gallery.removePictureRatingChangeListener(this.onPictureRatingChange);
		} else if (this.props.albumId) {
			Flux.Gallery.removeGalleryRatingChangeListener(this.onGalleryRatingChange);
		}
	}

	onPictureRatingChange(pictureId) {
		if (this.props.pictureId && pictureId === this.props.pictureId) {
			const data = Flux.Gallery.getPictureData(pictureId);

			if (data) {
				this.updateState(data.isLiked);
			}
		}
	}

	onGalleryRatingChange(actorId, albumId) {
		if (this.props.albumId && albumId === this.props.albumId) {
			const data = Flux.Gallery.getGalleryData(albumId);

			if (data) {
				this.updateState(data.isLiked);
			}
		}
	}

	toggle() {
		if (!this.state.isLiked) {
			if (this.props.pictureId) {
				Flux.Gallery.setPictureGuestRating(this.props.pictureId, 5);
			} else if (this.props.albumId) {
				Flux.Gallery.setGuestRating(this.props.albumId, 5);
			}
		}
		this.updateState(!this.state.isLiked);
	}

	updateState(isLiked) {
		this.setState({
			isLiked: isLiked,
		});
	}

	onButtonClicked(e) {
		this.toggle();

		if (e.preventDefault) {
			e.preventDefault();
		}
	}

	render() {
		if (!this.props.visible) {
			return null;
		}

		let wrapperClasses = 'like-button h-cursor-pointer';

		if (this.state.isLiked) {
			wrapperClasses += ' is-active';
		}

		return (
			<span className={wrapperClasses} onClick={this.onButtonClicked}>
				<i data-tip={this.props.tooltipText ? this.props.tooltipText : Translations.get('LikeContent')}
				   data-placement={this.props.tooltipPlacement}
				   className={'icon ' + (this.state.isLiked ? this.props.checkedIcon : this.props.icon)}
				/>
			</span>
		);
	}
}

Like.propTypes = {
	albumId:          PropTypes.number,
	initialIsLiked:   PropTypes.bool,
	pictureId:        PropTypes.number,
	tooltipPlacement: PropTypes.string,
	icon:             PropTypes.string,
	checkedIcon:      PropTypes.string,
	tooltipText:      PropTypes.string,
	visible:          PropTypes.bool,
};

Like.defaultProps = {
	tooltipPlacement: 'bottom',
	icon:             '-icon-like-full',
	checkedIcon:      '-icon-like-full',
	tooltipText:      null,
	visible:          true,
};

export default Like;
