import React                from 'react';
import ModalBoxVoucherOffer from "./ModalBoxVoucherOffer";
import Translations         from "../../utils/Translations";
import {getCurrencySymbol}  from '../../utils/CommonUtils.js';


const ModalBoxBlackFriday2020 = () =>
	<ModalBoxVoucherOffer
		headerText={Translations.get('ModalBoxBlackFriday2020Header')}
		subheaderText={Translations.get('ModalBoxBlackFriday2020Subheader')}
		line1={Translations.get('ModalBoxBlackFriday2020Line1')}
		line2={Translations.get('ModalBoxBlackFriday2020Line2')}
		line3={Translations.get('ModalBoxBlackFriday2020Line3')}
		btnTextLine1={(Translations.get('ModalBoxBlackFriday2020Btn'))}
		btnTextLine2={'19,90 ' + getCurrencySymbol()}
		footerText={Translations.get('ModalBoxBlackFriday2020Footer')}
		icon1={'/assets/img/modal/black-friday-2020/video_voucher_smaller.png'}
		icon2={'/assets/img/modal/black-friday-2020/video_icon_smaller.png'}
		icon3={'/assets/img/modal/black-friday-2020/swap_icon_smaller.png'}
		imgSubclass={'--black-friday-2020'}
		vxpayOptions={{
			voucherConfigIds: [1608],
			texts:            {
				TicketButtonOK:      'Video Voucher einlösen',
				TicketHeadline:      'Voucher gekauft',
				PaymentHeadline:     'Voucher kaufen',
				PaymentAmountsTitle: 'Black Friday Angebot',
				PaymentCartItem:     '5x Video Voucher',
			},
		}}
		vxpayOnSuccess={(result) => {
			if (result && result.success) {
				window.location.href = '/?jump=videostation';
			}
		}
		}
	/>;


export default ModalBoxBlackFriday2020;
