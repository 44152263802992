import React                from 'react';
import PropTypes            from 'prop-types';
import Footer               from './Footer';
import BonusTimer           from './BonusTimer';
import VideoSaleBox         from './VideoSaleBox';
import CloseButton          from '../Conversion/CloseButton';
import EnumAlbumButtonsType from '../ActorProfileVideos/AlbumTypeButtons/EnumAlbumButtonsType';
import Translations         from '../../utils/Translations';


const VideoPreviewEndedSaleBox = (props) => <div className={'conversion-box__wrapper ' + (props.isMobile ? '-mobile' : '-pos-abs-center') + props.className}>
	<div className="conversion-box__content-container">
		<CloseButton {...props} />
		<div className="conversion-box__content-row row">
			<div className="col-xs-5 conversion-box__content-left">
				<div className="feature-teaser-box__wrapper">
					<BonusTimer
						bonusAmount={props.videoPlayerIncentive.bonusAmount}
						countdown={props.videoPlayerIncentive.countdown}
						countdownStartTime={props.videoPlayerIncentive.countdownStartTime}
					/>
				</div>
			</div>
			<div className="col-xs-7 conversion-box__content-right h-pl-35">
				<VideoSaleBox
					albumId={props.videoPlayerIncentive.albumId}
					title={props.videoPlayerIncentive.albumTitle}
				/>
			</div>
		</div>
	</div>
	<Footer type={EnumAlbumButtonsType.bonus} currentVideo={props.currentVideo}
	        videoPlayerIncentive={props.videoPlayerIncentive}
	        items={[Translations.get('FooterItem12'),
	                Translations.get('FooterItem13'),
	                Translations.get('FooterItem5'),
	                Translations.get('FooterItem4')]}
	/>
</div>;

VideoPreviewEndedSaleBox.propTypes = {
	className:            PropTypes.string,
	isMobile:             PropTypes.bool,
	onCloseFn:            PropTypes.func,
	showCloseButton:      PropTypes.bool,
	currentVideo:         PropTypes.object,
	videoPlayerIncentive: PropTypes.shape({
		actorId:             PropTypes.number.isRequired,
		albumId:             PropTypes.number.isRequired,
		albumTitle:          PropTypes.string.isRequired,
		albumPrice:          PropTypes.number.isRequired,
		albumPreviewPicture: PropTypes.object.isRequired,
		needAvs:             PropTypes.bool.isRequired,
		needVip:             PropTypes.bool.isRequired,
		bonusCode:           PropTypes.string.isRequired,
		bonusAmount:         PropTypes.number.isRequired,
		countdown:           PropTypes.number.isRequired,
		countdownStartTime:  PropTypes.object,
	}).isRequired,
};

VideoPreviewEndedSaleBox.defaultProps = {
	className:       '',
	isMobile:        false,
	onCloseFn:       null,
	showCloseButton: false,
};

export default VideoPreviewEndedSaleBox;
