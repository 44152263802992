import Translations from "../../../utils/Translations";
import React        from "react";
import Flux         from "../../../flux/Flux";

function withDildocontrolGiftTiles(WrappedComponent) {
	return class withDildocontrolGiftTiles extends React.Component {

		static generateDildocontrolGiftTiles(toyControls) {
			const dildoControlTiles = [];
			const sortedToyControls = toyControls.sort((a, b) => a.id - b.id);
			for (const toyControl of sortedToyControls) {
				dildoControlTiles.push(
					<div className="dildocontrol-info-popup__gift-overview">
						<img className="dildocontrol-info-popup__gift-image" src={toyControl.imageUrl} />
						<div className="dildocontrol-info-popup__gift-title">{toyControl.displayName} {Flux.Guest.isLoggedIn() && toyControl.price.value.toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							style:                 "currency",
							currency:              "EUR",
						})}
						</div>
						<div className="dildocontrol-info-popup__gift-description">({
							toyControl.random ?
								Translations.get('DildocontrolInfoPopupUpTo60s') :
								`${toyControl.duration / 1000} ${Translations.get('DildocontrolInfoPopupSecondsAbbreviation')}`})
						</div>
					</div>
				);
			}

			return dildoControlTiles;
		}

		constructor(props) {
			super(props);

			this.state = {
				dildocontrolGiftTiles: withDildocontrolGiftTiles.generateDildocontrolGiftTiles(Flux.ToyControl.getToyControls()),
			};

			this.onToyControlStoreInitialized = this.onToyControlStoreInitialized.bind(this);
		}

		componentDidMount() {
			Flux.ToyControl.addToyControlStoreInitializedChangeListener(this.onToyControlStoreInitialized);
			Flux.ToyControl.init();
		}

		componentWillUnmount() {
			Flux.ToyControl.removeToyControlStoreInitializedChangeListener(this.onToyControlStoreInitialized);
		}

		onToyControlStoreInitialized() {
			this.setState({
				dildocontrolGiftTiles: withDildocontrolGiftTiles.generateDildocontrolGiftTiles(Flux.ToyControl.getToyControls()),
			});
		}

		render() {
			return <WrappedComponent dildocontrolGiftTiles={this.state.dildocontrolGiftTiles} {...this.props} />;
		}
	};
}

export default withDildocontrolGiftTiles;