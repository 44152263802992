import React     from "react";
import PropTypes from "prop-types";

const FeatureFeedbackPopupBackdrop = ({open, onClick}) => (
	<div className={"feature-feedback__backdrop" + (open ? " is-visible" : "")} onClick={onClick}></div>
);

FeatureFeedbackPopupBackdrop.propTypes = {
	open:    PropTypes.bool,
	onClick: PropTypes.func,
};

export default FeatureFeedbackPopupBackdrop;