import React                       from 'react';
import PropTypes                   from "prop-types";
import Modal                       from '../Modal';
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {VXPay}                     from '../../utils/VXPay';
import Flux                        from '../../flux/Flux';
import ReloadHelper                from '../../utils/ReloadHelper';
import UrlBuilder                  from '../../utils/UrlBuilder';

class SexoleInput extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			text:      '',
			focused:   false,
			showError: false,
		};

		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({text: e.target.value, showError: false});
	}

	showError() {
		this.setState({showError: true});
	}

	render() {
		return (
			<div className={'sexole-login__input' + (this.state.focused || this.state.text ? " sexole-login__input--focused" : "")}>
				<label>{this.props.label}</label>
				<input value={this.state.text} type={this.props.type} onChange={this.onChange} onFocus={() => this.setState({focused: true})}
				       onBlur={() => this.setState({focused: false})} autoCapitalize={"none"} autoCorrect={"none"}/>
				<div className={this.state.showError ? "show" : "hide"}>{this.props.error}</div>
			</div>
		);
	}
}

SexoleInput.propTypes = {
	label: PropTypes.string.isRequired,
	error: PropTypes.string.isRequired,
	type:  PropTypes.string.isRequired,
};

class ModalBoxSexoleLogin extends React.PureComponent {
	constructor(props) {
		super(props);


		this.usernameRef = null;
		this.passwordRef = null;

		this.onLoginClick  = this.onLoginClick.bind(this);
		this.onClickTerms  = this.onClickTerms.bind(this);
		this.onClickData   = this.onClickData.bind(this);
		this.onSignUpClick = this.onSignUpClick.bind(this);
		this.onForgetClick = this.onForgetClick.bind(this);
		this.onClose       = this.onClose.bind(this);

	}

	onLoginClick() {
		const username = this.usernameRef.state.text;
		const password = this.passwordRef.state.text;
		if (!username || !password) {
			!username && this.usernameRef.showError();
			!password && this.passwordRef.showError();
			return;
		}

		VXPay.onSuccess((result, oldSuccess) => {
			if (result.success) {
				Flux.Browser.setLocalItem('sexoleLogin', true);
			}

			oldSuccess(result);
		});

		VXPay.openLogin({
			loginName:       username,
			loginPassword:   password,
			autoSubmitLogin: true,
		}, true);
		this.onClose();
	}

	onSignUpClick() {
		VXPay.openSignup({}, true);
		this.onClose();
	}

	onForgetClick() {
		VXPay.openPwdLost();
		this.onClose();
	}

	onClickTerms(e) {
		e.preventDefault();
		this.onClose();
		ReloadHelper.reload(UrlBuilder.buildTermsOfServiceRoute(), e);
	}

	onClickData(e) {
		e.preventDefault();
		this.onClose();
		ReloadHelper.reload(UrlBuilder.buildDataProtectionRoute(), e);
	}

	onClose() {
		this.props.onClose();
		Flux.Browser.setShouldModalWindowClose();
	}

	render() {
		return <Modal className={"h-center modal__sexole-login -auto-size"} {...this.props} hideCloseButton={true}
		              onContainerClickFn={Flux.Browser.setShouldModalWindowClose}>
			<div className="sexole-login__content--grid">
				<div className="icon -icon-close-line modal__btn-close" onClick={Flux.Browser.setShouldModalWindowClose}></div>
				<div className="sexole-login__image-wrapper">
					<img className="sexole-login__image sexole-login__image--desktop" src="/assets/img/sexole/image_desktop.png"/>
				</div>

				<div className="h-flex -align-center -direction-column">
					<div className="sexole-login__logo h-flex -align-center -direction-column">
						<img src="/assets/img/sexole/sexole-logo-alt.svg"/>
					</div>
					<img className="sexole-login__image sexole-login__image--mobile" src="/assets/img/sexole/image_mobile.png"/>

					<div className="sexole-login__headline">Bienvenido al<span>nuevo SEXOLE</span></div>
					<div className="sexole-login__subline">Confirma tus datos para disfrutar de la nueva web</div>
					<div className="sexole-login__input-wrapper">
						<SexoleInput label={"Usuario"} error="El nombre de usuario es obligatorio." type={"text"}
						             ref={ref => this.usernameRef = ref}/>
						<SexoleInput label={"Contraseña"} error="Se requiere contraseña." type={"password"} ref={ref => this.passwordRef = ref}/>
						<div className="sexole-login__forget">
							<span onClick={this.onForgetClick}>Contraseña olvidada</span>
						</div>
					</div>

					<div className="sexole-login__button">
						<VXButton line1={[new VXButtonLineConfig("Confirmar y entra al nuevo Sexole")]}
						          color={COLOR_TYPE_CALL_TO_ACTION}
						          onClick={this.onLoginClick}
						/>
					</div>

					<div className="sexole-login__info">
						<i className="icon -icon-round-success-full"></i>
						<div>
							Estoy de acuerdo con que mi perfil se transfiera al nuevo sitio web Sexole. ¿Aún no estás registrado? <br /> <span onClick={this.onSignUpClick}>¡Regístrate gratis aquí ahora!</span>

						</div>
					</div>

					<div className="sexole-login__tos">
						<div className="sexole-login__tos-line"></div>
						<p>Por la presente aceptas los <a href={UrlBuilder.buildTermsOfServiceRoute()} onClick={this.onClickTerms}> términos y
							condiciones</a>.</p>
						<p>Encontrarás información sobre el tratamiento de tus datos en nuestra <a href={UrlBuilder.buildDataProtectionRoute()}
						                                                                           onClick={this.onClickData}>política de
							privacidad</a>.</p>
					</div>
				</div>
			</div>
			;
		</Modal>;
	}
}

ModalBoxSexoleLogin.propTypes = {
	onClose:  PropTypes.func.isRequired,
	isMobile: PropTypes.bool,
};

ModalBoxSexoleLogin.defaultProps = {
	isMobile: false,
};


export default ModalBoxSexoleLogin;