/*global VXConfig*/
import React   from "react";
import Flux    from "../../flux/Flux";
import {VXPay} from "../../utils/VXPay";
import {getCurrencySymbol, getStaticAmount} from '../../utils/CommonUtils.js';

const TRANSLATIONS = {
	header_vip:                 {
		DE: `Exklusive Vorteile in der <span class="h-hyphens-none">VISIT-X</span> Community!`,
		EN: `Exclusive benefits within the <span class="h-hyphens-none">VISIT-X</span> Community!`,
		ES: `Beneficios exclusivos dentro de la comunidad <span class="h-hyphens-none">VISIT-X</span>!`,
	},
	header:                 {
		DE: `Diese Vorteile erhalten nur VIP CLUB Mitglieder`,
		EN: `These benefits are only available for VIP CLUB members`,
		ES: `Estos beneficios solo están disponibles para miembros del VIP CLUB`,
	},
	priceHint:              {
		DE: `9,95€/Monat, monatlich kündbar`,
		EN: `9,95€/month, monthly cancellable`,
		ES: `9,95€/mes, cancelable mensualmente`,
	},
	benefitsHeadline:       {
		DE: `Alle Vorteile als VIP Mitglied auf einen Blick!`,
		EN: `All benefits as VIP member at a glance!`,
		ES: `¡Todos los beneficios como miembro VIP de un vistazo!`,
	},
	benefitsSubline:        {
		DE: `Deine Vorteile in der Erotik Community:`,
		EN: `Your benefits within the erotic community:`,
		ES: `Tus beneficios dentro de la comunidad erótica:`,
	},
	accessTo:               {
		DE: `Zugriff auf`,
		EN: `Access to`,
		ES: `Acceso a`,
	},
	vipDiscount:            {
		DE: `VIP-Rabatte`,
		EN: `VIP-Discounts`,
		ES: `Descuentos VIP`,
	},
	vipVideos:              {
		DE: `30 VIP-Videos`,
		EN: `30 VIP-Videos`,
		ES: `30 VIP-Videos`,
	},
	videos:                 {
		DE: `Videos`,
		EN: `Videos`,
		ES: `Videos`,
	},
	exclusiveSelection:     {
		DE: `Exclusive Auswahl`,
		EN: `Exclusive selection`,
		ES: `Selección exclusiva`,
	},
	exclusiveSelectionShort:{
		DE: `Deine exklusive Auswahl`,
		EN: `Your exclusive selection`,
		ES: `Tu selección exclusiva`,
	},
	exclusiveSelectionLong: {
		DE: `Deine exklusive VIP Video Auswahl`,
		EN: `Your exclusive vip video selection`,
		ES: `Tu selección exclusiva de videos VIP`,
	},
	discount:               {
		DE: `Rabatte`,
		EN: `Discounts`,
		ES: `Descuentos`,
	},
	monthAtLeast:           {
		DE: `Monatlich mind.`,
		EN: `At least`,
		ES: `Mensualmente al menos`,
	},
	changingMonthly:        {
		DE: `monatlich neu`,
		EN: `new every month`,
		ES: `Videos nuevos cada mes`,
	},
	creditMonthly:          {
		DE: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()} Extra-Guthaben*`,
		EN: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()} extra credit monthly*`,
		ES: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()} de crédito extra mensualmente*`,
	},
	getVIPPrice: Flux.Guest.isLoggedIn() ? {
		DE: `VIP-Mitglied werden (9,95€ /Monat)`,
		EN: `Become VIP Member (9.95€ /Month)`,
		ES: `Hazte miembro VIP (9,95€ /mes)`,
	} : {
        DE: `VIP-Mitglied werden`,
        EN: `Become VIP Member`,
        ES: `Hazte miembro VIP`,
    },

	getVIP:                 {
		DE: `VIP-Mitglied werden`,
		EN: `Become VIP member`,
		ES: `Hazte miembro VIP`,
	},
	yourAreMember:          {
		DE: `Du bist VIP-Mitglied!`,
		EN: `You are VIP Member!`,
		ES: `¡Eres miembro VIP!`,
	},
	showAllVideos:          {
		DE: `Alle 30 Videos anzeigen`,
		EN: `Show all 30 videos`,
		ES: `Mostrar los 30 videos`,
	},
	vipClubVideos:          {
		DE: `VIP Club Videos`,
		EN: `VIP Club videos`,
		ES: `Videos del VIP Club`,
	},

	banner_1_left_1:        {
		DE: `Sofort-Guthaben & Boni´s`,
		EN: `instant-credit & Boni´s`,
		ES: `Crédito instantáneo y bonos`,
	},
	banner_1_left_2:        {
		DE: `im Wert von mind.`,
		EN: `value of min.`,
		ES: `valor de al menos`,
	},
	banner_1_left_3:        {
		DE: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()}`,
		EN: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()}`,
		ES: `${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()}`,
	},
	banner_1_left_4:        {
		DE: `im Wert`,
		EN: `value`,
		ES: `en valor`,
	},
	banner_1_left_5:        {
		DE: `von mind.`,
		EN: `of min.`,
		ES: `de al menos`,
	},
	banner_1_left_6:        {
		DE: `VIP exklusive Boni`,
		EN: `VIP exclusive bonuses`,
		ES: `Bonos exclusivos VIP`,
	},
	banner_1_right_1:       {
		DE: `VIP Rabatte bei`,
		EN: `VIP discounts at`,
		ES: `Descuentos VIP en`,
	},
	banner_1_right_2:       {
		DE: `Bonus-Aktionen!*`,
		EN: `Bonus Promotions!*`,
		ES: `¡Promociones de bonos!*`,
	},

	banner_2_left_1:        {
		DE: `30 Videos`,
		EN: `30 Videos`,
		ES: `30 Videos`,
	},
	banner_2_left_2:        {
		DE: `im Wert \nvon mind.`,
		EN: `value \nof min.`,
		ES: `valor \nde al menos`,
	},
	banner_2_left_3:        {
		DE: `${getStaticAmount('promoVipVideosSum')}${getCurrencySymbol()}`,
		EN: `${getStaticAmount('promoVipVideosSum')}${getCurrencySymbol()}`,
		ES: `${getStaticAmount('promoVipVideosSum')}${getCurrencySymbol()}`,
	},
	banner_2_left_4:        {
		DE: `Vip Videos`,
		EN: `Vip Videos`,
		ES: `Vip Videos`,
	},
	banner_2_right_1:       {
		DE: `Jeden Monat`,
		EN: `every month`,
		ES: `¡30 nuevos videos`,
	},
	banner_2_right_2:       {
		DE: `30 neue Videos!`,
		EN: `30 new videos!`,
		ES: `cada mes!`,
	},

	benefit_preview1: {
		DE: `Verlängerte`,
		EN: `Extended`,
		ES: `Extendido`,
	},
	benefit_preview2: {
		DE: `Livechat Preview`,
		EN: `Livechat preview`,
		ES: `Vista previa del chat en vivo`,
	},
	benefit_preview3: {
		DE: `4x so lange`,
		EN: `4x as long`,
		ES: `Previo gratis`,
	},
	benefit_preview4: {
		DE: `kostenlos zusehen`,
		EN: `free watching`,
		ES: `4 veces más largo`,
	},

	benefit_clips1: {
		DE: `Exklusive`,
		EN: `Exclusive`,
		ES: `Videos`,
	},
	benefit_clips2: {
		DE: `Short-Clips`,
		EN: `Short-Clips`,
		ES: `cortos exclusivos`,
	},
	benefit_clips3: {
		DE: `Für dich als VIP`,
		EN: `For you as VIP`,
		ES: `Gratis para ti como VIP, `,
	},
	benefit_clips4: {
		DE: `natürlich Gratis`,
		EN: `of course free charge`,
		ES: `por supuesto`,
	},

	benefit_messages1: {
		DE: `10 Freinachrichten`,
		EN: `10 FREE MESSAGES `,
		ES: `10 MENSAJES GRATIS`,
	},
	benefit_messages2: {
		DE: `Im Monat`,
		EN: `per month`,
		ES: `por mes`,
	},
	benefit_messages3: {
		DE: `kostenfreie Nachtichten an`,
		EN: `free messages to`,
		ES: `Mensajes gratis para`,
	},
	benefit_messages4: {
		DE: `Deine Favoriten versenden`,
		EN: `send to your favorites`,
		ES: `tus cams favoritas`,
	},

	benefit_premium1: {
		DE: `Premium TV`,
		EN: `PREMIUM TV `,
		ES: `TV PREMIUM`,
	},
	benefit_premium2: {
		DE: `Zugang`,
		EN: `ACCESS`,
		ES: `ACCESO`,
	},
	benefit_premium3: {
		DE: `TV Premium Livestream & Shows`,
		EN: `TV Premium Livestream & Shows`,
		ES: `TV Premium Livestream & Shows`,
	},
	benefit_premium4: {
		DE: `im Wert von 4,99${getCurrencySymbol()} monatlich`,
		EN: `in the value of 4,99${getCurrencySymbol()} monthly`,
		ES: `en el valor de 4,99${getCurrencySymbol()} mensual`,
	},

	benefit_heatmap1: {
		DE: `Anzeige Amateur`,
		EN: `DISPLAY OF AMATEUR`,
		ES: `DISPLAY OF AMATEUR`,
	},
	benefit_heatmap2: {
		DE: `Webcam-Zeiten`,
		EN: `WEBCAM-TIMES`,
		ES: `TIEMPOS-WEBCAM`,
	},
	benefit_heatmap3: {
		DE: `Sei online, wenn auch`,
		EN: `Be online, when`,
		ES: `Estar en línea, cuando`,
	},
	benefit_heatmap4: {
		DE: `dein Girl online ist`,
		EN: `your girl is online, too`,
		ES: `tu chica esté en línea también`,
	},

	benefit_365_1: {
		DE: `Längere Nutzung vom`,
		EN: `Extended offers`,
		ES: `Ofertas extendidas`,
	},
	benefit_365_2: {
		DE: `365 Tage Kalender`,
		EN: `at 365 Days calender`,
		ES: `los 365 días del calendario`,
	},
	benefit_365_3: {
		DE: `Als VIP Angebote`,
		EN: `As a VIP offers from the previous day`,
		ES: `Como ofertas VIP del día anterior`,
	},
	benefit_365_4: {
		DE: `vom Vortag einlösen`,
		EN: `can be redeemed additionally`,
		ES: `se pueden canjear adicionalmente`,
	},

	benefit_latenight1: {
		DE: `Gratis zugang`,
		EN: `free access`,
		ES: `acceso gratuito`,
	},
	benefit_latenight2: {
		DE: `Late Night Show`,
		EN: `Late night show`,
		ES: `Late night show`,
	},
	benefit_latenight3: {
		DE: `Ticket zur Show`,
		EN: `Ticket to the show`,
		ES: `Ticket para el show`,
	},
	benefit_latenight4: {
		DE: `im Wert von 4,99${getCurrencySymbol()}`,
		EN: `in the value of 4,99${getCurrencySymbol()}`,
		ES: `en el valor de 4,99${getCurrencySymbol()}`,
	},

	benefit_contest1: {
		DE: `Dauerhafter Zugriff`,
		EN: `Permanent Access`,
		ES: `Acceso permanente`,
	},
	benefit_contest2: {
		DE: `Wettbewerbsvideos`,
		EN: `contest Videos`,
		ES: `Videos del concurso`,
	},
	benefit_contest3: {
		DE: `Unbegrenzter Zugriff auf die Top 3`,
		EN: `Unlimited Access to the Top 3`,
		ES: `Acceso ilimitado al top 3`,
	},
	benefit_contest4: {
		DE: `aller Wettbewerbsvideos`,
		EN: `of all contest videos`,
		ES: `de todos los videos del concurso`,
	},

	showMore:        {
		DE: `Alle Vorteile anzeigen`,
		EN: `Show more`,
		ES: `Mostrar más`,
	},
	showLess:        {
		DE: `Weniger Vorteile anzeigen`,
		EN: `Show less`,
		ES: `Mostrar menos`,
	},
	showoffHeadline: {
		DE: `Jeden Monat warten auf Dich`,
		EN: `Every month awaits you`,
		ES: `Cada mes te espera`,
	},
	showoffSubline:  {
		DE: `kostenlose Videos ab 18, satte Rabatte & weitere tolle Vorteile!`,
		EN: `Free 18+ videos, mega discounts & more great benefits`,
		ES: `Videos 18+ gratis, mega descuentos y otros grandes beneficios`,
	},
	recommended:     {
		DE: `empfohlen`,
		EN: `recommended`,
		ES: `recomendado`,
	},

	flexible:     {
		DE: `Flexibel und transparent`,
		EN: `Flexible and transparent`,
		ES: `Flexible y transparente`,
	},
	noObligation: {
		DE: `Keine Verpflichtungen, monatlich kündbar`,
		EN: `No obligations, can be canceled monthly`,
		ES: `Sin obligaciones, cancelable mensualmente`,
	},

	tr1_1: {
		DE: `Das bekommst Du als...`,
		EN: `What you get as...`,
		ES: `Esto es lo que obtienes como...`,
	},
	tr1_2: {
		DE: `GRATIS-MITGLIED`,
		EN: `FREE MEMBER`,
		ES: `MIEMBRO GRATUITO`,
	},
	tr1_3: {
		DE: `VIP-MITGLIED`,
		EN: `VIP MEMBER`,
		ES: `MIEMBRO VIP`,
	},

	tr2_1: {
		DE: `Preis monatlich`,
		EN: `Price monthly`,
		ES: `Precio mensual`,
	},
	tr2_2: {
		DE: `kostenfrei`,
		EN: `free`,
		ES: `gratis`,
	},
	tr2_3: {
		DE: `nur 9,95€`,
		EN: `only 9.95€`,
		ES: `solo 9,95€`,
	},

	tr3_1: {
		DE: `Kostenlose Livechat Preview`,
		EN: `Free Livechat Preview`,
		ES: `Vista previa de chat en vivo gratis`,
	},
	tr3_2: {
		DE: `5 Sekunden`,
		EN: `5 seconds`,
		ES: `5 segundos`,
	},
	tr3_3: {
		DE: `20 Sekunden`,
		EN: `20 seconds`,
		ES: `20 segundos`,
	},

	tr4_1: {
		DE: `Zugriff auf Top 3 Wettbewerbsvideos`,
		EN: `Access to Top 3 contest videos`,
		ES: `Acceso a los 3 mejores videos del concurso`,
	},
	tr4_2: {
		DE: `bei aktivem Wettbewerb`,
		EN: `at active contest`,
		ES: `en concurso activo`,
	},
	tr4_3: {
		DE: `unbegrenzt`,
		EN: `unlimited`,
		ES: `ilimitado`,
	},

	tr5_1: {
		DE: `Freinachrichten monatlich`,
		EN: `Free messages monthly`,
		ES: `Mensajes gratis mensuales`,
	},
	tr5_3: {
		DE: `10 Nachrichten`,
		EN: `10 messages`,
		ES: `10 mensajes`,
	},

    tr6_1: Flux.Guest.isLoggedIn() ? {
        DE: `Monatlich 30 exklusive Videos im Wert von mind. ${getStaticAmount('promoVipVideosSum')}${getCurrencySymbol()}`,
        EN: `Monthly 30 exclusive Vides with a min value of ${getStaticAmount('promoVipVideosSum')}${getCurrencySymbol()}`,
        ES: `30 Videos exclusivos mensuales con un valor de por lo menos ${getStaticAmount('promoVipVideosSum')} ${getCurrencySymbol()}`,
    } : {
        DE: `Monatlich 30 exklusive Videos`,
        EN: `Monthly 30 exclusive Videos`,
        ES: `30 Videos exclusivos mensuales`,
    },

	tr7_1: {
		DE: `Kostenpflichtige Amateur Videos gratis ansehen`,
		EN: `See free chargeable amateure videos`,
		ES: `Ver videos amateur de pago gratis`,
	},

    tr8_1: Flux.Guest.isLoggedIn() ? {
        DE: `TV Premium Livestream & Shows im Wert von 4,99${getCurrencySymbol()}`,
        EN: `TV Premium Livestream & Shows in value of 4,99${getCurrencySymbol()}`,
        ES: `Premium TV Livestream & Shows con un valor de 4,99 ${getCurrencySymbol()}`,
    } : {
        DE: `TV Premium Livestream & Shows`,
        EN: `TV Premium Livestream & Shows`,
        ES: `TV Premium Livestream & Shows`,
    },

    tr9_1: Flux.Guest.isLoggedIn() ? {
        DE: `Late Night Show im Wert von 4,99${getCurrencySymbol()}`,
        EN: `Late Night Show in value of 4,99${getCurrencySymbol()}`,
        ES: `Late Night Show con un valor de 4,99 ${getCurrencySymbol()}`,
    } : {
        DE: `Late Night Show`,
        EN: `Late Night Show`,
        ES: `Late Night Show`,
    },


    tr10_1: {
		DE: `Anzeige Amateur Webcam-Zeiten`,
		EN: `See amateur webcam hours`,
		ES: `Ver las horas de webcam amateur`,
	},

    tr11_1: Flux.Guest.isLoggedIn() ? {
        DE: `VIP Guthaben & Boni´s (im Wert von mind. ${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()}*)`,
        EN: `VIP credit & Boni´s (in value of min. ${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()}*)`,
        ES: `Crédito VIP y bonos (con un valor de por lo menos ${getStaticAmount('promoVipExtraBonus')} ${getCurrencySymbol()}*)`,
    } : {
        DE: `VIP Guthaben & Boni´s`,
        EN: `VIP credit & Boni´s`,
        ES: `Crédito VIP y bonos`,
    },

    tr12_1: {
		DE: `Monatlich kündbar`,
		EN: `Monthly cancelable`,
		ES: `Cancelable mensualmente`,
	},

	anonymous:     {
		DE: `Anonyme Abrechnung`,
		EN: `Anonymous billing`,
		ES: `Facturación anónima`,
	},
	anonymousHint: {
		DE: `Egal für welches Zahlungsmittel Du Dich entschieden hast - die Abrechnung unserer Produkte erfolgt immer vollkommen diskret und lässt keine Rückschlüsse auf erotische Inhalte zu!`,
		EN: `No matter what payment method you choose – the billing of our products will be completely discreet und leaves no reference on erotic content!`,
		ES: `No importa qué método de pago elijas: la facturación de nuestros productos es completamente discreta y no deja ninguna referencia sobre el contenido erótico.`,
},

	allDevices:     {
		DE: `Auf allen Geräten verfügbar:`,
		EN: `On all devices available:`,
		ES: `Disponible en todos los dispositivos:`,
	},
	allDevicesHint: {
		DE: `Nutze den VISIT-X VIP Club auf Deinem Smartphone, PC oder Tablet!`,
		EN: `Use the VISIT-X VIP Club on your Smartphone, Computer or Tablet!`,
		ES: `¡Utiliza el VISIT-X VIP Club en tu smartphone, PC o tablet!`,
	},

	useBenefits: {
		DE: `Vorteile nutzen`,
		EN: `Use benefits`,
		ES: `Utilizar ventajas`,
	},

	// mobile specials
	benefitsH1: {
		DE: `Nutze exklusive Features!`,
		EN: `Take advantage of exclusive features!`,
		ES: `¡Aprovecha las ventajas exclusivas!`,
	},
	benefitsH2: {
		DE: `Alle Vorteile als VIP Mitglied`,
		EN: `All benefits as VIP member`,
		ES: `Todas las ventajas como miembro VIP`,
	},
	benefitsH3: {
		DE: `auf einen Blick!`,
		EN: `at a glance!`,
		ES: `¡de un vistazo!`,
	},

    footerHint1: Flux.Guest.isLoggedIn() ? {
        DE: `* Garantiert mind. ${getStaticAmount('promoVipExtraBonus')}${getCurrencySymbol()} Extra-Guthaben im Monat durch exklusive VIP On-Top Gutscheine.`,
        EN: `* Guaranteed at least ${getCurrencySymbol()}${getStaticAmount('promoVipExtraBonus')} extra credit per month through exclusive VIP On-Top vouchers.`,
        ES: `* Crédito extra garantizado de por lo menos ${getStaticAmount('promoVipExtraBonus')} ${getCurrencySymbol()} al mes a través de exclusivos cupones VIP On-Top.`,
    } : {
        DE: `* Garantiert Extra-Guthaben jeden Monat durch exklusive VIP On-Top Gutscheine. Jetzt VIP werden und profitieren!`,
        EN: `* Guaranteed extra credit every month through exclusive VIP On-Top vouchers. Become VIP now and benefit!`,
        ES: `* Crédito extra garantizado al mes a través de exclusivos cupones VIP On-Top. ¡Hazte VIP ahora y benefíciate!`,
    },
	footerHint2: {
		DE: `Diese Gutscheine findest Du in unserem`,
		EN: `These vouchers can be found in our`,
		ES: `Estos cupones los encontrarás en nuestro`,
	},
	footerHint3: {
		DE: `365 Tage Kalender`,
		EN: `365 day calendar`,
		ES: `Calendario de 365 días`,
	},
	footerHint4: {
		DE: `und unseren Aktionen & Events.`,
		EN: `and our promotions & events.`,
		ES: `y nuestras promociones y eventos.`,
	},

	// VIP30
	january:   {
		DE: `Januar`,
		EN: `January`,
		ES: `Enero`,
	},
	february:  {
		DE: `Februar`,
		EN: `February`,
		ES: `Febrero`,
	},
	march:     {
		DE: `März`,
		EN: `March`,
		ES: `Marzo`,
	},
	april:     {
		DE: `April`,
		EN: `April`,
		ES: `Abril`,
	},
	may:       {
		DE: `Mai`,
		EN: `May`,
		ES: `Mayo`,
	},
	june:      {
		DE: `Juni`,
		EN: `June`,
		ES: `Junio`,
	},
	july:      {
		DE: `Juli`,
		EN: `July`,
		ES: `Julio`,
	},
	august:    {
		DE: `August`,
		EN: `August`,
		ES: `Agosto`,
	},
	september: {
		DE: `September`,
		EN: `September`,
		ES: `Septiembre`,
	},
	october:   {
		DE: `Oktober`,
		EN: `October`,
		ES: `Octubre`,
	},
	november:  {
		DE: `November`,
		EN: `November`,
		ES: `Noviembre`,
	},
	december:  {
		DE: `Dezember`,
		EN: `december`,
		ES: `Diciembre`,
	},

	headline30VIP: {
		DE: `Deine 30 exklusiven Videos im`,
		EN: `Your 30 exclusive videos for`,
		ES: `Tus 30 vídeos exclusivos en`,
	},
	headline30:    {
		DE: `30 exklusive Videos jeden Monat \nnur für VIP Kunden`,
		EN: `30 exclusive videos every month \nonly for VIP customers`,
		ES: `30 vídeos exclusivos cada mes \nsolo para clientes VIP`,
	},
	modelsOfMonth: {
		DE: `Amateure des Monats`,
		EN: `Amateur of the month`,
		ES: `Amateurs del mes`,
	},
	toProfile:     {
		DE: `zum Profil`,
		EN: `go to profile`,
		ES: `ir al perfil`,
	},
	still:         {
		DE: `noch`,
		EN: `still`,
		ES: `aún`,
	},
	days:          {
		DE: `Tage`,
		EN: `days`,
		ES: `días`,
	},
	daysAvailable: {
		DE: `Tage verfügbar`,
		EN: `days available`,
		ES: `días disponibles`,
	},
	onlyToday:     {
		DE: `nur noch heute`,
		EN: `only today left`,
		ES: `solo hoy`,
	},
	getVIPUnklock: {
		DE: `Jetzt VIP werden & alle freischalten`,
		EN: `Become VIP now & unlock all`,
		ES: `Hazte VIP ahora y desbloquea todos`,
	},
	vipVideosUnlockSubtitle: {
		DE: `VIP CLUB Mitglied werden & \nalle VIP Videos kostenlos ansehen!`,
		EN: `Become a VIP CLUB member & \nwatch all VIP videos for free!`,
		ES: `¡Hazte miembro del VIP CLUB y \nmira todos los vídeos VIP gratis!`,
	},

	loginMember:   {
		DE: `Anmelden & VIP Mitglied werden`,
		EN: `Signup & become VIP member`,
		ES: `Iniciar sesión y hacerse miembro VIP`,
	},
	vipNow:        {
		DE: `Jetzt VIP werden`,
		EN: `Join as a VIP`,
		ES: `Hazte VIP ahora`,
	},
	freeSignup:    {
		DE: `Kostenlos Anmelden`,
		EN: `free signup`,
		ES: `Registro gratuito`,
	},


	valueModule_thisMonthDiscount: {
		DE: `Diesen Monat sparst Du:`,
		EN: `This month you save:`,
		ES: `Este mes ahorras:`,
	},
	valueModule_value30:           {
		DE: `Wert der 30`,
		EN: `Value of`,
		ES: `Valor de los 30`,
	},
	valueModule_headline:          {
		DE: `Spare jeden Monat und streame 30 Videos kostenfrei!`,
		EN: `Save each month and stream 30 Videos for free!`,
		ES: `¡Ahorra cada mes y podrás ver 30 videos gratis!`,
	},
	valueModule_savings:           {
		DE: `Ersparnis im`,
		EN: `Savings in`,
		ES: `Ahorro en`,
	},
};

function getTranslation(key) {
	let language = (VXConfig.language).toUpperCase();
	if (language !== `DE` && language !== `EN` && language !== `ES`) {
		language = `EN`;
	}

	if (TRANSLATIONS[key] && TRANSLATIONS[key][language]) {
		return TRANSLATIONS[key][language];
	} else if (TRANSLATIONS[key] && TRANSLATIONS[key][`DE`]) {
		return TRANSLATIONS[key][`DE`];
	}

	return ``;
}

function getTextLine(text, size = `medium`, color = `white`, padding = true, shadow = false, light = false) {
	const translation = getTranslation(text);
	if (translation) {
		text = translation;
	}

	return (
		<div className={"vipclub--text " + (VXConfig.isVXMobilePlatform ? ` -mobile` : ``) + ` -` + size + ` -` + color + (!padding ? ` -no-padding` : ``) + (shadow ? ` -shadow` : ``) + (light ? ` -light` : ``)}
		     dangerouslySetInnerHTML={{__html: text}}
		/>
	);
}

function onClickGetVip() {
	if (!Flux.Guest.isLoggedIn()) {
		VXPay.openLogin();
	} else if (!Flux.Guest.isVip()) {
		VXPay.openAboPaytour();
	}
}

export {getTextLine, getTranslation, onClickGetVip};
