import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

const Labels = (props) => {
	const {isPreview, isHd, isLivestream} = props;
	if (!isPreview && !isHd && !isLivestream) {
		return null;
	}

	return (
		<div className={'vx_shaka--ui-controls -label-sized'}>
			{isPreview && <span className={isHd ? '-label-with-margin' : ''}>{Translations.get('ActorProfileAlbumPreview')}</span>}
			{isPreview && isHd && <span className="-label-with-margin">|</span>}
			{isHd && <span>HD</span>}
			{isLivestream && <span>LIVE</span>}
		</div>
	);
};

Labels.propTypes = {
	isPreview:    PropTypes.bool,
	isHd:         PropTypes.bool,
	isLivestream: PropTypes.bool,
};

Labels.defaultProps = {
	isPreview:    false,
	isHd:         false,
	isLivestream: false,
};

export default Labels;
