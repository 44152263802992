/* globals VXConfig */

import React                                                  from 'react';
import PropTypes                                              from 'prop-types';
import Modal                                                  from '../Modal';
import VXButton                                               from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DEFAULT_INVERT} from "../SimpleElements/VXButton/ButtonHelper";
import Flux                                                   from "../../flux/Flux";
import {VXButtonLineConfig}                                   from "../SimpleElements/VXButton/VXButtonConfig";
import Translations                                           from "../../utils/Translations";
import STYLES                                                 from "../SimpleElements/VXButton/EnumVXButton";
import {openAvs}                                              from "../../utils/CommuniPay";
import {formatCurrency, getStaticAmount}                      from '../../utils/CommonUtils.js';

class ModalBoxAvs extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isMobile = VXConfig.isVXMobilePlatform;

		this.state = {
			isLandscape:      this.isMobile && Flux.Browser.isLandscape(),
			bonusAvailable:   false,
			avsStatus:        Flux.Guest.getAVSStatus(),
			avsRejectMessage: Flux.Guest.getAVSRejectMessage(),
		};

		this.onWindowResize();

		this.getRef                 = this.getRef.bind(this);
		this.onWindowResize         = this.onWindowResize.bind(this);
		this.onAvsBonusStatusChange = this.onAvsBonusStatusChange.bind(this);
		this.onGuestChange          = this.onGuestChange.bind(this);
		this.startCPayAvsFlow       = this.startCPayAvsFlow.bind(this);

		Flux.Guest.isAvsBonusAvailable();
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
		Flux.Guest.addAvsBonusListener(this.onAvsBonusStatusChange);
		Flux.Guest.addGuestChangeListener(this.onGuestChange);
	}

	componentWillUnmount() {
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
		Flux.Guest.removeAvsBonusListener(this.onAvsBonusStatusChange);
		Flux.Guest.removeGuestChangeListener(this.onGuestChange);
	}

	onWindowResize() {
		this.setState({isLandscape: this.isMobile && Flux.Browser.isLandscape()});
	}

	onGuestChange() {
		if (this.state.avsStatus !== Flux.Guest.getAVSStatus() || this.state.avsRejectMessage !== Flux.Guest.getAVSRejectMessage()) {
			this.setState({
				avsStatus:        Flux.Guest.getAVSStatus(),
				avsRejectMessage: Flux.Guest.getAVSRejectMessage(),
			});
		}
	}

	onAvsBonusStatusChange(bonusAvailable) {
		this.setState({
			bonusAvailable: bonusAvailable,
		});
	}

	getRef(ref) {
		if (ref) {
			this.refModal = ref;
		}
	}

	startCPayAvsFlow() {
		openAvs();
	}

	getContent() {
		const classSuffix = this.state.isLandscape && this.isMobile ? ' -is-landscape' : (this.isMobile ? ' -is-mobile' : '');
        const bonusAmount = formatCurrency(getStaticAmount('promoAvsBonus'));

		let content;
		switch (this.state.avsStatus) {
			case Flux.Constants.AvsStatus.PENDING:
				content = (<div className={"avs-box__container"}>
					<h1 className={"avs-box__main-title" + classSuffix}>{Translations.get('ModalBoxAvsMainTitlePending')}</h1>
					{<div className="avs-box__sub-title">{Translations.get('ModalBoxAvsSubTitleShort')}</div>}
					{<div className={"avs-box__sub-title --alternative" + classSuffix}>{Translations.get('ModalBoxAvsSubTitleShort')}</div>}
					<div className="avs-box__content --column">
						<div className={"avs-box__warning-icon icon -icon-round-info-line" + classSuffix}></div>
						<div className={"avs-box__warning-text " + classSuffix}>{Translations.get('ModalBoxAvsPendingText')}</div>
						<div className={"avs-box__warning-subtext " + classSuffix}>{Translations.get('ModalBoxAvsPendingSubtext')}</div>
					</div>
					<div className={"avs-box__button" + classSuffix}>
						<VXButton
							color={COLOR_TYPE_DEFAULT_INVERT}
							border={STYLES.BORDER.OUTLINE}
							line1={[new VXButtonLineConfig(Translations.get('ModalBoxAvsButtonTextPending'))]}
							onClick={() => {
								this.startCPayAvsFlow();
								this.props.onClose();
							}}
						/>
					</div>
				</div>);
				break;
			case Flux.Constants.AvsStatus.REJECTED:
				content = (<div className={"avs-box__container"}>
					<h1 className={"avs-box__main-title" + classSuffix}>{Translations.get('ModalBoxAvsMainTitleRejected')}</h1>
					{<div className="avs-box__sub-title">{Translations.get('ModalBoxAvsSubTitleShort')}</div>}
					{<div className={"avs-box__sub-title --alternative" + classSuffix}>{Translations.get('ModalBoxAvsSubTitleShort')}</div>}
					<div className="avs-box__content --column">
						<div className={"avs-box__warning-icon h-color-highlight icon -icon-round-warning-line" + classSuffix}></div>
						<div className={"avs-box__warning-text h-color-highlight" + classSuffix}>{Translations.get('ModalBoxAvsRejectedText')}</div>
						<div className={"avs-box__warning-subtext " + classSuffix}>{this.state.avsRejectMessage}</div>
					</div>
					<div className={"avs-box__button" + classSuffix}>
						<VXButton
							color={COLOR_TYPE_DEFAULT_INVERT}
							border={STYLES.BORDER.OUTLINE}
							line1={[new VXButtonLineConfig(Translations.get('ModalBoxAvsButtonTextRejected'))]}
							onClick={() => {
								this.startCPayAvsFlow();
								this.props.onClose();
							}}
						/>
					</div>
				</div>);
				break;
			default:
				content = (<div className={"avs-box__container"}>
					<h1 className={"avs-box__main-title" + classSuffix}>{Translations.get('ModalBoxAvsMainTitle')}</h1>
					{<div className="avs-box__sub-title">{Translations.get('ModalBoxAvsSubTitle')}</div>}
					{<div className={"avs-box__sub-title --alternative" + classSuffix}>{Translations.get('ModalBoxAvsSubTitleShort')}</div>}
					<div className="avs-box__content">
						<div className="avs-box__content__text-container">
							{!this.state.bonusAvailable &&
							<div className={"avs-box__main-header" + classSuffix}>{Translations.get('ModalBoxAvsMainHeader')}</div>}
							{this.state.bonusAvailable && <div className={"avs-box__main-header" + classSuffix}
							                                   dangerouslySetInnerHTML={{__html: Translations.get('ModalBoxAvsMainHeaderGiftAmount').replace(':amount', bonusAmount)}}
							/>}

							<div className="avs-box__attraction">
								<span className="icon -icon-round-success-full h-color-success"></span>
								<span className="avs-box__attraction__text">{Translations.get('ModalBoxAvsAttraction1')}</span>
							</div>
							<div className="avs-box__attraction">
								<span className="icon -icon-round-success-full h-color-success"></span>
								<span className="avs-box__attraction__text">{Translations.get('ModalBoxAvsAttraction2')}</span>
							</div>
							<div className="avs-box__attraction">
								<span className="icon -icon-round-success-full h-color-success"></span>
								<span className="avs-box__attraction__text">{Translations.get('ModalBoxAvsAttraction3')}</span>
							</div>
							<div className={"avs-box__credibility-text" + classSuffix}>
								<p dangerouslySetInnerHTML={{__html: Translations.get('ModalBoxAvsCredibilityText')}}></p>
							</div>
						</div>
						<div className="avs-box__content__image-container">
							<img src={"/assets/img/AVS/avs_dancing_girl.jpg"} />
						</div>
					</div>
					<div className={"avs-box__button" + classSuffix}>
						<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
						          line1={[new VXButtonLineConfig(Translations.get('ModalBoxAvsButtonText'))]}
						          onClick={() => {
							          this.startCPayAvsFlow();
							          this.props.onClose();
						          }}
						/>
					</div>
				</div>);
		}

		return content;
	}

	render() {
		const classSuffix = this.state.isLandscape && this.isMobile ? ' -is-landscape' : (this.isMobile ? ' -is-mobile' : '');

		return <Modal containerWidth={1100}
		              dynamicSize={true}
		              className={"avs-box__modal -theme-dialog -no-padding -relative" + classSuffix}
		              ref={this.getRef}
		              onClose={this.props.onClose}
		>
			{this.getContent()}
		</Modal>;
	}
}

ModalBoxAvs.propTypes = {
	onClose: PropTypes.func,
	onFlowStart: PropTypes.func,
	onFlowEnd: PropTypes.func,
};

ModalBoxAvs.defaultProps = {
	onClose: () => {},
};

export default ModalBoxAvs;
