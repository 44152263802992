import React     from 'react';
import PropTypes from 'prop-types';

class VolumeBar extends React.PureComponent {

	constructor(props) {
		super(props);

		const volume = this.props.video.muted ? 0 : 1;
		this.state   = {
			previousVolume: volume,
			volume:         volume,
		};

		this.onVolumeChange = this.onVolumeChange.bind(this);
		this.onSliderChange = this.onSliderChange.bind(this);
		this.onClickMute    = this.onClickMute.bind(this);
	}

	componentDidMount() {
		this.props.video.addEventListener('volumechange', this.onVolumeChange);
	}

	componentWillUnmount() {
		this.props.video.removeEventListener('volumechange', this.onVolumeChange);
	}

	onVolumeChange() {
		if (this.props.video.muted && this.props.video.volume > 0) {
			const prevVolume = this.state.volume;
			this.setState({previousVolume: prevVolume, volume: 0}, () => {
				this.props.video.volume = 0;
			});
		} else {
			this.setState({volume: this.props.video.volume});
		}
	}

	onSliderChange(e) {
		if (this.props.video.muted && e.target.value > 0) {
			this.props.video.muted = false;
		}

		this.props.video.volume = e.target.value / 100;
	}

	onClickMute() {
		if (this.props.video.muted) {
			this.props.video.muted  = false;
			this.props.video.volume = this.state.previousVolume > 0 ? this.state.previousVolume : 1;
		} else {
			this.setState({previousVolume: this.state.volume}, () => {
				this.props.video.volume = 0;
				this.props.video.muted  = true;
			});
		}
	}

	render() {
		const sliderStyle = {
			background: 'linear-gradient(90deg, white ' + this.state.volume * 100 + '%, grey ' + this.state.volume * 100 + '%)',
			padding:    '0', // IE 11 adds a weird padding to the input element
		};

		return <div className={'vx_shaka--ui-controls vx_shaka--ui-controls-volumebar vx_shaka--ui-ms-slider'}>
			<span className={'icon ' + (this.state.volume === 0 ? '-icon-speaker-muted-full' : '-icon-speaker-full')} onClick={this.onClickMute} />
			{ !this.props.isMobile &&  <input onChange={this.onSliderChange} // needed for IE
			       onClick={this.onSliderChange}
			       style={sliderStyle}
			       type="range"
			       min="0"
			       max="100"
			       value={this.state.volume * 100}
			       className="vx_shaka--ui-controls-volumebar-slider"
			                           /> }
		</div>;
	}

}

VolumeBar.propTypes = {
	video:    PropTypes.instanceOf(HTMLVideoElement).isRequired,
	isMobile: PropTypes.bool,
};

VolumeBar.defaultPropTypes = {
	isMobile: false,
};

export default VolumeBar;
