import React     from 'react';
import PropTypes from 'prop-types';

class SeekBar extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			thumbnail:      null,
			sliderPosition: null,
			showThubmnail:  false,
			maxSliderValue: 10000,
			touchStart:     false,
			sliderValue:    0,
		};

		this.slider = React.createRef();

		this.onTimeUpdate   = this.onTimeUpdate.bind(this);
		this.onSliderChange = this.onSliderChange.bind(this);
		this.onMouseMove    = this.onMouseMove.bind(this);
		this.showThubmnail  = this.showThubmnail.bind(this);
		this.hideThubmnail  = this.hideThubmnail.bind(this);
		this.onTouchStart   = this.onTouchStart.bind(this);
		this.onTouchEnd     = this.onTouchEnd.bind(this);
		this.getCurrentTime = this.getCurrentTime.bind(this);
		this.onTouchChange  = this.onTouchChange.bind(this);
		this.onInputChange  = this.onInputChange.bind(this);
	}

	componentDidMount() {
		this.props.video.addEventListener('timeupdate', this.onTimeUpdate);
	}

	componentWillUnmount() {
		this.props.video.removeEventListener('timeupdate', this.onTimeUpdate);
	}

	getCurrentTime(value) {
		return value / 100 / 100 * this.props.video.duration;
	}

	onTimeUpdate() {
		if (!this.props.isMobile || !this.state.touchStart) {
			this.forceUpdate();
		}
	}

	onSliderChange(e) {
		if (!this.props.isMobile) {
			this.props.video.currentTime = this.getCurrentTime(e.target.value);
			this.forceUpdate();
		} else {
			this.onTouchChange(e);
		}
	}

	onTouchStart() {
		if (!this.props.isMobile) {
			return;
		} //only for mobile, no tablet
		this.setState({
			showThubmnail: true,
			touchStart:    true,
		});
	}

	onTouchEnd() {
		if (!this.props.isMobile) {
			return;
		} //only for mobile, no tablet
		this.props.video.currentTime = this.getCurrentTime(this.state.sliderValue);
		this.forceUpdate();
		this.setState({
			showThubmnail: false,
			touchStart:    false,
		});
	}

	showThubmnail() {
		this.setState({
			showThubmnail: true,
		});
	}

	hideThubmnail() {
		this.setState({
			showThubmnail: false,
		});
	}

	onTouchChange(e) {
		const sliderWidth   = this.slider.current.offsetWidth;
		const touchPosition = (((e.target.value) / this.state.maxSliderValue) * (sliderWidth));
		this.setThumbnail(touchPosition, e);
	}

	onMouseMove(e) {
		if (this.props.isMobile) {
			return;
		}
		const sliderLeft     = this.slider.current.getBoundingClientRect().left;
		const sliderPosition = e.clientX - sliderLeft + 1;
		this.setThumbnail(sliderPosition, e);
	}

	onInputChange(e) {
		this.setState({
			sliderValue: e.target.value,
		});
	}

	setThumbnail(sliderPosition, e) {
		if (!this.props.textTrackCues) {
			return;
		}

		const sliderWidth   = this.slider.current.offsetWidth;
		const duration      = this.props.video.duration;
		const textTrackCues = this.props.textTrackCues;
		const calcTime      = (duration / sliderWidth) * (sliderPosition);

		Object.keys(textTrackCues).map((cueIndex) => {
			const cue = textTrackCues[cueIndex];

			if (calcTime > cue.startTime && calcTime < cue.endTime) {
				this.setState({
					thumbnail:   this.getTextTrackData(cue.text),
					sliderPosition,
					sliderValue: e.target.value,
				});
			}
		});
	}

	getTextTrackData(textTrackString) {
		const image = textTrackString.split('#')[0];
		const coord = textTrackString.split('=')[1].split(',');

		return {
			image,
			x: parseInt(coord[0]),
			y: parseInt(coord[1]),
			w: parseInt(coord[2]),
			h: parseInt(coord[3]),
		};
	}

	getThumbnail() {
		if (!this.state.thumbnail || !this.state.sliderPosition || !this.state.showThubmnail || !this.props.webVTTImages) {
			return null;
		}
		const imgSrc = this.props.webVTTImages.find(img => img.includes(this.state.thumbnail.image));
		if (!imgSrc) {
			return null;
		}

		let containerLeft    = 0;
		const arrowWidth     = 10; // arrow width / 2
		const thumbnailWidth = this.state.thumbnail.w / 2;
		const sliderPosition = this.state.sliderPosition;
		const sliderWidth    = this.slider.current.offsetWidth;
		let maxContainerLeft = sliderPosition + containerLeft;
		let arrowPosition    = sliderPosition;

		if (this.props.isMobile) {
			// calculate with width of thumb
			containerLeft = ((this.state.sliderValue / this.state.maxSliderValue) * -20) + 10;
			arrowPosition = sliderPosition + containerLeft;
			// special handling if thumbnail reaches left side - mobile
			if (thumbnailWidth >= sliderPosition) {
				maxContainerLeft = thumbnailWidth;
				if (arrowPosition - arrowWidth > 0) {
					arrowPosition = sliderPosition + containerLeft;
				} else {
					arrowPosition = arrowWidth;
				}
			} else// special handling if thumbnail reaches right side - mobile
			if ((sliderPosition - 3) >= (sliderWidth - (thumbnailWidth))) {
				maxContainerLeft = sliderWidth - thumbnailWidth + 3;
				arrowPosition    = sliderPosition + containerLeft;
				if ((arrowPosition + arrowWidth) < sliderWidth) {
					arrowPosition = sliderPosition + containerLeft;
				} else {
					arrowPosition = sliderWidth - arrowWidth;
				}
			}
		} else {
			// special handling if thumbnail reaches left side - Desktop
			if ((thumbnailWidth - arrowWidth) >= sliderPosition) {
				maxContainerLeft = thumbnailWidth - arrowWidth;
			} else // special handling if thumbnail reaches right side - Desktop
			if ((sliderPosition >= (sliderWidth - (thumbnailWidth - arrowWidth))) && !((sliderPosition + thumbnailWidth) < window.innerWidth)) {
				maxContainerLeft = sliderWidth - thumbnailWidth + arrowWidth + 2;
			}
		}

		const containerTop   = this.props.isMobile ? `-${this.state.thumbnail.h + 28}px` : `-${this.state.thumbnail.h + 20}px`;
		const containerStyle = {
			height: `${this.state.thumbnail.h}px`,
			width:  `${this.state.thumbnail.w}px`,
			left:   `${maxContainerLeft}px`,
			top:    containerTop,
		};

		const thumbnailStyle = {
			height: `${this.state.thumbnail.h}px`,
			width:  `${this.state.thumbnail.w}px`,
			left:   0,
			top:    0,
		};

		const imgStyle = {
			position: 'relative',
			left:     `-${this.state.thumbnail.x}px`,
			top:      `-${this.state.thumbnail.y}px`,
		};

		const arrowStyle = {
			left: `${arrowPosition}px`,
		};

		return (
			[
				<div className={"vx_shaka--ui-thumbnail-container__arrow"} style={arrowStyle}></div>,
				<div className={"vx_shaka--ui-thumbnail-container"} style={containerStyle}>
					<div className={"vx_shaka--ui-thumbnail"} style={thumbnailStyle}>
						<img src={imgSrc} className={"vx_shaka--ui-thumbnail-image"} style={imgStyle} />
					</div>
				</div>,
			]);
	}

	render() {
		let positionInPercent;
		if (this.props.isMobile && this.state.touchStart) {
			positionInPercent = this.state.sliderValue / 100;
		} else {
			positionInPercent = 100 / this.props.video.duration * this.props.video.currentTime;
		}

		if (positionInPercent && !isFinite(positionInPercent)) {
			positionInPercent = 0;
		}
		const sliderStyle = {
			background: 'linear-gradient(90deg, #FF4D3C ' + positionInPercent + '%, white ' + positionInPercent + '%)',
			padding:    '0', // IE 11 adds a weird padding to the input element
		};

		return (
			<div className={'vx_shaka--ui-controls vx_shaka--ui-controls-seekbar vx_shaka--ui-ms-slider'}>
				{this.getThumbnail()}
					{!this.props.isMobile && <input onChange={this.onSliderChange}    // important for IE and drag thumb - Desktop
						style={sliderStyle}
						type="range"
						min="0"
						max={this.state.maxSliderValue}
						className={"vx_shaka--ui-controls-seekbar-slider vx_shaka--ui-controls-seekbar-slider--shadow"}
						ref={this.sliderShadow}
						value={positionInPercent * 100}
						onInput={this.onInputChange}         // handler for input change - Desktop / Mobile
						onMouseMove={this.onMouseMove}    // move Thumbnail by pointer - Desktop
						onMouseEnter={this.showThubmnail} // show Thumbnail if mouse hover over seekbar - Desktop
						onMouseLeave={this.hideThubmnail} // hide Thumbnail if mouse hover over seekbar - Desktop
						onTouchStart={this.onTouchStart}  // special handling for touch gestures to show thumbnail - Mobile
						onTouchEnd={this.onTouchEnd}      // special handling for touch gestures to hide thumbnail - Mobile
					/>}

					<input onChange={this.onSliderChange}    // important for IE and drag thumb - Desktop
						style={sliderStyle}
						type="range"
						min="0"
						max={this.state.maxSliderValue}
						className={"vx_shaka--ui-controls-seekbar-slider"}
						ref={this.slider}
						value={positionInPercent * 100}
						onInput={this.onInputChange}         // handler for input change - Desktop / Mobile
						onMouseMove={this.onMouseMove}    // move Thumbnail by pointer - Desktop
						onMouseEnter={this.showThubmnail} // show Thumbnail if mouse hover over seekbar - Desktop
						onMouseLeave={this.hideThubmnail} // hide Thumbnail if mouse hover over seekbar - Desktop
						onTouchStart={this.onTouchStart}  // special handling for touch gestures to show thumbnail - Mobile
						onTouchEnd={this.onTouchEnd}      // special handling for touch gestures to hide thumbnail - Mobile
					/>
			</div>
		);
	}

}

SeekBar.propTypes = {
	video:         PropTypes.instanceOf(HTMLVideoElement).isRequired,
	textTrackCues: PropTypes.object,
	webVTTImages:  PropTypes.array,
	isMobile:      PropTypes.bool,
};

export default SeekBar;
