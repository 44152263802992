import React                                           from 'react';
import PropTypes                                       from 'prop-types';
import Translations                                    from '../../utils/Translations';
import Flux                                            from '../../flux/Flux';
import VXButton                                        from '../SimpleElements/VXButton/VXButton';
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DEFAULT} from '../SimpleElements/VXButton/ButtonHelper';
import {VXButtonLineConfig, VXButtonLineStyle}         from "../SimpleElements/VXButton/VXButtonConfig";
import STYLES                                          from "../SimpleElements/VXButton/EnumVXButton";
import GuestAccess                                     from '../../utils/GuestAccess';
import ReloadHelper                                    from '../../utils/ReloadHelper';
import {formatCurrency}                                from "../../utils/CommonUtils";
import Pin                                             from "../Favorite/Actor/Pin";
import MediaPin                                        from '../Favorite/Actor/MediaPin.js';

const VIDEO = 'video';

class DefaultPlate extends React.Component {

	static onClickMediaPin(e) {
		if (e.preventDefault) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			photoVoucherCount: Flux.Guest.getVoucherCountByType(Flux.Constants.VoucherTypes.TYPE_PHOTO_ALBUM, this.props.item.actorId),
		};

		this.domParser    = new DOMParser;
		this.buyButtonRef = null;

		this.onBuyClick         = this.onBuyClick.bind(this);
		this.onLinkClick        = this.onLinkClick.bind(this);
		this.reloadVoucherCount = this.reloadVoucherCount.bind(this);
		this.isModelProfile     = this.isModelProfile.bind(this);
	}

	componentDidMount() {
		Flux.Guest.addGuestChangeListener(this.reloadVoucherCount);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.item.actorId !== this.props.item.actorId) {
			this.reloadVoucherCount();
		}
	}

	componentWillUnmount() {
		Flux.Guest.removeGuestChangeListener(this.reloadVoucherCount);
	}

	isModelProfile() {
		const actorTargetPathname  = this.props.item.targetUrl;
		const currentPathname      = window.location.pathname;

		if(actorTargetPathname.includes(currentPathname)) {
			return true;
		}
	}

	reloadVoucherCount() {
		this.setState({
			photoVoucherCount: Flux.Guest.getVoucherCountByType(Flux.Constants.VoucherTypes.TYPE_PHOTO_ALBUM, this.props.item.actorId),
		});
	}

	onLinkClick(e) {
		this.props.onClickFn(e, this.props.item);
	}

	onBuyClick(e) {
		const data = this.props.item;

		e.preventDefault();
		e.stopPropagation();

		GuestAccess.onEnoughMoneyForShop(
			data.actorId,
			data.price,
			data.isOver18Content && !Flux.Guest.hasAvs(),
			data.isVipContent && !Flux.Guest.isVip(),
			() => {
				const clb = (actorId, changedAlbumId) => {
					if (changedAlbumId === data.id) {
						Flux.Gallery.removeGalleryChangeListener(clb);
						ReloadHelper.reload(data.targetUrl);
					}
				};
				Flux.Gallery.addGalleryChangeListener(clb);
				Flux.Gallery.buyAlbum(data.id);
			},
			undefined,
			data.voucherPrice > 0
		);
	}

	render() {
		let colClassData, colClassButton;
		if (!this.props.isMobile && this.props.isPlateExtended && this.props.showButton && !this.props.showMediaPin) {
			colClassData = 'col-xs-8';
		} else if (this.props.showMediaPin) {
			colClassData = 'col-xs-10';
		} else {
			colClassData = 'col-xs-12';
		}
		if (!this.props.isMobile && !this.props.showMediaPin) {
			colClassButton = 'col-xs-4';
		} else if (!this.props.isMobile && this.props.showMediaPin) {
			colClassButton = 'col-xs-2';
		} else {
			colClassButton = 'col-xs-12';
		}

		const item         = this.props.item;
		const voucherPrice = item.voucherPrice ? item.voucherPrice : 0;

		const hintClass = !this.props.isMobile && this.props.isPlateExtended ? '' : ' h-color-fg-description';

		let button = null;
		let line1, line2, icon, onClick, animateSuccess, color;
		if (this.props.isPlateExtended && this.props.showButton) {
			if ((Flux.Guest.isLoggedIn() || Flux.Guest.isAnonymousVoicecall()) && item.needBuying) {
				color = COLOR_TYPE_CALL_TO_ACTION;
				line1 = [new VXButtonLineConfig(Translations.get('Buy'), new VXButtonLineStyle([STYLES.UPPERCASE]))];
				if (voucherPrice !== 0 && this.state.photoVoucherCount >= voucherPrice) {
					line2          = [new VXButtonLineConfig(voucherPrice + " VOUCHER")];
					icon           = "-icon-voucher-icon";
					onClick        = this.onBuyClick;
					animateSuccess = true;
				} else {
					line2          = [new VXButtonLineConfig(formatCurrency(item.price))];
					icon           = "vxbutton-icon-cart-full";
					onClick        = this.onBuyClick;
					animateSuccess = true;
				}
			} else {
				color          = COLOR_TYPE_DEFAULT;
				line1          = [new VXButtonLineConfig(Translations.get('View'), new VXButtonLineStyle([STYLES.UPPERCASE]))];
				line2          = [new VXButtonLineConfig(Translations.get('ViewAlbum'))];
				icon           = "-icon-image-album-lite";
				onClick        = this.onLinkClick;
				animateSuccess = true;
			}
			button =
				<div className={colClassButton + ' h-text-align-right'}>
					<VXButton
						color={color}
						line1={line1}
						line2={line2}
						icon={icon}
						ref={ref => (this.buyButtonRef = ref)}
						onClick={onClick}
						animateSuccess={animateSuccess}
						size={this.props.isMobile ? "full" : "small"}
					/>
				</div>;

		} else if (this.props.showMediaPin) {

            let mediaPins;
			let actorFavoritePin;

			if (this.props.item.mediaType === "photo_album" || this.props.item.mediaType === "video") {
                actorFavoritePin = <Pin albumId={this.props.item.id}
				                actorId={this.props.item.actorId}
				                initialIsFavorite={true}
				                parentTileIndex={this.props.tileIndex}
				                mediaType={this.props.item.mediaType}
				                fontSize="-size-xs"
				                initialIsFavoriteOfGuest={false}
				/>;
			} else if (this.props.item.mediaType === "sedcard") {
                actorFavoritePin = <Pin actorId={this.props.item.actorId}
				                initialIsFavorite={true}
				                parentTileIndex={this.props.tileIndex}
				                mediaType={this.props.item.mediaType}
				                fontSize="-size-xs"
				                initialIsFavoriteOfGuest={false}
				/>;
			}

            mediaPins = <>
                {actorFavoritePin}
                <MediaPin actorId={this.props.item.actorId}
                          albumId={parseInt(this.props.item.id)}
                          initialIsFavorite={this.props.isPinned}
                          pinOnClickFn={this.props.pinOnClickFn}
                />
            </>;

			button =
				<div onClick={DefaultPlate.onClickMediaPin} className={this.props.isMobile ? 'context-box--media-box__icons' : 'h-text-align-right context-box--media-box__icons'}>
					{mediaPins}
				</div>;
		}

		const albumTitle = (this.props.isPinnedPhoto) ? Translations.get('Album') + ": " + item.title : item.title;

		let decodedTitle = '';
		try {
			const tmp    = this.domParser.parseFromString(albumTitle, 'text/html');
			decodedTitle = tmp.body.textContent;
		} catch (e) {
			// do nothing
		}

		let soundElement = null;
		if (item.mediaType === VIDEO && item.audioType === '') {
			soundElement =
				<span className="content-box--media-box__subline__element h-right h-color-fg-description"><i className="icon -icon-speaker-muted-full" /></span>;
		}

		const ContentTitle = this.isModelProfile() ? 'h3' : 'div';

		return (
			<div className={'context-box--media-box__plate row' + (this.props.isPlateExtended ? ' -extended-plate' : '') + (this.props.isMobile ? ' h-justify-content-spaces-around' : '') + ' clearfix'}>
				<div className={colClassData + ' h-height-full'}>
					{this.props.iconClass &&
						<i className={'context-box--media-box__icon icon ' + this.props.iconClass} />}
					<div>
						<ContentTitle
							className="context-box--media-box__title"
							title={decodedTitle}
							dangerouslySetInnerHTML={{__html: albumTitle}}
						/>
						{this.props.isPlateExtended &&
							<div className="context-box--media-box__description h-color-fg-description"
							     dangerouslySetInnerHTML={{__html: item.description}}
						/>}
					</div>

					<div className="context-box--media-box__subline">
						{this.props.hintText &&
							<span className={'content-box--media-box__subline__element h-left' + hintClass}>{this.props.hintText}</span>}
						{item.isActorNameVisible &&
							<span className="content-box--media-box__subline__element -actor-name h-left h-text-highlight">{item.actorName}</span>}
						{soundElement}
						{item.likesCount > 0 &&
							<span className={"content-box--media-box__subline__element" + ((this.props.isPlateExtended) ? " -margin-left-xl" : " h-right")}>
							<i className="icon -icon-like-full h-color-highlight" /> <span>{item.likesCount}</span>
						</span>}
					</div>
				</div>
				{button}
			</div>
		);
	}
}

DefaultPlate.propTypes = {
	hintText:        PropTypes.string,
	iconClass:       PropTypes.string,
	isMobile:        PropTypes.bool,
	isPlateExtended: PropTypes.bool,
	item:            PropTypes.object.isRequired,
	onClickFn:       PropTypes.func.isRequired,
    pinOnClickFn:    PropTypes.func,
	showButton:      PropTypes.bool,
	showMediaPin:    PropTypes.bool,
	isPinnedPhoto:   PropTypes.bool,
	tileIndex:       PropTypes.number,
    isPinned:        PropTypes.bool,
};

DefaultPlate.defaultProps = {
	hintText:        '',
	iconClass:       '',
	isMobile:        false,
	isPlateExtended: false,
	onClickFn:       () => {
	},
    pinOnClickFn:    () => {
    },
	showButton:      false,
	isPinnedPhoto:   false,
};

export default DefaultPlate;
