import React                 from 'react';
import PropTypes             from 'prop-types';
import ModelBreakpointObject from './Models/ModelBreakpointObject';
import ModelTileSizes        from './Models/ModelTileSizes';
import Grid2Helper           from '../../utils/Grid2Helper';
import Flux                  from '../../flux/Flux';
import ModelTileConfigs      from './Models/ModelTileConfigs';
import ModelTileClasses      from './Models/ModelTileClasses';

const TilePadding = Flux.Constants.Grid2.TilePadding;

/*
 * NOTE: If you are adding anything Tile-specific to this component (e.g. badge-related), you're doing it wrong!
 */
const Grid2 = props => {
	let tileData          = [...props.tileData];
	const slider          = !!props.slider;
	const single          = !!props.single;
	const tilePadding     = props.tilePadding;
	const noMargins       = !!props.noContainerMargins;
	const columns         = new ModelBreakpointObject(slider ? 1 : props.columns);
	const defaultTileSize = new ModelBreakpointObject(slider ? 1 : props.defaultTileSize);
	const tileSizes       = new ModelTileSizes(slider ? [] : props.tileSizes, defaultTileSize);
	const tileConfigs     = new ModelTileConfigs(props.tileConfigs, props.defaultTileConfig);
	const tileClasses     = new ModelTileClasses(props.tileClasses, props.defaultTileClass);
	let containerClasses  = [];
	const bigTiles        = props.tileSizes.filter(item => item.size);
	const hasBigTile      = bigTiles.length > 0;

	!single && slider && containerClasses.push('grid-2--slider');
	containerClasses.push(getTilePaddingCssClass(tilePadding));
	noMargins && containerClasses.push('grid-2--with-no-margins');
	hasBigTile && containerClasses.push('grid-2--big');
	single && containerClasses.push('grid-2--single');
	containerClasses = [...containerClasses, getTileCssClasses(columns, slider)];

	
	if (props.onlyFullRows) {
		let tileCount = tileData.length;
		const currentBreakpoint = getColumnsFromBreakpoints(props.columns);
		hasBigTile && bigTiles.map(item => {
			tileCount += ((item.size * item.size) - 1);			
		});
		tileData = tileData.slice(0, tileData.length - (tileCount % currentBreakpoint));
	}
	
	if (tileData) {
		return (
			<div className={"grid-2__wrapper" + (slider && !single ? " grid-2__wrapper--slider" : "")}>
				<div className={'grid-2 ' + containerClasses.join(' ')}>
					{tileData.map((data, index) => {
						const tileSize          = tileSizes.getTileSizeAtIndex(index);
						const tileDisplayConfig = {tileSize, columns};
						let tileConfig          = tileConfigs.getConfigAtIndex(index);
						tileConfig              = {...tileConfig, index: index};
						const TileClass         = tileClasses.getTileClassAtIndex(index);
						const style             = {};
						const isBigTile         = bigTiles.find(item => item.index === index);

						if (isBigTile) {
							const windowWidth          = Flux.Browser.getWindowSize().width;
							const breakpointsNames     = Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE;
							const breakpoints          = Flux.Constants.Grid2.BREAKPOINT_MIN_WIDTHS;
							// find first window width that is higher || lowest -> xs 400
							const currentBreakpoint    = [...Object.keys(breakpoints)].reverse().find(item => windowWidth > breakpoints[item]) || breakpointsNames[0];
							// get all lower breakpoints in reverse order
							const availableBreakpoints = breakpointsNames.slice(0, breakpointsNames.indexOf(currentBreakpoint) + 1).reverse();
							// find current count of columns by breakpoint
							const currentCalumns       = columns.values[[...availableBreakpoints].find(item => columns.values[item])];
							// find current count of Tile Size
							const currentTileSize      = tileSize.values[[...availableBreakpoints].find(item => tileSize.values[item])];

							let columnPos = (index % currentCalumns);
							let rowPos    = parseInt(index / currentCalumns);

							// if tile will not fit, bring it to the next row
							if ((columnPos + currentTileSize) > currentCalumns) {
								columnPos = 0;
								rowPos += 1;
							}

							style['gridColumnStart'] = columnPos + 1;
							style['gridColumnEnd']   = columnPos + 1 + tileSize.values.xs;
							style['gridRowStart']    = rowPos + 1;
							style['gridRowEnd']      = rowPos + 1 + tileSize.values.xs;
						}

						return (
							<div className={'grid-2__item'} style={style} key={index}>
								<TileClass data={data}
										   key={index}
								           displayConfig={tileDisplayConfig}
								           config={tileConfig}
										   lazyImageLoading={props.lazyImageLoading}
										   headlineType={index < props.headlineLimit ? props.headlineType : 'h4'}
										   isMobile={props.isMobile}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
	return null;
};

/**
 *
 * @param {ModelBreakpointObject} columns
 * @param {ModelBreakpointObject} tileSize
 * @param {boolean} slider
 * @param {string} tilePadding
 * @returns {string}
 */
function getTileCssClasses(columns, tileSize, slider) {
	const classNames = [];

	if (slider) {
		classNames.push('grid-2--slider__item');
	} else {
		for (const breakpoint of Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE) {
			if (columns.hasValueForBreakpoint(breakpoint)) {
				const cssSizePrefix        = Grid2Helper.getCssBreakpointPrefixByBreakpoint(breakpoint);
				const matchingColumnsCount = columns.getValueAtBreakpoint(breakpoint);

				classNames.push(`grid-2--column-${cssSizePrefix}${matchingColumnsCount}`);
			}
		}
	}

	return classNames.join(' ');
}

/**
 * @param {string} tilePadding
 * @return {string}
 */
function getTilePaddingCssClass(tilePadding) {
	return `grid-2--padding-${tilePadding.toLowerCase()}`;
}

function getColumnsFromBreakpoints(breakpoints) {
	const windowWidth = Flux.Browser.getWindowSize().width;
	const BREAKPOINT  = Flux.Constants.Grid2.BREAKPOINTS;
	const WIDTH       = Flux.Constants.Grid2.BREAKPOINT_MIN_WIDTHS;

	if (windowWidth > WIDTH.xl && breakpoints[BREAKPOINT.XL]) {
		return breakpoints[BREAKPOINT.XL];
	} else if (windowWidth > WIDTH.lg && breakpoints[BREAKPOINT.LG]) {
		return breakpoints[BREAKPOINT.LG];
	} else if (windowWidth > WIDTH.md && breakpoints[BREAKPOINT.MD]) {
		return breakpoints[BREAKPOINT.MD];
	} else if (windowWidth > WIDTH.sm && breakpoints[BREAKPOINT.SM]) {
		return breakpoints[BREAKPOINT.SM];
	} 
		
	return breakpoints[BREAKPOINT.XS];
}

const TileSizeType = PropTypes.shape({
	index: PropTypes.number.isRequired,
	size:  PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			xs: PropTypes.number.isRequired,
			sm: PropTypes.number,
			md: PropTypes.number,
			lg: PropTypes.number,
			xl: PropTypes.number,
		}),
	]).isRequired,
});

const TileClassesType = PropTypes.shape({
	index:     PropTypes.number.isRequired,
	tileClass: PropTypes.elementType.isRequired,
});

const TileConfigsType = PropTypes.shape({
	index:  PropTypes.number.isRequired,
	config: PropTypes.object.isRequired,
});

Grid2.propTypes = {
	tileData:           PropTypes.arrayOf(PropTypes.object).isRequired,
	slider:             PropTypes.bool,
	single:             PropTypes.bool,
	tilePadding:        PropTypes.oneOf(Object.keys(TilePadding).map(key => TilePadding[key])),
	noContainerMargins: PropTypes.bool,
	/** Ignored in slider mode */
	columns: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			xs: PropTypes.number.isRequired,
			sm: PropTypes.number,
			md: PropTypes.number,
			lg: PropTypes.number,
			xl: PropTypes.number,
		}),
	]),
	/** Ignored in slider mode */
	tileSizes: PropTypes.oneOfType([
		TileSizeType,
		PropTypes.arrayOf(TileSizeType),
	]),
	/** Ignored in slider mode */
	defaultTileSize:   PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			xs: PropTypes.number.isRequired,
			sm: PropTypes.number,
			md: PropTypes.number,
			lg: PropTypes.number,
			xl: PropTypes.number,
		}),
	]),
	tileClasses:       PropTypes.oneOfType([
		TileClassesType,
		PropTypes.arrayOf(TileClassesType),
	]),
	defaultTileClass:  PropTypes.elementType.isRequired,
	tileConfigs:       PropTypes.oneOfType([
		TileConfigsType,
		PropTypes.arrayOf(TileConfigsType),
	]),
	defaultTileConfig: PropTypes.object,
	lazyImageLoading:  PropTypes.bool,
	headlineType:      PropTypes.string,
	headlineLimit:     PropTypes.number,
	isMobile:          PropTypes.bool,
	onlyFullRows:      PropTypes.bool,
};

Grid2.defaultProps = {
	tilePadding:        TilePadding.NORMAL,
	noContainerMargins: false,
	slider:             false,
	single:             false,
	columns:            4,
	tileSizes:          [],
	defaultTileSize:    1,
	tileClasses:        [],
	tileConfigs:        [],
	defaultTileConfig:  {},
	lazyImageLoading:   false,
	headlineType:       Flux.Constants.Headlines.h4,
	headlineLimit:      9007199254740991, // MAX_SAFE_INTEGER
	isMobile:           false,
	onlyFullRows:       false,
};

export default Grid2;

export const TileDisplayConfigType = PropTypes.shape({
	tileSize: PropTypes.instanceOf(ModelBreakpointObject), // Data type: number
	columns:  PropTypes.instanceOf(ModelBreakpointObject), // Data type: number
});