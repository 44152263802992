import React     from 'react';
import PropTypes from 'prop-types';
import Flux      from "../../flux/Flux";

const FeatureFeedbackButton = (props) => {
	let innerClassModifier   = props.popupOpen ? " is-open" : "";
	const outerClassModifier = props.hide ? " is-hidden" : "";

	if (!Flux.Browser.isIE() && !Flux.Browser.isEdge()){
		innerClassModifier += ' -animate';
	}

	return (
		<div className={"feature-feedback__button-outer" + outerClassModifier + ' ' + props.additionalClasses + ' ' + props.additionalButtonClass}>
			<div className={"feature-feedback__button-circle-wrapper"}>
				<div className={"feature-feedback__button-border" + innerClassModifier}></div>
			</div>
			<div className={"feature-feedback__button-circle-wrapper"}>
				<button onClick={props.onClick} className={"feature-feedback__button" + innerClassModifier} />
			</div>
		</div>
	);
};

FeatureFeedbackButton.propTypes = {
	popupOpen:              PropTypes.bool,
	onClick:                PropTypes.func,
	hide:                   PropTypes.bool,
	additionalClasses:      PropTypes.string,
	additionalButtonClass:  PropTypes.string,
};

FeatureFeedbackButton.defaultProps = {
	additionalClasses: '',
};


export default FeatureFeedbackButton;