import React                  from "react";
import PropTypes              from 'prop-types';
import ProSearchRewardBoxVideoTile from "./ProSearchRewardBoxVideoTile";

const LAST_REWARD_EXPIRE_TIMESTAMP = 1709765999;

const ProSearchMysteryBoxTile = (props) =>
	<ProSearchRewardBoxVideoTile
		className={props.className}
		contentBoxClassModifier={props.contentBoxClassModifier}
		eventId={props.eventId}
		isMobile={props.isMobile}
		lastRewardExpTime={LAST_REWARD_EXPIRE_TIMESTAMP}
		qmDate={props.qmDate}
		reward={props.reward}
		rewardBoxBgModifier={'-mystery-box'}
		rewardBoxVideoUrl={"/assets/img/special/mystery-box/march2024/video.mp4"}
		rewardBoxStartImgUrl={"/assets/img/special/mystery-box/march2024/first.png"}
		rewardBoxImgUrl={"/assets/img/special/mystery-box/march2024/last.png"}
		rewardBoxLogoUrl={"/assets/img/special/mystery-box/march2024/logo.svg"}
		withScaleEffect={true}
	/>;


ProSearchMysteryBoxTile.propTypes = {
	className:                PropTypes.string,
	contentBoxClassModifier:  PropTypes.string,
	eventId:                  PropTypes.number.isRequired,
	isMobile:                 PropTypes.bool,
	qmDate:                   PropTypes.number,
	reward:                   PropTypes.object,
	rewardVideoUrl:           PropTypes.string.isRequired,
	modalBackgroundUrl:       PropTypes.string,
	modalBackgroundTabletUrl: PropTypes.string,
};

ProSearchMysteryBoxTile.defaultProps = {
	className:               '',
	contentBoxClassModifier: '-padding-equal-smooth',
	isMobile:                false,
	qmDate:                  0,
	reward:                  null,
};


export default ProSearchMysteryBoxTile;
