import React from "react";
import PropTypes from 'prop-types';
import GlobalEventHandler from "./../../utils/GlobalEventHandler";
import Translations from "./../../utils/Translations";
import Flux from "./../../flux/Flux";
import MediaPin from "../../components/Favorite/Actor/MediaPin";

class ViewerControls extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: this.props.selectedIndex,
			showModal:     this.props.showModal,
			isLoggedIn:    Flux.Guest.isLoggedIn(),
		};

		this.onSelect    = this.onSelect.bind(this);
		this.onPrevClick = this.onPrevClick.bind(this);
		this.onNextClick = this.onNextClick.bind(this);
		this.onChange    = this.onChange.bind(this);
		this.onShowClick = this.onShowClick.bind(this);
	}

	componentDidMount() {
		GlobalEventHandler.addListener(GlobalEventHandler.GALLERY_SELECT, this.onSelect);

		window.setTimeout(function() {
			this.setState({
				isLoggedIn: Flux.Guest.isLoggedIn(),
			});
		}.bind(this), 0);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedIndex !== this.props.selectedIndex) {
			this.setState({
				selectedIndex: nextProps.selectedIndex,
			});
		}
		if (nextProps.showModal !== this.props.showModal) {
			this.setState({
				showModal: nextProps.showModal,
			});
		}
	}

	componentWillUnmount() {
		GlobalEventHandler.removeListener(GlobalEventHandler.GALLERY_SELECT, this.onSelect);
	}

	onSelect(instanceId, index) {
		if (this.props.instanceId === instanceId) {
			this.setState({
				selectedIndex: index,
			});
		}
	}

	onPrevClick() {
		this.onChange(-1);
	}

	onNextClick() {
		this.onChange(1);
	}

	onChange(offset) {
		this.props.onChange(offset);
	}

	onShowClick() {
		GlobalEventHandler.emit(GlobalEventHandler.GALLERY_SHOW_MODAL, this.props.instanceId);
	}

	render() {
		if (!this.props.images || this.props.images.length <= 0) {
			return false;
		}

		const countImages = this.props.images.length;
		const image       = this.props.images && this.props.images[this.state.selectedIndex];
		const imagePinned = image && this.props.images[this.state.selectedIndex].pinned;

		return (
			<div>
				{this.state.showModal && <div>
					{this.props.images.length > 1 &&
					<div className={'gallery__controls-cell -button -left -middle h-pos-absolute' + (this.props.isControlsHidden ? ' is-hidden' : '')}
					     onClick={this.onPrevClick}
					>
						<i className="icon -icon-complete-arrow-left-line" />
					</div>}
					{this.props.images.length > 1 &&
					<div className={'gallery__controls-cell -button -right -middle h-pos-absolute' + (this.props.isControlsHidden ? ' is-hidden' : '')}
					     onClick={this.onNextClick}
					>
						<i className="icon -icon-complete-arrow-right-line" />
					</div>}
				</div>}
				{!this.state.showModal && this.props.showControlsNonModal && <div className="gallery__controls h-pos-absolute">
					<div className="gallery__controls-table h-width-full">
						{this.props.images.length > 1 && <div className="gallery__controls-cell -button" onClick={this.onPrevClick}>
							<i className="icon -icon-complete-arrow-left-line" /></div>}
						<div className="gallery__controls-cell -text -wide">{Translations.get('Picture')} {this.state.selectedIndex + 1} / {countImages}</div>
						{this.state.isLoggedIn &&
						<div className="gallery__controls-cell -button">
							<MediaPin pictureId={image.id} initialIsFavorite={imagePinned} tooltipPlacement="top" />
						</div>}
						<div className="gallery__controls-cell -button" onClick={this.onShowClick}>
							<i className="icon -icon-fullscreen-player-open-line" /></div>
						{this.props.images.length > 1 && <div className="gallery__controls-cell -button" onClick={this.onNextClick}>
							<i className="icon -icon-complete-arrow-right-line" /></div>}
					</div>
				</div>}
			</div>
		);
	}
}

ViewerControls.propTypes = {
	images:               PropTypes.array,
	instanceId:           PropTypes.string,
	isControlsHidden:     PropTypes.bool,
	onChange:             PropTypes.func,
	selectedIndex:        PropTypes.number,
	showControlsNonModal: PropTypes.bool,
	showModal:            PropTypes.bool,
};

ViewerControls.defaultProps = {
	isControlsHidden:     false,
	selectedIndex:        0,
	showControlsNonModal: true,
	showModal:            false,
};

export default ViewerControls;
