import Flux from '../../flux/Flux';

// EVENT-TRACKING
const EVENT_TYPE_ROTATOR_MUTE      = 'welcome_clip_rotator_mute';
const EVENT_TYPE_ROTATOR_PLAYING   = 'welcome_clip_rotator_playing';
const EVENT_TYPE_ROTATOR_PROFILE   = 'welcome_clip_rotator_profile';
const EVENT_TYPE_ROTATOR_SHOWN     = 'welcome_clip_rotator_shown';
const EVENT_TYPE_ROTATOR_SKIP      = 'welcome_clip_rotator_skip';
const EVENT_TYPE_ROTATOR_UNMUTE    = 'welcome_clip_rotator_unmute';
const EVENT_TYPE_ROTATOR_USER_PLAY = 'welcome_clip_rotator_user_play';

const CONTEXT = 'welcome_clip_rotator';

export default {
	eventTracker: Flux.Tracker.getEventTracker(),

	trackRotatorShown() {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_SHOWN;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';

		this.eventTracker.trackEvent(event);
	},

	trackPlaying({playlistItem}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_PLAYING;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'albumId=' + playlistItem.albumId;

		this.eventTracker.trackEvent(event);
	},

	trackProfile({actorId, albumId}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_PROFILE;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'actorId=' + actorId + ';albumId=' + albumId;

		this.eventTracker.trackEvent(event);
	},

	trackSkip({playlistItem, prevAlbumId}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_SKIP;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'prevAlbumId=' + prevAlbumId + ';albumId=' + playlistItem.albumId;

		this.eventTracker.trackEvent(event);
	},

	trackMute({playlistItem}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_MUTE;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'albumId=' + playlistItem.albumId;

		this.eventTracker.trackEvent(event);
	},

	trackUnmute({playlistItem}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_UNMUTE;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'albumId=' + playlistItem.albumId;

		this.eventTracker.trackEvent(event);
	},

	trackUserPlay({playlistItem}) {
		// Init Event
		const event   = this.eventTracker.getNewEvent();
		event.type    = EVENT_TYPE_ROTATOR_USER_PLAY;
		event.source  = 'www';
		event.context = CONTEXT;
		event.data    = 'url=' + window.location + ';';
		event.data += 'albumId=' + playlistItem.albumId;

		this.eventTracker.trackEvent(event);
	},

};
