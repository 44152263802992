/* globals VXConfig */
import React                  from 'react';
import PropTypes              from 'prop-types';
import Modal                  from '../Modal';
import Flux                   from '../../flux/Flux';
import LogoLoader             from "../Loader/LogoLoader.js";
import Grid2                  from "../Grid2/Grid2.js";
import VideoTile              from "../Grid2/Tiles/VideoTile.js";
import withVideosGrid2Adapter from "../HigherOrderComponents/Videos/withVideosGrid2Adapter.js";
import reloadHelper           from "../../utils/ReloadHelper.js";
import TileGrid               from "../TileGrid/TileGrid.js";
import Tile                   from "../TileGrid/Tile.js";
import PlateFactory           from "../TileGrid/Plate/PlateFactory.js";
import UrlBuilder             from "../../utils/UrlBuilder.js";
import {getCommonTranslation} from "../../utils/TranslationHelper.js";
import {getCurrencySymbol}    from '../../utils/CommonUtils.js';

const GetVideo = ({isMobile, ...props}) => {
	// override stuff and disable click callback
	props.tileData[0].callback         = () => false;
	props.tileData[0].guestInfo.gifted = false;
	props.tileData[0].guestInfo.bought = false;
	props.tileData[0].classic          = false;
	props.tileData[0].free             = true;

	return (
		<Grid2
			{...props}
			defaultTileClass={VideoTile}
			columns={{xs: 1, sm: 1, md: 1, lg: 1}}
			defaultTileConfig={{footer: {show: {likes: false}}, badges: {textBadges: {show: {price: Flux.Guest.isLoggedIn()}}}}}
			tileSizes={[{index: 0, size: isMobile ? 1 : 2}]}
		/>
	);
};

GetVideo.propTypes = {
	isMobile: PropTypes.bool,
	tileData: Grid2.propTypes.tileData,
};

const GetPhoto = ({isMobile, photos}) => {
	const gallery = photos[0];

	const previews           = isMobile ? gallery.previews.slice(0, 1) : gallery.previews;
	const previewPictureUrls = previews.map(item => item.images.map(itemImages => ({
		id:         item.id,
		format:     itemImages.size,
		src:        itemImages.url,
		width:      item.width,
		height:     item.height,
		isPortrait: item.orientation === 'portrait',
	})));

	// TileGrid adapter
	const gridConfig = {
		cols:         1,
		initialTiles: [
			{
				tileIdentifier:             'special-' + gallery.id,
				id:                         gallery.id,
				previewPictureUrls,
				title:                      gallery.title,
				targetUrl:                  gallery.linkVX,
				description:                gallery.description,
				price:                      0,
				isShowPlateOnHover:         false,
				pictureLayout:              "4x4",
				isAccessAllowed:            true,
				actorId:                    gallery.model.id,
				actorName:                  gallery.model.name,
				isActorNameVisible:         true,
				type:                       Tile.type.PHOTO_ALBUM_GRID_TILE,
				mediaType:                  Flux.Constants.MediaTypes.PHOTO_ALBUM,
				showPreviewPicturesOnHover: false,
				plateType:                  PlateFactory.type.DEFAULT_PLATE,
				elementCount:               gallery.photosCount,
			}
		],
		showButton:   false,
		crop:         true,
		pagination:   {},
		sortOptions:  [],
	};

	return <TileGrid gridConfig={gridConfig} guestIsLoggedIn={true} useTileWithSecondary={true} isMobile={isMobile} />;
};

GetPhoto.propTypes = {
	isMobile: PropTypes.bool,
	photos:   PropTypes.array,
};

const getActorName = (model) => [10641720, 12135526, 11252407].includes(model.id) ? model.name : 'Liaengel, LanaGrey & Mia-Nouvelle';

class ModalBoxSpecialTopUp extends React.Component {

	constructor(props) {
		super(props);

		// set initial state
		this.state = {};

		this.modalRef          = null;
		this.setModalRef       = this.setModalRef.bind(this);
		this.onCloseClick      = this.onCloseClick.bind(this);
		this.onRedeemClick     = this.onRedeemClick.bind(this);
		this.onBonusCodeResult = this.onBonusCodeResult.bind(this);
	}

	componentDidMount() {
		Flux.Guest.getBonusCode(this.props.code, this.onBonusCodeResult);
	}

	setModalRef(ref) {
		if (ref) {
			this.modalRef = ref;
		}
	}

	onCloseClick() {
		if (this.modalRef) {
			this.props.onClose();
			this.modalRef.removeModal();
		}
	}

	onRedeemClick(e) {
		if (this.modalRef) {
			this.modalRef.removeModal();
		}

		if (this.state.reward?.targetUrl) {
			reloadHelper.reload(this.state.reward.targetUrl, e);
		}
	}

	onBonusCodeResult(result) {
		const benefit = result?.data?.bonusCode?.config?.benefits[0];

		// which benefit we have?
		switch (benefit?.__typename) {
			case Flux.Constants.BenefitsType.MEDIA:
				const ids = benefit.albumIdStaggering.map((item) => item.id);

				// try to load videos
				new Promise((resolve) => {
					Flux.Vxql.getVideosFromVXQL({ids, types: [{bought: true}]}).then((result) => {
						const video = result.data.videos.items[0];
						if (video) {
							resolve(video);
						}
					});
				}).then((video) => {
					const actorName = getActorName(video.model);

					this.setState({
						reward: {
							type:      Flux.Constants.RewardTypes.VIDEO,
							targetUrl: video.linkVX,
							info:      getCommonTranslation('modal-special-topup__reward-info--video', actorName),
							video
						}
					});
				});

				// try to load photo albums
				new Promise((resolve) => {
					Flux.Vxql.getPhotoAlbumsFromVXQL({ids, types: [{bought: true}]}).then((result) => {
						const gallery = result.data.galleries.items[0];
						if (gallery) {
							resolve(gallery);
						}
					});
				}).then((gallery) => {
					const actorName = getActorName(gallery.model);

					this.setState({
						reward: {
							type:      Flux.Constants.RewardTypes.PHOTO_ALBUM,
							targetUrl: gallery.linkVX,
							info:      getCommonTranslation('modal-special-topup__reward-info--photo', actorName),
							gallery
						}
					});
				});

				break;
			case Flux.Constants.BenefitsType.BONUS:
				const bonus = (benefit.bonusTopUpFlat ? benefit.bonusTopUpFlat + getCurrencySymbol() : benefit.bonusTopUpPercent + '%');

				this.setState({
					reward: {
						type:      Flux.Constants.RewardTypes.ONTOP,
						targetUrl: UrlBuilder.buildCamsRoute(),
						bonus
					}
				});
				break;
		}
	}


	render() {
		const reward = this.state.reward;
		let content  = <LogoLoader />;

		switch (reward?.type) {
			case Flux.Constants.RewardTypes.VIDEO:
				const GetVideoComponent = withVideosGrid2Adapter(GetVideo);

				content = (
					<div className={'modal-special-topup__content-container'}>
						<div className={'modal-special-topup__content-container__headline'}>{getCommonTranslation('modal-special-topup__headline')}</div>
						<div className={'modal-special-topup__content-container__bonus-subline'}>{reward.info}</div>
						<div className={'modal-special-topup__content-container__content'}>
							<GetVideoComponent videos={[reward.video]} isMobile={VXConfig.isVXMobilePlatform} />
						</div>
						<button onClick={this.onRedeemClick}
						        className="modal-special-topup__content-container__btn button -btn-color-special h-text-transform-none"
						>
							{getCommonTranslation('watch_video')}
						</button>
						<div className="modal-special-topup__content-container__close-text"
						     onClick={this.onCloseClick}>{getCommonTranslation('close_and_see_later')}
						</div>
					</div>
				);
				break;
			case Flux.Constants.RewardTypes.PHOTO_ALBUM:
				const GetPhotoComponent = GetPhoto;

				content = (
					<div className={'modal-special-topup__content-container'}>
						<div className={'modal-special-topup__content-container__headline'}>{getCommonTranslation('modal-special-topup__headline')}</div>
						<div className={'modal-special-topup__content-container__bonus-subline'}>{reward.info}</div>
						<div className={'modal-special-topup__content-container__content'}>
							<GetPhotoComponent photos={[reward.gallery]} isMobile={VXConfig.isVXMobilePlatform} />
						</div>
						<button onClick={this.onRedeemClick}
						        className="modal-special-topup__content-container__btn button -btn-color-special h-text-transform-none"
						>
							{getCommonTranslation('watch_album')}
						</button>
						<div className="modal-special-topup__content-container__close-text"
						     onClick={this.onCloseClick}>{getCommonTranslation('close_and_see_later')}
						</div>
					</div>
				);
				break;
			case Flux.Constants.RewardTypes.ONTOP:
				content = (
					<div className={'modal-special-topup__content-container -slim'}>
						<div className={'h-flex -direction-column -align-center h-text-align-center'}>
							<div className="modal-special-topup__content-container__headline">{getCommonTranslation('modal-special-topup__headline')}</div>
							<div className="modal-special-topup__content-container__bonus-amount">{getCommonTranslation('modal-special-topup__bonus-amount', reward.bonus)}</div>
							<div className="modal-special-topup__content-container__bonus-subline -slim">{getCommonTranslation('modal-special-topup__bonus-desc1')}</div>
							<div className="modal-special-topup__content-container__bonus-subline -slim">{getCommonTranslation('modal-special-topup__bonus-desc2')}</div>
						</div>
						<button onClick={this.onRedeemClick}
						        className="modal-special-topup__content-container__btn button -btn-color-special h-text-transform-none"
						>
							{getCommonTranslation('modal-special-topup__reward-info--bonus-c2a')}
						</button>
					</div>
				);
				break;
		}

		return (
			<Modal
				className="-theme-dialog -no-padding h-center modal-special-topup__modal-container"
				containerWidth={900}
				containerHeight={700}
				dynamicSize={true}
				hideCloseButton={false}
				onClose={this.props.onClose}
				ref={ref => {
					this.setModalRef(ref);
				}}
				divProps={{id: this.props.id}}
			>
				{content}
			</Modal>
		);
	}

}

ModalBoxSpecialTopUp.defaultProps = {
	onClose: () => {
	},
};

ModalBoxSpecialTopUp.propTypes = {
	id:      PropTypes.string.isRequired,
	code:    PropTypes.string.isRequired,
	onClose: PropTypes.func,
};

export default ModalBoxSpecialTopUp;
