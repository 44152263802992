/* globals VXConfig */

import React                                                  from 'react';
import PropTypes                                              from 'prop-types';
import Modal                                                  from '../Modal';
import {VXPay}                                                from '../../utils/VXPay';
import VXButton                                               from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_SPECIAL_INVERT} from "../SimpleElements/VXButton/ButtonHelper";
import Flux                                                   from "../../flux/Flux";
import Translations                                           from "../../utils/Translations";
import {VXButtonLineConfig}                                   from "../SimpleElements/VXButton/VXButtonConfig";
import STYLES                                                 from "../../components/SimpleElements/VXButton/EnumVXButton";

const redirectUrl = '/?jump=freechat';

class ModalBoxConversionChat extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isMobile    = VXConfig.isVXMobilePlatform;
		this.forceMobile = false;

		this.state = {isLandscape: this.isMobile && Flux.Browser.isLandscape()};

		this.onWindowResize();

		this.onBuyClick     = this.onBuyClick.bind(this);
		this.getRef         = this.getRef.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
	}

	componentWillUnmount() {
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
	}

	onWindowResize() {
		const windowSize = Flux.Browser.getWindowSize();

		if (!this.isMobile && windowSize && windowSize.width < 690) {
			this.isMobile    = true;
			this.forceMobile = true;
			this.forceUpdate();
		} else if (this.forceMobile) {
			this.isMobile    = false;
			this.forceMobile = false;
			this.forceUpdate();
		} else {
			this.setState({isLandscape: this.isMobile && Flux.Browser.isLandscape()});
		}

	}

	onRedirectClick(e) {
		// stop event propagation for preventing react issue with Modal click listener
		e.stopPropagation();

		window.location.href = redirectUrl;

		this.refModal.removeModal();
	}

	onBuyClick(e) {
		// stop event propagation for preventing react issue with Modal click listener
		e.stopPropagation();

		VXPay.openPaytour();

		VXPay.onSuccess((result) => {
			if (result && result.success) {
				window.location.href = redirectUrl;
			}
		});

		this.refModal.removeModal();
	}

	getRef(ref) {
		if (ref) {
			this.refModal = ref;
		}
	}

	render() {
		const classSuffix = this.state.isLandscape && this.isMobile ? ' -is-landscape' : (this.isMobile ? ' -is-mobile' : '');

		return <Modal containerWidth={290}
		              dynamicSize={true}
		              className={"conversion-chat-box-container -theme-dialog -no-padding" + classSuffix}
		              modalClassName={'-static -transparent'}
		              ref={this.getRef}
		              onClose={this.props.onClose}
		              renderInline={true}
		       >
			<div className={"conversion-chat-box__body-container" + classSuffix + ' ' + (this.props.showTop ? '-arrow-top' : '-arrow-bottom')}>
				<div className={"conversion-chat-box__body" + classSuffix}>
					<div className={"conversion-chat-box__text" + classSuffix} dangerouslySetInnerHTML={{__html: Translations.get('ConversionChatModalBoxMessage').replace(':actorName', this.props.actorName)}}></div>
					<div className={"conversion-chat-box__button" + classSuffix}>
						<VXButton onClick={this.onBuyClick}
					          line1={[new VXButtonLineConfig(Translations.get('MessengerCharge'))]}
					          color={COLOR_TYPE_CALL_TO_ACTION}
						/>
					</div>
					<div className={"conversion-chat-box__button" + classSuffix}>
						<VXButton onClick={this.onRedirectClick}
					          line1={[new VXButtonLineConfig(Translations.get('ConversionChatModalBoxRedirectButton'))]}
					          color={COLOR_TYPE_SPECIAL_INVERT}
					          border={STYLES.BORDER.OUTLINE}
						/>
					</div>
				</div>
			</div>
		</Modal>;
	}
}

ModalBoxConversionChat.propTypes = {
	actorName: PropTypes.string,
	onClose:   PropTypes.func,
	showTop:   PropTypes.bool,
};

export default ModalBoxConversionChat;