import React                  from 'react';
import PropTypes              from 'prop-types';
import Flux                   from '../../flux/Flux';
import {VXPay}                from '../../utils/VXPay';
import GuestAccess            from '../../utils/GuestAccess';
import Translations           from '../../utils/Translations';
import ReloadHelper           from "../../utils/ReloadHelper";
import { TYPES }              from '../Loader/Loader';

class LivePreviewButton extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			isVoicecallActive: Flux.Guest.isVoicecallActive(),
		};

		this.isUnmounting = false;

		this.onLivePreviewClick   = this.onLivePreviewClick.bind(this);
		this.onNotificationChange = this.onNotificationChange.bind(this);
	}

	componentDidMount() {
		Flux.Notification.addNotificationChangeListener(this.onNotificationChange);
	}

	componentWillUnmount() {
		this.isUnmounting = true;

		Flux.Notification.removeNotificationChangeListener(this.onNotificationChange);
	}

	onLivePreviewClick(event) {
		if (event.cancelable) {
			event.preventDefault();
		}

		if(this.props.isBannedCountryCode) {
			ReloadHelper.reload(this.props.actorLink);
			return false;
		}

		// special logic for sender landing page: only redirect to profile instead of really executing an action (hard redirect also for mobile!)
		if (this.props.profileLink) {
			if (Flux.Guest.isLoggedIn()) {
				window.location.href = this.props.profileLink;
			} else {
				VXPay.openSignupOrLogin();
			}
		} else {
			GuestAccess.onLivePreviewAccess(() => {
				Flux.NewMessenger.startChat('LIVE_PREVIEW', this.props.actorId);
				this.props.useLoader && Flux.Special.showLoader(TYPES.DOT);

				if (typeof this.props.onClick === 'function') {
					this.props.onClick();
				}
			}, this.props.actorId, false);
		}
	}

	onNotificationChange(notification) {
		// leave function in order to avoid react warnings (setting state of unmounted component); matsch
		if (this.isUnmounting) {
			return false;
		}

		const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;

		switch (notification.type) {
			case NotificationTypes.VOICECALL_START :
				if (Flux.Guest.isLoggedIn()) {
					this.setState({
						isVoicecallActive: true,
					});
				}
				break;
			case NotificationTypes.VOICECALL_STOP :
				if (Flux.Guest.isLoggedIn()) {
					this.setState({
						isVoicecallActive: false,
					});
				}
				break;
			default:
		}
	}

	render() {
		const className     = ['btn--icon-detailed', '-click-material', '-live-preview'];
		const iconClassName = [];
		let textLine1       = '';
		let textLine2       = '';
		let textMobile      = '';
		let tooltip         = '';

		if (this.props.additionalClassName) {
			className.push(this.props.additionalClassName);
		}

		let button = null;
		if (!this.props.freeChat && this.props.isActorOnline && !this.state.isVoicecallActive && this.props.isEnabled) {
			if (Flux.Guest.isLoggedIn() && Flux.Guest.isVip()) {
				className.push('-btn-color-gold');

				if (this.props.useStandaloneIcon) {
					iconClassName.push('icon-actor');
					iconClassName.push('-icon-person-princess');

				} else {
					className.push('-icon-person-princess');
				}

				textLine1  = Translations.get('LivePreviewVipButtonLine1');
				textLine2  = Translations.get('LivePreviewVipButtonLine2');
				textMobile = Translations.get('LivePreviewVipButton');
				tooltip    = Translations.get('TooltipLivePreviewButton').replace(':seconds', Flux.Constants.TimeLimits.LIVE_PREVIEW_VIP + '');
			} else {
				if (this.props.useStandaloneIcon) {
					iconClassName.push('icon');
					iconClassName.push('-icon-eyes-full');

				} else {
					className.push('-icon-eyes-full');
				}

				textLine1  = Translations.get('LivePreviewButtonLine1');
				textLine2  = Translations.get('LivePreviewButtonLine2');
				textMobile = Translations.get('LivePreviewButton');
				tooltip    = Translations.get('TooltipLivePreviewButton').replace(':seconds', Flux.Constants.TimeLimits.LIVE_PREVIEW + '');
			}



			if (this.props.isMobileMenuItem) {
				iconClassName.unshift('actor-overview__menu--icon');

				button = (
					<div className="actor-overview__menu--entry" onClick={this.onLivePreviewClick}>
						<div className={iconClassName.join(' ')} />
						<div className="actor-overview__menu--text">
							<div className="actor-overview__menu--label -inline">{textMobile}</div>
						</div>
					</div>
				);
			} else {
				button = (
					<button className={className.join(' ')} data-tip={tooltip} data-place="top" onClick={this.onLivePreviewClick}>
						<span className="btn__text-container">
							{this.props.useStandaloneIcon && <i className={iconClassName.join(' ')} />}
							<span className="h-text-nowrap h-left">{textLine1}</span>
							<span className="h-text-nowrap h-disp-inline-block">{textLine2}</span>
						</span>
					</button>
				);
			}
		}

		return button;
	}
}

LivePreviewButton.propTypes = {
	actorId:             PropTypes.number.isRequired,
	additionalClassName: PropTypes.string,
	freeChat:            PropTypes.object,
	isActorOnline:       PropTypes.bool,
	isEnabled:           PropTypes.bool,
	isMobileMenuItem:    PropTypes.bool,
	onClick:             PropTypes.func,
	profileLink:         PropTypes.string,
	useStandaloneIcon:   PropTypes.bool,
	isBannedCountryCode: PropTypes.bool,
	actorLink:           PropTypes.string,
	useLoader:           PropTypes.bool,
};

LivePreviewButton.defaultProps = {
	additionalClassName: '',
	freeChat:            null,
	isActorOnline:       false,
	isEnabled:           false,
	isMobileMenuItem:    false,
	profileLink:         '',
	isBannedCountryCode: false,
	useLoader:           false,
};

export default LivePreviewButton;