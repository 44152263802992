export default {
	VIDEO_TRENDS:         1,
	HOMEPAGE_DAILY_BONUS: 2,
	HEATMAP:              3,
	VIP_30:               4,
	TIMELINE_NAVIGATION:  5,
	BENEFITS:             6,
	FEED:                 7,
	SPINWHEEL:			  8,
	MODAL_ONBOARDING:	  9,
    SPINWHEEL_V2:         10,
};
