import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

const AlbumButtonBonus = (props) =>
	<div onClick={props.onClick} className={`${props.baseClassName}__button--buy`}>
		<div className={`${props.baseClassName}__text--button`}>{Translations.get('TeaserExistingBonusCaption3')}</div>
	</div>
;

AlbumButtonBonus.propTypes = {
	baseClassName: PropTypes.string,
	onClick:       PropTypes.func,
};

export default AlbumButtonBonus;
