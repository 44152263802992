/* globals VXConfig */

import React                     from 'react';
import PropTypes                 from 'prop-types';
import Modal                     from '../Modal';
import Translations              from "../../utils/Translations";
import withDildocontrolGiftTiles from "../HigherOrderComponents/Dildocontrol/withDildocontrolGiftTiles";
import Flux                      from "../../flux/Flux";

class ModalBoxToyControl extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			shouldAddFilter: false,
		};

		this.onBtnClick = this.onBtnClick.bind(this);

		this.onFilterChange = this.onFilterChange.bind(this);
	}

	componentDidMount() {
		Flux.ProSearch.addChangeListener(this.onFilterChange);
	}

	componentWillUnmount() {
		Flux.ProSearch.removeChangeListener(this.onFilterChange);
	}

	onBtnClick() {
		this.setState({
			shouldAddFilter: true,
		}, () => {
			Flux.ProSearch.deleteFilter();
		});
	}

	onFilterChange() {
		let shouldAddFilter;

		this.setState(state => {
			shouldAddFilter = state.shouldAddFilter;

			const nextState = {};

			if (shouldAddFilter) {
				nextState.shouldAddFilter = false;
			}

			return nextState;
		}, () => {
			if (shouldAddFilter) {
				Flux.ProSearch.changeSearchCondition('actorOther', 'other_toy_control', 'Toy Control', true, Flux.Constants.ActorSearch.SearchTypes.SEARCH_TYPE_DEFAULT);
				this.props.onClose();
			}
		});
	}

	render() {
		return (
			<Modal containerWidth={650}
			       dynamicSize={true}
			       className={'-theme-dialog -no-padding -relative'}
			       onClose={this.props.onClose}
			>
				<div className="dildocontrol-modal__container">
					<h3 className="dildocontrol-info-popup__header">
						<img className="dildocontrol-info-popup__header-logo"
						     src="/assets/img/dildocontrol/dildocontrol-icon-colored.png"
						/>
						{Translations.get('DildocontrolModalHeaderNew')}
						{Translations.get('DildocontrolInfoPopupHeaderPart1')}
						{VXConfig.isVXMobilePlatform ?
							<br /> : ' '}
						{Translations.get('DildocontrolInfoPopupHeaderPart2')}
					</h3>

					<p className="dildocontrol-info-popup__body">
						{Translations.get('DildocontrolInfoPopupBody')}
						{Translations.get('DildocontrolModalBodyNew')}
					</p>

					<div className="dildocontrol-info-popup__gifts-overview">
						{this.props.dildocontrolGiftTiles}
					</div>

					<button onClick={this.onBtnClick} className="dildocontrol-modal__btn btn -btn-color-special">
						{Translations.get('DildocontrolModalBtn')}
					</button>
				</div>
			</Modal>);
	}
}

ModalBoxToyControl.propTypes = {
	onClose:               PropTypes.func,
	dildocontrolGiftTiles: PropTypes.array.isRequired,
};

ModalBoxToyControl.defaultProps = {
	onClose: () => {
	},
};

const ModalBoxToyControlWithTiles = withDildocontrolGiftTiles(ModalBoxToyControl);

export default ModalBoxToyControlWithTiles;
