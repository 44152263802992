import React, {useState} from "react";
import PropTypes         from "prop-types";
import Flux              from "../../../../../flux/Flux";
import {VXPay}           from '../../../../../utils/VXPay';

const FooterAlbumLikesButton = props => {
	const [liked, setLiked] = useState(props.data.guestInfo && props.data.guestInfo.liked);

    const likebuttonClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if ((Flux.Guest.isLoggedIn() && props.data.guestInfo.bought) || (props.data.isVip30Offer || props.data.vip ? Flux.Guest.isVip() : false)) {
			setLiked(!liked);
			if (props.type === Flux.Constants.MediaTypes.VIDEO) {
				liked ? Flux.Guest.addVideoDislike(props.data.id, () => {}) : Flux.Guest.addVideoLike(props.data.id, () => {});
			} else if (props.type === Flux.Constants.MediaTypes.PHOTO_ALBUM) {
				liked ? Flux.Guest.addGalleryDislike(props.data.id, () => {}) : Flux.Guest.addGalleryLike(props.data.id, () => {});
			}
		} else {
			VXPay.onClickGetVip();
		}
	};

	 return(
        <div onClick={likebuttonClick} className={"grid-2__tile__type-video__footer__button-like"}>
            <i className={"icon " + (liked ? "-icon-like-full" : "-icon-like-line")}></i>
        </div>
    );
};

FooterAlbumLikesButton.propTypes = {
	data:    PropTypes.object.isRequired,
	type:    PropTypes.oneOf([
		Flux.Constants.MediaTypes.VIDEO,
		Flux.Constants.MediaTypes.PHOTO_ALBUM,
	]),
};

export default FooterAlbumLikesButton;