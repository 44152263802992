import React         from 'react';
import PropTypes     from 'prop-types';
import ReloadHelper  from '../../utils/ReloadHelper';
import PictureBoxes  from '../TileGrid/PictureBoxes';
import {VideoPlayer} from '../Gallery/VideoPlayer';

class CoverTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClickFn = this.onClickFn.bind(this);
	}

	onClickFn(e) {
		this.props.onClickFn(e, this.props.item, this.props.tileIndex);
		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.item.targetUrl, e);
		}
	}

	render() {
		const tileClassName       = this.props.className;
		const item                = this.props.item;
		const useRatio            = false;
		const contentBoxClassName = 'context-box--media-box h-over-hidden -margin-bottom-small -margin-left-small -margin-right-small clearfix ' + (this.props.contentBoxClassModifier ? this.props.contentBoxClassModifier : '');
		const outerClassName      = 'context-box--media-box__picture' + (useRatio ? ' box--fixed-ratio' : '');
		const innerClassName      = useRatio ? 'box--fixed-ratio__content' : '';
		const boxRationClassName  = ' -ratio16_9';
		const showDetail          = item.showDetail;

		let content;

		if (item.videosUrls && item.videosUrls.length > 0) {
			const previewPictureUrls = this.props.previewPictureUrls[0].map((item) => {
				const obj      = {};
				obj.pictureUrl = item.src;
				return obj;
			});

			const videos = [
				{
					previewPictureUrls: previewPictureUrls,
					videoUrls:          item.videosUrls,
				},
			];

			content = <VideoPlayer initialVideos={videos} initialShowVideo={true} containerClass="gallery__video-player--inline" />;
		} else {
			content = <PictureBoxes pictures={this.props.previewPictureUrls}
			                        useIndexForKey={true}
			                        outerClassName={outerClassName}
			                        innerClassName={innerClassName}
			                        setMaxWidth={true}
			          />;
		}

		const icon = showDetail ? '' : <i className="context-box--media-box__cover-icon icon -icon-videotake-full" />;

		let tileContent;
		if (item.targetUrl) {
			tileContent = (
				<a
					href={item.targetUrl}
					onClick={this.onClickFn}
				>
					<div className={contentBoxClassName}>
						<div className={'box--fixed-ratio' + boxRationClassName}>
							<div className="box--fixed-ratio__content">
								{content}
							</div>
						</div>
						{icon}
						{/* plates */}
						{this.props.children}
					</div>
				</a>
			);
		} else {
			tileContent = (
				<div className={contentBoxClassName}>
					<div className={'box--fixed-ratio' + boxRationClassName}>
						<div className="box--fixed-ratio__content">
							{content}
						</div>
					</div>
					{icon}
					{/* plates */}
					{this.props.children}
				</div>
			);
		}

		return (
			<div className={tileClassName}>
				{tileContent}
			</div>
		);
	}

}

CoverTile.propTypes = {
	children:                PropTypes.node,
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	item:                    PropTypes.object,
	onClickFn:               PropTypes.func,
	previewPictureUrls:      PropTypes.array,
	tileIndex:               PropTypes.number,
	usePjax:                 PropTypes.bool,
};

CoverTile.defaultProps = {
	contentBoxClassModifier: '',
	isMobile:                false,
	useRatio:                false,
	usePjax:                 false,
};

export default CoverTile;
