import React, {useState, useEffect} from 'react';
import PropTypes                    from 'prop-types';
import CinematicMode                from './Controls/CinematicMode';
import Download                     from './Controls/Download';
import Duration                     from './Controls/Duration';
import Fullscreen                   from './Controls/Fullscreen';
import Labels                       from './Controls/Labels';
import LandscapeZoom                from './Controls/LandscapeZoom';
import PlayPause                    from './Controls/PlayPause';
import PortraitZoom                 from './Controls/PortraitZoom';
import ScreenMirroring              from './Controls/ScreenMirroring';
import Seek15SecsBack               from './Controls/Seek15SecsBack';
import Seek15SecsForward            from './Controls/Seek15SecsForward';
import SeekBar                      from './Controls/SeekBar';
import VolumeBar                    from './Controls/VolumeBar';
import PinButton                    from './Controls/PinButton';
import MediaPin                     from '../Favorite/Actor/MediaPin';
import Like                         from '../Like';
import Dislike                      from '../Dislike';
import {getFullScreenElement}       from '../../utils/Fullscreen';

const VXShakaUI = (props) => {
	const {
		      showCinematicMode,
		      showDownload,
		      showSeek15Secs,
		      showPreviewLabel,
		      showHdLabel,
		      showPin,
		      showLike,
		      showDislike,
		      showScreenMirroring,
		      actorId,
		      albumId,
		      albumIsFavorite,
		      isLiked,
		      isDisliked,
		      contestVoteComponent,
		      onVideoDownload,
		      isLandscape,
		      ctaButton,
		      videoOffsetHeight,
		      isMobile,
		      zoomFunc,
		      isZoomed,
		      wrapper,
		      video,
		      visible,
		      textTrackCues,
		      webVTTImages,
		      showFullscreen,
		      downloadUrl,
		      isLivestream,
			  onFullscreenClick
	      } = props;

	const [fullscreen, setFullscreen] = useState(getFullScreenElement() === props.wrapper);
	useEffect(() => {
		const fn = () => {
			setFullscreen(getFullScreenElement() === props.wrapper);
		};

		document.addEventListener('fullscreenchange', fn);
		return () => {
			document.removeEventListener('fullscreenchange', fn);
		};
	}, [document]);

	function getMobileView() {
		return (<div className={'vx_shaka--ui--mobile vx_shaka--ui' + (!visible ? ' -hide' : '')}>
			<div className="vx_shaka--ui-row vx_shaka--ui-row--screen-mirroring">
				{showScreenMirroring && <ScreenMirroring />}
			</div>

			<div className={'vx_shaka--ui-row vx_shaka--ui-row--play-seek ' + (showPreviewLabel || !showSeek15Secs ? "vx_shaka--ui-row--play-seek--center" : "")}>
				{showSeek15Secs && <Seek15SecsBack video={video} />}
				<PlayPause video={video} />
				{showSeek15Secs && <Seek15SecsForward video={video} />}
			</div>
			<div className="vx_shaka--ui-row vx_shaka--ui-row--volume-seek bottom-control-panel">
				<div className={"vx_shaka--ui-row vx_shaka--ui-row--volume-fullscreen bottom-control-panel--left"}>
					<VolumeBar video={video} isMobile={isMobile} />
					<div className={"vx_shaka--ui-row vx_shaka--ui-row--preview-fullscreen"}>
						{!showPreviewLabel && !isLivestream && <Duration video={video} />}
						<Labels isPreview={showPreviewLabel} isHd={showHdLabel} isLivestream={isLivestream} />
						{showFullscreen && <Fullscreen wrapper={wrapper} video={video} isMobile={true} onFullscreenClick={onFullscreenClick} />}
					</div>
				</div>
				{getSeekRow()}
			</div>
		</div>);
	}

	function getSeekRow() {
		if (isLivestream) {
			return null;
		}
		return (<div className={"vx_shaka--ui-row bottom-control-panel--right"}>
			<SeekBar video={video} textTrackCues={textTrackCues} webVTTImages={webVTTImages} isMobile={isMobile} />
		</div>);
	}

	function getMobileFullscreenView() {
		const controlTop      = (videoOffsetHeight / 2) + (window.innerHeight / 2);
		const controllerStyle = isLandscape || isZoomed ? {} : {top: `${controlTop - 20}px`};
		const playbuttonstyle = isLandscape || isZoomed ? {bottom: `${window.innerHeight / 2}px`} : {};
		const pinButton       = (<PinButton isAlbumFavorite={albumIsFavorite} albumId={albumId} />);

		return (
			<div className={'vx_shaka--ui--mobile vx_shaka--ui' + (!visible ? ' -hide' : '')}>
				<div className="vx_shaka--ui-row vx_shaka--ui-row--screen-mirroring--fullscreen">
					<div className={"vx_shaka--ui-row--item-pair "}>
						{showFullscreen && <Fullscreen wrapper={wrapper} video={video} isMobile={true} onFullscreenClick={onFullscreenClick} />}
						{!isLandscape && isMobile && <PortraitZoom onClick={zoomFunc} checked={isZoomed} />}
						{isLandscape && isMobile && <LandscapeZoom onClick={zoomFunc} checked={isZoomed} />}
					</div>
					<div className={"vx_shaka--ui-row--item-pair " + (showScreenMirroring ? "" : "vx_shaka--ui-row--item-pair--right")}>
						{showScreenMirroring && <ScreenMirroring />}
						<VolumeBar video={video} isMobile={isMobile} />
					</div>
				</div>
				<div className={"vx_shaka--ui-row vx_shaka--ui-row--seekbar--fullscreen " + (isZoomed || isLandscape ? "" : "vx_shaka--ui-row--seekbar--fullscreen--fixed")}
				     style={controllerStyle}
				>

					{(!isLandscape && !isZoomed) && getSeekRow()}

					<div className={"vx_shaka--ui-seeker-buttons " + (isLandscape ? "vx_shaka--ui-seeker-buttons--landscape" : "")}>
						<div>
							<div className={"vx_shaka--ui-row vx_shaka--ui-row--duration--fullscreen"}>
								{!showPreviewLabel && !isLivestream && <Duration video={video} />}
								<Labels isPreview={showPreviewLabel} isHd={showHdLabel} isLivestream={isLivestream} />
							</div>

							{(isLandscape || isZoomed) && getSeekRow()}
						</div>
						{isLandscape &&
							<div className={"vx-shaka--ui-buy-pin vx-shaka--ui-buy-pin--landscape"}>
								{ctaButton}
								{pinButton}
							</div>
						}
					</div>

					<div className={'vx_shaka--ui-row vx_shaka--ui-row--play-seek vx_shaka--ui-row--play-seek--fullscreen ' + (showPreviewLabel ? "vx_shaka--ui-row--play-seek--center " : "") + (isZoomed ? "vx_shaka--ui-row--play-seek--fullscreen--fixed " : "")}
					     style={playbuttonstyle}
					>
						{showSeek15Secs && <Seek15SecsBack video={video} />}
						<PlayPause video={video} />
						{showSeek15Secs && <Seek15SecsForward video={video} />}
					</div>
				</div>

				{!isLandscape && !isZoomed &&
					<div className={"vx-shaka--ui-buy-pin"}>
						{ctaButton}
						{pinButton}
					</div>
				}
			</div>
		);
	}

	function getDesktopView() {
		return (
			<div className={'vx_shaka--ui' + (!visible ? ' -hide' : '')}>
				<div className="vx_shaka--ui-row">
					{!isLivestream && <SeekBar video={video} textTrackCues={textTrackCues} webVTTImages={webVTTImages} isMobile={isMobile} />}
					{!showPreviewLabel && !isLivestream && <Duration video={video} />}
					<Labels isPreview={showPreviewLabel} isHd={showHdLabel} />
				</div>
				<div className="vx_shaka--ui-row -adjust-play-button-margin">
					<PlayPause video={video} />
					{showSeek15Secs && <Seek15SecsBack video={video} />}
					{showSeek15Secs && <Seek15SecsForward video={video} />}
					{showDownload && !showPreviewLabel && downloadUrl && <Download downloadUrl={downloadUrl} onClick={onVideoDownload} />}
					{showPin && fullscreen &&
						<MediaPin actorId={actorId}
						          albumId={albumId}
						          initialIsFavorite={albumIsFavorite}
						          fontSize=""
						          tooltipEnabled={false}
						/>}
					{showLike && <Like initialIsLiked={isLiked} albumId={albumId} icon="-icon-like-line" tooltipText={null} visible={fullscreen} />}
					{showDislike &&
						<Dislike initialIsDisliked={isDisliked} albumId={albumId} icon="-icon-unlike-line" tooltipText={null} visible={fullscreen} />}
					{contestVoteComponent}
					<span className="vx_shaka--ui-controls-spacer" />
					{isLivestream && <Labels isLivestream={true} />}
					<VolumeBar video={video} />
					{showCinematicMode && !fullscreen && <CinematicMode />}
					{!isLandscape && isMobile && <PortraitZoom />}
					{isLandscape && isMobile && <LandscapeZoom />}
					{showScreenMirroring && <ScreenMirroring />}
					{showFullscreen && <Fullscreen wrapper={wrapper} video={video} onFullscreenClick={onFullscreenClick} />}
				</div>
			</div>
		);
	}

	if (isMobile) {
		if (fullscreen) {
			return getMobileFullscreenView();
		}
		return getMobileView();
	}
	return getDesktopView();
};

VXShakaUI.propTypes = {
	video:                PropTypes.instanceOf(HTMLVideoElement).isRequired,
	wrapper:              PropTypes.object.isRequired,
	visible:              PropTypes.bool,
	showSeek15Secs:       PropTypes.bool,
	showDownload:         PropTypes.bool,
	showCinematicMode:    PropTypes.bool,
	showPreviewLabel:     PropTypes.bool,
	showHdLabel:          PropTypes.bool,
	showPin:              PropTypes.bool,
	showLike:             PropTypes.bool,
	showDislike:          PropTypes.bool,
	showScreenMirroring:  PropTypes.bool,
	showFullscreen:       PropTypes.bool,
	actorId:              PropTypes.number,
	albumId:              PropTypes.number,
	albumIsFavorite:      PropTypes.bool,
	isLiked:              PropTypes.bool,
	isDisliked:           PropTypes.bool,
	contestVoteComponent: PropTypes.node,
	onVideoDownload:      PropTypes.func,
	isMobile:             PropTypes.bool,
	isLandscape:          PropTypes.bool,
	ctaButton:            PropTypes.node,
	videoOffsetHeight:    PropTypes.number,
	zoomFunc:             PropTypes.func,
	isZoomed:             PropTypes.bool,
	textTrackCues:        PropTypes.object,
	webVTTImages:         PropTypes.array,
	onFullscreenClick:    PropTypes.func,
};

VXShakaUI.defaultProps = {
	visible:             true,
	showSeek15Secs:      false,
	showDownload:        false,
	showCinematicMode:   false,
	showPreviewLabel:    false,
	showHdLabel:         false,
	showPin:             false,
	showLike:            false,
	showDislike:         false,
	showScreenMirroring: false,
	showFullscreen:      true,
	isMobile:            false,
	isLandscape:         false,
	onFullscreenClick:   null,
};

export default VXShakaUI;
