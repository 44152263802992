import React                  from 'react';
import PropTypes              from 'prop-types';
import Flux                   from '../../../flux/Flux';
import AlbumPinButtonSmall    from '../../ActorProfileVideos/AlbumPinButtonSmall';

class PinButton extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			albumIsFavorite:   this.props.albumIsFavorite,
		};

		this.pinAlbum               = this.pinAlbum.bind(this);
		this.unpinAlbum             = this.unpinAlbum.bind(this);
		this.onAlbumPinStatusChange = this.onAlbumPinStatusChange.bind(this);

	}

	componentDidMount() {
		Flux.Favorite.addAlbumPinStatusListener(this.onAlbumPinStatusChange);
	}

	componentWillUnmount() {
		Flux.Favorite.removeAlbumPinStatusListener(this.onAlbumPinStatusChange);
	}
    
	pinAlbum() {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Favorite.pinAlbum(this.props.albumId);
		}
	}

	unpinAlbum() {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Favorite.unpinAlbum(this.props.albumId);
		}
	}

	onAlbumPinStatusChange(albumId, status) {
		if (albumId === this.props.albumId) {
			this.setState({
				albumIsFavorite: status,
			});
		}
	}
    
	render() {
		return <AlbumPinButtonSmall isAlbumFavorite={this.state.albumIsFavorite} onClick={this.state.albumIsFavorite ? this.unpinAlbum : this.pinAlbum} />;
	}



}

PinButton.propTypes = {
	showPin:         PropTypes.bool,
	albumId:         PropTypes.number,
	albumIsFavorite: PropTypes.bool,
    
};

PinButton.defaultProps = {
	showPin:         false,
	albumIsFavorite: false,
};

export default PinButton;