import React                       from 'react';
import Modal                       from "../Modal";
import {getUrlParams}              from "../../utils/CommonUtils";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {VXPay}                     from "../../utils/VXPay";
import Translations                from "../../utils/Translations";

class ModalBoxLexyKalender2023 extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			isHidden: false
		};

		VXPay.onSuccess(function () {
			this.setState({
				isHidden: true
			});
		}.bind(this));

		const urlParams = getUrlParams(window.location.search);
		if (urlParams.qmdate) {
			this.qmDate = urlParams.qmdate;
		}

		this.render = this.render.bind(this);
	}

	render() {
		let date = new Date();
		if (this.qmDate) {
			date = this.qmDate;
		}
		const begin2023 = new Date("2023-01-01T00:00:00");
		const isBefore2023 = date < begin2023;

		return <Modal className={"modal__container--lexykalender2023 -auto-size"} modalClassName={"modal--lexykalender2023"} isHidden={this.state.isHidden}>
			<img className={"lexykalender2023__image"} src={"/assets/img/special/LexyKalender.jpg"} />
			{isBefore2023
				? <div className={"lexykalender2023__text"}>
					{Translations.get("_event-lexykalender2023.text")}
				</div>

				: <div className={"lexykalender2023__text"}>
					<VXButton line1={[new VXButtonLineConfig(Translations.get("_event-lexykalender2023.button"))]}
			            color={COLOR_TYPE_CALL_TO_ACTION}
			            onClick={() => VXPay.openPromoCode({pc: "LEXYROXXSHOW-CAL-23"})} />
				</div>
			}
		</Modal>;
	}
}

export default ModalBoxLexyKalender2023;