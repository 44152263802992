import React                from 'react';
import PropTypes            from 'prop-types';
import Flux                 from '../flux/Flux';
import {VXPay, VXPayAction} from './../utils/VXPay';

class ContestVoting extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			isVoted: props.isVoted,
		};

		this.checkCorrectItemClicked = this.checkCorrectItemClicked.bind(this);
		this.onVotingClick           = this.onVotingClick.bind(this);
		this.onVotingChange          = this.onVotingChange.bind(this);
	}

	componentDidMount() {
		Flux.Contest.addContestVotingChangeListener(this.onVotingChange);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.mediaId !== this.props.mediaId && nextProps.isVoted !== this.state.isVoted) {
			this.setState({
				isVoted: nextProps.isVoted,
			});
		}
	}

	componentWillUnmount() {
		Flux.Contest.removeContestVotingChangeListener(this.onVotingChange);
	}

	checkCorrectItemClicked(contestId, mediaId, guestId) {
		return (
			this.props.contestId === contestId &&
			this.props.mediaId === mediaId &&
			this.props.guestId === guestId
		);
	}

	onVotingClick(e) {
		if (e.preventDefault) {
			e.preventDefault();
		}
		if (e.stopPropagation) {
			e.stopPropagation();
		}

		if (!Flux.Guest.isLoggedIn()) {
			const action = new VXPayAction(Flux.Constants.ActionTypes.Contest.SET_SHOULD_OPEN_MEDIA, {
				config: {
					mediaId:     this.props.mediaId,
					mediaType:   this.props.mediaType,
					currentPage: this.props.currentPage,
					voteMedia:   true,
				},
				shouldOpen: true,
			});
			VXPay.openSignupOrLogin({}, action);
			return;
		}
		if (this.props.isVotingAllowed) {
			if (this.state.isVoted) {
				Flux.Contest.deleteContestMediaVoting(this.props.contestId, this.props.mediaId, this.props.actorId, this.props.guestId, this.props.albumId);
			} else {
				Flux.Contest.addContestMediaVoting(this.props.contestId, this.props.mediaId, this.props.actorId, this.props.guestId, this.props.albumId);
			}
		}
	}

	onVotingChange(guestId, contestId, mediaId, isVoted) {
		if (this.checkCorrectItemClicked(contestId, mediaId, guestId)) {
			this.setState({isVoted: isVoted});
		}
	}


	render() {
		return (
			<span onClick={this.onVotingClick}
			      className={"contents__plate-guestvoting " + this.props.styleClass + ((this.props.isVotingAllowed) ? ' h-cursor-pointer' : '')}
			>
				{this.props.isVotingAllowed ? (this.state.isVoted ? <i className="icon -icon-like-full" /> : <i className="icon -icon-like-line" />) : <i className="icon -icon-like-full" />}
			</span>
		);
	}
}

ContestVoting.propTypes = {
	actorId:          PropTypes.number.isRequired,
	contestId:        PropTypes.number.isRequired,
	guestId:          PropTypes.number.isRequired,
	isVoted:          PropTypes.bool.isRequired,
	mediaId:          PropTypes.number,
	albumId:          PropTypes.number,
	isVotingAllowed:  PropTypes.bool,
	styleClass:       PropTypes.string,
	mediaType:        PropTypes.string,
	currentPage:      PropTypes.number,
};

ContestVoting.defaultProps = {
	actorId:          0,
	contestId:        0,
	guestId:          0,
	isVoted:          false,
	mediaId:          0,
	albumId:          0,
	isVotingAllowed:  false,
	styleClass:       '',
	mediaType:        '',
	currentPage:      null,
};

export default ContestVoting;