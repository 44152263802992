import React     from 'react';
import PropTypes from 'prop-types';

const Download = props => (
	<a className={'vx_shaka--ui-controls -icon-button -color-is-fg'}
	   href={props.downloadUrl + '&download=force'}
	   onClick={() => props.onClick && props.onClick()}
	>
		<span className="icon -icon-download-line" />
	</a>
);

Download.propTypes = {
	downloadUrl: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export default Download;
