/* globals VXConfig */

'use strict';

import Routes                                                                                     from './Routes';
import Translations                                                                               from './Translations';
import ReloadHelper                                                                               from './ReloadHelper';
import GlobalEventHandler                                                                         from "./GlobalEventHandler";
import Flux                                                                                       from "../flux/Flux";
import ModalboxPayment                                                                            from "../vendor/vxpay-iframe@1.5.53.js";
import {doFetch, isCookieCategoryEnabled, isMobileRequest, removeUrlParam, shouldShowSexoleModal} from "./CommonUtils";
import {ModalBoxEnum, openModalBox}                                                               from "../components/ModalBox/ModalBoxFactory";
import {setOnFlowEnd, setOnFlowStart}                                                             from "./CommuniPay";
import EnumGA4Events                                                                              from './EnumGA4Events';
import {trackLoginState, trackMoneycharge}                                                        from './DataLayerHelper';

let avsCallback               = null;
let chatExitCloseCallback     = null;
let vipAboCallback            = null;
let vxtvAboCallback           = null;
let isCallbackInProgress      = false;
let isPaytourOpen             = false;
let loginExecuted             = false;
let moneyChargeCallback       = null;
let moneyChargeCallbackResult = null;
let onVoiceCallCallback       = null;
let hasLoginCookiePromise     = null;
let bonusCodeRedeemedCallback = null;

const callbackQueue = [];
const ga            = window.ga || (() => {
});
const PAYTYPES      = {
	VOICECALL: 'Voice',
};
/** according to EnumVXPayFlow */
const VXPayFlow     = {
	METHOD_SIGNUP: 'Signup',
};

function getUrlWithoutVXPayFlow(url) {
	return url ? removeUrlParam('vxpFlow', removeUrlParam('vxpAutoFlow', url)) : '';
}

function reloadWithoutVXPayFlow(usePjax = false) {
	if (!VXConfig.isVXMobilePlatform && usePjax) {
		if (window.location.href.indexOf('vxpAutoFlow') > -1 || window.location.href.indexOf('vxpFlow') > -1) {
			window.history.replaceState(null, '', getUrlWithoutVXPayFlow(window.location.href));
		}

		ReloadHelper.reloadCurrentPage();
	} else {
		if (window.location.href.indexOf('vxpAutoFlow') > -1 || window.location.href.indexOf('vxpFlow') > -1) {
			window.location.href = getUrlWithoutVXPayFlow(window.location.href);
		} else {
			window.location.reload();
		}
	}
}

function showSexoleModal() {
	if (shouldShowSexoleModal()) {
		openModalBox(ModalBoxEnum.SEXOLE_LOGIN, {overCookie: true});
		return true;
	}

	return false;
}

function delayedCallbacks(callback) {
	isPaytourOpen = false;

	const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;
	while (callbackQueue.length > 0) {
		const queueEntry = callbackQueue.shift();
		if (Object.hasOwn(queueEntry, 'name')) {
			queueEntry.callback(queueEntry.name === NotificationTypes.GUEST_BALANCE ? moneyChargeCallbackResult : null);
			switch (queueEntry.name) {
				case NotificationTypes.GUEST_BALANCE: {
					moneyChargeCallback       = null;
					moneyChargeCallbackResult = null;

					break;
				}
				case NotificationTypes.AVS_SET: {
					avsCallback = null;

					break;
				}
				case NotificationTypes.VIP_ABO_UPDATE: {
					vipAboCallback = null;

					break;
				}
				case NotificationTypes.VXTV_ABO_UPDATE: {
					vxtvAboCallback = null;

					break;
				}
				// No default
			}
		}
	}

	Flux.Guest.requestGuestBalance();

	if (typeof callback === 'function') {
		callback();
	}
}

function notificationChangeListener(notification) {
	const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;

	switch (notification.type) {
		case NotificationTypes.GUEST_BALANCE :
			if (notification.isPayment) {
				ga('send', 'event', 'User', 'payment');

				// when receiving a payment notification re-check current page load incentives because some might have become obsolete with the payment
				Flux.Conversion.loadPageLoadIncentives();
			}
			if (moneyChargeCallback && notification.isPayment) {
				// delay callback as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.unshift({
						callback: moneyChargeCallback,
						name:     NotificationTypes.GUEST_BALANCE,
					});
				} else {
					moneyChargeCallback(moneyChargeCallbackResult);
					moneyChargeCallback       = null;
					moneyChargeCallbackResult = null;
				}
			}
			break;
		case NotificationTypes.GUEST_LOCKED :
			VXPay.logout();
			break;
		case NotificationTypes.VIP_ABO_UPDATE :
			ga('send', 'event', 'User', 'vip_abo_update');
			if (vipAboCallback) {
				// delay callback as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.push({
						callback: vipAboCallback,
						name:     NotificationTypes.VIP_ABO_UPDATE,
					});
				} else {
					vipAboCallback();
					vipAboCallback = null;
				}
			} else {
				reloadWithoutVXPayFlow(true);
			}
			break;
		case NotificationTypes.VXTV_ABO_UPDATE :
			ga('send', 'event', 'User', 'vxtv_abo_update');
			if (vxtvAboCallback) {
				// delay callback as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.push({
						callback: vxtvAboCallback,
						name:     NotificationTypes.VXTV_ABO_UPDATE,
					});
				} else {
					vxtvAboCallback();
					vxtvAboCallback = null;
				}
			} else {
				reloadWithoutVXPayFlow(true);
			}
			break;
		case NotificationTypes.AVS_REVOKED :
			ga('send', 'event', 'User', 'avs_revoked');
			window.location.reload();
			break;
		case NotificationTypes.AVS_SET :
			ga('send', 'event', 'User', 'avs_set');
			if (avsCallback) {
				// delay callback as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.push({
						callback: avsCallback,
						name:     NotificationTypes.AVS_SET,
					});
				} else {
					avsCallback();
					avsCallback = null;
				}
			} else {
				// delay reload as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.push({
						callback: reloadWithoutVXPayFlow,
						name:     NotificationTypes.AVS_SET,
					});
				} else {
					reloadWithoutVXPayFlow();
				}
			}
			break;
		case NotificationTypes.VOICECALL_STOP:
			// reload page when anonymous voicecall is stopped, except when the chat exit is open. if the chat exit is open delay reload until chat exit is closed so the guest can rate the chat
			if (Flux.Guest.isAnonymousVoicecall() && notification.voicecallIdHash && notification.voicecallIdHash === VXConfig.voicecallIdHash) {
				if (Flux.NewMessenger.isChatExitOpen()) {
					chatExitCloseCallback = reloadWithoutVXPayFlow;
				} else {
					reloadWithoutVXPayFlow();
				}
			}
			break;
		case NotificationTypes.BONUS_CODE_REDEEMED :
			if (bonusCodeRedeemedCallback) {
				const clb = bonusCodeRedeemedCallback.bind(null, notification);

				// delay callback as long as paytour is still open
				if (isPaytourOpen) {
					callbackQueue.unshift({
						callback: clb,
						name:     NotificationTypes.BONUS_CODE_REDEEMED,
					});
				} else {
					clb();
				}
			}
			break;
		default:
	}
}

function onChatExitChangeEvent(isOpen) {
	// reload page on chat exit close if user stopped the voicecall
	if (Flux.Guest.isAnonymousVoicecall() && !isOpen && typeof chatExitCloseCallback === 'function') {
		chatExitCloseCallback();
	}
}

/**
 * Try to retrieve login cookie info from VXPay
 * @returns {Promise}
 */
function getHasLoginCookiePromise() {
	return new Promise(function(resolve) {
		// post message listener for VXPay
		window.addEventListener('message', function(e) {
			// origin check
			if (e && e.origin && VXConfig.vxPay.baseUrl.indexOf(e.origin) === -1) {
				return false;
			}

			try {
				const data = e.data;
				const json = JSON.parse(data);

				if (json.type === 'modalbox-response-hasLoginCookie') {
					VXPay.userAlreadySignedUp = !!json.data;
					resolve(VXPay.userAlreadySignedUp);
				}
			} catch (e) {
				// do nothing
			}
		}, false);

		const iframe         = document.createElement('iframe');
		iframe.src           = VXConfig.vxPay.helperUrl;
		iframe.style.display = 'none';
		document.body.appendChild(iframe);
	});
}

function getPrefillOptions(options = {}) {
	if (VXConfig.vxPay.screenName) {
		options.screenName = VXConfig.vxPay.screenName;
	}
	if (VXConfig.vxPay.signupEmail) {
		options.signupEmail = VXConfig.vxPay.signupEmail;
	}
	if (VXConfig.vxPay.signupName) {
		options.signupName = VXConfig.vxPay.signupName;
	}

	// update actor data
	options.host     = VXConfig.vxPay.actorIdOrName || options.host || 0;
	options.hostData = options.hostData || {id: VXConfig.vxPay.actorIdOrName || 0};

	const coversionVXPayConfig = Flux.Conversion.getVXPayConfig();

	if (isCookieCategoryEnabled(Flux.Constants.CookieCategories.ANALYTICS)) {
		options.enableTracking = true;
	}

	if (!options.selectedAmount) {
		options.selectedAmount = 6000;
	}

	return {...coversionVXPayConfig, ...options,};
}

/**
 *
 * @param {Object} result
 * @returns {boolean}
 */
function shouldForcePostRequest(result) {
	return !Flux.Guest.isLoggedIn() && (
		(typeof result.userFromSignup !== 'undefined' && result.userFromSignup === true)
		|| (typeof result.userFromLogin !== 'undefined' && result.userFromLogin === true)
	);
}

const getOptions = () => ({
	'agbUrl':        'https://www.visit-x.net/?jump=agb',
	'privacyUrl':    'https://www.visit-x.net/CAMS/DE/Info/Zentrum.html?submod=Datenschutz&track=Index',
	'environment':   VXConfig.vxPay.environment,
	'flow':          VXConfig.vxPay.flow,
	'lang':          VXConfig.vxPay.language,
	'pfm':           VXConfig.pfm,
	'pfmsub':        VXConfig.pfmSubref,
	'w':             VXConfig.wmId,
	'ws':            VXConfig.wmSubref,
	'wt':            VXConfig.wmToken,
	'gk_cid':        VXConfig.wmGkCid,
	'adtv':          VXConfig.adtv,
	'sub':           VXConfig.subref,
	'enableTab':     isMobileRequest() || VXConfig.guestIsLoggedIn,
	'option':        VXConfig.vxPay.option,
	'pc':            VXConfig.vxPay.promoCode,
	'tt':            VXConfig.vxPay.token,
	'ruri':          VXConfig.vxPay.sessionStartEntryUrl,
	'host':          VXConfig.vxPay.actorIdOrName,
	'externalRefId': VXConfig.vxPay.externalRefId,
	'externalToken': VXConfig.vxPay.externalToken,
});

const vxpay = new ModalboxPayment(
	{
		'baseUrl': VXConfig.vxPay.baseUrl,
	},
	window,
	getOptions(),
	{
		'login':         1,
		'showHeader':    1,
		'showTeaser':    VXConfig.vxPay.showTeaser,
		'showFooter':    1,
		'neutralHeader': 1,
		'teaserBonus':   0,
		'support':       VXConfig.vxPay.showSupportChat,
		'showOAuth':     1,
		'showNL':        1,
		'showThank':     0,
		'showLogo':      VXConfig.vxPay.showLogo,
		'showTeaserBar': !VXConfig.vxPay.actorIdOrName ? 1 : 0,
		'hideClose':     VXConfig.vxPay.hideClose,
		'tracking':      isCookieCategoryEnabled(Flux.Constants.CookieCategories.ANALYTICS) ? 1 : 0,
	},
	{
		onSuccess: function(result) {
			const isMoneyChargeFlow = result && result.flow && result.flow === Flux.Constants.VXPay.Flows.MONEY_CHARGE;
			const isAvsFlow         = result && result.flow && result.flow === Flux.Constants.VXPay.Flows.AVS;
			const isLoginFlow       = result && result.flow && result.flow === Flux.Constants.VXPay.Flows.LOGIN;
			const isVipAboFlow      = result && result.flow && result.flow === Flux.Constants.VXPay.Flows.VIP_ABO;
			const isVXTVFlow        = result && result.flow && result.flow === Flux.Constants.VXPay.Flows.VXTV_ABO;

			// avoid duplicate execution of login success
			if (isLoginFlow && loginExecuted) {
				reloadWithoutVXPayFlow();
				return;
			}

			const flowForPostRequest = !isMoneyChargeFlow && !isAvsFlow && !isVipAboFlow && !isVXTVFlow;
			const forcePostRequest   = shouldForcePostRequest(result);
			const needsPostRequest   = flowForPostRequest || forcePostRequest;

			if (!isCallbackInProgress && needsPostRequest) {
				isCallbackInProgress = true;
			} else if (isCallbackInProgress) {
				return;
			}

			if (isLoginFlow) {
				GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true, {
					overlayClassName: '-dark',
				});
			}

			if (needsPostRequest) {
				doFetch(
					Routes.getRoute(Routes.Names.VX_PAY_ON_LOGIN_SUCCESS, null, null),
					{
						result:    result,
						targetUrl: result.redirectTo || window.location.href.split('#')[0],
					},
					Flux.Constants.HttpMethods.POST,
					true
				)
					.then(function(response) {
						isCallbackInProgress = false;
						if (isLoginFlow) {
							Flux.Messenger.clearChannel();
						}

						let action = Flux.Browser.getLocalItem('onLoginAction');
						try {
							action = JSON.parse(action);
						} catch (e) {
							action = null;
						}

						if (action && action.type === Flux.Constants.ActionTypes.VXPay.REDIRECT_TO) {
							Flux.Browser.removeLocalItem('onLoginAction');
							if (action.params.targetUrl) {
								window.location.assign(getUrlWithoutVXPayFlow(action.params.targetUrl));
							}
						} else if (response.data && response.data.url) {
							if (VXConfig.routeName === 'Lindemann') {
								setTimeout(function() {
									window.location.href = 'https://' + window.location.hostname + '/' + VXConfig.language + '/lindemann/home' + window.location.search;
								}, 500);
							} else {
								window.location.href = getUrlWithoutVXPayFlow(response.data.url);
							}
						} else {
							reloadWithoutVXPayFlow();
						}
					})
					.catch(function() {
						isCallbackInProgress = false;
						/* simple fix, when VXPay session is expired */
						window.location.reload();
					});
			} else if (loginExecuted) {
				reloadWithoutVXPayFlow();
			}

			delayedCallbacks();
		},
		onClose:   function(result) {
			if (shouldForcePostRequest(result)) {
				this.onSuccess(result);
			} else {
				delayedCallbacks();
			}
		},
		onLogout:  (/*isLoggedIn*/) => {
			if (typeof window.$zopim !== 'undefined' && window.$zopim && window.$zopim.livechat) {
				window.$zopim.livechat.clearAll();
			}
			doFetch(Routes.getRoute(Routes.Names.VX_PAY_LOGOUT, null, null), null, Flux.Constants.HttpMethods.POST, true)
				.then(function() {
					window.location.reload();
				})
				.catch(function() {
					window.location.reload();
				});
		},
	}
);

// also for mobile use fixed positioned paytour iframe - VX1V2-246
vxpay.on('createIframe', (ifrm) => {
    ifrm.style.position = 'fixed';
});

if (VXConfig.vxPay.useIframe) {
    vxpay._setUseTab(false, true);
}

// initial flow options
if (VXConfig.vxPay.defaultFlowOptions) {
	vxpay.setDefaultFlowOptions(VXConfig.vxPay.defaultFlowOptions);
}

// should we init VXPay app with the new token?
if (VXConfig.vxPay.token) {
	// force iframe
	const useTab = vxpay._getUseTab();
	vxpay._setUseTab(false, false);
	// let VXPay create session
	vxpay.init();
	// set original behaviour for mobile
	if (useTab) {
		vxpay._setUseTab(true, true);
		vxpay.onReady = () => {
			vxpay.remove();
			vxpay.onReady = null;
		};
	}
}

// restore VXPay, if needed after redirect
if (VXConfig.vxPay.allowRestore) {
	vxpay.tryRestore();
}

// also for pjax refresh
document.addEventListener('pjax:success', function() {
	vxpay.setOptions(getOptions());
	vxpay.tryRestore();
});

// add hooks
vxpay.hook('emailVerified', function() {
	Flux.Notification.onReady(function() {
		Flux.Notification.refreshEmailProperties();
	});
});
vxpay.hook('emailNotVerified', function() {
	Flux.Notification.onReady(function() {
		Flux.Notification.refreshEmailProperties();
	});
});
vxpay.hook('passwordChanged', function() {
	Flux.Notification.onReady(function() {
		Flux.Notification.passwordChanged();
	});
});
vxpay.hook('waitForFeedback', function(shouldWait) {
	VXPay.waitForFeedback = shouldWait;
});
vxpay.hook('signup', function() {
	ga('send', 'event', 'User', 'signup');
});
vxpay.hook('login', function() {
	ga('send', 'event', 'User', 'login');
});
vxpay.hook('flowChanged', function(data) {
	if (data.prevFlow === Flux.Constants.VXPay.Flows.LOGIN && data.flow !== Flux.Constants.VXPay.Flows.LOGIN && data.success) {
		// force login flow in order for VXPayFlowApi to work correctly
		data.result.flow = Flux.Constants.VXPay.Flows.LOGIN;

		doFetch(
			Routes.getRoute(Routes.Names.VX_PAY_ON_LOGIN_SUCCESS, null, null),
			{
				result:    data.result,
				targetUrl: window.location.href.split('#')[0],
			},
			Flux.Constants.HttpMethods.POST,
			true
		)
			.then(function() {
				Flux.Messenger.clearChannel();
				loginExecuted = true;

				vxpay.onClose = () => {
					reloadWithoutVXPayFlow();
				};
			})
			.catch(function() {
					/* simple fix, when VXPay session is expired */
					window.location.reload();
				}
			);
	}
});
vxpay.hook('track', function(data) {
	// login_state
	if (data.data[EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE]) {
		trackLoginState(data.data[EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE]);
	}

	// moneycharge
	if (data.data[EnumGA4Events.CUSTOM_PARAMETER_MONEYCHARGE]) {
		trackMoneycharge(data.data[EnumGA4Events.CUSTOM_PARAMETER_MONEYCHARGE]);
	}
});

class VXPayAction {

	/**
	 * @param {string} type
	 * @param {object} params
	 */
	constructor(type, params = null) {
		this.type   = type || null;
		this.params = params;
	}
}

const VXPay = {
	userAlreadySignedUp: VXConfig.vxPay.userAlreadySignedUp,

	openAvsWithLogout: function() {
		const options = {
			isAVSWithLogout: true,
		};
		VXPay.openAVS(options);
	},

	openSignupOrLogin: function(options = {}, action) {
		if (typeof action !== 'undefined' && action instanceof VXPayAction) {
			const hostData = options.hostData || {};
			switch (action.type) {
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ACTOR:
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ALBUM:
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_PICTURE:
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_SEDCARDS:
					if (typeof hostData.teaserText === 'undefined' || !hostData.teaserText) {
						hostData.teaserText = VXPay.hasLoginCookie((val) => val ? Translations.get('PinLoginTextForVXPay') : Translations.get('PinRegisterTextForVXPay'));
					}
					break;
				default:
			}

			options.hostData = hostData;
			Flux.Browser.setLocalItem('onLoginAction', JSON.stringify(action));
		} else {
			Flux.Browser.removeLocalItem('onLoginAction');
		}

		if (this.userAlreadySignedUp) {
			vxpay.openLogin(getPrefillOptions(options));
		} else {
			vxpay.openSignup(getPrefillOptions(options));
		}
	},

	callVXPayAction: function() {
		let action = Flux.Browser.getLocalItem('onLoginAction');
		try {
			action = JSON.parse(action);
		} catch (e) {
			action = null;
		}

		if (action) {
			Flux.Browser.removeLocalItem('onLoginAction');
			switch (action.type) {
				case Flux.Constants.ActionTypes.Actor.LOAD_NOTE:
					if (action.params.targetId) {
						Flux.Actor.getActorNote(action.params.targetId, action.params.storeAutoOpenSetting);
					}
					break;
				case Flux.Constants.ActionTypes.Special.LOAD_SPECIAL_VENUS_ATTACKS_REWARD:
					Flux.Special.loadVenusAttacksReward(action.params && action.params.qmDate ? action.params.qmDate : '');
					break;
				case Flux.Constants.ActionTypes.Special.LOAD_SPECIAL_WIESN_GAUDI_REWARD:
					Flux.Special.loadWiesnShootingReward(action.params && action.params.qmDate ? action.params.qmDate : '');
					break;
				case Flux.Constants.ActionTypes.Contest.SET_SHOULD_OPEN_MEDIA:
					if (action.params.shouldOpen && action.params.config) {
						Flux.Contest.setShouldOpenMedia(action.params.shouldOpen, action.params.config);
					}
					break;
				case Flux.Constants.ActionTypes.Contest.SET_SHOULD_OPEN_NEXT_CONTESTS_MODAL:
					if (action.params.shouldOpen) {
						Flux.Contest.setShouldOpenNextContestsModal(action.params.shouldOpen);
					}
					break;
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ACTOR:
					if (action.params.targetId) {
						Flux.Favorite.pinActor(action.params.targetId);
					}
					break;
				case Flux.Constants.ActionTypes.Notification.FAVORITE_UNPIN_ACTOR:
					if (action.params.targetId) {
						Flux.Favorite.unpinActor(action.params.targetId);
					}
					break;
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ALBUM:
					if (action.params.targetId) {
						Flux.Favorite.pinAlbum(action.params.targetId);
					}
					break;
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_PICTURE:
					if (action.params.targetId) {
						Flux.Favorite.pinPicture(action.params.targetId);
					}
					break;
				case Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_SEDCARDS:
					if (action.params.targetId) {
						Flux.Favorite.pinSedcards(action.params.targetId);
					}
					break;
				case Flux.Constants.ActionTypes.Gallery.SET_GUEST_RATING:
					if (action.params.targetId && action.params.value) {
						Flux.Gallery.setGuestRating(action.params.targetId, action.params.value);
					}
					break;
				case Flux.Constants.ActionTypes.Gallery.GALLERY_SET_SEDCARDS_GUEST_RATING:
					if (action.params.targetId && action.params.value) {
						Flux.Gallery.setSedcardsGuestRating(action.params.targetId, action.params.value);
					}
					break;
				case Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL:
					if (action.params.targetId && !VXConfig.isVXMobilePlatform) {
						Flux.Messenger.selectChannelByActorId(action.params.targetId, action.params.storeAutoOpenSetting);
					} else if (action.params.targetUrl) {
						window.location.assign(action.params.targetUrl);
					}
					break;
				case Flux.Constants.ActionTypes.VXPay.SHOW_PAYTOUR:
					if (action.params.options) {
						VXPay.openPaytour(action.params.options);
					} else {
						VXPay.openPaytour();
					}
					break;
				case Flux.Constants.ActionTypes.VXPay.SHOW_SETTINGS:
					VXPay.openSettings();
					break;
				case Flux.Constants.ActionTypes.VXPay.SHOW_VXTVABO:
					if (!Flux.Guest.isPremium()) {
						VXPay.openVXTVAbo(action.params ? action.params.options : null);

						if (action.params && action.params.targetUrl) {
							VXPay.onVXTVAboSuccess(() => {
								ReloadHelper.reload(action.params.targetUrl);
							});
						}
					}
					break;
				case Flux.Constants.ActionTypes.VXPay.REDIRECT_TO:
					if (action.params.targetUrl) {
						window.location.assign(action.params.targetUrl);
					}
					break;
				default:
			}
		}
	},

	openLogin: function(options = {}, isModal = false) {
		if (showSexoleModal() && !isModal) {
			return false;
		}
		Flux.Browser.removeLocalItem('onLoginAction');
		vxpay.openLogin(getPrefillOptions(options));
	},

	openPwdLost: function(options = {}) {
		vxpay.openPasswordLost(getPrefillOptions(options));
	},

	openSignup: function(options = {}) {
		Flux.Browser.removeLocalItem('onLoginAction');
		vxpay.openSignup(getPrefillOptions(options));
	},

	openPaytour: function(options) {
		if (Flux.Guest.isRestricted()) {
			this.openOPCompensation();
		} else {
			isPaytourOpen = true;
			vxpay.openPaytour('', getPrefillOptions(options));
		}
	},

	openTrialPaytour: function(options) {
		isPaytourOpen = true;
		vxpay.openTrialPaytour(getPrefillOptions(options));
	},

	openVoicecall: function() {
		const options = {isGuestLoggedIn: Flux.Guest.isLoggedIn()};
		vxpay.openPaytourByPaytype(PAYTYPES.VOICECALL, options);

		// special success / close handling for anonymous voicecall: reload page
		if (!Flux.Guest.isLoggedIn()) {
			vxpay.onClose = vxpay.onSuccess = function() {
				// give the SVXExchangeWorker time to process the information
				setTimeout(reloadWithoutVXPayFlow, 100);
			};
		}
	},

	openAutoRecharge: function(config) {
		isPaytourOpen = true;
		vxpay.openAutoRechargePaytour({
			autoRechargeData: {
				data: config,
			},
		});
	},

	openOneClick: function(config) {
		isPaytourOpen = true;
		vxpay.openOneClickPaytour({
			oneClickData: {
				data: config,
			},
		});
	},

	openAVS: function() {

		setOnFlowStart(() => {
			isPaytourOpen = true;
		});
		setOnFlowEnd(() => {
			delayedCallbacks();
		});

		if (Flux.Guest.isLoggedIn()) {
			openModalBox(ModalBoxEnum.AVS);
		} else {
			VXPay.openSignupOrLogin();
		}
	},

	openSettings: function(options = {}) {
		vxpay.openVXSettings(options);
	},

	openPromoCode: function(options = {}) {
		isPaytourOpen = true;
		vxpay.openPromoCode(options);
	},

	openScratchCard: function() {
		isPaytourOpen = true;
		vxpay.openScratchCard();
	},

	openAboPaytour: function() {
		isPaytourOpen = true;
		vxpay.openAboPaytour();
	},

	openVXTVAbo: function(options = {}) {
		isPaytourOpen = true;
		vxpay.openVXTVAbo(options);
	},

	openVXAboPaytour: function(options = {}) {
		isPaytourOpen = true;
		vxpay.openVXAboPaytour(options);
	},

	openTrialAboPaytour: function(route = '', options = {}) {
		if (route) {
			options.flow = Flux.Constants.VXPay.Flows.TRIAL_VIP_ABO;
			vxpay.openPaytour(route, options);
		} else {
			vxpay.openTrialAboPaytour(options);
		}
	},

	openOPCompensation: function() {
		vxpay.openOPCompensation();
	},

	onSettingsClose: function(callback) {
		const oldClose = vxpay.onClose;
		vxpay.onClose  = () => {
			callback();
			vxpay.onClose = oldClose;
			oldClose();
		};
	},

	/**
	 * @param {function(result, oldSuccess):string} callback
	 */
	onSuccess: function(callback) {
		const oldClose   = vxpay.onClose;
		const oldSuccess = vxpay.onSuccess;

		vxpay.onClose   = function() {
			vxpay.onClose   = oldClose;
			vxpay.onSuccess = oldSuccess;
			oldClose();
		};
		vxpay.onSuccess = (result) => {
			callback(result, oldSuccess);
			vxpay.onClose   = oldClose;
			vxpay.onSuccess = oldSuccess;

			delayedCallbacks();
		};
	},

	onAVSSuccess: function(callback) {
		avsCallback = callback;

		setOnFlowEnd(() => {
			delayedCallbacks(() => {
				avsCallback = null;
			});
		});
	},

	onVIPAboSuccess: function(callback) {
		const oldClose   = vxpay.onClose;
		const oldSuccess = vxpay.onSuccess;

		vipAboCallback  = callback;
		vxpay.onClose   = function() {
			delayedCallbacks(() => {
				vipAboCallback  = null;
				vxpay.onClose   = oldClose;
				vxpay.onSuccess = oldSuccess;
			});
		};
		vxpay.onSuccess = function() {
			delayedCallbacks(() => {
				vxpay.onClose   = oldClose;
				vxpay.onSuccess = oldSuccess;
			});
		};
	},

	onVXTVAboSuccess: function(callback) {
		const oldClose   = vxpay.onClose;
		const oldSuccess = vxpay.onSuccess;

		vxtvAboCallback = callback;
		vxpay.onClose   = function() {
			delayedCallbacks(() => {
				vxtvAboCallback = null;
				vxpay.onClose   = oldClose;
				vxpay.onSuccess = oldSuccess;
			});
		};
		vxpay.onSuccess = function() {
			delayedCallbacks(() => {
				vxpay.onClose   = oldClose;
				vxpay.onSuccess = oldSuccess;
			});
		};
	},

	onMoneyChargeSuccess: function(callback) {
		const oldClose      = vxpay.onClose;
		const oldSuccess    = vxpay.onSuccess;
		moneyChargeCallback = callback;

		vxpay.onClose   = function() {
			delayedCallbacks(() => {
				moneyChargeCallback       = null;
				moneyChargeCallbackResult = null;
				vxpay.onClose             = oldClose;
				vxpay.onSuccess           = oldSuccess;
			});
		};
		vxpay.onSuccess = function(result) {
			moneyChargeCallbackResult = result;

			delayedCallbacks(() => {
				vxpay.onClose   = oldClose;
				vxpay.onSuccess = oldSuccess;
			});
		};
	},

	onBonusCodeRedeemed: function(callback) {
		bonusCodeRedeemedCallback = callback;
	},

	onVoiceCallSuccess: function(callback) {
		// only set custom callback for logged in guest
		if (Flux.Guest.isLoggedIn()) {
			const oldClose   = vxpay.onClose;
			const oldSuccess = vxpay.onSuccess;

			onVoiceCallCallback = callback;

			vxpay.onClose   = function() {
				delayedCallbacks(() => {
					onVoiceCallCallback();
					vxpay.onClose   = oldClose;
					vxpay.onSuccess = oldSuccess;
				});
			};
			vxpay.onSuccess = function() {
				delayedCallbacks(() => {
					onVoiceCallCallback();
					vxpay.onClose   = oldClose;
					vxpay.onSuccess = oldSuccess;
				});
			};
		}
	},

	openChangeCreditcard: function() {
		vxpay.openChangeCreditcard();
	},

	openLimitPaytour: function() {
		vxpay.openLimitPaytour();
	},

	remove: function() {
		vxpay.remove();
	},

	hasLoginCookie: function(clb) {
		if (VXPay.userAlreadySignedUp !== null) {
			if (clb) {
				clb(VXPay.userAlreadySignedUp);
			}
		} else {
			if (clb && hasLoginCookiePromise) {
				hasLoginCookiePromise.then(() => {
					clb(VXPay.userAlreadySignedUp);
				});
			}
		}
	},

	logout: function() {
		vxpay.logout(function() {
			if (typeof window.$zopim !== 'undefined' && window.$zopim && window.$zopim.livechat) {
				window.$zopim.livechat.clearAll();
			}

			trackLoginState(EnumGA4Events.CUSTOM_PARAMETER_LOGIN_STATE__VALUE_LOGOUT_SUCCESS);

			doFetch(Routes.getRoute(Routes.Names.VX_PAY_LOGOUT, null, null), null, Flux.Constants.HttpMethods.POST, true)
				.then(function() {
					window.location.reload();
				})
				.catch(function() {
					window.location.reload();
				});
		}.bind(this), true);
	},

	onClickGetVip() {
		if (!Flux.Guest.isLoggedIn()) {
			VXPay.openLogin();
		} else if (!Flux.Guest.isVip()) {
			VXPay.openAboPaytour();
		}
	},

	onCountdownfinished: function(func) {
		vxpay.onCountdownfinished = func;
	},

	hook: function(event, callback) {
		vxpay.hook(event, callback);
	}


};

if (typeof window === 'object') {
	window.VXPay = VXPay;

	// reload if AVS changed
	Flux.Notification.addNotificationChangeListener(notificationChangeListener);
	Flux.NewMessenger.addChatExitChangeListener(onChatExitChangeEvent);
}

// if user is not logged in
if (!Flux.Guest.isLoggedIn()) {
	// check login cookie and store it in VXPay object to allow openSignupOrLogin to work on mobile devices (with new tab for VXPay)
	hasLoginCookiePromise = getHasLoginCookiePromise();

	if (VXConfig.vxPay.vxpFlow === VXPayFlow.METHOD_SIGNUP && VXConfig.vxPay.externalToken && VXConfig.vxPay.signupEmail && VXConfig.vxPay.signupName) {
		const useTab   = vxpay._getUseTab();
		const oldClose = vxpay.onClose;
		vxpay._setUseTab(false, true);
		VXPay.openSignup();
		vxpay.onClose = () => {
			if (useTab) {
				vxpay._setUseTab(true, true);
			}

			vxpay.onClose = oldClose;
		};
	}
} else {
	VXPay.userAlreadySignedUp = true;
	VXPay.callVXPayAction();

	if (!Flux.Guest.hasAvs() && VXConfig.forceAVSModal) {
		const useTab     = vxpay._getUseTab();
		const oldSuccess = vxpay.onSuccess;
		vxpay._setUseTab(false, true);

		const options = {
			isAVSWithLogout: true,
		};
		VXPay.openAVS(options);

		vxpay.onSuccess = (result) => {
			if (useTab) {
				vxpay._setUseTab(true, true);
			}
			vxpay.onSuccess = oldSuccess;
			oldSuccess(result);
		};
	}
}

export {
	VXPay,
	VXPayAction,
};
