import React                                                           from 'react';
import PropTypes                                                       from 'prop-types';
import {VXButtonConfig, VXButtonLineConfig, VXButtonLineStyle}                            from "./VXButtonConfig";
import {getColor, getClassByLineStyle, whichAnimationEvent, getBorder} from "./ButtonHelper";
import Flux                                                            from "../../../flux/Flux";
import STYLES                                                          from './EnumVXButton';
import ReloadHelper                                                    from "../../../utils/ReloadHelper";
import { generateKey } from '../../../utils/CommonUtils';

const ANIMATION_STEP_NONE = 0;
const ANIMATION_STEP_IN   = 1;
const ANIMATION_STEP_WAIT = 2;
const ANIMATION_STEP_OUT  = 3;

const ANIMATION_EVENT_TYPE_IN_OUT   = 'inOut';
const ANIMATION_EVENT_TYPE_OUT_NONE = 'outNone';
const ANIMATION_EVENT_TYPE_IN_WAIT  = 'inWait';


class VXButton extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			config:        new VXButtonConfig(),
			animationStep: ANIMATION_STEP_NONE,
		};

		this.success            = true;
		this.animationRef       = null;
		this.animationEventType = whichAnimationEvent();
		this.disableAnimation   = Flux.Browser.isIE() || Flux.Browser.isEdge();

		this.applyProps           = this.applyProps.bind(this);
		this.startTransition      = this.startTransition.bind(this);
		this.endTransition        = this.endTransition.bind(this);
		this.handleAnimationEvent = this.handleAnimationEvent.bind(this);
		this.onButtonClick        = this.onButtonClick.bind(this);
		this.onMouseDown          = this.onMouseDown.bind(this);
		this.getClassByStep       = this.getClassByStep.bind(this);

		this.applyProps();
	}

	componentDidUpdate(prevProps) {
		// reset
		this.success = true;

		if (this.props.staticMode) {
			this.applyProps();
			if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
				this.forceUpdate();
			}
		}
	}

	applyProps() {
		this.state.config.color       = this.props.color;
		this.state.config.line1       = this.props.line1;
		this.state.config.line2       = this.props.line2;
		this.state.config.onClick     = this.props.onClick;
		this.state.config.onMouseDown = this.props.onMouseDown;
		this.state.config.icon        = this.props.icon;
		this.state.config.disabled    = this.props.disabled;
		this.state.config.border      = this.props.border;
		this.state.config.size        = this.props.size;
	}

	/**
	 * If you don't give a config here, the transition will wait for the endTransition() call.
	 * This should be used if you have possible loading times. You end the transition after you received your result.
	 * If a config is given, the transition will fully run.
	 *
	 * @param {VXButtonConfig} config
	 */
	startTransition(config = null) {
		if (this.disableAnimation) {
			if (config) {
				this.setState({config});
			}
			return;
		}

		this.setState({animationStep: ANIMATION_STEP_IN});

		const type = config ? ANIMATION_EVENT_TYPE_IN_OUT : ANIMATION_EVENT_TYPE_IN_WAIT;

		this.animationRef.addEventListener(this.animationEventType, e => this.handleAnimationEvent(e, type, config), {once: true});
	}

	/**
	 * This function ends a transition that got started previously
	 *
	 * @param {VXButtonConfig} config
	 * @param {boolean} success
	 */
	endTransition(config, success = true) {
		if (this.disableAnimation) {
			this.setState({config});
			return;
		}

		this.success = success;

		switch (this.state.animationStep) {
			case ANIMATION_STEP_IN:
				this.animationRef.addEventListener(this.animationEventType, (e) => this.handleAnimationEvent(e, ANIMATION_EVENT_TYPE_IN_OUT, config), {once: true});
				break;
			case ANIMATION_STEP_WAIT:
				this.setState({
					config:        config,
					animationStep: ANIMATION_STEP_OUT,
				});

				this.animationRef.addEventListener(this.animationEventType,
					e => (this.handleAnimationEvent(e, ANIMATION_EVENT_TYPE_OUT_NONE)), {once: true});
				break;
			case ANIMATION_STEP_NONE:
			case ANIMATION_STEP_OUT:
			default:
				break;
		}

	}

	handleAnimationEvent(e, type, config = null) {
		switch (type) {
			case ANIMATION_EVENT_TYPE_IN_OUT:
				const state         = {};
				state.animationStep = ANIMATION_STEP_OUT;
				if (config) {
					state.config = config;
				}
				// Because we animate more than one Element, we have multiple events coming in after each other
				// We need to make sure that every animation has ended before we add a new handler
				setTimeout(() => {
					this.setState(state);
					this.animationRef.addEventListener(this.animationEventType, (e) => this.handleAnimationEvent(e, ANIMATION_EVENT_TYPE_OUT_NONE), {once: true});
				}, 10);
				break;
			case ANIMATION_EVENT_TYPE_OUT_NONE:
				this.setState({animationStep: ANIMATION_STEP_NONE});
				break;
			case ANIMATION_EVENT_TYPE_IN_WAIT:
				this.setState({animationStep: ANIMATION_STEP_WAIT});
				break;
			default:
				break;
		}
	}

	onButtonClick(e) {
		// disable while transitioning
		if (!this.state.config.disabled && this.state.animationStep === ANIMATION_STEP_NONE) {
			if (typeof this.props.callback === 'function') {
				this.props.callback();
			}
			if (this.props.link) {
				ReloadHelper.reload(this.props.link, e);
			} else if (typeof this.state.config.onClick === 'function') {
				this.state.config.onClick(e);
			}
		}
	}

	onMouseDown(e) {
		// disable while transitioning
		if (!this.state.config.disabled && this.state.animationStep === ANIMATION_STEP_NONE && typeof this.state.config.onMouseDown === 'function') {
			this.state.config.onMouseDown(e);
		}
	}

	getClassByStep() {
		switch (this.state.animationStep) {
			case ANIMATION_STEP_IN:
				return ' -animate-in';
			case ANIMATION_STEP_WAIT:
				return ' -animate-wait';
			case ANIMATION_STEP_OUT:
				return ' -animate-out';
			case ANIMATION_STEP_NONE:
			default:
				return '';
		}
	}

	/**
	 *
	 * @param {VXButtonLineConfig} config
	 * @param key unique key within parent
	 * @returns {*}
	 */
	getLineByConfig(config, key) {
		if (!config) {
			return null;
		}

		const styleClass = getClassByLineStyle(config.style);

		return <span className={styleClass} key={key}>{config.text}</span>;
	}

	render() {
		let icon = null;
		if (this.state.config.icon) {
			icon = <span key={generateKey('icon')} className={"icon " + this.state.config.icon + " " + this.props.iconPosition} />;
		}

		const line1 = [];
		let length  = this.state.config.line1.length;
		for (let i = 0; i < length; i++) {
			line1.push(this.getLineByConfig(this.state.config.line1[i], i));
			if (i !== length - 1) {
				line1.push(" ");
			}
		}

		const line2 = [];
		length      = this.state.config.line2.length;
		for (let i = 0; i < length; i++) {
			line2.push(this.getLineByConfig(this.state.config.line2[i], i));
			if (i !== length - 1) {
				line2.push(" ");
			}
		}

		const lineWrapper = (
			<span key={generateKey('lineWrapper')} className={"vxbutton-lwrapper"}>
				<div className={"vxbutton-l1"}>{line1}</div>
				{line2.length > 0 ? <div className={"vxbutton-l2"}>{line2}</div> : null}
			</span>
		);

		let dummy     = null;
		let className = 'vxbutton -size-' + this.props.size;
		if (icon) {
			if (line2.length > 0) {
				className += ' vxbutton-icon-line2';
				dummy = <span key={generateKey('dummy')} className={"vxbutton-dummy"} />;
			} else {
				className += ' vxbutton-icon';
			}
		} else if (line2.length > 0) {
			className += " vxbutton-line2";
			dummy = <span key={generateKey('dummy')} className={"vxbutton-dummy"} />;
		}

		const colorClass = getColor(this.state.config.color);
		className += ' ' + colorClass;

		className += this.getClassByStep();
		className += ' ' + getBorder(this.state.config.border);

		let loader = null;
		if (this.state.animationStep === ANIMATION_STEP_WAIT) {
			loader = <span className={"vxbutton-loader"} />;
		} else if (this.state.animationStep === ANIMATION_STEP_OUT && this.props.animateSuccess) {
			if (this.success) {
				loader = (
					<span className={"vxbutton-success-wrapper"}>
						<i className={"icon -icon-success-rounded-full vxbutton-success " + (line2.length > 0 ? '-big ' : '') + colorClass} />
					</span>
				);
			} else {
				loader = (
					<span key={generateKey('loader')} className={"vxbutton-success-wrapper"}>
						<i className={"icon -icon-close-full vxbutton-success " + (line2.length > 0 ? '-big ' : '') + colorClass} />
					</span>
				);
			}
		}

		if (this.state.config.disabled) {
			className += ' -disabled';
		}

		const children = [
			loader,
			this.props.iconPosition === "left" && icon,
			lineWrapper,
			this.props.iconPosition === "right" && icon,
			dummy,
		];

		const elementProps = {
			ref:         ref => (this.animationRef = ref),
			onClick:     this.onButtonClick,
			onMouseDown: this.onMouseDown,
			className:   className,
			style:       this.props.overrideStyles,
			["data-place"]:   'top',
			["data-tip"]:     this.props.toolTip,
		};

		let element;
		if (this.props.link) {
			element = <a {...elementProps} href={this.props.link}>{children}</a>;
		} else {
			element = <span {...elementProps}>{children}</span>;
		}

		return element;
	}

}

const VXBUTTON_LINE_INSTANCES = [
	PropTypes.instanceOf(VXButtonLineConfig),
	PropTypes.instanceOf(VXButtonLineStyle)
];

VXButton.propTypes = {
	/**
	 * @see ButtonHelper.js
	 */
	color:  PropTypes.string.isRequired,
	border: PropTypes.oneOf([
		STYLES.BORDER.NONE,
		STYLES.BORDER.OUTLINE,
	]),

	line1: PropTypes.arrayOf(PropTypes.oneOfType(VXBUTTON_LINE_INSTANCES)).isRequired,
	line2: PropTypes.arrayOf(PropTypes.oneOfType(VXBUTTON_LINE_INSTANCES)),

	/**
	 * e.g. "-icon-search-full"
	 */
	icon:    PropTypes.string,
	onClick: PropTypes.func,
	/**
	 * If link is given onClick will be ignored and the Button will be rendered as <a/> with default handling
	 */
	link:        PropTypes.string,
	onMouseDown: PropTypes.func,
	disabled:    PropTypes.bool,
	/**
	 * Should we show checkmark / cross
	 */
	animateSuccess: PropTypes.bool,
	size:           PropTypes.oneOf(Object.values(STYLES.SIZE)), //animation + multi-line buttons only work with 'small'
	overrideStyles: PropTypes.object,
	iconPosition:   PropTypes.oneOf(['left', 'right']),
	staticMode:     PropTypes.bool,
	callback:       PropTypes.func,
	toolTip:        PropTypes.string
};

VXButton.defaultProps = {
	line2:          [],
	icon:           null,
	disabled:       false,
	animateSuccess: false,
	border:         STYLES.BORDER.NONE,
	size:           STYLES.SIZE.SMALL,
	iconPosition:   'left',
	staticMode:     false,
	link:           null,
	toolTip:        ''
};

export default VXButton;