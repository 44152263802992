import ModalBoxTagReward   from "./ModalBoxTagReward";
import Translations        from "../../utils/Translations";
import React               from "react";
import PropTypes           from 'prop-types';


const ModalBoxTagRewardBasic = (props) =>
	<ModalBoxTagReward {...props}
	                   imageUrl={'/assets/img/modal/tag-reward/Coins.svg'}
	                   headline={Translations.get('ModalBoxTagRewardHeadlineBasic')}
	                   subline={Translations.get('ModalBoxTagRewardSublineBasic')}
	                   bonusName={'Münzen'}
	                   bonusSubline={Translations.get('RewardMessengerGift')}
	/>;

ModalBoxTagRewardBasic.propTypes = {
	onClose:            PropTypes.func,
	isMobile:           PropTypes.bool,
};

export default ModalBoxTagRewardBasic;