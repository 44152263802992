import React           from 'react';
import PropTypes       from 'prop-types';
import Translations    from '../../utils/Translations';
import ActorPin        from '../Favorite/Actor/Pin';
import VideochatButton from '../SimpleElements/VideochatButton';
import Flux            from '../../flux/Flux';

class ActorPlateExtended extends React.PureComponent {

	render() {
		return (
			<div className="row context-box--media-box__plate -actor-plate-extended">
				<div className="col-xs-6">
					<ul className="h-list--horizontal actor-name">
						<li className="context-box--media-box__list__item actor-plate__actor-name -size-medium"
						    title={this.props.name}
						><h3 className="actor-plate__actor-name -size-medium">{this.props.name}</h3></li>
						<li className="context-box--media-box__list__item actor-age -size-medium">({this.props.age})</li>
						{Flux.Guest.isLoggedIn() && <li className="context-box--media-box__list__item">
							<span className="actorFavorite">
								<ActorPin actorId={this.props.actorId}
								          initialIsFavoriteOfGuest={this.props.isModelGuestFavorite}
								          fontSize="-size-sm"
								/>
							</span>
						</li>}
					</ul>
					<ul className="h-list--horizontal actor-plate__actor-languages">
						{this.props.spokenLanguages.map(lang =>
							<li key={'lang-' + lang} className="context-box--media-box__list__item">
								<span className={"badge-language -lang-" + lang}> </span>
							</li>
						)}
					</ul>
				</div>
				<div className="col-xs-6">
					<div className="btn__videochat">
						<VideochatButton
							multiChatPrice={this.props.multiChatPrice}
							actorId={this.props.actorId}
							isActorOnline={this.props.isOnline}
							isVoyeurMode={false}
							buttonSubline={Translations.get('ActorGridVideoTextFeaturedSubline')}
							buttonText={Translations.get('ActorGridVideoTextFeatured')}
							freeChat={this.props.freeChat}
							isBannedCountryCode={this.props.isBannedCountryCode}
							actorLink={this.props.actorLink}
							isCurrentlyTicketShow={this.props.isCurrentlyTicketShow}
							guestHasTicket={this.props.guestHasTicket}
							gaString={this.props.gaString}
							isOnlineMobileVideocall={this.props.isOnlineMobileVideocall}
							tooltipText={this.props.isOnlineMobileVideocall ? Translations.get('common.videocall-tooltip') : ''}
						/>
					</div>
				</div>
			</div>
		);
	}
}

ActorPlateExtended.propTypes = {
	actorId:                 PropTypes.number.isRequired,
	name:                    PropTypes.string.isRequired,
	age:                     PropTypes.number.isRequired,
	isOnline:                PropTypes.bool.isRequired,
	spokenLanguages:         PropTypes.array.isRequired,
	multiChatPrice:          PropTypes.number.isRequired,
	guestIsLoggedIn:         PropTypes.bool.isRequired,
	isModelGuestFavorite:    PropTypes.bool.isRequired,
	freeChat:                PropTypes.object,
	isBannedCountryCode:     PropTypes.bool,
	actorLink:               PropTypes.string,
	isCurrentlyTicketShow:   PropTypes.bool,
	guestHasTicket:          PropTypes.bool,
	isOnlineMobileVideocall: PropTypes.bool,
	gaString:                PropTypes.string,
};

export default ActorPlateExtended;
