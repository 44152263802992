import React               from 'react';
import PropTypes           from 'prop-types';
import {formatLargeNumber} from '../../utils/CommonUtils';
import Translations        from '../../utils/Translations';

const FeatureList = (props) => {
	let list;

	if (props.inFooter) {
		list = (
			<div className="feature-teaser-box -feature-list">
				<div className="feature-teaser-box__headline">{Translations.get('FeatureList1')}</div>
				<div className="feature-teaser-box__bullet">
					<i className="icon -icon-girl-full feature-teaser-box__bullet-icon" />
					<span className="feature-teaser-box__bullet-text">{formatLargeNumber(props.countPlatformActors)} <strong>{Translations.get('FeatureList2')}</strong></span>
				</div>
				<div className="feature-teaser-box__bullet">
					<i className="icon -icon-play-rounded-full feature-teaser-box__bullet-icon" />
					<span className="feature-teaser-box__bullet-text">{formatLargeNumber(props.countPlatformVideos)} <strong>{Translations.get('FeatureList3')}</strong></span>
				</div>
				<div className="feature-teaser-box__bullet">
					<i className="icon -icon-image-full icon-small feature-teaser-box__bullet-icon" />
					<span className="feature-teaser-box__bullet-text">{formatLargeNumber(props.countPlatformPictures)} <strong>{Translations.get('FeatureList4')}</strong></span>
				</div>
			</div>
		);
	} else {
		list = (
			<div className="feature-teaser-box__wrapper">
				<div className="feature-teaser-box row -feature-list">
					<div className="feature-teaser-box__headline--arrow-full">{Translations.get('FeatureList1')}</div>
					<div className="feature-teaser-box__bullet">
						<i className="icon -icon-girl-full" />
						<span>{formatLargeNumber(props.countPlatformActors)}<br /><strong>{Translations.get('FeatureList2')}</strong></span>
					</div>
					<div className="feature-teaser-box__bullet">
						<i className="icon -icon-play-rounded-full" />
						<span>{formatLargeNumber(props.countPlatformVideos)}<br /><strong>{Translations.get('FeatureList3')}</strong></span>
					</div>
					<div className="feature-teaser-box__bullet">
						<i className="icon -icon-image-full icon-small" />
						<span>{formatLargeNumber(props.countPlatformPictures)}<br /><strong>{Translations.get('FeatureList4')}</strong></span>
					</div>
				</div>
			</div>
		);
	}

	return list;
};

FeatureList.propTypes = {
	countPlatformActors:   PropTypes.number.isRequired,
	countPlatformPictures: PropTypes.number.isRequired,
	countPlatformVideos:   PropTypes.number.isRequired,
	inFooter:              PropTypes.bool,
};

FeatureList.defaultProps = {
	inFooter: false,
};

export default FeatureList;
