/* globals VXConfig */
import React                       from 'react';
import PropTypes                   from 'prop-types';
import Modal                       from '../Modal';
import Translations                from "../../utils/Translations";
import VXButton                    from '../SimpleElements/VXButton/VXButton';
import {VXButtonLineConfig}        from '../SimpleElements/VXButton/VXButtonConfig';
import {COLOR_TYPE_CALL_TO_ACTION} from '../SimpleElements/VXButton/ButtonHelper';
import {VXPay}                     from '../../utils/VXPay';
import Flux                        from '../../flux/Flux';

class ModalBoxNotEnoughMoney extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isMobile = VXConfig.isVXMobilePlatform;

		this.state = {isLandscape: this.isMobile && Flux.Browser.isLandscape()};

		this.onBuyClick   = this.onBuyClick.bind(this);
		this.getRef       = this.getRef.bind(this);
	}

	onBuyClick(e) {
		// stop event propagation for preventing react issue with Modal click listener
		e.stopPropagation();

		VXPay.openPaytour();

		this.refModal.removeModal();
	}

	getRef(ref) {
		if (ref) {
			this.refModal = ref;
		}
	}

	render() {
		const classSuffix = this.state.isLandscape && this.isMobile ? ' -is-landscape' : (this.isMobile ? ' -is-mobile' : '');

		const content = (
			<div className={"not-enough-money__container" + classSuffix}>
				<div className={"not-enough-money__body" + classSuffix}>
					<img src={"/assets/icons/ChatDisabled.svg"} />
					<div className={"not-enough-money__headline" + classSuffix}>{Translations.get('messenger.popupNotEnoughMoneyHeadline')}</div>
					<div className={"not-enough-money__text" + classSuffix}>{Translations.get('messenger.popupNotEnoughMoneyText', this.props.actorName)}</div>
				</div>
				<div className={"not-enough-money__footer" + classSuffix}>
					<div className={"conversion-chat-box__button" + classSuffix}>
						<VXButton onClick={this.onBuyClick}
						          line1={[new VXButtonLineConfig(Translations.get('MessengerCharge'))]}
						          color={COLOR_TYPE_CALL_TO_ACTION}
						/>
					</div>
					<div className={"not-enough-money__close" + classSuffix} onClick={this.props.onClose}>{Translations.get("BtnClose")}</div>
				</div>
			</div>
		);
		return (
			<Modal
				className="-theme-dialog -no-padding h-center"
				modalClassName="not-enough-money-modal"
				dynamicSize={true}
				renderInline={true}
				ref={this.getRef}
				onClose={this.props.onClose}
			>
				{content}
			</Modal>
		);
	}

}

ModalBoxNotEnoughMoney.propTypes = {
	actorName: PropTypes.string.isRequired,
	onClose:   PropTypes.func.isRequired,
};

export default ModalBoxNotEnoughMoney;