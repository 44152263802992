/*global VXConfig*/
import Translations    from "../utils/Translations";
import React                           from "react";
import {onClickGetVip} from "./VIPClub/VIPClubHelper";

class Vip30ButtonBadge extends React.PureComponent {

	getRestDays() {
		const now = new Date();
		const monthDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
		const daysLeft  = monthDays - now.getDate();
		if (daysLeft > 0) {
			return `${Translations.get('VIP30.vip30_button_badge_only_still')} ${daysLeft} ${Translations.get('VIP30.vip30_button_badge_days_available')}`;
		}

		return Translations.get('VIP30.vip30_button_badge_only_today_available');
	}

	render() {
		const onClick = function(e) {
			e.preventDefault();
			e.stopPropagation();
			onClickGetVip();
		};

		const freeTranslation = Translations.get("ChatButtonFree");
		const freeCapitalized = freeTranslation.charAt(0).toUpperCase() + freeTranslation.slice(1);

		return (
			<div className={"vip30-button-badge"}>
				{VXConfig.isVip ?
					<span className={"vip30-button-badge-info"}>{this.getRestDays()}</span>
					:
					<div className={"vip30-button-badge"} onClick={onClick}>
						{freeCapitalized} {Translations.get("With")} <img className={"vip30-button-badge-img"} src={"/assets/img/vip30/VXLogo_Shadow.svg"}/>
						<span className={"vip30-button-badge--gold"}>VIP</span>
						<span className={"vip30-button-badge--normal"}>CLUB</span>
						<i className={"icon -icon-single-arrow-right-line"}></i>
					</div>}
			</div>
		);
	}

}

export default Vip30ButtonBadge;