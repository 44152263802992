import React                    from 'react';
import PropTypes                from 'prop-types';
import LogoLoader               from "../../Loader/LogoLoader";
import { generateKey }          from '../../../utils/CommonUtils';

function withStaticLoader(WrappedComponent) {

	class withStaticLoader extends React.Component {
		render() {
			if (this.props.loading && !this.props.isLoadMore) {
				return this.props.useFullscreenLoader ? <div className="logo-loader__overlay"><LogoLoader pulse={true} /></div> : <div className="h-pos-relative"><LogoLoader className="-static" pulse={true} /></div>;
			}
			return [
				this.props.isLoadMore && this.props.loading && <div key={generateKey('overlay')} className="logo-loader__overlay"><LogoLoader pulse={true} /></div>, 
				<WrappedComponent key={generateKey('wrapped-component')} {...this.state} {...this.props} />,
			];
		}
	}

	withStaticLoader.propTypes    = {
		loading:             PropTypes.bool,
		isLoadMore:          PropTypes.bool,
		useFullscreenLoader: PropTypes.bool,
	};
	withStaticLoader.defaultProps = {
		loading:             false,
		isLoadMore:          false, 
		useFullscreenLoader: false,
	};

	return withStaticLoader;
}

export default withStaticLoader;
