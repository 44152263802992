import React                                               from 'react';
import Modal                                               from '../Modal';
import Translations                                        from "../../utils/Translations";
import VXButton                                            from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}                                from "../SimpleElements/VXButton/VXButtonConfig";
import {getCommonTranslation}                              from "../../utils/TranslationHelper";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_TRANSPARENT} from "../SimpleElements/VXButton/ButtonHelper";
import {VXPay}                                             from "../../utils/VXPay";
import {trackMixedFeed}                                    from "../../utils/GAHelper";
class ModalBoxFeedSignup extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	onClickSignUp() {
		trackMixedFeed('click_signup-screen');
		VXPay.openSignup();
	}
	
	onClickLogin() {
		trackMixedFeed('click_login-screen');
		VXPay.openLogin();
	}

	render() {
		return (
			<Modal
				className="h-center modal__design-guideline -theme-dark modal__feed_signup"
				containerWidth={390}
				containerHeight={300}
				{...this.props}
			>
				<i className={"icon -icon-user-full"}></i>
				<h3>{Translations.get("common.modal__feed-signup__headline")}</h3>
				<h4>{Translations.get("common.modal__feed-signup__text")}</h4>
				<VXButton line1={[new VXButtonLineConfig(getCommonTranslation('login_or_signup_button').toUpperCase())]}
				          color={COLOR_TYPE_CALL_TO_ACTION}
				          onClick={this.onClickSignUp}
				/>
				<br />
				<VXButton line1={[new VXButtonLineConfig('LOGIN')]}
				          onClick={this.onClickLogin}
				          color={COLOR_TYPE_TRANSPARENT}
				/>
			</Modal>
		);
	}

}

export default ModalBoxFeedSignup;