import React           from 'react';
import PropTypes       from 'prop-types';
import Translations    from '../../../utils/Translations';
import ActorPin        from '../../Favorite/Actor/Pin';
import Flux            from '../../../flux/Flux';
import {VXPay}         from '../../../utils/VXPay';
import VideochatButton from '../../SimpleElements/VideochatButton';

class ActorPlateExtended extends React.Component {

	constructor(props) {
		super(props);

		this.onStartTextChat = this.onStartTextChat.bind(this);
	}

	onStartTextChat(e) {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(this.props.actorId, true);
		} else {
			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  this.props.actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				});
			} else {
				VXPay.openSignupOrLogin();
			}
		}
		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}
		return false;
	}

	render() {
		return (
			<div className="row context-box--media-box__plate -actor-plate-extended">
				<div className="col-xs-12 h-text-align-center">
					<ul className="h-list--horizontal actor-name h-disp-inline-block">
						<li className="context-box--media-box__list__item actor-plate__actor-name -size-tall">{this.props.name}</li>
						<li className="context-box--media-box__list__item actor-age -size-tall">({this.props.age})</li>
						{Flux.Guest.isLoggedIn() && <li className="context-box--media-box__list__item">
							<span className="actorFavorite">
								<ActorPin actorId={this.props.actorId}
								          initialIsFavoriteOfGuest={this.props.isModelGuestFavorite}
								          fontSize="-size-sm"
								/>
							</span>
						</li>}
					</ul>
				</div>
				<div className="col-xs-6">
					<div className="content-box -no-padding-left">
						<button className="btn--icon-detailed -icon-user-full -click-material h-width-full">
							<span className="ink animate" />
							{Translations.get('ActorGridProfileText')}
							<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridLearnMoreText')}</span>
						</button>
					</div>
				</div>
				<div className="col-xs-6">
					<div className="content-box">
						{this.props.isOnline ?
							<VideochatButton
								additionalClassNames={'-click-material h-width-full'}
								multiChatPrice={this.props.multiChatPrice}
								actorId={this.props.actorId}
								isActorOnline={this.props.isOnline}
								isVoyeurMode={false}
								buttonSubline={Translations.get('ActorGridVideoTextFeaturedSubline')}
								buttonText={Translations.get('ActorGridVideoText')}
								freeChat={this.props.freeChat}
								isBannedCountryCode={this.props.isBannedCountryCode}
								actorLink={this.props.actorLink}
								isCurrentlyTicketShow={this.props.isCurrentlyTicketShow}
							    guestHasTicket={this.props.guestHasTicket}
							/> :
							<button onClick={this.onStartTextChat}
							        className="btn--icon-detailed -icon-chat-text-full -click-material h-width-full"
							>
								<span className="ink animate" />
								{Translations.get('ActorGridMessageText')}
								<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridWriteFreeText')}</span>
							</button>
						}
					</div>
				</div>
			</div>
		);
	}
}

ActorPlateExtended.propTypes = {
	actorId:               PropTypes.number.isRequired,
	addedFormatted:        PropTypes.string,
	name:                  PropTypes.string.isRequired,
	age:                   PropTypes.number.isRequired,
	isOnline:              PropTypes.bool.isRequired,
	spokenLanguages:       PropTypes.array.isRequired,
	multiChatPrice:        PropTypes.number.isRequired,
	isModelGuestFavorite:  PropTypes.bool.isRequired,
	freeChat:              PropTypes.object,
	isBannedCountryCode:   PropTypes.bool,
	actorLink:             PropTypes.string,
	isCurrentlyTicketShow: PropTypes.bool,
	guestHasTicket:        PropTypes.bool,
};

ActorPlateExtended.defaultProps = {
	addedFormatted: null,
};

export default ActorPlateExtended;
