import React            from 'react';
import PropTypes        from 'prop-types';
import {formatCurrency} from "../../utils/CommonUtils";
import Countdown        from "./Countdown";
import Translations     from "../../utils/Translations";

class BonusTimer extends React.PureComponent {

	render() {
		return (
			<div className={'feature-teaser-box -bonus h-text-align-center'}>
				<div className="feature-teaser-box__headline--arrow-full-bonus">
					{Translations.get('VideoBonusBoxHeadLine')}
				</div>
				<div className="feature-teaser-box__offertitle">
					{Translations.get('TeaserBonusTileText3')}
				</div>
				<div className="feature-teaser-box__bonusvalue">
					{formatCurrency(this.props.bonusAmount)}
				</div>
				<div className="feature-teaser-box__offertitle">
					{Translations.get('VideoBonusBoxTitle')}
				</div>
				<Countdown className="h-center" countdown={this.props.countdown} countdownStartTime={this.props.countdownStartTime} />
			</div>);
	}
}

BonusTimer.propTypes = {
	bonusAmount:        PropTypes.string.isRequired,
	countdown:          PropTypes.number.isRequired,
	countdownStartTime: PropTypes.object,
};

BonusTimer.defaultProps = {
	countdownStartTime: null,
};

export default BonusTimer;
