import React            from 'react';
import PropTypes        from 'prop-types';
import Translations     from '../../utils/Translations';
import {VXPay}          from '../../utils/VXPay';
import CountDownSpinner from './CountDownSpinner';
import Player           from './Player';
import Tracker          from './Tracker';
import Flux             from '../../flux/Flux';
import VisibilitySensor from 'react-visibility-sensor';

const USER_INTERACTION_SKIP = 'skip';
const USER_INTERACTION_PLAY = 'play';

//const TRANSITION_SPEED = 2;

let isInit = false;

function Overlay({global}) {
	function onClick(e) {
		if (global) {
			e.stopPropagation();
		}
	}

	return <div className={'welcome-clip-rotator__overlay' + (global ? ' -global' : '')} onClick={onClick} />;
}

Overlay.propTypes = {
	className: PropTypes.string,
	global:    PropTypes.bool,
};

function Preview({blur, src, lazyImageLoading}) {
	return <img src={src} className={'welcome-clip-rotator__preview' + (blur ? ' -blurred' : '')} loading={lazyImageLoading ? 'lazy' : 'eager'} />;
}

Preview.propTypes = {
	className:        PropTypes.string,
	blur:             PropTypes.bool,
	src:              PropTypes.string,
	lazyImageLoading: PropTypes.bool,
};

Preview.defaultProps = {
	lazyImageLoading: false,
};

function PrevArrow({className, isMobile}) {
	return <i className={'welcome-clip-rotator__profile-arrow--left icon ' + (className ? ' ' + className : '') + (isMobile ? '-icon-complete-arrow-left-line' : '-icon-single-arrow-left-line')} />;
}

PrevArrow.propTypes = {
	className: PropTypes.string,
	isMobile:  PropTypes.bool,
};

function NextArrow({className, isMobile}) {
	return <i className={'welcome-clip-rotator__profile-arrow--right icon ' + (className ? ' ' + className : '') + (isMobile ? '-icon-complete-arrow-right-line' : '-icon-single-arrow-right-line')} />;
}

NextArrow.propTypes = {
	className: PropTypes.string,
	isMobile:  PropTypes.bool,
};

class WelcomeClipRotator extends React.Component {
	static onSignupClick(e) {
		e.preventDefault();

		VXPay.openSignupOrLogin();
	}

	static onBigPlayClick({playlistItem}) {
		Tracker.trackUserPlay({playlistItem});
	}

	constructor(props) {
		super(props);

		this.state = {
			autoplay:      false,
			muted:         true,
			playlist:      props.initialPlaylist,
			playlistIndex: 0,
			machineState:  WelcomeClipRotator.mode.INITIAL,
		};

		this.onCancelClick      = this.onCancelClick.bind(this);
		this.onCountDownEnded   = this.onCountDownEnded.bind(this);
		this.onPlaying          = this.onPlaying.bind(this);
		this.onPlayManualClick  = this.onPlayManualClick.bind(this);
		this.onPlayerEnded      = this.onPlayerEnded.bind(this);
		this.onPlayerError      = this.onPlayerError.bind(this);
		this.onPrevClick        = this.onPrevClick.bind(this);
		this.onNextClick        = this.onNextClick.bind(this);
		this.onVisibilityChange = this.onVisibilityChange.bind(this);
		this.move               = this.move.bind(this);
	}

	componentDidMount() {
		Tracker.trackRotatorShown();
	}

	onPlayManualClick() {
		this.move(1, USER_INTERACTION_PLAY);
	}

	onPrevClick() {
		if (this.state.playlistIndex > 0) {
			this.move(-1, USER_INTERACTION_SKIP);
		}
	}

	onNextClick(e) {
		this.move(1, USER_INTERACTION_SKIP);

		// last element?
		if (this.state.playlistIndex === this.state.playlist.length - 1 && !Flux.Guest.isLoggedIn()) {
			WelcomeClipRotator.onSignupClick(e);
		}
	}

	onCancelClick(e) {
		e.preventDefault();

		this.setState({
			autoplay:      false,
			showCountDown: false,
			machineState:  WelcomeClipRotator.mode.INITIAL,
		});
	}

	onPlaying({player, playlistItem}) {
		this.player = player;
		Tracker.trackPlaying({playlistItem});
	}

	onPlayerEnded({player}) {
		const isVideoPlayerFullScreen = window.innerHeight === screen.height;
		// last video?
		if (this.state.playlistIndex === this.state.playlist.length - 1) {
			this.setState({
				machineState: WelcomeClipRotator.mode.FINAL,
			});
		} else if (!isVideoPlayerFullScreen) {
			this.setState({
				showCountDown: true,
				autoplay:      true,
			});
			if (player && player.muted()) {
				this.setState({
					autoplay: false,
				});
			}
		} else if (isVideoPlayerFullScreen) {
			this.setState({
				showCountDown: false,
				autoplay:      false,
			});
		} else {
			this.move(1);
		}
	}

	onPlayerError() {
		// remove current failed video and skip to the next, if not the last one
		const playlist = this.state.playlist.filter((value, index) => index !== this.state.playlistIndex);
		this.setState({playlist, playlistIndex: Math.min(this.state.playlistIndex, playlist.length - 1)});
	}

	onCountDownEnded() {
		this.move(1);
	}

	move(offset, userInteraction = '') {
		let nextIndex = this.state.playlistIndex + offset;
		nextIndex     = Math.max(0, nextIndex);
		nextIndex     = Math.min(nextIndex, this.state.playlist.length - 1);

		if (userInteraction) {
			const currPlaylist = this.state.playlist[this.state.playlistIndex];

			if (userInteraction === USER_INTERACTION_SKIP) {
				Tracker.trackSkip({playlistItem: this.state.playlist[nextIndex], prevAlbumId: currPlaylist.albumId});
			} else if (userInteraction === USER_INTERACTION_PLAY) {
				Tracker.trackUserPlay({playlistItem: this.state.playlist[nextIndex]});
			}
		}

		// index change?

		this.setState(() => ({autoplay: true, showCountDown: false, playlistIndex: nextIndex}));
	}

	onVisibilityChange(isVisible) {
		const oldIsVisible = this.state.isVisible;
		if (oldIsVisible !== isVisible) {
			this.setState({
				autoplay: isVisible,
				isVisible,
			}, () => {
				if (isVisible && this.player) {
					this.player.play();
				} else if (this.player) {
					this.player.pause();
				}
			});
		}
	}

	render() {
		const {autoplay, machineState, playlist, playlistIndex, showCountDown} = this.state;
		const playlistCopy                                                     = JSON.parse(JSON.stringify(playlist));

		const defaultPrevPreviewPictureUrl = '/assets/img/welcome-clip-rotator/vx_welcomeclip_start_img.jpg';
		const defaultNextPreviewPictureUrl = '/assets/img/welcome-clip-rotator/vx_welcomeclip_end_img.jpg';

		const playlistItem     = playlistCopy[playlistIndex];
		const prevPlaylistItem = typeof playlist[playlistIndex - 1] !== 'undefined' ? playlist[playlistIndex - 1] : null;
		const nextPlaylistItem = typeof playlist[playlistIndex + 1] !== 'undefined' ? playlist[playlistIndex + 1] : null;
		const isLoggedIn       = Flux.Guest.isLoggedIn();

		if (!autoplay && !isInit) {
			// prevent loading video files
			playlistItem.videoSources = [];
		} else {
			isInit                         = true;
			// Remove Poster for current Item
			playlistItem.previewPictureUrl = null;
		}

		const countDownSpinner = autoplay ? <CountDownSpinner onEnded={this.onCountDownEnded} /> : <CountDownSpinner initialTime={0} />;

		let content;
		switch (machineState) {
			case WelcomeClipRotator.mode.INITIAL:
				content = <div className={'welcome-clip-rotator' + (showCountDown ? ' has-blurred has-no-arrows' : '')}>
					{showCountDown && <div>
						<Overlay global={true} />
						<div className="welcome-clip-rotator__countdown-block">
							<div className="welcome-clip-rotator__box-centered -margin-auto h-text-align-center">
								<div>{Translations.get('PlayNextVideo')}</div>
								<div onClick={this.onPlayManualClick} className="welcome-clip-rotator__countdown-block__countdown h-cursor-pointer">
									{countDownSpinner}
								</div>
								<div className="welcome-clip-rotator__countdown-block__bottom">
									<a href="#"
									   onClick={this.onCancelClick}
									   className="welcome-clip-rotator__countdown-block__cancel"
									>{Translations.get('BtnCancel')}</a>
								</div>
							</div>
						</div>
					</div>}
					<div className={'welcome-clip-rotator__block--left' + (playlistIndex === 0 ? '' : ' h-cursor-pointer')}
					     onClick={this.onPrevClick}
					>
						{playlistIndex === 0 && <div className="welcome-clip-rotator__motto">
							<div className="welcome-clip-rotator__box-centered">
								<div className="welcome-clip-rotator__headline -padding">{Translations.get('KnowOurAmateurs')}</div>
							</div>
						</div>}
						<Overlay />
						{prevPlaylistItem && <PrevArrow />}
						<Preview src={prevPlaylistItem ? prevPlaylistItem.previewPictureUrl : defaultPrevPreviewPictureUrl}
						         lazyImageLoading={this.props.lazyImageLoading}
						/>
					</div>
					<div className="welcome-clip-rotator__block--main">
						<Player autoplay={!showCountDown && autoplay}
						        muted={this.state.muted}
						        playlistItem={playlistItem}
						        onPlaying={this.onPlaying}
						        onEnded={this.onPlayerEnded}
						        onError={this.onPlayerError}
						        onBigPlayClick={WelcomeClipRotator.onBigPlayClick}
						        onClick={this.onVideoClick}
						        showCountDown={this.state.showCountDown}
						/>
					</div>
					<div className="welcome-clip-rotator__block--right h-cursor-pointer" onClick={this.onNextClick}>
						<Overlay />
						{nextPlaylistItem && <NextArrow />}
						<Preview src={nextPlaylistItem ? nextPlaylistItem.previewPictureUrl : defaultNextPreviewPictureUrl}
						         lazyImageLoading={this.props.lazyImageLoading}
						/>
					</div>
				</div>;
				break;
			case WelcomeClipRotator.mode.FINAL:
				content = <div className={'welcome-clip-rotator' + (!isLoggedIn ? ' has-blurred' : '')}>
					{!isLoggedIn && <Overlay global={true} />}
					{!isLoggedIn && <div className="welcome-clip-rotator__final-block">
						<div className="welcome-clip-rotator__box-centered -center-global -margin-auto h-text-align-center">
							<div className="welcome-clip-rotator__headline -padding">{Translations.get('SeeMoreAmateurs')}
							</div>
							<a href="#"
							   className="btn -btn-color-special -click-material welcome-clip-rotator__signup-button"
							   onClick={WelcomeClipRotator.onSignupClick}
							   dangerouslySetInnerHTML={{__html: Translations.get('NavbarLabelLoginOrSignup')}}
							></a>
							<div><a href="#" onClick={this.onCancelClick} className="h-color-fg">{Translations.get('BtnCancel')}</a></div>
						</div>
					</div>}
					<div className="welcome-clip-rotator__block--left" onClick={this.onPrevClick}>
						{prevPlaylistItem && <PrevArrow />}
						<Preview src={prevPlaylistItem ? prevPlaylistItem.previewPictureUrl : defaultPrevPreviewPictureUrl}
						         lazyImageLoading={this.props.lazyImageLoading}
						/>
					</div>
					<div className="welcome-clip-rotator__block--main">
						<Player autoplay={false}
						        muted={this.state.muted}
						        playlistItem={playlistItem}
						        onPlaying={this.onPlaying}
						        onEnded={this.onPlayerEnded}
						        onError={this.onPlayerError}
						        onBigPlayClick={WelcomeClipRotator.onBigPlayClick}
						/>
					</div>
					<div className="welcome-clip-rotator__block--right" onClick={this.onNextClick}>
						{nextPlaylistItem && <NextArrow />}
						<Preview src={nextPlaylistItem ? nextPlaylistItem.previewPictureUrl : defaultNextPreviewPictureUrl}
						         lazyImageLoading={this.props.lazyImageLoading}
						/>
					</div>
				</div>;
				break;
			default:
		}

		return <VisibilitySensor onChange={this.onVisibilityChange}>
			{content}
		</VisibilitySensor>;
	}
}

WelcomeClipRotator.mode = {
	INITIAL: 'initial',
	FINAL:   'final',
};

WelcomeClipRotator.propTypes = {
	initialPlaylist:  PropTypes.array,
	lazyImageLoading: PropTypes.bool,
};

WelcomeClipRotator.defaultProps = {
	initialPlaylist:  [],
	lazyImageLoading: false,
};

export default WelcomeClipRotator;
