import React     from 'react';
import PropTypes from 'prop-types';

const CLASS_NAME = 'badge--normal tile-badge tile-badge-free';

class TileBadgeClassic extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>CLASSIC</span>;
	}
}

TileBadgeClassic.propTypes = {
	show: PropTypes.bool.isRequired,
};

TileBadgeClassic.defaultProps = {
	show: false,
};

export default TileBadgeClassic;
