/* globals VXConfig VXPay */

import React                       from 'react';
import PropTypes                   from 'prop-types';
import Modal                       from '../Modal';
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import Flux                        from '../../flux/Flux';
import Constants                   from '../../flux/Constants.js';


const TRANSLATIONS = {
	sublineText:   {DE: 'Diese Vorteile erhalten nur VIP CLUB Mitglieder', EN: 'These benefits are only available for VIP CLUB members'},
	premiumTv:     {DE: `TV Premium Inhalte 18+ im Wert von 4,99${Constants.CurrencySymbol.EUR} monatlich`, EN: `Premium TV adult content worth 4,99${Constants.CurrencySymbol.EUR} per month`},
	lateNightShow: {DE: `Late Night Show Ticket im Wert von 4,99${Constants.CurrencySymbol.EUR} monatlich`, EN: `Late Night Show ticket worth 4,99${Constants.CurrencySymbol.EUR} per month`},
	boni:          {DE: `VIP Guthaben & Boni (mind. im Wert von 20${Constants.CurrencySymbol.EUR}* monatlich)`, EN: `VIP credit & bonuses (worth over 20${Constants.CurrencySymbol.EUR} per month)`},
	videos:        {DE: 'Kostenpflichtige Amateur Videos gratis', EN: 'Fee-based amateur videos for free'},
	getVip:        {DE: 'VIP Mitglied werden', EN: 'Become a VIP member'},
	priceHint:     {DE: `9,95${Constants.CurrencySymbol.EUR}/Monat, monatlich kündbar`, EN: `9,95${Constants.CurrencySymbol.EUR}/per month, monthly cancellation`},
};


function getTranslation(KEY) {
	let language = (VXConfig.language).toUpperCase();
	if (language !== 'DE' && language !== 'EN') {
		language = 'EN';
	}

	if (KEY[language]) {
		return KEY[language];
	}

	return '';
}

class ModalBoxVip30 extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isMobile = VXConfig.isVXMobilePlatform;

		this.onClickGetVip = this.onClickGetVip.bind(this);
	}

	onClickGetVip() {
		VXPay.openAboPaytour();
	}

	getBenefitRow(text, classSuffix) {
		return (
			<div className={"vip30-modal-box-benefit" + classSuffix}>
				<span className={"icon -icon-success-rounded-full"}/>
				<span className={"vip30-modal-box-benefit-text" + classSuffix}>{text}</span>
			</div>
		);
	}

	render() {
		const classSuffix = this.isMobile ? ' -mobile' : '';

		const benefits = [
			this.getBenefitRow(getTranslation(TRANSLATIONS.premiumTv), classSuffix),
			this.getBenefitRow(getTranslation(TRANSLATIONS.lateNightShow), classSuffix),
			this.getBenefitRow(getTranslation(TRANSLATIONS.boni), classSuffix),
			this.getBenefitRow(getTranslation(TRANSLATIONS.videos), classSuffix),
		];

		const content = (
			<div className={"vip30-modal-box" + classSuffix}>
				<div className={"vip30-modal-box-overlay" + classSuffix}>
					<div className={"vip30-modal-box-title-wrapper" + classSuffix}>
						<img src={Flux.Browser.isSexole() ? "/assets/img/vipclub/vipclub_logo_color.svg" : "/assets/img/vipclub/vxvipclub_logo_color.svg"} className={"vip30-modal-box-header-img" + classSuffix}/>
					</div>
					<div className={"vip30-modal-box-subline" + classSuffix}>{getTranslation(TRANSLATIONS.sublineText)}!</div>
					<div className={"vip30-modal-box-benefits" + classSuffix}>
						{benefits}
						{this.isMobile && <img src={"/assets/img/vipclub/blue_badge_vipprice.png"} className={"vip30-modal-box-benefits-img"}/>}
					</div>
					<div className={"vip30-modal-box-button" + classSuffix}>
						<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
						          line1={[new VXButtonLineConfig(getTranslation(TRANSLATIONS.getVip))]}
						          icon={"-icon-single-arrow-right-line"}
						          iconPosition={'right'}
						          onClick={this.onClickGetVip}
						/>
						<div className={"vip30-modal-box-button-info"}>{getTranslation(TRANSLATIONS.priceHint)}</div>
					</div>
				</div>
			</div>
		);

		return (
			<Modal containerWidth={this.isMobile ? 'undefined' : 1168}
			       containerHeight={this.isMobile ? 'undefined' : 630}
			       dynamicSize={true}
			       className={"vip30-modal-box-container -theme-dialog -no-padding"}
			       ref={this.getRef}
			       renderInline={true}
			       onClose={this.props.onClose}
			>
				{content}
			</Modal>
		);
	}
}

ModalBoxVip30.propTypes = {
	onClose: PropTypes.func,
};

export default ModalBoxVip30;
