import React            from 'react';
import PropTypes        from 'prop-types';
import Footer           from './Footer';
import VideoPreview     from './VideoPreview';
import VideoPurchaseBox from './VideoPurchaseBox';
import CloseButton      from '../Conversion/CloseButton';
import Translations     from '../../utils/Translations';

const VideoPreviewEndedPurchaseBox = (props) =>
	<div className={'conversion-box__wrapper ' + (props.isMobile ? '-mobile' : '-pos-abs-center') + props.className}>
		<div className="conversion-box__content-container">
			<CloseButton {...props} />
			<div className="conversion-box__content-row row">
				<div className="col-xs-5 conversion-box__content-left -video-image-teaser">
					<VideoPreview previewImageUrl={props.videoPlayerIncentive.albumPreviewPicture.pictureUrl}>
						<div className="feature-teaser-box__wrapper" />
					</VideoPreview>
				</div>
				<div className="col-xs-7 conversion-box__content-right">
					<VideoPurchaseBox
						actorId={props.videoPlayerIncentive.actorId}
						albumId={props.videoPlayerIncentive.albumId}
						title={props.videoPlayerIncentive.albumTitle}
						price={props.videoPlayerIncentive.albumPrice}
						albumVoucherPrice={props.videoPlayerIncentive.albumVoucherPrice}
						needAvs={props.videoPlayerIncentive.needAvs}
						needVip={props.videoPlayerIncentive.needVip}
						onMobileSuccessFn={props.onCloseFn}
					/>
				</div>
			</div>
		</div>
		<Footer currentVideo={props.currentVideo}
		        videoPlayerIncentive={props.videoPlayerIncentive}
		        onCloseFn={props.onCloseFn}
		        items={[Translations.get('FooterItem1'),
		                Translations.get('FooterItem2'),
		                Translations.get('FooterItem3'),
		                Translations.get('FooterItem4')]}

		/>
	</div>;

VideoPreviewEndedPurchaseBox.propTypes = {
	className:            PropTypes.string,
	isMobile:             PropTypes.bool,
	onCloseFn:            PropTypes.func,
	showCloseButton:      PropTypes.bool,
	videoPlayerIncentive: PropTypes.shape({
		actorId:             PropTypes.number.isRequired,
		albumId:             PropTypes.number.isRequired,
		albumTitle:          PropTypes.string.isRequired,
		albumPrice:          PropTypes.number.isRequired,
		albumVoucherPrice:   PropTypes.number.isRequired,
		albumPreviewPicture: PropTypes.object.isRequired,
		needAvs:             PropTypes.bool.isRequired,
		needVip:             PropTypes.bool.isRequired,
	}).isRequired,
	currentVideo:         PropTypes.object,
};

VideoPreviewEndedPurchaseBox.defaultProps = {
	className:       '',
	isMobile:        false,
	onCloseFn:       null,
	showCloseButton: false,
};

export default VideoPreviewEndedPurchaseBox;
