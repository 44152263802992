import React        from 'react';
import PropTypes    from 'prop-types';
import ReloadHelper from '../../../utils/ReloadHelper';
import Pin          from "../../Favorite/Actor/Pin";

class NewActorPlate extends React.Component {

	componentWillMount() {
		this.gotoProfile = this.gotoProfileHandler.bind(this);
	}

	gotoProfileHandler(e) {
		ReloadHelper.reload(this.props.targetUrl, e);
	}

	render() {
		const subLine   = this.props.isHighlighted ? <li className="context-box--media-box__subline">{this.props.sublineText}</li> : null;
		const languages = this.props.spokenLanguages.map(l => <span key={'lang-' + l} className={'badge-language -lang-' + l} />);
		const age       = !this.props.isHighlighted ? <li className="context-box--media-box__list__item actor-age">({this.props.age})</li> : null;

		const pin = this.props.isHighlighted
			? <Pin actorId={this.props.actorId} initialIsFavoriteOfGuest={this.props.isModelGuestFavorite} fontSize="-size-xs" />
			: null;

		return (
			<div className="context-box--media-box__plate" onClick={this.gotoProfile}>
				<ul className="h-list--horizontal">
					<li className="context-box--media-box__list__item actor-name">{this.props.name} {pin}</li>
					{age}
					<li className="context-box--media-box__list__item -right">{languages}</li>
					{subLine}
				</ul>
			</div>
		);
	}
}

NewActorPlate.propTypes = {
	name:                 PropTypes.string.isRequired,
	targetUrl:            PropTypes.string.isRequired,
	age:                  PropTypes.number.isRequired,
	actorId:              PropTypes.number,
	sublineText:          PropTypes.string,
	isHighlighted:        PropTypes.bool,
	isModelGuestFavorite: PropTypes.bool,
	spokenLanguages:      PropTypes.array.isRequired,
};

NewActorPlate.defaultPlates = {
	isModelGuestFavorite: false,
	isHighlighted:        false,
};

export default NewActorPlate;

