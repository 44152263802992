import React     from 'react';
import PropTypes from 'prop-types';

class CloseButton extends React.Component {
	constructor(props) {
		super(props);

		this.onCloseClick = this.onCloseClick.bind(this);
	}

	onCloseClick() {
		if (typeof this.props.onCloseFn === 'function') {
			this.props.onCloseFn();
		}
	}

	render() {
		let closeButton = null;

		if (this.props.showCloseButton) {
			closeButton = (
				<div className={'conversion-box__close-button ' + this.props.classNames}>
					<i className={'icon -icon-close-line'} onClick={this.onCloseClick} />
				</div>
			);
		}

		return closeButton;
	}
}

CloseButton.defaultProps = {
	onCloseFn:       null,
	showCloseButton: false,
	classNames:      '',
};

CloseButton.propTypes = {
	onCloseFn:       PropTypes.func,
	showCloseButton: PropTypes.bool,
	classNames:      PropTypes.string,
};

export default CloseButton;
