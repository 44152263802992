import React     from 'react';
import PropTypes from 'prop-types';

const IconButton = (props) => {
	const onClick = () => {
		if (props.onClick) {
			props.onClick();
		}
	};

	return (
		<div className={'vx_shaka--ui-controls -icon-button'} onClick={onClick}>
			<span className={'icon ' + (props.checked ? props.checkedIcon : props.icon)} />
		</div>
	);
};

IconButton.propTypes = {
	checked:     PropTypes.bool,
	icon:        PropTypes.string,
	checkedIcon: PropTypes.string,
	onClick:     PropTypes.func,
};

IconButton.defaultProps = {
	checked:     false,
	checkedIcon: '',
};

export default IconButton;
