/* globals VXConfig */
import React               from 'react';
import PropTypes           from 'prop-types';
import AlbumBuyButtons     from '../ActorProfileVideos/AlbumBuyButtons';
import Flux                from '../../flux/Flux';
import Translations        from '../../utils/Translations';
import AlbumPinButtonSmall from '../ActorProfileVideos/AlbumPinButtonSmall';

const defaultItems = [];

class Footer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isAlbumFavorite: props.videoPlayerIncentive.isFavoriteAlbum,
		};

		defaultItems.push(
			Translations.get('FooterItem9'),
			Translations.get('FooterItem5'),
			Translations.get('FooterItem3'),
			Translations.get('FooterItem4')
		);

		this.pinAlbum               = this.pinAlbum.bind(this);
		this.unpinAlbum             = this.unpinAlbum.bind(this);
		this.onAlbumPinStatusChange = this.onAlbumPinStatusChange.bind(this);
	}

	componentDidMount() {
		Flux.Favorite.addAlbumPinStatusListener(this.onAlbumPinStatusChange);
	}

	componentWillUnmount() {
		Flux.Favorite.removeAlbumPinStatusListener(this.onAlbumPinStatusChange);
	}

	pinAlbum() {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Favorite.pinAlbum(this.props.videoPlayerIncentive.albumId);
		}
	}

	unpinAlbum() {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Favorite.unpinAlbum(this.props.videoPlayerIncentive.albumId);
		}
	}

	onAlbumPinStatusChange(albumId, status) {
		if (albumId === this.props.videoPlayerIncentive.albumId) {
			this.setState({
				isAlbumFavorite: status,
			});
		}
	}

	render() {
		const items = this.props.items;

		let i = 0;
		while (items.length < 4) {
			items.push(defaultItems[i]);
			i++;
		}

		return <div className="conversion-box__footer">
			<div className="conversion-box__footer-content">
				<div className="cb-footer__content row">
					<div style={{'float': 'right'}}
					     className={window.matchMedia("(orientation: landscape)").matches ? "col-sm-4 col-xs-4 col-btn" : ''}
					>
						<AlbumBuyButtons actorDataId={this.props.videoPlayerIncentive.actorId}
						                 isAlbumBought={this.props.currentVideo ? this.props.currentVideo.isBought : false}
						                 baseClassName="conversion-box__footer-content"
						                 type={this.props.type}
						                 bonusCode={this.props.videoPlayerIncentive.bonusCode}
						                 price={this.props.videoPlayerIncentive.albumPrice}
						                 voucherPrice={this.props.videoPlayerIncentive.albumVoucherPrice}
						                 needAvs={this.props.videoPlayerIncentive.needAvs}
						                 needVip={this.props.videoPlayerIncentive.needVip}
						                 id={this.props.videoPlayerIncentive.albumId}
						                 needBuying={this.props.videoPlayerIncentive.needBuying}
						                 needPayment={this.props.videoPlayerIncentive.needPayment}
						                 isVip30={this.props.videoPlayerIncentive.isVip30}
						                 crossPrice={this.props.videoPlayerIncentive.crossPrice}
						                 hasActiveDiscount={this.props.videoPlayerIncentive.hasActiveDiscount}
						                 onBuy={this.props.onCloseFn}
						                 sticky={false}
						>
							{VXConfig.isVXMobilePlatform &&
								<AlbumPinButtonSmall isAlbumFavorite={this.state.isAlbumFavorite}
								                     onClick={this.state.isAlbumFavorite ? this.unpinAlbum : this.pinAlbum}
								/>}
						</AlbumBuyButtons>
					</div>
					{VXConfig.isVXMobilePlatform && window.matchMedia("(orientation: landscape)").matches ?
						<ul className="col-sm-4 col-xs-6 h-list--unstyled-block cb-footer__content-list">
							<li className="cb-footer__bullet">
								<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[0]}</li>
							<li className="cb-footer__bullet">
								<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[1]}</li>
							<li className="cb-footer__bullet">
								<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[2]}</li>
							<li className="cb-footer__bullet">
								<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[3]}</li>
						</ul> :
						<div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
							<ul className="col-sm-6 col-xs-6 h-list--unstyled-block cb-footer__content-list">
								<li className="cb-footer__bullet">
									<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[0]}</li>
								<li className="cb-footer__bullet">
									<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[1]}</li>
							</ul>
							<ul className="col-sm-6 col-xs-6 h-list--unstyled-block cb-footer__content-list">
								<li className="cb-footer__bullet">
									<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[2]}</li>
								<li className="cb-footer__bullet">
									<i className="icon -icon-success-rounded-full h-disp-inline-block -color-conversionbox-success" /> {items[3]}</li>
							</ul>
						</div>}
				</div>
			</div>
		</div>;
	}
}

Footer.propTypes = {
	items:                PropTypes.arrayOf(PropTypes.string),
	videoPlayerIncentive: PropTypes.object,
	currentVideo:         PropTypes.object,
	type:                 PropTypes.string,
	onCloseFn:            PropTypes.func,
};

Footer.defaultProps = {
	items: [],
};

export default Footer;
