const EnumAlbumButtonsType = {
	avs:         'AVS',
	withVoucher: 'VOUCHER',
	buy:         'BUY',
	bought:      'BOUGHT',
	vip:         'VIP',
	contest:     'CONTEST',
	login:       'LOGIN',
	bonus:       'BONUS',
	vip30:       'VIP30',
};

export default EnumAlbumButtonsType;
