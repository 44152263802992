import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

function VideoSaleBox(props) {

	return (
		<div className="feature-teaser-box row">
			<div className="col-xs-12 feature-teaser-box__subheadline h-over-ellipsis"
			     title={props.title}
			     dangerouslySetInnerHTML={{__html: props.title}}
			/>
			<div className="col-xs-12 feature-teaser-box__line" />
			<div className="col-xs-12 feature-teaser-box__text -text-bonus"
			     dangerouslySetInnerHTML={{__html: Translations.get('VideoSaleBoxHeadLine2')}}
			/>
			<div className="col-xs-12 feature-teaser-box__headline -headline-bonus h-text-uppercase"
			     dangerouslySetInnerHTML={{__html: Translations.get('VideoSaleBoxHeadLine')}}
			/>
		</div>
	);
}

VideoSaleBox.propTypes = {
	albumId:   PropTypes.number.isRequired,
	title:     PropTypes.string.isRequired,
};

export default VideoSaleBox;
