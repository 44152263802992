import React     from 'react';
import PropTypes from 'prop-types';

import TileBadgeNew      from "../TileGrid/Badge/TileBadgeNew";
import TileBadge18plus   from "../TileGrid/Badge/TileBadge18plus";
import TileBadgeBought   from "../TileGrid/Badge/TileBadgeBought";
import TileBadgeVip      from "../TileGrid/Badge/TileBadgeVip";
import TileBadgeGift     from "../TileGrid/Badge/TileBadgeGift";
import TileBadgeFreeClip from "../TileGrid/Badge/TileBadgeFreeClip";
import TileBadgeContest  from "../TileGrid/Badge/TileBadgeContest";
import TileBadgePremium  from "../TileGrid/Badge/TileBadgePremium";
import TileBadgeClassic  from "../TileGrid/Badge/TileBadgeClassic";

class VideoBadgeManager extends React.PureComponent {
	render() {
		const vip     = (this.props.isLoggedIn || this.props.isAnonymousVoicecall) && this.props.videoBadgesConfig.album && (this.props.videoBadgesConfig.isVipContent || this.props.isVip30) && !this.props.videoBadgesConfig.isGift ?
			<TileBadgeVip show={this.props.showBadges} /> : null;
		const over18  = (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.isOver18Content) ?
			<TileBadge18plus className={"badge--highlight"} show={this.props.showBadges} /> : null;
		const New     = (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.isNew) ?
			<TileBadgeNew className={"badge--highlight"} show={this.props.showBadges} /> : null;
		const classic = (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.isClassic) ?
			<TileBadgeClassic className={"badge--highlight"} show={this.props.showBadges} /> : null;

		const premium = this.props.videoBadgesConfig.isPremium ? <TileBadgePremium show={this.props.showBadges} /> : null;

		const lastBadge = () => {
			if (this.props.isBought) {
				return <TileBadgeBought className={"badge--highlight"} show={this.props.showBadges} />;
			} else if (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.getPrice > 0.0 && this.props.videoBadgesConfig.needBuying && this.props.videoBadgesConfig.hasActiveDiscount) {
				return <span className="badge--normal h-text-highlight"
				             dangerouslySetInnerHTML={{__html: this.props.videoBadgesConfig.getDiscountBadge}}
				></span>;
			} else if (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.getPrice > 0.0 && this.props.videoBadgesConfig.needBuying) {
				return <span className="badge--normal">{this.props.videoBadgesConfig.currency}</span>;
			} else if (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.isGift) {
				return <TileBadgeGift className={"badge--highlight"} show={this.props.showBadges} />;
			} else if ((this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.getPrice === 0) && !this.props.videoBadgesConfig.needBuying && !this.props.videoBadgesConfig.isVipContent) {
				return <TileBadgeFreeClip className={"badge--normal"} show={this.props.showBadges} />;
			} else if (this.props.videoBadgesConfig.album && this.props.videoBadgesConfig.isContestContent && !this.props.videoBadgesConfig.isGift) {
				return <TileBadgeContest className={"badge--normal"} show={this.props.showBadges} />;
			}

			return null;
		};

		return (
			<div className="badge-container">
				{vip}
				{premium}
				{over18}
				{New}
				{classic}
				{(this.props.isLoggedIn || this.props.isAnonymousVoicecall) && this.props.showBadges ? lastBadge() : null}
			</div>
		);
	}
}

VideoBadgeManager.propTypes = {
	isLoggedIn:           PropTypes.bool,
	isBought:             PropTypes.bool,
	isVip30:              PropTypes.bool,
	showBadges:           PropTypes.bool,
	isAnonymousVoicecall: PropTypes.bool,
	videoBadgesConfig:    PropTypes.shape({
		album:             PropTypes.object,
		isGift:            PropTypes.bool,
		isOver18Content:   PropTypes.bool,
		isNew:             PropTypes.bool,
		isVipContent:      PropTypes.bool,
		isPremium:         PropTypes.bool,
		getPrice:          PropTypes.number,
		needBuying:        PropTypes.bool,
		hasActiveDiscount: PropTypes.bool,
		isContestContent:  PropTypes.bool,
		getDiscountBadge:  PropTypes.string,
		currency:          PropTypes.string,
		isClassic:         PropTypes.bool,
	}),
};

VideoBadgeManager.defaultProps = {
	showBadges:           false,
	isLoggedIn:           false,
	isBought:             false,
	isAnonymousVoicecall: false,
	videoBadgesConfig:    {
		album:             {},
		isGift:            false,
		isOver18Content:   false,
		isNew:             false,
		isVipContent:      false,
		isPremium:         false,
		getPrice:          0,
		needBuying:        false,
		hasActiveDiscount: false,
		isContestContent:  false,
		getDiscountBadge:  '',
		currency:          '',
	},
};

export default VideoBadgeManager;
