import React               from 'react';
import PropTypes           from 'prop-types';
import Flux                from '../../flux/Flux';
import Translations        from '../../utils/Translations';
import {VXPay}             from '../../utils/VXPay';
import ReloadHelper        from '../../utils/ReloadHelper';
import PictureBoxes        from './PictureBoxes';
import VideochatButton     from '../SimpleElements/VideochatButton';
import DildocontrolOverlay from "./DildocontrolOverlay";

class FavoriteTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClick         = this.onClick.bind(this);
		this.openMessengerFn = this.openMessengerFn.bind(this);
	}

	onClick(e) {
		ReloadHelper.reload(this.props.profileLink, e);
	}

	openMessengerFn(e) {
		const actorId = this.props.actorId;

		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(actorId, true);
		} else {
			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				});
			} else {
				VXPay.openSignupOrLogin();
			}
		}
		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (typeof this.props.onOpenMessengerClick === 'function') {
			this.props.onOpenMessengerClick();
		}

		return false;
	}

	render() {
		let responsiveMultiplier = 1;

		if (this.props.isMobile) {
			responsiveMultiplier = 1;
		}

		const outerClassName = 'context-box--media-box__picture ' + (this.props.useRatio ? ' box--fixed-ratio' : '');
		const innerClassName = this.props.useRatio ? 'box--fixed-ratio__content' : '';
		const pictureBoxes   = <PictureBoxes pictures={this.props.previewPictureUrls}
		                                     useIndexForKey={true}
		                                     outerClassName={outerClassName}
		                                     innerClassName={innerClassName}
		                                     responsiveMultiplier={responsiveMultiplier}
		                                     usePlaceholder={true}
											 lazyImageLoading={this.props.lazyImageLoading}
		                       />;

		const tileClassName = (!this.props.isMobile) ? 'col-xs-6 col-lg-3' : this.props.className;

		return (
			<div className={tileClassName}>
				<div className="context-box--media-box h-over-hidden clearfix">
					<div className="context-box--media-box__picture box--fixed-ratio -ratio4_3">
						<div className="box--fixed-ratio__content">
							<div>
								{pictureBoxes}
							</div>
						</div>

						{this.props.isDildocontrolAvailable && this.props.isOnline && <DildocontrolOverlay />}
					</div>
					<div className="actorcontact__container">
						<div className="actorcontact">
							<div>
								<div className="context-box--media-panel__actorchattile--text">
									{Translations.get('FavoriteAddedText')} {this.props.asFavoriteAdded}
								</div>
							</div>
							<a href={this.props.profileLink} onClick={this.onClick}>
								<button className="btn--icon-detailed -icon-user-full -size-sm -click-material -twobuttonsarow"
								        data-actor-name={this.props.actorName}
								>
									<span className="ink animate" />
									{Translations.get('ActorGridProfileText')}
									<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridLearnMoreText')}</span>
								</button>
							</a>
							{(this.props.isOnline && !this.props.showTextChatButtonOnly) && <VideochatButton
								multiChatPrice={this.props.multiChatPrice}
								actorId={this.props.actorId}
								isActorOnline={this.props.isOnline}
								isVoyeurMode={false}
								buttonText={Translations.get('ActorGridVideoText')}
								additionalClassNames={'-click-material -size-sm -twobuttonsarow'}
							                                                                />}
							{(!this.props.isOnline || this.props.showTextChatButtonOnly) && <button onClick={this.openMessengerFn}
							                                                                        className="btn--icon-detailed -btn-color- -icon-chat-text-full -click-material -size-sm -twobuttonsarow"
							                                                                >
								<span className="ink animate" />
								{Translations.get('ActorGridMessageText')}
								<span className="btn--icon-detailed__teaser">
									{Translations.get('ActorGridWriteFreeText')}
								</span>
							</button>}
						</div>
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

FavoriteTile.propTypes = {
	actorId:                 PropTypes.number.isRequired,
	actorName:               PropTypes.string.isRequired,
	className:               PropTypes.string,
	multiChatPrice:          PropTypes.number.isRequired,
	asFavoriteAdded:         PropTypes.string.isRequired,
	profileLink:             PropTypes.string.isRequired,
	isOnline:                PropTypes.bool.isRequired,
	isDildocontrolAvailable: PropTypes.bool,
	showTextChatButtonOnly:  PropTypes.bool.isRequired,
	usePjax:                 PropTypes.bool,
	onOpenMessengerClick:    PropTypes.func,
	isMobile:                PropTypes.bool,
	useRatio:                PropTypes.bool,
	previewPictureUrls:      PropTypes.arrayOf(
		PropTypes.array
	),
	children:                PropTypes.node,
	lazyImageLoading:        PropTypes.bool,
};

FavoriteTile.defaultProps = {
	lazyImageLoading: false,
};

export default FavoriteTile;