import React                        from 'react';
import PropTypes                    from 'prop-types';
import VideoPreviewEndedSignupBox   from './VideoPreviewEndedSignupBox';
import VideoPreviewEndedPurchaseBox from './VideoPreviewEndedPurchaseBox';
import VideoPreviewEndedSaleBox     from './VideoPreviewEndedSaleBox';
import VideoPreviewEndedAvsBox      from './VideoPreviewEndedAvsBox';
import Flux                         from '../../flux/Flux';
import VideoPreviewEndedVipBox      from "./VideoPreviewEndedVipBox";
import {gaHelper, gtagHelper}       from "../../utils/CommonUtils";
import EnumGA4Events                from "../../utils/EnumGA4Events";

class ConversionBox extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			videoPlayerIncentive: null,
		};

		this.onIncentivesChange = this.onIncentivesChange.bind(this);
	}

	componentDidMount() {
		Flux.Conversion.addIncentivesChangeListener(this.onIncentivesChange);
	}

	componentWillUnmount() {
		Flux.Conversion.removeIncentivesChangeListener(this.onIncentivesChange);
	}

	onIncentivesChange(type, albumId) {
		if (type === Flux.Constants.ConversionEventTypes.VIDEO_PREVIEW_ENDED && albumId === this.props.albumId) {
			const videoPlayerIncentive = Flux.Conversion.getIncentiveByRegion(Flux.Constants.ConversionIncentiveRegions.VIDEO_PLAYER);

			this.setState({
				videoPlayerIncentive: videoPlayerIncentive,
			});

			// solve more generic if more incentives than video player incentives are introduced
			if (videoPlayerIncentive && videoPlayerIncentive.type) {
				gaHelper('send', 'event', 'Conversion', videoPlayerIncentive.type);
				gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
					[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_VIDEO_PLAYER_INCENTIVE,
					[EnumGA4Events.CUSTOM_PARAMETER_VIDEO_PLAYER_INCENTIVE__TYPE]: videoPlayerIncentive.type,
				});
			}
		}
	}

	render() {
		let conversionBox = null;

		if (this.state.videoPlayerIncentive) {
			switch (this.state.videoPlayerIncentive.type) {
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_AVS:
					conversionBox = <VideoPreviewEndedAvsBox
						videoPlayerIncentive={this.state.videoPlayerIncentive}
						{...this.props}
					                />;
					break;
				case  Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_SALE:
					conversionBox = <VideoPreviewEndedSaleBox
						videoPlayerIncentive={this.state.videoPlayerIncentive}
						{...this.props}
					                />;
					break;
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_PAYMENT:
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_BUY:
					conversionBox = <VideoPreviewEndedPurchaseBox
						videoPlayerIncentive={this.state.videoPlayerIncentive}
						{...this.props}
					                />;
					break;
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_LOGIN:
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_SIGNUP:
					conversionBox = <VideoPreviewEndedSignupBox
						videoPlayerIncentive={this.state.videoPlayerIncentive}
						{...this.props}
					                />;
					break;
				case Flux.Constants.ConversionIncentiveTypes.VIDEO_PREVIEW_VIP:
					conversionBox = <VideoPreviewEndedVipBox
						videoPlayerIncentive={this.state.videoPlayerIncentive}
						{...this.props}
					                />;
					break;
				default:
					break;
			}
		}

		return conversionBox;
	}
}


ConversionBox.propTypes    = {
	albumId:      PropTypes.number.isRequired,
	currentVideo: PropTypes.object.isRequired,
	className:    PropTypes.string,
};
ConversionBox.defaultProps = {
	className: '',
};

export default ConversionBox;
