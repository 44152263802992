import React                         from 'react';
import PropTypes                     from 'prop-types';
import Flux                          from '../../flux/Flux';
import Grid2                         from '../Grid2/Grid2';
import VideoTile                     from '../Grid2/Tiles/VideoTile';
import ActorTooFewItemsTile, {TYPES} from '../Grid2/Tiles/ActorTooFewItemsTile';

const COL_COUNT_XS                 = 2;
const COL_COUNT_LG                 = 4;
const MAX_ELEMENTS_SAME_ACTOR      = 4 * COL_COUNT_LG - 1;
const MAX_ELEMENTS_DIFFERENT_ACTOR = COL_COUNT_LG;

class VideoRecommendationsGrid extends React.PureComponent {
	/**
	 * @param {boolean} sameActor
	 * @return {number}
	 */
	static getCount(sameActor) {
		return sameActor ? MAX_ELEMENTS_SAME_ACTOR : MAX_ELEMENTS_DIFFERENT_ACTOR;
	}

	render() {
		const videosValid = Array.isArray(this.props.videos);
		this.props.renderCallback((videosValid ? this.props.videos : []).length);
		if (!videosValid) {
			return null;
		}

		const isGuestLoggedIn = Flux.Guest.isLoggedIn();

		const tileData    = this.props.videos.slice(0, typeof this.props.displayCount === 'number' ? this.props.displayCount :
			(this.props.sameActor ? MAX_ELEMENTS_SAME_ACTOR : MAX_ELEMENTS_DIFFERENT_ACTOR));
		const tileClasses = [];
		const tileSizes   = [];
		const tileConfigs = [];

		if (this.props.sameActor && this.props.displayTooFewItemsTile) {
			const videosCount = tileData.length;

			tileData.push({
				actorId:   this.props.actorId,
				actorName: this.props.actorName,
			});
			tileClasses.push({index: videosCount, tileClass: ActorTooFewItemsTile});
			tileSizes.push({
				index: videosCount,
				size:  {xs: COL_COUNT_XS - (videosCount % COL_COUNT_XS), lg: COL_COUNT_LG - (videosCount % COL_COUNT_LG)},
			});
			tileConfigs.push({index: videosCount, config: {type: videosCount < MAX_ELEMENTS_SAME_ACTOR ? TYPES.DEFAULT : TYPES.ALL_VIDEOS}});
		}

		let columns = {xs: 3, sm: 3, md: 3, lg: 3};

		if (this.props.isSimilarVideos && this.props.displayCount === null) {
			columns = {xs: 2, sm: 2, md: 4, lg: 1};
		} else if (this.props.displayCount % 4 === 0) {
			columns = {xs: 2, sm: 2, md: 4, lg: 4};
		}

		const defaultTileConfig = {
			badges: {
				textBadges: {
					show: {
						price: isGuestLoggedIn,
					},
				},
			},
			footer: {
				show: {
					likes: this.props.sameActor,
				},
			},
		};

		return (
			<div>
				<Grid2 tileData={tileData}
				       defaultTileClass={VideoTile}
				       tileClasses={tileClasses}
				       columns={columns}
				       tileConfigs={tileConfigs}
				       defaultTileConfig={defaultTileConfig}
				       tileSizes={tileSizes}
				       slider={this.props.slider}
					   lazyImageLoading={this.props.lazyImageLoading}
					   headlineType={Flux.Constants.Headlines.h3}
				/>
			</div>
		);
	}

}

VideoRecommendationsGrid.propTypes = {
	id:                                       PropTypes.number, // e.g. actorId, albumId
	actorId:                                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	actorName:                                PropTypes.string.isRequired,
	sameActor:                                PropTypes.bool.isRequired,
	slider:                                   PropTypes.bool,
	fetchData:                                PropTypes.bool,
	displayTooFewItemsTile:                   PropTypes.bool,
	displayCount:                             PropTypes.number,
	getVideoRecommendationsById:              PropTypes.func,
	loadVideoRecommendationsById:             PropTypes.func,
	addVideoRecommendationsChangeListener:    PropTypes.func,
	removeVideoRecommendationsChangeListener: PropTypes.func,
	videos:                                   PropTypes.array,
	renderCallback:                           PropTypes.func,
	isSimilarVideos:                          PropTypes.bool,
	lazyImageLoading:                         PropTypes.bool,
};

VideoRecommendationsGrid.defaultProps = {
	displayTooFewItemsTile: true,
	isSimilarVideos:        false,
	renderCallback:         () => {
	},
	lazyImageLoading:       false,
};

export default VideoRecommendationsGrid;