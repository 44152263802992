/* global VXConfig */
import React                       from 'react';
import Modal                       from '../Modal';
import PropTypes                   from 'prop-types';
import Flux                        from "../../flux/Flux";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import ReloadHelper                from "../../utils/ReloadHelper";
import STYLES                      from "../SimpleElements/VXButton/EnumVXButton";
import { VideoPlayer  }            from '../Gallery/VideoPlayer';
import { getCommonTranslation }    from "../../utils/TranslationHelper";

class ModalBoxVideoWithPlayer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: VXConfig.isVXMobilePlatform,
            video:    null,
        };

        this.onClickLink = this.onClickLink.bind(this);
    }

    componentDidMount() {
        if (Flux.Guest.isLoggedIn()) {
            this.loadVideos();
        }
    }

	loadVideos(){
		const filter = {ids: [this.props.id]};
		Flux.Vxql.getVideosFromVXQL(filter).then(({data}) => {
			const videos = data.videos.items;
            const video  = {
                    previewPictureUrls: [{pictureUrl: videos[0].preview.images[0].url}],
                    videoUrls:          [{src: videos[0].url}],
                    title:              videos[0].title,
                    actorName:          videos[0].model.name,
                    linkVX:             videos[0].linkVX,
                };
			this.setState({video});	
		});
	}
  
    onClickLink() {
        this.props.onClose();
        ReloadHelper.reload(this.state.video.linkVX);
    }

    render() {
        if (!Flux.Guest.isLoggedIn() || !this.state.video) {
            return null;
        }

        return (
            <Modal className="modal--video" modalClassName="modal--video__wrapper" onClose={this.removeParam}>
                <div className="modal--video__name-wrapper">
                    <div className="modal--video__name">{getCommonTranslation('free_video_from')} {this.state.video.actorName}</div>
                    <div className="modal--video__title">"{this.state.video.title}"</div>
                </div>
                <div className="modal--video__video">
                    {this.state.video && <VideoPlayer initialShowVideo={true} initialVideos={[this.state.video]} />}
                </div>
                <div className="modal--video__buttonn-wrapper">
                    <VXButton color={COLOR_TYPE_CALL_TO_ACTION}
                                line1={[new VXButtonLineConfig(getCommonTranslation('to_the_video'))]}
                                onClick={this.onClickLink}
                                size={STYLES.SIZE.SMALL}
                    />
                    <div onClick={this.props.onClose} className="modal--video__link">{getCommonTranslation('close_and_see_later')}</div>
                </div>
            </Modal>
        );
    }
}

ModalBoxVideoWithPlayer.propTypes = {
    id:      PropTypes.number.isRequired,
    onClose: PropTypes.func,
};


export default ModalBoxVideoWithPlayer;