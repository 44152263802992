import React                    from 'react';
import PropTypes                from 'prop-types';
import Flux                     from '../../../flux/Flux';
import VideoRecommendationsGrid from '../../VideoRecommendations/VideoRecommendationsGrid';

function withVideoRecommendationsByActorIdOrAlbumId(T, byActorOrByAlbumId) {

	const vtable = {
		getId:          props => byActorOrByAlbumId ? props.actorId : props.albumId,
		addListener:    byActorOrByAlbumId ? Flux.Actor.addVideoRecommendationsByActorIdChangeListener : Flux.Actor.addVideoRecommendationsByAlbumIdChangeListener,
		load:           byActorOrByAlbumId ? Flux.Actor.loadVideoRecommendationsByActorId : Flux.Actor.loadVideoRecommendationsByAlbumId,
		removeListener: byActorOrByAlbumId ? Flux.Actor.removeVideoRecommendationsByActorIdChangeListener : Flux.Actor.removeVideoRecommendationsByAlbumIdChangeListener,
		get:            byActorOrByAlbumId ? Flux.Actor.getVideoRecommendationsByActorId : Flux.Actor.getVideoRecommendationsByAlbumId,
	};

	class VideoRecommendationsByActorIdOrAlbumId extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				videos:                      null,
				recommendationsLoadingState: true,
			};

			this.onVideoRecommendationsChange = this.onVideoRecommendationsChange.bind(this);
			this.getRecommendations           = this.getRecommendations.bind(this);
		}

		componentDidMount() {
			vtable.addListener(this.onVideoRecommendationsChange);

			const recommendations = this.getRecommendations();
			if (!recommendations) {
				const {sameActor} = this.props;
				const count       = VideoRecommendationsGrid.getCount(sameActor);
				vtable.load(vtable.getId(this.props), sameActor ? count : 0, sameActor ? 0 : count);
			} else {
				this.setState(() => ({videos: recommendations, recommendationsLoadingState: false}));
			}
		}

		componentWillUnmount() {
			vtable.removeListener(this.onVideoRecommendationsChange);
		}

		/**
		 * @return {?Array}
		 */
		getRecommendations() {
			const videos = vtable.get(vtable.getId(this.props), this.props.sameActor);
			typeof this.props.videosCallback === 'function' && this.props.videosCallback(videos ? videos.length : null);
			return videos;
		}

		/**
		 * @param {number} id
		 */
		onVideoRecommendationsChange(id) {
			if (id === vtable.getId(this.props)) {
				const videos                    = this.getRecommendations();
				let recommendationsLoadingState = false;
				if (videos === null && this.state.videos === null) {
					// addVideoRecommendationsByAlbum{Actor}IdChangeListener doesn't honor whether it is same or different actors were requested
					recommendationsLoadingState = this.state.recommendationsLoadingState;
				}
				this.setState(() => ({videos: videos, recommendationsLoadingState: recommendationsLoadingState}));
			}
		}

		render() {
			const displayCount = this.props.displayCount ? this.props.displayCount : 4;
			return <T {...this.state} {...this.props} displayCount={displayCount} />;
		}
	}

	VideoRecommendationsByActorIdOrAlbumId.propTypes    = {
		sameActor:      PropTypes.bool,
		albumId:        PropTypes.number,
		actorId:        PropTypes.number.isRequired,
		displayCount:   PropTypes.number,
		videosCallback: PropTypes.func,
	};
	VideoRecommendationsByActorIdOrAlbumId.defaultProps = {
		sameActor: true,
	};

	return VideoRecommendationsByActorIdOrAlbumId;
}

export default withVideoRecommendationsByActorIdOrAlbumId;
