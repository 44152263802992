import React     from 'react';
import PropTypes from 'prop-types';

class Duration extends React.PureComponent {

	constructor(props) {
		super(props);

		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		this.onSeeking    = this.onSeeking.bind(this);
	}

	componentDidMount() {
		this.props.video.addEventListener('timeupdate', this.onTimeUpdate);
		this.props.video.addEventListener('seeking', this.onSeeking);
	}

	componentWillUnmount() {
		this.props.video.removeEventListener('timeupdate', this.onTimeUpdate);
		this.props.video.removeEventListener('seeking', this.onSeeking);
	}

	onTimeUpdate() {
		this.forceUpdate();
	}

	onSeeking() {
		this.forceUpdate();
	}

	format(rawSeconds) {
		let minutes = Math.floor(rawSeconds / 60);
		let seconds = Math.floor(rawSeconds % 60);

		if (minutes < 10) {
			minutes = '0' + minutes;
		}

		if (seconds < 10) {
			seconds = '0' + seconds;
		}

		return minutes + ':' + seconds;
	}

	render() {
		return <div className={'vx_shaka--ui-controls -label-sized'}>
			{this.format(this.props.video.currentTime)}&nbsp;/&nbsp;{this.format(this.props.video.duration)}
		</div>;
	}

}

Duration.propTypes = {
	video: PropTypes.instanceOf(HTMLVideoElement).isRequired,
};

export default Duration;
