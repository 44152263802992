import React      from 'react';
import IconButton from './IconButton';
import PropTypes  from 'prop-types';

const PortraitZoom = (props) => {
	const {	onClick, checked } = props;

	return <IconButton icon={'-icon-fullscreen-height-player-open-line'} checkedIcon={'-icon-fullscreen-height-player-close-line'} onClick={onClick} checked={checked} />;
};

PortraitZoom.propTypes = {
	onClick: PropTypes.func,
	checked: PropTypes.bool,
};

export default PortraitZoom;
