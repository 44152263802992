import React          from "react";
import PropTypes      from "prop-types";
import BasicTextInput from "../Text/BasicTextInput";
import Translations   from "../../utils/Translations";

function dummyFunction() {
}

class FeatureFeedbackPopupTextArea extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange() {
		if(this.input){
			this.props.onChange(this.input.getPlainText());
		}
	}

	render() {
		return (
			<BasicTextInput value={this.props.text}
			                onChange={this.onChange}
			                onSubmit={dummyFunction}
			                maxLength={this.props.maxLength}
			                displayUsedChars={true}
			                textInputPlaceholder={Translations.get('FeatureFeedbackInputPlaceholder')}
			                rows={4}
			                rowsAutoGrowMax={10}
			                className="feature-feedback__popup__textarea"
			                editorContainerClassName="feature-feedback__popup__textarea-div"
			                usedCharsClassName="feature-feedback__popup__textarea-used-chars"
			                ref={ref => this.input = ref}
			/>
		);
	}
}

FeatureFeedbackPopupTextArea.propTypes = {
	text:      PropTypes.string,
	maxLength: PropTypes.number,
	onChange:  PropTypes.func,
};

export default FeatureFeedbackPopupTextArea;