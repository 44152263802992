import React            from 'react';
import PropTypes        from 'prop-types';
import Flux             from '../../flux/Flux';
import {formatCurrency} from '../../utils/CommonUtils';
import GuestAccess      from '../../utils/GuestAccess';
import Translations     from '../../utils/Translations';
import ReloadHelper     from '../../utils/ReloadHelper';

class ActorTextPlate extends React.Component {

	constructor(props) {
		super(props);

		this.openBuyDialog = this.openBuyDialog.bind(this);
		this.onViewClick   = this.onViewClick.bind(this);
	}

	openBuyDialog(e) {
		const actorId    = this.props.actorId;
		const albumId    = this.props.albumId;
		const albumPrice = this.props.albumPrice;
		const needAvs    = this.props.needAvs;
		const targetUrl  = this.props.targetUrl;

		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}

		GuestAccess.onEnoughMoneyForShop(
			actorId,
			albumPrice,
			needAvs,
			false,
			function() {
				const clb = function(actorId, changedAlbumId) {
					if (changedAlbumId === albumId) {
						Flux.Gallery.removeGalleryChangeListener(clb);
						window.location.href = targetUrl;
					}
				};

				Flux.Gallery.addGalleryChangeListener(clb);
				Flux.Gallery.buyAlbum(albumId);
			},
			targetUrl
		);
	}

	onViewClick(e) {
		ReloadHelper.reloadWithPjax(this.props.targetUrl, e);
	}

	render() {

		const headline = <h3 className="content-box--text-box__title">{this.props.title}</h3>;

		let content      = <span dangerouslySetInnerHTML={{__html: this.props.sublineText}} />;
		let viewButton   = null,
		    buyButton    = null;
		let contentClass = 'context-box--text-box__cell -eye-catcher';
		let style        = {};

		if (this.props.albumType === Flux.Constants.MediaTypes.VIDEO || this.props.albumType === Flux.Constants.MediaTypes.PHOTO_ALBUM) {
			const buttonLabelView = (this.props.albumPrice > 0) ? Translations.get('ActorProfileAlbumPreview') : Translations.get('View');
			viewButton            =
				<a href={this.props.targetUrl} onClick={this.onViewClick}>
					<button className="btn content-box--media-box__btn-buy">
						<span className="h-text-bold">{buttonLabelView}</span>
					</button>
				</a>;

			if (this.props.albumType === Flux.Constants.MediaTypes.VIDEO && this.props.albumPrice > 0 && this.props.needBuying) {
				const buttonLabelBuy = ((this.props.albumType === Flux.Constants.MediaTypes.VIDEO) ? Translations.get('BuyVideo') : Translations.get('BuyAlbum')) + ' ' + formatCurrency(this.props.albumPrice);
				buyButton            =
					<button className="btn -btn-color-special content-box--media-box__btn-buy"
					        onClick={this.openBuyDialog}
					>
						<span className="h-text-bold">{buttonLabelBuy}</span>
					</button>;
			}

			contentClass = 'context-box--media-box__plate -eye-catcher clearfix';

			style = {transform: 'translateY(-50%)'};

			content = <div className="context-box--media-box__plate--title">{this.props.sublineText}</div>;

		}

		return (
			<div className="h-disp-table-row h-text-align-center h-vertical-align-middle">
				<div className={contentClass} style={style}>
					{headline}
					{content}
					<div className="context-box--media-box__plate--buttonline">
						{viewButton} {buyButton}
					</div>
				</div>
			</div>
		);
	}
}

ActorTextPlate.propTypes = {
	title:       PropTypes.string.isRequired,
	sublineText: PropTypes.string.isRequired,
	albumType:   PropTypes.string,
	albumId:     PropTypes.number,
	actorId:     PropTypes.number,
	albumPrice:  PropTypes.number,
	needAvs:     PropTypes.bool,
	needBuying:  PropTypes.bool,
	targetUrl:   PropTypes.string,
};

ActorTextPlate.defaultProps = {
	title:       '',
	sublineText: '',
	albumType:   '',
	albumId:     0,
	actorId:     0,
	albumPrice:  0.00,
	targetUrl:   '',
};

export default ActorTextPlate;

