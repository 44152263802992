import React           from 'react';
import PropTypes       from 'prop-types';
import Translations    from '../../../../utils/Translations';
import VideochatButton from '../../../SimpleElements/VideochatButton';

class VideochatButtonWrapper extends React.Component {
	render() {
		if (!this.props.isOnline && !this.props.isOnlineMobileVideocall) {
			return null;
		}

		return (
			<VideochatButton
				multiChatPrice={this.props.multiChatPrice}
				actorId={this.props.actorId}
				isActorOnline={this.props.isOnline}
				isVoyeurMode={false}
				buttonSubline={Translations.get('ActorGridVideoSubline')}
				buttonText={Translations.get('ActorGridVideoText')}
				additionalClassNames="-click-material -size-sm h-text-align-center"
				isTextWrapped={true}
				isOnlineMobileVideocall={this.props.isOnlineMobileVideocall}
				isOnlineMobileVideocallUpper={this.props.isOnlineMobileVideocallUpper}
			/>
		);
	}
}

VideochatButtonWrapper.propTypes = {
	isOnline:       PropTypes.bool,
	multiChatPrice: PropTypes.number,
	actorId:        PropTypes.number,
	isOnlineMobileVideocall: PropTypes.bool,
	isOnlineMobileVideocallUpper: PropTypes.bool,
};

export default VideochatButtonWrapper;
