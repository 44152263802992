/* globals VXConfig */

import React                                   from 'react';
import PropTypes                               from 'prop-types';
import Modal                                   from '../Modal';
import {VXPay}                                 from '../../utils/VXPay';
import VXButton                                from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION}             from "../SimpleElements/VXButton/ButtonHelper";
import Flux                                    from "../../flux/Flux";
import {VXButtonLineConfig, VXButtonLineStyle} from "../SimpleElements/VXButton/VXButtonConfig";
import STYLES                                  from "../SimpleElements/VXButton/EnumVXButton";

class ModalBoxVoucherOffer extends React.PureComponent {

	constructor(props) {
		super(props);

		this.isMobile    = VXConfig.isVXMobilePlatform;
		this.forceMobile = false;

		this.state = {isLandscape: this.isMobile && Flux.Browser.isLandscape()};

		this.onWindowResize();

		this.onBuyClick     = this.onBuyClick.bind(this);
		this.getRef         = this.getRef.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addWindowResizeListener(this.onWindowResize);
	}

	componentWillUnmount() {
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
	}

	onWindowResize() {
		const windowSize = Flux.Browser.getWindowSize();

		if (!this.isMobile && windowSize && windowSize.width < 690) {
			this.isMobile    = true;
			this.forceMobile = true;
			this.forceUpdate();
		} else if (this.forceMobile) {
			this.isMobile    = false;
			this.forceMobile = false;
			this.forceUpdate();
		} else {
			this.setState({isLandscape: this.isMobile && Flux.Browser.isLandscape()});
		}

	}

	onBuyClick(e) {
		// stop event propagation for preventing react issue with Modal click listener
		e.stopPropagation();

		VXPay.openPaytour(this.props.vxpayOptions);

		VXPay.onSuccess(this.props.vxpayOnSuccess);

		this.refModal.removeModal();
	}

	getRef(ref) {
		if (ref) {
			this.refModal = ref;
		}
	}

	render() {
		const classSuffix = this.state.isLandscape && this.isMobile ? ' -is-landscape' : (this.isMobile ? ' -is-mobile' : '');

		return <Modal containerWidth={this.isMobile && !this.state.isLandscape ? 450 : 900}
		              dynamicSize={true}
		              className={"voucher-box-container -theme-dialog -no-padding -relative" + classSuffix + ' ' + this.props.imgSubclass}
		              ref={this.getRef}
		              onClose={this.props.onClose}
		>
			<div className={"voucher-box__body" + classSuffix}>
				{this.isMobile ? null : <div className={"voucher-box__body-styledummy"} />}
				<div className={"voucher-box__header" + classSuffix} dangerouslySetInnerHTML={{__html: this.props.headerText}} />
				<div className={"voucher-box__headline" + classSuffix}>{this.props.subheaderText}</div>
				<div className={"voucher-box__row" + classSuffix}>
					<img src={this.props.icon1} />
					<span className={"voucher-box__row-textwrapper" + classSuffix}>
						<span>{this.props.line1}</span>
					</span>
				</div>
				<div className={"voucher-box__row" + classSuffix}>
					<img src={this.props.icon2} />
					<span className={"voucher-box__row-textwrapper" + classSuffix}>
						<span>{this.props.line2}</span>
					</span>
				</div>
				<div className={"voucher-box__row" + classSuffix}>
					<img src={this.props.icon3} />
					<span className={"voucher-box__row-textwrapper" + classSuffix}>
						<span>{this.props.line3}</span>
					</span>
				</div>

				<div className={"voucher-box__button" + classSuffix}>
					<VXButton onClick={this.onBuyClick}
					          line1={[new VXButtonLineConfig(this.props.btnTextLine1, new VXButtonLineStyle([STYLES.UPPERCASE]))]}
					          line2={[new VXButtonLineConfig(this.props.btnTextLine2)]}
					          color={COLOR_TYPE_CALL_TO_ACTION}
					          icon={"-icon-cart-full"}
					/>
				</div>
			</div>
			<div className={"voucher-box__footer" + classSuffix}>{this.props.footerText}</div>
		</Modal>;
	}
}

ModalBoxVoucherOffer.propTypes = {
	vxpayOptions:   PropTypes.object,
	vxpayOnSuccess: PropTypes.func,
	headerText:     PropTypes.string,
	subheaderText:  PropTypes.string,
	line1:          PropTypes.string,
	line2:          PropTypes.string,
	line3:          PropTypes.string,
	btnTextLine1:   PropTypes.string,
	btnTextLine2:   PropTypes.string,
	footerText:     PropTypes.string,
	icon1:          PropTypes.string,
	icon2:          PropTypes.string,
	icon3:          PropTypes.string,
	imgSubclass:    PropTypes.string,
	containerId:    PropTypes.string,
	onClose:        PropTypes.func,
};

export default ModalBoxVoucherOffer;