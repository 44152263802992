import React                from 'react';
import PropTypes            from 'prop-types';
import Footer               from './Footer';
import FeatureList          from './FeatureList';
import VideoSignupBox       from './VideoSignupBox';
import CloseButton          from '../Conversion/CloseButton';
import VideoPreview         from './VideoPreview';
import EnumAlbumButtonsType from '../ActorProfileVideos/AlbumTypeButtons/EnumAlbumButtonsType';
import Translations         from '../../utils/Translations';

const VideoPreviewEndedSignupBox = (props) =>
	<div className={'conversion-box__wrapper ' + (props.isMobile ? '-mobile' : '-pos-abs-center') + props.className}>
		<div className="conversion-box__content-container">
			<CloseButton {...props} />
			<div className="conversion-box__content-row row">
				{props.isMobile ? <div className="col-xs-5 conversion-box__content-left -feature-list">
						<div className="h-hide-in-portrait-mode">
							<VideoPreview previewImageUrl={props.videoPlayerIncentive.albumPreviewPicture.pictureUrl}>
								<FeatureList countPlatformActors={props.videoPlayerIncentive.countPlatformActors}
								             countPlatformPictures={props.videoPlayerIncentive.countPlatformPictures}
								             countPlatformVideos={props.videoPlayerIncentive.countPlatformVideos}
								/>
							</VideoPreview>
						</div>
						<div className="h-hide-in-landscape-mode">
							<Footer items={[Translations.get('FooterItem5'),
							                Translations.get('FooterItem6'),
							                Translations.get('FooterItem7'),
							                Translations.get('FooterItem8')]}
							        type={EnumAlbumButtonsType.login}
							        currentVideo={props.currentVideo}
							        videoPlayerIncentive={props.videoPlayerIncentive}
							/>
						</div>
					</div> :
					<div className="col-xs-5 conversion-box__content-left">
						<VideoPreview previewImageUrl={props.videoPlayerIncentive.albumPreviewPicture.pictureUrl}>
							<FeatureList countPlatformActors={props.videoPlayerIncentive.countPlatformActors}
							             countPlatformPictures={props.videoPlayerIncentive.countPlatformPictures}
							             countPlatformVideos={props.videoPlayerIncentive.countPlatformVideos}
							/>
						</VideoPreview>
					</div>}
				<div className="col-xs-7 conversion-box__content-right">
					<VideoSignupBox title={props.videoPlayerIncentive.albumTitle} />
				</div>
			</div>
		</div>
		{props.isMobile ? <div>
				<div className="h-hide-in-portrait-mode">
					<Footer type={EnumAlbumButtonsType.login}
					        currentVideo={props.currentVideo}
					        videoPlayerIncentive={props.videoPlayerIncentive}
					/>
				</div>
				<div className="h-hide-in-landscape-mode">
					<div className="conversion-box__footer">
						<div className="conversion-box__footer-content -full-width">
							<FeatureList countPlatformActors={props.videoPlayerIncentive.countPlatformActors}
							             countPlatformPictures={props.videoPlayerIncentive.countPlatformPictures}
							             countPlatformVideos={props.videoPlayerIncentive.countPlatformVideos}
							             inFooter={true}
							/>
						</div>
					</div>
				</div>
			</div> :
			<Footer type={EnumAlbumButtonsType.login}
			        currentVideo={props.currentVideo}
			        videoPlayerIncentive={props.videoPlayerIncentive}
			        items={[Translations.get('FooterItem10'),
			                Translations.get('TeaserFootnoteNoHiddenCosts'),
			                Translations.get('FooterItem11'),
			                Translations.get('FooterItem4')]}
			/>}
	</div>;

VideoPreviewEndedSignupBox.propTypes = {
	className:            PropTypes.string,
	isMobile:             PropTypes.bool,
	onCloseFn:            PropTypes.func,
	showCloseButton:      PropTypes.bool,
	videoPlayerIncentive: PropTypes.shape({
		albumTitle:            PropTypes.string.isRequired,
		countPlatformActors:   PropTypes.number.isRequired,
		countPlatformPictures: PropTypes.number.isRequired,
		countPlatformVideos:   PropTypes.number.isRequired,
		albumPreviewPicture:   PropTypes.shape({
			pictureUrl: PropTypes.string,
		}),
	}).isRequired,
	currentVideo:         PropTypes.object,
};

VideoPreviewEndedSignupBox.defaultProps = {
	className:       '',
	isMobile:        false,
	onCloseFn:       null,
	showCloseButton: false,
};

export default VideoPreviewEndedSignupBox;
