/* global VXConfig */

import React     from 'react';
import Flux      from "../../flux/Flux";
import PropTypes from 'prop-types';

class DildocontrolOverlay extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			parentWidth: 0,
		};

		this.onResize = this.onResize.bind(this);
	}

	componentDidMount() {
		this.parent = this.getContentBox();

		window.addEventListener('resize', this.onResize);
		this.onResize();

		// Ugly workaround that is needed on the dev server due to how LESS files are loaded and generated on the fly
		if (VXConfig.isVXMobilePlatform) {
			try {
				if (Flux.VXMobile.isDevServer()) {
					setTimeout(this.onResize, 100);
				}
			} catch (err) {
				// Ignore
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	getContentBox() {
		let element = this.ref;

		do {
			if (element.classList.contains('content-box') || element.classList.contains('context-box--media-box')) {
				return element;
			}

			element = element.parentElement || element.parentNode;
		} while (element !== null && element.nodeType === 1);

		return null;
	}

	onResize() {
		this.setState({
			parentWidth: this.parent ? this.parent.offsetWidth : 0,
		});
	}

	render() {
		let showLargeImage = this.props.isLarge;
		if (this.parent && this.parent.closest('.is-highlight-actor-tile')) {
			showLargeImage = this.state.parentWidth > 350;
		}

		const imgFileName = showLargeImage ? 'Lovesense-Badge-small.svg' : 'overlay-small.png';
		const imgSrc      = `/assets/img/dildocontrol/${imgFileName}`;
		let classModifier = '';

		if (showLargeImage) {
			classModifier += ' is-large';
		}
		if (VXConfig.isVXMobilePlatform) {
			classModifier += ' is-mobile';
		}

		return (
			<div className={`content-box__overlay-dildocontrol-outer ${classModifier}`} ref={ref => this.ref = ref}>
				<img className={`content-box__overlay-dildocontrol ${classModifier}`}
				     src={imgSrc}
				/>
			</div>
		);
	}
}

DildocontrolOverlay.propTypes = {
	isLarge: PropTypes.bool
};

DildocontrolOverlay.defaultProps = {
	isLarge: false
};

export default DildocontrolOverlay;