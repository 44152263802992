import React             from 'react';
import PropTypes         from 'prop-types';
import LivePreviewButton from '../../../SimpleElements/LivePreviewButton';

class LivePreviewWrapper extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<LivePreviewButton
				isActorOnline={true}
				actorId={this.props.actorId}
				useStandaloneIcon={false}
				isEnabled={true}
				additionalClassName=' -size-sm'
			/>
		);
	}
}

LivePreviewWrapper.propTypes = {
	show:    PropTypes.bool,
	actorId: PropTypes.number,
};

export default LivePreviewWrapper;
