import React     from 'react';
import PropTypes from 'prop-types';

export default class CountDownSpinner extends React.PureComponent {
	constructor(props) {
		super(props);

		const initialOffset = 2 * 3.14159 * props.radius;

		this.state = {
			initialOffset: initialOffset,
			offset:        initialOffset,
		};
	}

	componentDidMount() {
		let i = 0;

		this.interval = setInterval(() => {
			if (i === this.props.initialTime) {
				clearInterval(this.interval);

				if (typeof this.props.onEnded === 'function') {
					this.props.onEnded();
				}
				return;
			}
			this.setState({
				offset: this.state.initialOffset - ((i + 1) * (this.state.initialOffset / this.props.initialTime)),
			});
			i++;
		}, 1000);
	}

	componentWillUnmount() {
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	render() {
		const {radius, strokeWidth}   = this.props;
		const {initialOffset, offset} = this.state;
		const size                    = radius * 2 + 10;
		const center                  = Math.ceil(size / 2);

		return <div className="welcome-clip-rotator__countdown" style={{width: size + 'px'}}>
			<span className="welcome-clip-rotator__countdown__text"><i className="icon -icon-arrow-right-full -size-md" /></span>
			<svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
				<g>
					<circle r={radius} cy={center} cx={center} strokeWidth={strokeWidth} stroke="#909090" fill="none" />
					<circle className="welcome-clip-rotator__countdown__animation"
					        r={radius}
					        cy={center}
					        cx={center}
					        strokeWidth={strokeWidth}
					        stroke="#FFFFFF"
					        fill="none"
					        strokeDasharray={initialOffset}
					        strokeDashoffset={offset}
					/>
				</g>
			</svg>
		</div>;
	}
}

CountDownSpinner.propTypes = {
	initialTime: PropTypes.number,
	onEnded:     PropTypes.func,
	radius:      PropTypes.number,
	strokeWidth: PropTypes.number,
};

CountDownSpinner.defaultProps = {
	initialTime: 3,
	radius:      30,
	strokeWidth: 5,
};
