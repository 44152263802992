import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

function VideoPurchaseBox(props) {
	return (
		<div className="feature-teaser-box row">
			<div className="col-xs-12 feature-teaser-box__subheadline h-over-ellipsis"
			     title={props.title}
			     dangerouslySetInnerHTML={{__html: props.title}}
			/>
			<div className="col-xs-12 feature-teaser-box__line" />
			<div className="col-xs-12 feature-teaser-box__headline h-text-uppercase"
			     dangerouslySetInnerHTML={{__html: Translations.get('VideoPurchaseBoxHeadLine')}}
			/>
		</div>);
}

VideoPurchaseBox.propTypes = {
	albumId:           PropTypes.number.isRequired,
	actorId:           PropTypes.number.isRequired,
	title:             PropTypes.string.isRequired,
	price:             PropTypes.number.isRequired,
	albumVoucherPrice: PropTypes.number.isRequired,
	needAvs:           PropTypes.bool.isRequired,
	needVip:           PropTypes.bool.isRequired,
	onMobileSuccessFn: PropTypes.func,
};

export default VideoPurchaseBox;
