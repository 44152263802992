/**
 * @param {HTMLVideoElement} video
 */
function playPause(video) {
	if (!video) {
		return;
	}

	video.paused ? video.play() : video.pause();
}

/**
 * @param {HTMLVideoElement} video
 */
function seek15SecsBack(video) {
	if (!video) {
		return;
	}

	video.currentTime = Math.max(0, video.currentTime - 15);
}

/**
 * @param {HTMLVideoElement} video
 */
function seek15SecsForward(video) {
	if (!video) {
		return;
	}

	video.currentTime = Math.min(video.duration, video.currentTime + 15);
}

export {playPause, seek15SecsBack, seek15SecsForward};