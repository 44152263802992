/*global System VXConfig */
import React                                          from 'react';
import PropTypes                                      from 'prop-types';
import VXShakaUI                                      from './VXShakaUI';
import LogoLoader                                     from '../Loader/LogoLoader';
import {
	exitFullScreen,
	getFullScreenElement,
	requestFullscreen,
}                                                     from '../../utils/Fullscreen';
import Flux                                           from '../../flux/Flux';
import Translations                                   from "../../utils/Translations";
import {playPause, seek15SecsBack, seek15SecsForward} from "./Controls/Actions";
import muxjs                                          from "muxjs";
import { generateKey } from '../../utils/CommonUtils';

const RESOLUTIONS = [
//	{width: 160, height: 90},   // available but probably not reasonable to use
//	{width: 426, height: 240},  // available but probably not reasonable to use
	{referenceWidth: 400, width: 640, height: 360},
	{referenceWidth: 600, width: 854, height: 480},
	{referenceWidth: 1100, width: 1280, height: 720},
	{referenceWidth: 1920, width: 1920, height: 1080},
	{referenceWidth: 3840, width: 3840, height: 2160, mobileExcluded: true},
];

/**
 * @return {{width: number, height: number}}
 */
function getMaxResolution() {
	const windowSize  = Flux.Browser.getWindowSize();
	const windowWidth = windowSize.width * window.devicePixelRatio;

	const resolutions = RESOLUTIONS.filter(
		function(resolution) {
			return !resolution.mobileExcluded || !VXConfig.isVXMobilePlatform;
		}
	);

	for (let i = 0; i < resolutions.length; i++) {
		if (windowWidth <= resolutions[i].referenceWidth || i + 1 === resolutions.length) {
			return resolutions[i];
		}
	}
}

function getPlayerConfiguration(props) {
	const resolution = getMaxResolution();
	const streaming  = props.videoType === "application/x-mpegurl"
		? {
			bufferingGoal:   30,
			rebufferingGoal: 3,
			bufferBehind:    20,
		}
		: {};

	return {
		streaming,
		abr: {
			bandwidthDowngradeTarget: 0.75,
			bandwidthUpgradeTarget:   0.95,
			switchInterval:           5,
			restrictions:             {
				maxHeight: resolution.height,
				maxWidth:  resolution.width,
				minHeight: 360,
			},
		},
	};
}

class VXShaka extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			played:              this.props.autoplay,
			showUI:              this.props.initialShowUI,
			showLoader:          false,
			isMobile:            !!VXConfig.isVXMobilePlatform,
			isFullscreen:        false,
			isDesktopFullscreen: false,
			videoOffsetHeight:   0,
			videoOffsetWidth:    0,
			videoStyle:          {},
			isZoomed:            false,
			textTrackCues:       null,
			disableUI:           false,
			skipSrc:             false,
			showTranscodeError:  false,
		};

		this.uiTimeout = null;

		this.video              = null;
		this.player             = null;
		this.wrapper            = null;
		this.textTrack          = null;
		this.doubleClickTimeout = null;
		this.lastClick          = null;

		this.initPlayer = this.initPlayer.bind(this);

		this.onPlay   = this.onPlay.bind(this);
		this.onSeek   = this.onSeek.bind(this);
		this.onSeeked = this.onSeeked.bind(this);

		this.onClickPoster            = this.onClickPoster.bind(this);
		this.onMouseMove              = this.onMouseMove.bind(this);
		this.onDoubleClick            = this.onDoubleClick.bind(this);
		this.onKeyDown                = this.onKeyDown.bind(this);
		this.onFullscreenChanged      = this.onFullscreenChanged.bind(this);
		this.handleZoom               = this.handleZoom.bind(this);
		this.onWindowResize           = this.onWindowResize.bind(this);
		this.initTextTrack            = this.initTextTrack.bind(this);
		this.requestVXShakaFullscreen = this.requestVXShakaFullscreen.bind(this);
		this.exitVXShakaFullscreen    = this.exitVXShakaFullscreen.bind(this);
		this.toggleFullscreen         = this.toggleFullscreen.bind(this);
		this.hasAdditionalUIStyle     = this.hasAdditionalUIStyle.bind(this);
		this.showUI                   = this.showUI.bind(this);
	}

	componentDidMount() {
		if (!window.muxjs) {
			
				window.muxjs = muxjs;
				this.initPlayer();
	
		} else {
			this.initPlayer();
		}

		this.video.addEventListener('webkitbeginfullscreen', this.onFullscreenChanged);
		this.video.addEventListener('webkitendfullscreen', this.onFullscreenChanged);
		this.wrapper.addEventListener('webkitfullscreenchange', this.onFullscreenChanged);
		this.wrapper.addEventListener('mozfullscreenchange', this.onFullscreenChanged);
		this.wrapper.addEventListener('msfullscreenchange', this.onFullscreenChanged);
		this.wrapper.addEventListener('MSFullscreenChange', this.onFullscreenChanged); // IE11
		this.wrapper.addEventListener('fullscreenchange', this.onFullscreenChanged);

		Flux.Browser.addWindowResizeListener(this.onWindowResize);
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.isLandscape !== this.props.isLandscape) && this.state.isFullscreen) {
			this.handleZoom();
		} else {
			if (nextProps.isLandscape) {
				const height = (window.innerHeight / nextProps.videoWidth) * nextProps.videoHeight;
				this.setState({videoOffsetHeight: height, videoOffsetWidth: window.innerWidth});
			} else {
				const height = (window.innerWidth / nextProps.videoWidth) * nextProps.videoHeight;
				this.setState({videoOffsetHeight: height, videoOffsetWidth: window.innerHeight});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.src !== prevProps.src) {
			this.initPlayer(true);
		}
	}

	componentWillUnmount() {
		if (this.video) {
			this.video.removeEventListener('play', this.onPlay);
			this.video.removeEventListener('seeking', this.onSeek);
			this.video.removeEventListener('seeked', this.onSeeked);
			this.video.removeEventListener('webkitbeginfullscreen', this.onFullscreenChanged);
			this.video.removeEventListener('webkitendfullscreen', this.onFullscreenChanged);
		}

		if (this.wrapper) {
			this.wrapper.removeEventListener('webkitfullscreenchange', this.onFullscreenChanged);
			this.wrapper.removeEventListener('mozfullscreenchange', this.onFullscreenChanged);
			this.wrapper.removeEventListener('msfullscreenchange', this.onFullscreenChanged);
			this.wrapper.removeEventListener('MSFullscreenChange', this.onFullscreenChanged);
			this.wrapper.removeEventListener('fullscreenchange', this.onFullscreenChanged);
		}

		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
	}

	hasAdditionalUIStyle() {
		const additionalUIStyle = this.props.additionalUIStyle || {};
		if (Object.keys(additionalUIStyle).length === 0) {
			return false;
		}
		return true;
	}

	toggleFullscreen() {
		if (this.wrapper) {
			if (this.state.isFullscreen || this.state.isDesktopFullscreen) {
				exitFullScreen([this.wrapper, this.video]);
			} else {
				requestFullscreen(this.wrapper, this.video, this.state.isMobile);
			}
		}
	}

	requestVXShakaFullscreen() {
		if(this.wrapper && !(this.state.isFullscreen || this.state.isDesktopFullscreen)) {
				requestFullscreen(this.wrapper, this.video, this.state.isMobile);
			}
	}

	exitVXShakaFullscreen() {
		if(this.wrapper && (this.state.isFullscreen || this.state.isDesktopFullscreen)) {
				exitFullScreen([this.wrapper, this.video]);
			}
	}

	initPlayer(autoPlay = false) {
		if (!this.video) {
			return;
		}

		if (this.props.isLivestream && !this.props.src) {
			// src is still loading
			this.setState({showLoader: true});
			return;
		}

		this.video.disablePictureInPicture = true;

		const loadPlayer = (shaka) => {
			this.player = new shaka.Player();
			this.player.attach(this.video);
			this.player.configure(getPlayerConfiguration(this.props));

			if (this.props.src && !this.state.skipSrc) {
				this.player.load(this.props.src).then(() => {
					if (autoPlay) {
						this.video.play();
					}
				}).catch(() => {
					// handle invalid file format on orig
					this.setState({
						skipSrc: true,
					}, () => {
						this.initPlayer(autoPlay);
					});
				});
			} else if (this.props.origSrc) {
				this.player.load(this.props.origSrc).then(() => {
					if (autoPlay) {
						this.video.play();
					}
				});
			} else if (this.props.src) {
				this.setState({
					showTranscodeError: true,
				});
			}

			this.video.addEventListener('play', this.onPlay);
			this.video.addEventListener('seeking', this.onSeek);
			this.video.addEventListener('seeked', this.onSeeked);

			this.video.currentTime = this.props.videoTimestamp;
		};

		if (this.props.useHlsJs || (VXConfig.forceHlsJs && this.props.videoType && this.props.videoType.toLowerCase() === "application/x-mpegurl")) {
				const video = this.video;
				const src   = this.props.src;

				// destroy hlsPlayer if we have one already
				if (this.state.hlsPlayer) {
					this.state.hlsPlayer.destroy();
				}

				if (src) {
					import('hls.js').then((m) => {
						// necessary because System.import() behaves different than import()
						const Hls = m.default ? m.default : m;
						if (Hls.isSupported()) {
							const hls = new Hls();
							hls.loadSource(src);
							hls.attachMedia(video);
							hls.on(Hls.Events.MANIFEST_PARSED, function() {
								this.setState({showLoader: false});
								if (autoPlay) {
									video.play();
								}
							});

						this.setState({
							hlsPlayer: hls,
						});
					} else if (video.canPlayType("application/vnd.apple.mpegurl")) {
						video.src = src;
						video.addEventListener("loadedmetadata", function() {
							this.setState({showLoader: false});
							if (autoPlay) {
								video.play();
							}
						}.bind(this));
					}

					});
				}

				this.video.addEventListener('play', this.onPlay);
				this.video.addEventListener('seeking', this.onSeek);
				this.video.addEventListener('seeked', this.onSeeked);
		} else {
			import('shaka-player').then((shaka) => {
				shaka.polyfill.installAll();
				if (this.player) {
					// unload & destroy if player already exists
					this.player.unload().then(() => {
						this.player.destroy().then(() => {
							loadPlayer(shaka);
						});
					});
				} else {
					loadPlayer(shaka);
				}
			});
		}
	}

	onPlay() {
		if (this.props.isLivestream && this.state.played) {
			this.video.currentTime = this.video.duration;
		}
		this.setState({played: true, showLoader: false}, () => {
			if (this.state.isMobile) {
				this.onMouseMove();
			}
		});
	}

	onSeek() {
		this.setState({showLoader: true});
	}

	onSeeked() {
		this.setState({showLoader: false});
	}

	onClickPoster() {
		if (!this.props.src || !this.props.controlsVisible) {
			return;
		}

		if (typeof this.props.onDoubleClick === 'function') {
			const date        = new Date();
			const time        = date.getTime();
			const timeBetween = 200;
			
			if (time - this.lastClick < timeBetween) {
				clearTimeout(this.doubleClickTimeout);
				this.props.onDoubleClick();
				return;
			}

			this.lastClick = time;
		} 

		this.doubleClickTimeout = setTimeout(
			() => {				
				if (this.video.paused) {
					this.video.play();
				} else {
					this.video.pause();
				}
				this.video.focus();
			}, 200);
	}

	onMouseMove() {
		if (!this.props.src || !this.props.controlsVisible || !this.state.played) {
			return;
		}

		this.showUI();
	}

	showUI() {
		if (this.uiTimeout) {
			clearTimeout(this.uiTimeout);
			this.uiTimeout = null;
		}
		this.setState(
			{
				showUI: true,
			}, () => {
				this.uiTimeout = setTimeout(() => {
					this.setState({
						showUI: false,
					});
					this.uiTimeout = null;
				}, 2000);
			},
		);
	}

	onKeyDown(e) {
		e.preventDefault();
		e.stopPropagation();

		let validKeyCode = true;
		switch (e.keyCode) {
			case 37: // left
				seek15SecsBack(this.video);
				break;
			case 39: // right
				seek15SecsForward(this.video);
				break;
			case 32: // spacebar
				playPause(this.video);
				break;
			default:
				validKeyCode = false;
		}

		if (validKeyCode) {
			this.showUI();
		}
	}

	onDoubleClick(e) {
		e.preventDefault();
		e.stopPropagation();
		if (typeof this.props.onDoubleClick === 'function') {
			//this.props.onDoubleClick();
			return;
		} 
		this.toggleFullscreen();
		if (this.video) {
			this.video.play();
		}
	}

	onFullscreenChanged() {
		if (getFullScreenElement()) {
			if (typeof this.props.onFullScreenEnter === 'function') {
				this.props.onFullScreenEnter();
			}
			if (this.state.isMobile) {
				this.setState({isFullscreen: true, disableUI: true});
			} else {
				this.setState({isDesktopFullscreen: true});
			}
		} else {
			if (typeof this.props.onFullScreenExit === 'function') {
				this.props.onFullScreenExit();
			}
			if (this.state.isMobile) {
				this.setState({isFullscreen: false, videoStyle: {transform: `scale(${1})`}, isZoomed: false, disableUI: false});
			} else {
				this.setState({isDesktopFullscreen: false});
			}
		}
	}

	onWindowResize() {
		const isMobile = VXConfig.isVXMobilePlatform || false;
		if (this.state.isMobile !== isMobile) {
			this.setState({isMobile});
		}

		if (!isMobile) {
			const height = (window.innerHeight / this.props.videoWidth) * this.props.videoHeight;
			this.setState({videoOffsetHeight: height, videoOffsetWidth: window.innerWidth});
		}

		if (this.player) {
			const resolution      = getMaxResolution();
			const currentMaxWidth = this.player.getConfiguration().abr.restrictions.maxWidth;
			if (resolution.width !== currentMaxWidth) {
				this.player.configure('abr.restrictions.maxWidth', resolution.width);
				this.player.configure('abr.restrictions.maxHeight', resolution.height);
			}
		}
	}

	handleZoom() {
		if (this.state.isFullscreen) {
			if (this.state.isZoomed) {
				this.setState({videoStyle: {transform: `scale(${1})`}, isZoomed: false});
			} else {
				if (this.props.isLandscape) {
					this.setState({videoStyle: {transform: `scale(${window.innerWidth / this.state.videoOffsetWidth})`}, isZoomed: true});
				} else {
					this.setState({videoStyle: {transform: `scale(${window.innerHeight / this.state.videoOffsetHeight})`}, isZoomed: true});
				}
			}
		}
	}

	initTextTrack(ref) {
		this.textTrack = ref;
		if (this.video && ref) {
			ref.track.mode = 'showing';
			if (ref.track.cues && !this.state.textTrackCues) {
				this.setState({
					textTrackCues: ref.track.cues,
				});
			}
		}
	}

	render() {
		if (this.state.showTranscodeError) {
			const style = {};

			if (this.props.poster) {
				style.backgroundImage = `url(${this.props.poster})`;
			}

			return <div style={style} className={"vx_shaka--wrapper -error" + (this.state.isMobile ? ' -is-mobile' : '')}>
				<div className={"vx_shaka--error-overlay"}>
					<div className={"vx_shaka--error-headline"}>{Translations.get('video.transcode_error_headline')}</div>
					<div className={"vx_shaka--error-info"}>{Translations.get('video.transcode_error_info1')}</div>
					<div className={"vx_shaka--error-info"}>{Translations.get('video.transcode_error_info2')}</div>
				</div>
			</div>;
		}


		const loader = [];
		const poster = [];

		if ((!this.state.played && (this.props.src || this.props.origSrc)) || this.props.needLogin) {
			const src = this.props.src || this.props.origSrc;
			if (this.props.poster) {
				poster.push(
					<div className="vx_shaka--poster"
						 key={generateKey('poster', poster.length)}
					     style={{'backgroundImage': `url(${this.props.poster})`}}
					     onClick={this.onClickPoster}
					/>
				);
			}
			if (this.props.showBigPlayButton) {
				poster.push(
					<span key={generateKey('big-play-button', poster.length)} className={"icon -icon-play-rounded-line vx_shaka--poster-play" + (this.props.playButtonClass ? this.props.playButtonClass : '')} onClick={this.onClickPoster}>
						{this.props.showPreviewLabel && <span className='vx_shaka--poster-text'>{Translations.get('video.play-button__preview-text')}</span>}
					</span>);
			}
		}

		if (this.state.showLoader) {
			loader.push(
				<LogoLoader key={generateKey('logo-loader', loader.length)}/>,
				<div key={generateKey('vx_shaka--loader-overlay', loader.length)} className={'vx_shaka--loader-overlay'}/>,
			);
		}

		return (
			<div
				className={(this.state.isMobile ? "vx_shaka--wrapper--mobile " : "") + 'vx_shaka--wrapper' + (!this.state.showUI && this.state.played ? ' -ui-hidden' : '') + (this.state.isFullscreen && this.state.isMobile ? ' vx_shaka--wrapper--fullscreen' : '')}
				ref={ref => this.wrapper = ref}
				onMouseMove={this.onMouseMove}
				onTouchMove={this.onMouseMove}
				onMouseDown={this.onMouseMove}
				onKeyDown={this.onKeyDown}
				onClick={this.props.onVideoClick}
			>
				<video
					className="vx_shaka--player"
					ref={ref => {
						if (ref) {
							this.video = ref;
							this.props.storeVideoRef ? this.props.storeVideoRef(ref) : null;
						}
					}}
					tabIndex={-1}
					playsInline={true}
					poster={this.props.poster}
					onClick={this.onClickPoster}
					onPlay={this.props.onPlay}
					onPause={this.props.onPause}
					onPlaying={this.props.onPlaying}
					onTimeUpdate={this.props.onTimeUpdate}
					onLoadedMetadata={this.props.onLoadedMetadata}
					onEnded={this.props.onEnded}
					onError={this.props.onError}
					muted={(this.props.autoplay || this.props.muted) && !this.props.forceUnmute}
					autoPlay={this.props.autoplay}
					style={this.state.videoStyle}
					crossOrigin="true"
					controls={this.state.disableUI}
					onDoubleClick={this.onDoubleClick}
					loop={this.props.loop}
				>
					{this.props.webVTT && <track kind="metadata"
					                             src={this.props.webVTT.webVTT}
					                             default
					                             ref={ref => this.initTextTrack(ref)}
					/>}
				</video>
				{this.props.previewImg}
				{poster}
				<div className={'vx_shaka--player-bg ' + (this.state.showUI ? 'vx_shaka--player-bg-shadow' : '')}/>
				{this.props.recommendationsLayer}
				{!this.state.disableUI &&
					<div className={'vx_shaka--ui__wrapper ' + (this.state.showUI ? '' : ' -hide') + (this.state.isFullscreen ? ' -fullscreen' : '')}
					     style={this.hasAdditionalUIStyle() && !this.state.isDesktopFullscreen ? this.props.additionalUIStyle : {}}
					>
						<div style={{width: '100%', height: '100%'}}>
							{this.video && !isNaN(this.video.duration) &&
								<VXShakaUI
									video={this.video}
									wrapper={this.wrapper}
									visible={this.state.showUI && this.props.controlsVisible && this.state.played}
									isMobile={this.state.isMobile}
									videoOffsetHeight={this.state.videoOffsetHeight}
									zoomFunc={this.handleZoom}
									isZoomed={this.state.isZoomed}
									textTrackCues={this.state.textTrackCues}
									webVTTImages={this.props.webVTT ? this.props.webVTT.images : null}
									{...this.props}
								/>}
						</div>
						{!this.state.isMobile && this.props.ctaButton}
					</div>}
				{loader}
			</div>
		);
	}

}


VXShaka.propTypes = {
	poster:               PropTypes.string,
	src:                  PropTypes.string, // src or origSrc have to be present
	origSrc:              PropTypes.string,
	videoType:            PropTypes.string,
	downloadUrl:          PropTypes.string,
	webVTT:               PropTypes.shape({
		webVTT: PropTypes.string,
		images: PropTypes.array,
	}),
	showSeek15Secs:       PropTypes.bool,
	showDownload:         PropTypes.bool,
	showCinematicMode:    PropTypes.bool,
	showPreviewLabel:     PropTypes.bool,
	showHdLabel:          PropTypes.bool,
	showFullscreen:       PropTypes.bool,
	videoTimestamp:       PropTypes.number,
	onVideoClick:         PropTypes.func,
	onPlay:               PropTypes.func,
	onPause:              PropTypes.func,
	onPlaying:            PropTypes.func,
	onTimeUpdate:         PropTypes.func,
	onLoadedMetadata:     PropTypes.func,
	onEnded:              PropTypes.func,
	onError:              PropTypes.func,
	storeVideoRef:        PropTypes.func,
	showPin:              PropTypes.bool,
	showLike:             PropTypes.bool,
	showDislike:          PropTypes.bool,
	actorId:              PropTypes.number,
	albumId:              PropTypes.number,
	albumIsFavorite:      PropTypes.bool,
	isLiked:              PropTypes.bool,
	isDisliked:           PropTypes.bool,
	recommendationsLayer: PropTypes.node,
	contestVoteComponent: PropTypes.node,
	onVideoDownload:      PropTypes.func,
	onFullScreenEnter:    PropTypes.func,
	onFullScreenExit:     PropTypes.func,
	muted:                PropTypes.bool,
	controlsVisible:      PropTypes.bool,
	autoplay:             PropTypes.bool,
	ctaButton:            PropTypes.node,
	previewImg:           PropTypes.node,
	initialShowUI:        PropTypes.bool,
	isLandscape:          PropTypes.bool,
	videoWidth:           PropTypes.number,
	videoHeight:          PropTypes.number,
	showBigPlayButton:    PropTypes.bool,
	additionalUIStyle:    PropTypes.object,
	playButtonClass:      PropTypes.string,
	useHlsJs:             PropTypes.bool,
	isLivestream:         PropTypes.bool,
	needLogin:            PropTypes.bool,
	onFullscreenClick:    PropTypes.func,
	onDoubleClick:        PropTypes.func,
	loop:                 PropTypes.bool,
	forceUnmute:          PropTypes.bool,
};

VXShaka.defaultProps = {
	downloadUrl:       null,
	origSrc:           null,
	poster:            null,
	showSeek15Secs:    false,
	showDownload:      false,
	showCinematicMode: false,
	showPreviewLabel:  false,
	videoTimestamp:    0,
	showPin:           false,
	showLike:          false,
	showDislike:       false,
	showFullscreen:    true,
	controlsVisible:   true,
	autoplay:          false,
	initialShowUI:     false,
	showBigPlayButton: true,
	additionalUIStyle: {},
	useHlsJs:          false,
	isLivestream:      false,
	needLogin:         false,
	onFullscreenClick: null,
	onDoubleClick:     null,
	loop:              false,
	forceUnmute:       false,
};

export default VXShaka;
