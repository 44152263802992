class EnumAnalyticsEvents {
}

// activate Google Optimize changes for variant (user event based)
EnumAnalyticsEvents.DL_OPTIMIZE_ACTIVATE = 'optimize.activate';

EnumAnalyticsEvents.CATEGORY_WPM        = 'web-push';
EnumAnalyticsEvents.CATEGORY_365        = 'Conversion365';
EnumAnalyticsEvents.CATEGORY_CONVERSION = 'Conversion';

// when subscribe bar was shown to the user
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_BAR_SHOWN = 'subscribe-bar';

// when subscribe modal dialog was shown to the user
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_DIALOG_SHOWN = 'subscribe-dialog';

// when user accepted our UI
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_UI_ACCEPTED = 'subscribe-ui-accepted';

// when user declined our UI
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_UI_DECLINED = 'subscribe-ui-declined';

// when user closed our UI
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_UI_CLOSED = 'subscribe-ui-closed';

// when user accepted browser UI (notification permissions request)
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_BROWSER_ACCEPTED = 'subscribe-browser-accepted';

// when user declined browser UI (notification permissions request)
EnumAnalyticsEvents.GA_WPM_SUBSCRIBE_BROWSER_DECLINED = 'subscribe-browser-declined';

// final success event - user subscribed
EnumAnalyticsEvents.GA_WPM_SUBSCRIBED = 'subscribed';

EnumAnalyticsEvents.ACTION_365_TOP_UP    = 'TOP_UP';
EnumAnalyticsEvents.ACTION_365_VOUCHER   = 'VIDEO_VOUCHER';
EnumAnalyticsEvents.ACTION_365_BUY_ALBUM = 'BUY_ALBUM';
EnumAnalyticsEvents.ACTION_365_DOWNLOAD  = 'DOWNLOAD';
EnumAnalyticsEvents.ACTION_365_FORWARD   = 'FORWARD';

EnumAnalyticsEvents.LABEL_VIP_OPEN  = 'VIP_OPEN';
EnumAnalyticsEvents.LABEL_OPEN      = 'OPEN';
EnumAnalyticsEvents.LABEL_PURCHASED = 'PURCHASED';
EnumAnalyticsEvents.LABEL_SALE      = 'SALE';
EnumAnalyticsEvents.LABEL_DOWNLOAD  = 'DOWNLOAD';
EnumAnalyticsEvents.LABEL_MERCH     = 'MERCH';
EnumAnalyticsEvents.LABEL_BOUGHT    = 'BOUGHT';
EnumAnalyticsEvents.MESSENGER_GIFT  = 'MESSENGER_GIFT';
EnumAnalyticsEvents.BECOME_VIP      = 'BECOME_VIP';

// Conversion events
EnumAnalyticsEvents.OPEN_VIP_CLICK         = 'OPEN_VIP_CLICK';
EnumAnalyticsEvents.OPEN_SIGNUP_CLICK      = 'OPEN_SIGNUP_CLICK';
EnumAnalyticsEvents.OPEN_AVS_CLICK         = 'OPEN_AVS_CLICK';
EnumAnalyticsEvents.PURCHASE_VIDEO_CLICK   = 'PURCHASE_VIDEO_CLICK';
EnumAnalyticsEvents.PURCHASE_VIDEO_SUCCESS = 'PURCHASE_VIDEO_SUCCESS';
EnumAnalyticsEvents.OPEN_SALE_CLICK        = 'OPEN_SALE_CLICK';
EnumAnalyticsEvents.SALE_SUCCESS           = 'SALE_SUCCESS';

export default EnumAnalyticsEvents;
