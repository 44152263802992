/* global VXConfig */
import React                      from 'react';
import PropTypes                  from 'prop-types';
import ReactDOM                   from 'react-dom';
import isEqual                    from 'lodash.isequal';
import Flux                       from '../../flux/Flux';
import {formatCurrency}           from '../../utils/CommonUtils';
import GlobalEventHandler         from '../../utils/GlobalEventHandler';
import GuestAccess                from '../../utils/GuestAccess';
import PjaxWrapper                from '../../utils/PjaxWrapper';
import ReloadHelper               from '../../utils/ReloadHelper';
import Translations               from '../../utils/Translations';
import {exitFullScreen,
	getFullScreenElement}         from '../../utils/Fullscreen';
import ContestVoting              from '../ContestVoting';
import ConversionBox              from '../ConversionBox/ConversionBox';
import Dislike                    from '../Dislike';
import MediaPin                   from '../Favorite/Actor/MediaPin';
import ActorPin                   from '../Favorite/Actor/Pin';
import VideoBadgeManager          from '../Gallery/VideoBadgeManager';
import Like                       from '../Like';
import Modal                      from '../Modal';
import PreviewPictures            from './PreviewPictures';
import VideoPlayerRecommendations from './VideoPlayerRecommendations';
import VXShaka                    from '../VXShaka/VXShaka';
import AlbumBuyButtons            from '../ActorProfileVideos/AlbumBuyButtons';

let initialVideosChanged = false;

const GRID_MIN_WIDTH_LG = 1199; // follow profile-video-details max width in ProfilePhotoAlbumDetail.less

// EVENT-TRACKING
const EVENT_TYPE_VIDEO_PLAY             = 'video_play';
const EVENT_TYPE_VIDEO_DOWNLOAD         = 'video_download';
const EVENT_CONTEXT_ACTOR_PROFILE_VIDEO = 'actor_profile_videos';
const EVENT_CONTEXT_MEDIATHEK           = 'mediathek';

const SOURCE_TYPE_ORIGINAL = 'original';

const onPurchase = (e, albumId, initActorId, albumPrice, needAvs, needVip) => {
	e.preventDefault();

	GuestAccess.onEnoughMoneyForShop(
		initActorId,
		albumPrice,
		needAvs,
		needVip,
		() => {
			const clb = (actorId, changedAlbumId) => {
				if (changedAlbumId === albumId) {
					Flux.Gallery.removeGalleryChangeListener(clb);
					// reload gallery
					if (!VXConfig.isVXMobilePlatform) {
						ReloadHelper.reloadCurrentPage();
					}
				}
			};

			Flux.Gallery.addGalleryChangeListener(clb);
			Flux.Gallery.buyAlbum(albumId);
			Flux.Conversion.loadVideoPreviewEndIncentives(albumId);
		}
	);
};


class VideoPlayer extends React.Component {

	static didInitialVideosChange(initialVideos = [], newVideos = []) {
		let videosChanged = false;

		if ((!initialVideos || !initialVideos.length) && newVideos && newVideos.length > 0) {
			videosChanged = true;
		} else if (newVideos && newVideos.length > 0) {
			if (initialVideos[0] && initialVideos[0].albumId !== newVideos[0].albumId) {
				videosChanged = true;
			} else if (initialVideos[0] && initialVideos[0].videoUrls.length !== newVideos[0].videoUrls.length) {
				videosChanged = true;
			} else if (initialVideos[0] && initialVideos[0].videoUrls.length > 0 && initialVideos[0].videoUrls[0].src !== newVideos[0].videoUrls[0].src) {
				videosChanged = true;
			}
		}

		return videosChanged;
	}

	constructor(props) {
		super(props);

		const isLandscape = VXConfig.isVXMobilePlatform ? Flux.Browser.isLandscape() : null;

		this.state = {
			videos:                       this.props.initialVideos,
			showVideo:                    this.props.containerWidth && this.props.containerHeight,
			showControls:                 this.props.showControls,
			isModelGuestFavorite:         this.props.initialIsFavoriteOfGuest,
			showModal:                    this.props.initShowModal,
			isLoggedIn:                   Flux.Guest.isLoggedIn(),
			isAnonymousVoicecall:         Flux.Guest.isAnonymousVoicecall(),
			showConversionBox:            false,
			isLandscape:                  isLandscape,
			showBadges:                   this.props.showBadges,
			windowWidth:                  Flux.Browser.getWindowSize().width,
			windowHeight:                 Flux.Browser.getWindowSize().height,
			showRecommendations:          false,
			dontShowAgainRecommendations: false,
			orientationChanged:   		  { toLandscape: Flux.Browser.isLandscape(), recently: 	 false },
		};

		// Event-Tracking
		this.eventTracker   = Flux.Tracker.getEventTracker();
		this.firstplay      = false;
		this.trackedTime    = 0;
		this.timer          = null;
		this.expireInterval = null;
		this.unmounted      = false;
		this.videoElement   = null;
		this.shaka          = null;

		this.showModal                        = this.showModal.bind(this);
		this.hideModal                        = this.hideModal.bind(this);
		this.onModalClose                     = this.onModalClose.bind(this);
		this.initVideo                        = this.initVideo.bind(this);
		this.onGalleryChange                  = this.onGalleryChange.bind(this);
		this.stopAllOtherPlayer               = this.stopAllOtherPlayer.bind(this);
		this.onVideoClickFn                   = this.onVideoClickFn.bind(this);
		this.onIncentiveButtonClick           = this.onIncentiveButtonClick.bind(this);
		this.onWindowResize                   = this.onWindowResize.bind(this);
		this.handleOrientationChange          = this.handleOrientationChange.bind(this);
		this.handleUnload                     = this.handleUnload.bind(this);
		this.startTimeTracker                 = this.startTimeTracker.bind(this);
		this.stopTimeTracker                  = this.stopTimeTracker.bind(this);
		this.trackEvent                       = this.trackEvent.bind(this);
		this.getEvent                         = this.getEvent.bind(this);
		this.onVideoDownload                  = this.onVideoDownload.bind(this);
		this.trackVideoPlayEvent              = this.trackVideoPlayEvent.bind(this);
		this.onVideoRecommendationsCloseClick = this.onVideoRecommendationsCloseClick.bind(this);
		this.onVideoPlay                      = this.onVideoPlay.bind(this);
		this.onVideoPause                     = this.onVideoPause.bind(this);
		this.onLoadedMetadata                 = this.onLoadedMetadata.bind(this);
		this.onEnded                          = this.onEnded.bind(this);
		this.onFullScreenEnter                = this.onFullScreenEnter.bind(this);
		this.onFullScreenExit                 = this.onFullScreenExit.bind(this);
		this.windowPopStateHandler            = this.windowPopStateHandler.bind(this);
		this.hidesConversionBox               = this.hidesConversionBox.bind(this);
	}

	componentDidMount() {
		Flux.Gallery.addGalleryChangeListener(this.onGalleryChange);
		GlobalEventHandler.addListener(GlobalEventHandler.GALLERY_VIDEO_STARTED, this.stopAllOtherPlayer);

		if (this.props.initShowModal) {
			this.showModal();
		}

		Flux.Browser.addWindowResizeListener(this.onWindowResize);
		if (VXConfig.isVXMobilePlatform) {
			Flux.Browser.addWindowPopStateListener(this.windowPopStateHandler);
		}

		this.initVideo();
		window.addEventListener('orientationchange', this.handleOrientationChange);
	}

	componentWillReceiveProps(nextProps) {
		if (VideoPlayer.didInitialVideosChange(this.props.initialVideos, nextProps.initialVideos)) {
			this.setState({
				videos:            nextProps.initialVideos,
				showConversionBox: nextProps.initialVideos[0] && nextProps.initialVideos[0].isPreviewTrailer,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (VideoPlayer.didInitialVideosChange(this.props.initialVideos, nextProps.initialVideos)) {
			initialVideosChanged = true;
		}

		if (VideoPlayer.didInitialVideosChange(this.state.videos, nextState.videos)) {
			initialVideosChanged = true;
		}

		return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
	}

	componentDidUpdate() {
		if (initialVideosChanged && this.firstplay) {
				const event = this.getEvent(EVENT_TYPE_VIDEO_PLAY);
				this.trackEvent(event);
		}

		initialVideosChanged = false;
	}

	componentWillUnmount() {
		this.unmounted = true;
		if (this.firstplay) {
			this.trackVideoPlayEvent(false);

			window.removeEventListener('unload', this.handleUnload, false);
		}

		window.removeEventListener('orientationchange', this.handleOrientationChange);

		Flux.Gallery.removeGalleryChangeListener(this.onGalleryChange);
		Flux.Browser.removeWindowResizeListener(this.onWindowResize);
		if (VXConfig.isVXMobilePlatform) {
			Flux.Browser.removeWindowPopStateListener(this.windowPopStateHandler);
		}

		if (this.expireInterval) {
			clearInterval(this.expireInterval);
		}

		GlobalEventHandler.removeListener(GlobalEventHandler.GALLERY_VIDEO_STARTED, this.stopAllOtherPlayer);
	}

	windowPopStateHandler() {
		if (!this.unmounted && this.state.showModal) {
			window.history.forward();
			this.onModalClose();
		}
	}

	onVideoRecommendationsCloseClick() {
		this.setState({showRecommendations: false, dontShowAgainRecommendations: true});
	}

	onGalleryChange(actorId, albumId) {
		const currentVideo = this.state.videos[0];

		if (currentVideo && albumId === currentVideo.albumId) {
			const data = Flux.Gallery.getGalleryData(albumId);

			if (data && data.video) {
				this.setState({
					videos:            [data.video],
					showConversionBox: data.video && data.video.isPreviewTrailer,
				});
			}
		}
	}

	onVideoClickFn(e) {
		if (typeof this.props.onVideoClick === 'function') {
			this.props.onVideoClick(e);
		}
	}

	onWindowResize() {
		// wait for orientationChange state to change
		setTimeout(() => {
			if (this.props.fullscreenOnLandscape && VXConfig.isVXMobilePlatform) {
				const orientationChange = this.state.orientationChanged;
				if(orientationChange.recently) {
					if(orientationChange.toLandscape) {
						this.shaka.requestVXShakaFullscreen();
					} else {
						this.shaka.exitVXShakaFullscreen();
					}
				}
			}
		}, 200);

		this.setState({
			isLandscape:  VXConfig.isVXMobilePlatform ? Flux.Browser.isLandscape() : null,
			windowWidth:  Flux.Browser.getWindowSize().width,
			windowHeight: Flux.Browser.getWindowSize().height,
		});
	}

	handleOrientationChange() {
		const toLandscape = Flux.Browser.isLandscape();
		if(!this.state.orientationChanged.recently) {
			this.setState({
				orientationChanged: {
					toLandscape,
					recently: true
				},
			});
			setTimeout(() => {
				this.setState({
					orientationChanged: {
						toLandscape,
						recently: false
					},
				});
			}, 1000);
		}
	}

	handleUnload() {
		this.trackVideoPlayEvent(true);
	}

	startTimeTracker() {
		this.timer = setInterval(() => {
			this.trackedTime++;
		}, 100);
	}

	stopTimeTracker() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	/**
	 *
	 * @param {Event} event
	 * @param {boolean} windowClosed
	 */
	trackEvent(event, windowClosed = false) {
		let valid = false;

		switch (event.type) {
			case EVENT_TYPE_VIDEO_PLAY:
				event.measure_float1 = this.trackedTime / 10;
				event.data += 'windowClosed=' + (windowClosed ? '1;' : '0;');
				valid                = true;
				break;
			case EVENT_TYPE_VIDEO_DOWNLOAD:
				valid = true;
				break;
			default:
				break;
		}

		if (valid) {
			this.eventTracker.trackEvent(event);
		}
	}

	trackVideoPlayEvent(windowClosed = false) {
		const event          = this.getEvent(EVENT_TYPE_VIDEO_PLAY);
		event.measure_float1 = this.trackedTime;
		this.trackEvent(event, windowClosed);
	}

	getEvent(type) {
		// Init Event
		const event  = this.eventTracker.getNewEvent();
		event.type   = type;
		event.source = 'www';
		event.data   = 'url=' + window.location + ';';

		const videoData = this.state.videos.length > 0 ? this.state.videos[0] : null;

		// Determine whether its an album or mediathek video
		if (videoData && videoData.albumId) {
			event.context = EVENT_CONTEXT_ACTOR_PROFILE_VIDEO;
			event.data += 'albumId=' + videoData.albumId + ';';
		} else if (this.props.videoId !== '') {
			event.context = EVENT_CONTEXT_MEDIATHEK;
			event.data += 'videoId=' + this.props.videoId + ';';
		}

		// override context if props is given
		if (this.props.trackingContext) {
			event.context = this.props.trackingContext;
		}

		return event;
	}

	onVideoDownload() {
		const event = this.getEvent(EVENT_TYPE_VIDEO_DOWNLOAD);
		this.trackEvent(event);
	}

	initVideo() {
		const currentVideo = this.state.videos[0];

		if (!currentVideo) {
			return;
		}

		if (currentVideo.videoUrls.length > 0) {
			let containsHlsVideo = false;
			let isMediathekVideo = false;

			for (const currentValue of currentVideo.videoUrls) {
				if (currentValue.src.indexOf('.m3u8') > -1) {
					containsHlsVideo = true;
				}

				if (currentValue.src.indexOf('stream.vxcdn.org/mediathek') > -1) {
					isMediathekVideo = true;
				}
			}

			if (this.expireInterval) {
				clearInterval(this.expireInterval);
			}
			if (containsHlsVideo && !isMediathekVideo) {
				this.expireInterval = setInterval(() => {
					const video = this.state.videos[0];

					if (!video || !video.albumId) {
						return;
					}

					const videoSrc = (video.videoUrls && video.videoUrls.length > 0) ? video.videoUrls[0].src : '';
					const matcher  = videoSrc.substr(videoSrc.indexOf('amlst:') + 6).match('[-]\\d*[-](\\d*?)[-]');
					if (matcher && matcher[1]) {
						const expire_hex = matcher[1];

						//parse to millis
						const expire = parseInt(expire_hex) * 1000;

						// add 5 Seconds because of interval
						if ((new Date()).getTime() + 5000 > expire) {
							// load new src
							Flux.Gallery.loadGallery(video.albumId);
						}
					}

				}, 5000);
			}

			// show video for preview trailers and when chrome's data saver is enabled (preload will be turned off for that)
			if ((containsHlsVideo && currentVideo.isPreviewTrailer) || (navigator.connection && navigator.connection.saveData === true)) {
				this.setState({
					showVideo: true,
				});
			}

		} else if (this.state.showModal) {
			this.setState({
				showVideo:         true,
				showConversionBox: true,
			});

			Flux.Conversion.loadVideoPreviewEndIncentives(currentVideo.albumId);
		}
	}

	onIncentiveButtonClick(e) {
		e.preventDefault();

		this.setState({
			showVideo:         true,
			showConversionBox: true,
		});

		Flux.Conversion.loadVideoPreviewEndIncentives(this.state.videos[0].albumId);
	}

	showModal() {
		if (typeof this.props.onShowModal === 'function') {
			this.props.onShowModal();
			return;
		}

		document.onkeydown = checkKey.bind(this);

		function checkKey(e) {
			e = e || window.event;

			switch (e.keyCode) {
				// escape
				case 27:
					this.hideModal();
					break;
				// left arrow
				case 37:
					if (this.props.onMoveFn) {
						this.props.onMoveFn(-1);
					}
					break;
				// right arrow
				case 39:
					if (this.props.onMoveFn) {
						this.props.onMoveFn(1);
					}
					break;
				default:
			}
		}

		this.setState({
			showModal: true,
		});
	}

	hideModal() {
		document.onkeydown = null;

		if (this.props.initShowModal) {
			const what = ReactDOM.findDOMNode(this).parentNode;
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(what);
			}, 0);
		} else {
			this.setState({
				showModal: false,
			});
		}

		if (typeof this.props.onClose === 'function') {
			this.props.onClose();
		}

	}

	hidesConversionBox() {
		this.setState({showConversionBox: false});
	}

	onModalClose() {
		this.hideModal();
	}

	stopAllOtherPlayer(instanceId) {
		if (this.props.instanceId !== instanceId && this.videoElement) {
			this.videoElement.pause();
		}
	}

	onVideoPlay() {
		this.setState({showBadges: false});

		this.startTimeTracker();
		GlobalEventHandler.emit(GlobalEventHandler.GALLERY_VIDEO_STARTED, this.props.instanceId);

		if (typeof this.props.onPlay === 'function') {
			this.props.onPlay();
		}

		if (this.state.showRecommendations) {
			this.setState({showRecommendations: false});
		}

		if (!this.firstplay) {
			this.firstplay = true;
			if (typeof this.props.onFirstPlay === 'function') {
				this.props.onFirstPlay();
			}
			window.addEventListener('unload', this.handleUnload, false);
		}
	}

	onVideoPause() {
		this.stopTimeTracker();

		const currentVideo = this.state.videos[0];
		if ((this.props.showRecommendationsOnVideoEnd && !Flux.VXMobile.isIOS()
			&& currentVideo && (!currentVideo.needBuying || currentVideo.isBought) && !this.state.dontShowAgainRecommendations) && getFullScreenElement()) {
			this.setState({showRecommendations: true});
		}
	}

	onLoadedMetadata() {
		this.setState({
			showVideo:   true,
			videoWidth:  this.videoElement.videoWidth,
			videoHeight: this.videoElement.videoHeight,
		}, () => {
			if (typeof this.props.onLoad === 'function') {
				this.props.onLoad(this.videoElement);
			}
		});
	}

	onEnded() {
		this.setState({showBadges: true});

		this.stopTimeTracker();
		if (typeof this.props.onEnded === 'function') {
			this.props.onEnded(this.videoElement);
		}

		const currentVideo = this.state.videos[0];
		if (currentVideo.isPreviewTrailer) {
			this.setState({
				showConversionBox: true,
			});

			exitFullScreen();

			Flux.Conversion.loadVideoPreviewEndIncentives(currentVideo.albumId);
		}

		if (this.props.showRecommendationsOnVideoEnd && !Flux.VXMobile.isIOS() && (!currentVideo.needBuying || currentVideo.isBought) && !this.state.dontShowAgainRecommendations) {
			this.setState({showRecommendations: true});
		}
	}

	onFullScreenEnter() {
		if (typeof this.props.onFullScreenEnter === 'function') {
			this.props.onFullScreenEnter();
		}
	}

	onFullScreenExit() {
		this.setState({showRecommendations: false});
		if (typeof this.props.onFullScreenExit === 'function') {
			this.props.onFullScreenExit();
		}
	}

	render() {
		const dimensions   = {...VideoPlayer.defaultProps.initialDimensions, ...this.props.initialDimensions};
		const currentVideo = this.state.videos[0];
		if (!currentVideo) {
			return null;
		}

		const hasFooter                = currentVideo.albumTitle;
		const contentCNames            = ['gallery__video-player hasVideo',
		                                  (this.props.containerClass ? this.props.containerClass : ''),
		                                  (currentVideo.isPreviewTrailer ? 'isPreviewTrailer' : ''),
		                                  (this.props.isMobile ? 'gallery__video-player--auto' : ''),
		];
		const recommendationsLayer     = (
			<VideoPlayerRecommendations actorId={currentVideo.actorId}
			                            actorName={currentVideo.actorName}
			                            albumId={currentVideo.albumId}
			                            onCloseClick={this.onVideoRecommendationsCloseClick}
			/>
		);
		const showContentActionButtons = this.state.isLoggedIn && (currentVideo.albumId || currentVideo.forceShowDownload);
		const contestVoteComponent     = (
			showContentActionButtons && this.props.mediaElement && this.props.mediaElement.isVotingAllowed &&
			<ContestVoting
				contestId={this.props.mediaElement.contestId}
				actorId={this.props.mediaElement.actorId}
				mediaId={this.props.mediaElement.mediaId}
				guestId={this.props.mediaElement.guestId}
				isVoted={this.props.mediaElement.isVoted}
				albumId={this.props.mediaElement.albumId}
				isVotingAllowed={this.props.mediaElement.isVotingAllowed}
				styleClass="-modal"
				key={this.props.mediaElement.mediaId + 'videoplayer'}
			/>
		);

		let ctaButton = null;
		if (this.props.renderC2aButtons) {
			ctaButton =
				<AlbumBuyButtons actorDataId={this.props.actorId}
				                 isAlbumBought={currentVideo.isBought}
				                 baseClassName="vx_shaka--ui"
				                 isFromCurrentVideoContest={this.props.mediaElement && this.props.mediaElement.contestId > 0}
				                 needVip={currentVideo.needVip}
				                 hasActiveDiscount={currentVideo.hasActiveDiscount}
				                 isAccessAllowed={currentVideo.isAccessAllowed}
				                 needPayment={currentVideo.needPayment}
				                 needBuying={currentVideo.needBuying}
				                 needAvs={currentVideo.needAvs}
				                 price={currentVideo.videoPrice}
				                 crossPrice={currentVideo.crossPrice}
				                 voucherPrice={currentVideo.voucherPrice}
				                 id={currentVideo.albumId}
				                 contestLink={currentVideo.contestLink}
				                 isVip30={currentVideo.isVip30}
				                 canBuy={currentVideo.canBuy}
				                 hasComment={currentVideo.hasComment}
								 onBuyModalId={currentVideo.onBuyModalId}
								 showSignupButton={this.props.showSignupButton}
								 vxbuttonAdditionalStyle={this.props.vxbuttonAdditionalStyle}
								 onBuy={this.props.onBuy}
				>
				</AlbumBuyButtons>;
		}

		const previewImg = (
			<PreviewPictures onClickFn={this.props.onShowModal}
			                 videoDuration={currentVideo.videoDuration}
			                 previewPictureUrls={currentVideo.previewPictureUrls}
			                 displayStyle={currentVideo.videoUrls.length > 0 ? 'none' : 'block'}
			                 needBuying={currentVideo.needBuying}
			                 enablePreviewButton={this.props.enablePreviewButton}
			/>
		);


		let src              = null, origSrc = null, type = null;
		const videoUrlsCount = currentVideo.videoUrls.length;
		if (videoUrlsCount > 0) {
			if (videoUrlsCount === 1) {
				src = currentVideo.videoUrls[0].src;
				type = currentVideo.videoUrls[0].type;
			} else {
				for (let i = 0; i < videoUrlsCount; i++) {
					if (src && origSrc) {
						break;
					}

					if (currentVideo.videoUrls[i].type === SOURCE_TYPE_ORIGINAL && !origSrc) {
						origSrc = currentVideo.videoUrls[i].src;
						type = currentVideo.videoUrls[i].type;
					} else if (!src) {
						src = currentVideo.videoUrls[i].src;
						type = currentVideo.videoUrls[i].type;
					}
				}
			}
		}

		const content = (
			<div className={contentCNames.filter(_ => _).join(' ')}>
				{!VXConfig.isVXMobilePlatform && this.state.showConversionBox &&
				<ConversionBox onCloseFn={this.hidesConversionBox}
				               showCloseButton={true}
				               currentVideo={currentVideo}
				               albumId={currentVideo.albumId}
				               className={this.state.showModal ? ' -width-auto' : ''}
				/>}
				<VXShaka
					poster={(currentVideo.previewPictureUrls.length > 0) ? currentVideo.previewPictureUrls[0].pictureUrl : null}
					src={src}
					origSrc={origSrc}
					videoType={type}
					webVTT={(currentVideo.videoUrls.length > 0) && currentVideo.videoUrls[0].webVTT ? currentVideo.videoUrls[0].webVTT : null}
					showSeek15Secs={!currentVideo.isPreviewTrailer}
					showDownload={showContentActionButtons && this.props.showDownload && !currentVideo.isPreviewTrailer}
					downloadUrl={currentVideo.downloadUrl}
					onVideoDownload={this.onVideoDownload}
					showPreviewLabel={currentVideo.isPreviewTrailer}
					showPin={showContentActionButtons && this.props.showMediaPin && this.state.isLandscape}
					showLike={showContentActionButtons && this.props.showLike && this.state.isLandscape}
					showDislike={showContentActionButtons && this.props.showDislike && this.state.isLandscape}
					actorId={currentVideo.actorId}
					albumId={currentVideo.albumId}
					albumIsFavorite={currentVideo.pinned}
					isLiked={currentVideo.isLiked}
					isDisliked={currentVideo.isDisliked}
					videoTimestamp={this.props.videoTimestamp}
					onVideoClick={this.onVideoClickFn}
					onPlaying={this.props.onPlaying}
					onPlay={this.onVideoPlay}
					onPause={this.onVideoPause}
					onTimeUpdate={() => {
						Flux.Gallery.setCurrentVideoTime(this.videoElement.currentTime);
					}}
					onLoadedMetadata={this.onLoadedMetadata}
					onEnded={this.onEnded}
					onError={() => {
						if (typeof this.props.onError === 'function') {
							this.props.onError();
						}
					}}
					storeVideoRef={ref => (this.videoElement = ref)}
					recommendationsLayer={this.state.showRecommendations && recommendationsLayer}
					contestVoteComponent={contestVoteComponent}
					onFullScreenEnter={this.onFullScreenEnter}
					onFullScreenExit={this.onFullScreenExit}
					muted={this.props.muted}
					controlsVisible={true}
					showHdLabel={this.props.isHD}
					autoplay={this.props.autoplay}
					showCinematicMode={this.props.showCinematicMode && this.state.windowWidth > GRID_MIN_WIDTH_LG}
					ctaButton={ctaButton}
					previewImg={previewImg}
					initialShowUI={true}
					isLandscape={this.state.isLandscape}
					videoWidth={this.state.videoWidth}
					videoHeight={this.state.videoHeight}
					ref={(ref) => this.shaka = ref}
					showBigPlayButton={this.props.showBigPlayButton}
					additionalUIStyle={this.props.additionalUIStyle}
					playButtonClass={this.props.playButtonClass}
					needLogin={this.props.needLogin}
				/>
			</div>
		);

		// calculate video container size
		let containerWidth  = this.props.containerWidth || 0;
		let containerHeight = this.props.containerHeight || 0;

		let contentY;
		if (!containerWidth && !containerHeight && this.state.showModal && this.state.videoWidth) {
			contentY       = hasFooter ? dimensions.footerHeight : dimensions.contentTop + dimensions.contentBottom;
			const marginsX = dimensions.margins;
			const marginsY = dimensions.margins + contentY;
			let calcVideoWidth, calcVideoHeight;

			// find max dimensions
			containerWidth  = Math.min(this.state.windowWidth * 0.95, calcVideoWidth = Math.max(this.state.videoWidth + marginsX, this.props.viewport.width));
			containerHeight = Math.min(this.state.windowHeight * 0.95, calcVideoHeight = Math.max(this.state.videoHeight + marginsY, this.props.viewport.height));

			// count ratios
			const ratioVideo  = this.state.videoWidth / this.state.videoHeight;
			const ratioWidth  = (calcVideoWidth) / containerWidth;
			const ratioHeight = (calcVideoHeight) / containerHeight;

			// calculate the other dimension
			if (ratioWidth > ratioHeight) {
				containerHeight = (containerWidth - marginsX) / ratioVideo + marginsY;
			} else {
				containerWidth = (containerHeight - marginsY) * ratioVideo + marginsX;
			}
		}
		const isHls           = (currentVideo.videoUrls && currentVideo.videoUrls.length > 0) ? currentVideo.videoUrls[0].src.includes('.m3u8') : false;
		const onPurchaseClick = (e) => onPurchase(e, currentVideo.albumId, this.props.actor.id, currentVideo.videoPrice, currentVideo.needAvs, currentVideo.needVip);

		return (
			<div className={this.props.outerContainerClass + ' gallery__video-player gallery__video-player--top-most-wrapper'}>
				{this.state.showModal &&
				<Modal instanceId={this.props.instanceId}
					/*isHidden={!this.state.showVideo}*/
					   onClose={this.onModalClose}
					   className={(hasFooter ? 'hasFooter' : '') + ' ' + this.props.modalClassName}
					   modalClassName={this.props.modalClassName}
					   containerWidth={containerWidth}
					   containerHeight={containerHeight}
					   hideCloseButton={this.state.showRecommendations}
					   isHls={isHls}
					   useFlexDirection={true}
					   isFullHeight={this.props.isMobile}
				>
					<div className={'modal__video' + (this.props.isMobile ? ' modal__video--flex' : '')}>
						{this.props.beforeContent}
						{content}
						{this.props.afterContent}
					</div>
					{hasFooter && <div className={"modal__footer" + (this.props.isMobile ? ' modal__footer--relative' : '')}>
						<div className="h-left">
							<div className="h-color-fg">
								{this.props.isAudio ? Translations.get('PrivateCollectionSortOnlyAudio') : Translations.get('Video')} {currentVideo.albumTitle &&
							<a href={currentVideo.titleUrl}>
								<span className="h-text-highlight" dangerouslySetInnerHTML={{__html: currentVideo.albumTitle}} />
							</a>} <span className="h-text-grey">({Translations.get('PlayerVideoDuration')} {currentVideo.videoDuration})</span>
							</div>
							{currentVideo.actorName && <div className="h-color-fg-hint">
								<span className="h-color-fg">{Translations.get('From')}: </span>
								<a href={currentVideo.actorUrl}><span className="h-text-highlight">{currentVideo.actorName}</span></a>
								&nbsp;{this.props.actor && this.state.isLoggedIn ?
								<ActorPin actorId={this.props.actor.id} initialIsFavoriteOfGuest={this.state.isModelGuestFavorite} /> : ''}
							</div>}
						</div>
						<div className="h-right">
							<div className="gallery__controls-table">
								{this.state.isLoggedIn && currentVideo.needBuying &&
								<button className="btn -btn-color-special -click-material h-disp-table-cell gallery__controls-cta h-mr-10"
								        onClick={onPurchaseClick}
								>
									Video kaufen <span className="cta__price">für {formatCurrency(currentVideo.videoPrice)}</span>
									<i className="icon -icon-arrow-right-full btn-arrow-icon" />
								</button>}
								{(this.state.isLoggedIn && this.props.showMediaPin) &&
								<div className="gallery__controls-cell -button -bg-light">
									<MediaPin albumId={currentVideo.albumId} initialIsFavorite={currentVideo.pinned} tooltipPlacement="top" />
								</div>}
								{(this.state.isLoggedIn && this.props.showLike && !currentVideo.isPreviewTrailer) &&
								<div className={'gallery__controls-cell -button -bg-light' + (currentVideo.isLiked ? ' is-active' : '')}>
									<Like albumId={currentVideo.albumId}
									      initialIsLiked={currentVideo.isLiked}
									      tooltipPlacement="top"
									/>
								</div>}
								{(this.state.isLoggedIn && this.props.showDislike && !currentVideo.isPreviewTrailer) &&
								<div className={'gallery__controls-cell -button -bg-light' + (currentVideo.isDisliked ? ' is-active' : '')}>
									<Dislike albumId={currentVideo.albumId}
									         initialIsDisliked={currentVideo.isDisliked}
									         tooltipPlacement="top"
									/>
								</div>}
								{(this.props.mediaElement && this.props.mediaElement.isVotingAllowed) &&
								<div className={'gallery__controls-cell -button -bg-light' + (currentVideo.isDisliked ? ' is-active' : '')}>
									<ContestVoting
										contestId={this.props.mediaElement.contestId}
										actorId={this.props.mediaElement.actorId}
										mediaId={this.props.mediaElement.mediaId}
										guestId={this.props.mediaElement.guestId}
										isVoted={this.props.mediaElement.isVoted}
										albumId={this.props.mediaElement.albumId}
										isVotingAllowed={this.props.mediaElement.isVotingAllowed}
										styleClass="-modal"
										key={this.props.mediaElement.mediaId + 'videoplayer'}
									/>
								</div>}
							</div>
						</div>
					</div>}
				</Modal>}
				{!this.state.showModal && content}
				{this.props.profileButton}
				{!VXConfig.isVXMobilePlatform &&
				<VideoBadgeManager
					showBadges={this.state.showBadges}
					isLoggedIn={this.state.isLoggedIn}
					isAnonymousVoicecall={this.state.isAnonymousVoicecall}
					isBought={this.state.videos[0].isBought}
					isVip30={this.state.videos[0].isVip30}
					videoBadgesConfig={this.props.videoBadgesConfig}
				/>}
			</div>
		);
	}
}

/**
 *
 * @param {string|array<string>} videoUrl
 * @param {object} webVTT
 * @return {array}
 */
function buildVideoUrls(videoUrl, webVTT) {
	const allVideoUrls      = typeof videoUrl === 'string' ? [videoUrl] : videoUrl;
	const supportsHLSNative = Flux.Browser.supportsHlsNative();

	const videoUrls = allVideoUrls.reduce((acc, itemUrl) => {
		const isHLS = itemUrl.indexOf('.m3u8') > -1;
		const type  = isHLS ? 'application/x-mpegURL' : 'video/mp4';
		if (isHLS && supportsHLSNative) {
			acc.unshift({
				src: itemUrl,
				type,
				webVTT,
			});
		} else {
			acc.push({
				src: itemUrl,
				webVTT,
				type,
			});
		}

		return acc;
	}, []);
	return videoUrls;
}

/**
 * @param {object} container
 * @param {string|array<string>} videoUrl
 * @param {string[]} previewPictureUrls
 * @param {boolean} modal
 * @param {boolean} autoplay
 * @param {func} onEnded
 * @param {boolean} showContestVoting
 * @param {object} mediaElement
 * @param {int} videoTimestamp
 * @param {func} onCloseModalBox
 * @param {string} videoId
 * @param {string} trackingContext
 * @param {boolean} showRecommendationsOnVideoEnd
 * @param {boolean} showBadges
 * @param {boolean} premiumBadge
 * @param {boolean} over18Badge
 * @param {boolean} renderC2aButtons
 * @param {object} webVTT
 * @param {string} modalClassName
 */
function openVideoUrlInPlayer(
	container,
	videoUrl,
	previewPictureUrls,
	modal                         = true,
	autoplay                      = true,
	onEnded                       = null,
	showContestVoting             = false,
	mediaElement                  = null,
	videoTimestamp                = 0,
	onCloseModalBox               = null,
	videoId                       = '',
	trackingContext               = '',
	showRecommendationsOnVideoEnd = false,
	showBadges                    = true,
	premiumBadge                  = false,
	over18Badge                   = false,
	renderC2aButtons              = true,
	webVTT,
	modalClassName                = '',
	isMobile                      = false,
	needLogin                     = false,
) {
	const videoUrls  = buildVideoUrls(videoUrl, webVTT);
	const instanceId = typeof videoUrl === 'string' ? videoUrl : videoUrl[0];
	const videoData  = {
		pinned:             (mediaElement ? mediaElement.isElementPinned : false),
		videoUrls:          videoUrls,
		previewPictureUrls: previewPictureUrls.map(_ => ({pictureUrl: _})),
		albumId:            (mediaElement ? mediaElement.albumId : null),
		albumTitle:         (mediaElement ? (mediaElement.albumTitle ? mediaElement.albumTitle : mediaElement.title) : null),
		titleUrl:           (mediaElement ? (mediaElement.titleUrl ? mediaElement.titleUrl : mediaElement.shopUrl) : null),
		actorName:          (mediaElement ? mediaElement.actorName : null),
		videoDuration:      (mediaElement ? mediaElement.videoDuration : null),
		actorUrl:           (mediaElement ? mediaElement.actorUrl : null),
	};

	const videoBadgesConfig = {};
	if (premiumBadge) {
		videoBadgesConfig.isPremium = true;
	}

	if (over18Badge) {
		videoBadgesConfig.isOver18Content = true;
		videoBadgesConfig.album           = true;
	}

	ReactDOM.render(
		<VideoPlayer initialVideos={[videoData]}
		             initShowModal={modal}
		             autoplay={autoplay}
		             onEnded={onEnded}
		             showContestVoting={showContestVoting}
		             mediaElement={mediaElement}
		             showDownload={!mediaElement}
		             showLike={!mediaElement}
		             showDislike={!mediaElement}
		             videoTimestamp={videoTimestamp}
		             onClose={onCloseModalBox}
		             instanceId={instanceId}
		             videoId={videoId}
		             trackingContext={trackingContext}
		             showRecommendationsOnVideoEnd={showRecommendationsOnVideoEnd}
		             showBadges={showBadges}
		             videoBadgesConfig={videoBadgesConfig}
		             renderC2aButtons={renderC2aButtons}
		             modalClassName={modalClassName}
					 isMobile={isMobile}
					 needLogin={needLogin}
		/>,
		container
	);

	PjaxWrapper.addUnloadFunc(() => {
		ReactDOM.unmountComponentAtNode(container);
	});
}

VideoPlayer.propTypes = {
	actor:                         PropTypes.object,
	afterContent:                  PropTypes.object,
	beforeContent:                 PropTypes.object,
	initialDimensions:             PropTypes.object,
	enablePreviewButton:           PropTypes.bool,
	forceInline:                   PropTypes.bool,
	initialIsFavoriteOfGuest:      PropTypes.bool,
	initShowModal:                 PropTypes.bool,
	initialShowVideo:              PropTypes.bool,
	initialVideos:                 PropTypes.arrayOf(PropTypes.object),
	instanceId:                    PropTypes.string,
	isMobile:                      PropTypes.bool,
	loadIncentiveOnEmptyVideo:     PropTypes.bool,
	modalClassName:                PropTypes.string,
	onShowModal:                   PropTypes.func,
	onEnded:                       PropTypes.func,
	onClose:                       PropTypes.func,
	onError:                       PropTypes.func,
	onFullScreenEnter:             PropTypes.func,
	onFullScreenExit:              PropTypes.func,
	onLoad:                        PropTypes.func,
	onMoveFn:                      PropTypes.func,
	onPlay:                        PropTypes.func,
	onPlaying:                     PropTypes.func,
	onRetryPlaylist:               PropTypes.func,
	onTap:                         PropTypes.func,
	onVideoClick:                  PropTypes.func,
	onFirstPlay:                   PropTypes.func,
	onBuy:                         PropTypes.func,
	autoplay:                      PropTypes.bool,
	muted:                         PropTypes.bool,
	showControls:                  PropTypes.bool,
	containerClass:                PropTypes.string,
	containerWidth:                PropTypes.number,
	containerHeight:               PropTypes.number,
	outerContainerClass:           PropTypes.string,
	showContestVoting:             PropTypes.bool,
	mediaElement:                  PropTypes.object,
	showDownload:                  PropTypes.bool,
	showMediaPin:                  PropTypes.bool,
	showLike:                      PropTypes.bool,
	showDislike:                   PropTypes.bool,
	viewport:                      PropTypes.object,
	videoTimestamp:                PropTypes.number,
	videoId:                       PropTypes.string,
	trackingContext:               PropTypes.string,
	showRecommendationsOnVideoEnd: PropTypes.bool,
	showBadges:                    PropTypes.bool,
	videoBadgesConfig:             PropTypes.shape({
		album:             PropTypes.object,
		isGift:            PropTypes.bool,
		isOver18Content:   PropTypes.bool,
		isNew:             PropTypes.bool,
		getPrice:          PropTypes.number,
		needBuying:        PropTypes.bool,
		hasActiveDiscount: PropTypes.bool,
		isContestContent:  PropTypes.bool,
		getDiscountBadge:  PropTypes.string,
		currency:          PropTypes.string,
	}),
	isHD:                          PropTypes.bool,
	showCinematicMode:             PropTypes.bool,
	actorId:                       PropTypes.number,
	renderC2aButtons:              PropTypes.bool,
	showBigPlayButton:             PropTypes.bool,
	profileButton:                 PropTypes.node,
	additionalUIStyle:             PropTypes.object,
	fullscreenOnLandscape:         PropTypes.bool,
	isAudio:                       PropTypes.bool,
	playButtonClass:               PropTypes.string,
	showSignupButton:              PropTypes.bool,
	vxbuttonAdditionalStyle:       PropTypes.object,
	needLogin:                     PropTypes.bool
};

VideoPlayer.defaultProps = {
	autoplay:                      false,
	forceInline:                   false,
	initialDimensions:             {
		footerHeight:  60,
		margins:       20,
		contentTop:    0,
		contentBottom: 0,
	},
	initialShowVideo:              false,
	onTap:                         () => {
	},
	isMobile:                      false,
	modalClassName:                '',
	showControls:                  true,
	showContestVoting:             false,
	mediaElement:                  null,
	showMediaPin:                  true,
	showLike:                      true,
	showDislike:                   true,
	showDownload:                  true,
	viewport:                      {width: 0, height: 0},
	videoId:                       '',
	trackingContext:               '',
	showRecommendationsOnVideoEnd: false,
	showBadges:                    true,
	outerContainerClass:           '',
	videoBadgesConfig:             {
		album:             {},
		isGift:            false,
		isOver18Content:   false,
		isNew:             false,
		getPrice:          0,
		needBuying:        false,
		hasActiveDiscount: false,
		isContestContent:  false,
		getDiscountBadge:  '',
		currency:          '',
	},
	isHD:                          false,
	showCinematicMode:             false,
	initShowModal:                 false,
	renderC2aButtons:              true,
	showBigPlayButton:             true,
	additionalUIStyle:             {},
	fullscreenOnLandscape:         true,
	isAudio:                       false,
	needLogin:                     false,
};

export {
	VideoPlayer,
	openVideoUrlInPlayer,
	buildVideoUrls,
	SOURCE_TYPE_ORIGINAL,
};
