import ModalBoxTagReward   from "./ModalBoxTagReward";
import Translations        from "../../utils/Translations";
import React               from "react";
import PropTypes           from 'prop-types';


const ModalBoxTagRewardPro = (props) =>
	<ModalBoxTagReward {...props}
	                   imageUrl={'/assets/img/modal/tag-reward/TreasureChast.svg'}
	                   headline={Translations.get('ModalBoxTagRewardHeadlinePro')}
	                   subline={Translations.get('ModalBoxTagRewardSublinePro')}
	                   bonusName={'Schatztruhe'}
	                   bonusSubline={Translations.get('RewardMessengerGift')}
	/>;

ModalBoxTagRewardPro.propTypes = {
	onClose:            PropTypes.func,
	isMobile:           PropTypes.bool,
};

export default ModalBoxTagRewardPro;