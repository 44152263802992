import ModalBoxTagReward   from "./ModalBoxTagReward";
import Translations        from "../../utils/Translations";
import React               from "react";
import PropTypes           from 'prop-types';


const ModalBoxTagRewardAdvanced = (props) =>
	<ModalBoxTagReward {...props}
	                   imageUrl={'/assets/img/modal/tag-reward/Moneybag.svg'}
	                   headline={Translations.get('ModalBoxTagRewardHeadlineAdvanced')}
	                   subline={Translations.get('ModalBoxTagRewardSublineAdvanced')}
	                   bonusName={'Geldsack'}
	                   bonusSubline={Translations.get('RewardMessengerGift')}
	/>;

ModalBoxTagRewardAdvanced.propTypes = {
	onClose:            PropTypes.func,
	isMobile:           PropTypes.bool,
};

export default ModalBoxTagRewardAdvanced;