import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

function VideoSignupBox(props) {
	return (
		<div className="feature-teaser-box row">
			<div className="col-xs-12 feature-teaser-box__subheadline h-over-ellipsis"
			     title={props.title}
			     dangerouslySetInnerHTML={{__html: props.title}}
			/>
			<div className="col-xs-12 feature-teaser-box__line" />
			<div className="col-xs-12 feature-teaser-box__headline h-text-uppercase"
			     dangerouslySetInnerHTML={{__html: Translations.get('VideoSignUpBoxHeadLine')}}
			/>
		</div>);
}

VideoSignupBox.propTypes = {
	title: PropTypes.string.isRequired,
};

export default VideoSignupBox;
