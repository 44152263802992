import React, {useState, useEffect} from 'react';
import IconButton                   from './IconButton';
import Flux                         from '../../../flux/Flux';

const CinematicMode = () => {
	const [isActive, setIsActive] = useState(Flux.Gallery.isCinematicModeEnabled());
	useEffect(() => {
		const fn = () => {
			setIsActive(Flux.Gallery.isCinematicModeEnabled());
		};
		Flux.Gallery.addCurrentVideoCinematicModeListener(fn);
		return () => {
			Flux.Gallery.removeCurrentVideoCinematicModeListener(fn);
		};
	});
	const onClick = () => {
		Flux.Gallery.setCinematicModeEnabled(!isActive);
	};
	return (
		<IconButton checked={isActive} icon='-icon-cinema-mode-line' checkedIcon='-icon-exit-cinema-mode-line' onClick={onClick} />
	);
};

export default CinematicMode;
