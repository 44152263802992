/* global VXConfig */

import React                             from 'react';
import PropTypes                         from "prop-types";
import FeatureFeedbackPopupRatingButtons from "./FeatureFeedbackPopupRatingButtons";
import FeatureFeedbackPopupTextArea      from "./FeatureFeedbackPopupTextArea";
import VXButton                          from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION}       from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}              from "../SimpleElements/VXButton/VXButtonConfig";
import Translations                      from "../../utils/Translations";

class FeatureFeedbackPopup extends React.PureComponent {

	constructor(props) {
		super(props);

		this.onSelectRating = this.onSelectRating.bind(this);
		this.onChangeText   = this.onChangeText.bind(this);
		this.onSubmit       = this.onSubmit.bind(this);
	}

	onSelectRating(rating) {
		this.props.onSelectRating(rating);
	}

	onChangeText(value) {
		this.props.onChangeText(value);
	}

	onSubmit(event, ...args) {
		event.preventDefault();

		this.props.onSubmit(event, ...args);
	}

	render() {
		let popupClassName = "feature-feedback__popup ";
		if(this.props.additionalPopupClass){
			popupClassName += this.props.additionalPopupClass;
		}

		if (this.props.open) {
			popupClassName += " is-visible";
		}
		if (VXConfig.isVXMobilePlatform) {
			popupClassName += " is-mobile";
		}

		return (
			<div className={popupClassName}>
				{!this.props.displaySuccessMessage ? (
					<form>
						<h2 className="feature-feedback__popup__header">{this.props.title}</h2>

						<FeatureFeedbackPopupRatingButtons selectedRating={this.props.selectedRating}
						                                   onSelectRating={this.onSelectRating}
						/>

						{this.props.selectedRating ? (
							<div>
								<FeatureFeedbackPopupTextArea text={this.props.text}
								                              onChange={this.onChangeText}
								                              maxLength={500}
								/>

								<div className="feature-feedback__popup__submit-button-div">
									<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
									          line1={[new VXButtonLineConfig(Translations.get('FeatureFeedbackSubmitBtn'))]}
									          onClick={this.onSubmit}
									/>
								</div>
							</div>
						) : null}
					</form>
				) : (
					<div className="feature-feedback__success">
						<i className="feature-feedback__success__icon icon -icon-round-success-line h-color-success"></i>
						<h2 className="feature-feedback__success__header"
						    dangerouslySetInnerHTML={{__html: Translations.get('FeatureFeedbackSuccessMessage')}}
						>
						</h2>
					</div>
				)}
			</div>
		);
	}

}

FeatureFeedbackPopup.propTypes = {
	open:                  PropTypes.bool,
	title:                 PropTypes.string,
	selectedRating:        PropTypes.string,
	text:                  PropTypes.string,
	displaySuccessMessage: PropTypes.bool,
	onSelectRating:        PropTypes.func,
	onChangeText:          PropTypes.func,
	onSubmit:              PropTypes.func,
	additionalClasses:     PropTypes.string,
	additionalPopupClass:  PropTypes.string,
};

export default FeatureFeedbackPopup;