/*global VXConfig*/
import React            from 'react';
import PropTypes        from 'prop-types';
import Flux             from "../../../flux/Flux";
import Translations     from '../../../utils/Translations';
import {formatCurrency} from '../../../utils/CommonUtils';
import Vip30ButtonBadge from "../../Vip30ButtonBadge";
import AlbumButtonBadgePrevius from "../AlbumButtonBadgePrevius";
import AlbumButtonBadgeCurrent from "../AlbumButtonBadgeCurrent";

function AlbumButtonBuy(props) {
	return (
		<div className="h-flex -content-center">
			{VXConfig.isVXMobilePlatform ?
				<div className={`${props.baseClassName}__button--buy -centered-buy-button`} onClick={props.onClick}>
					{props.isVip30 && Flux.Guest.isLoggedIn() && !Flux.Guest.isVip() && !VXConfig.isVXMobilePlatform && <Vip30ButtonBadge />}
					<div className={`${props.baseClassName}__icon--button icon -icon-cart-full`} />
					<div className={`${props.baseClassName}__text`}>
						<div className={`${props.baseClassName}__text--button`}>{props.isVip30 && VXConfig.isVip ? Translations.get('vip30Buy') : Translations.get('Buy')}</div>
						{props.hasActiveDiscount &&
					<span className="h-text-strike h-color-bg h-ml-5">{formatCurrency(props.crossPrice)}</span>}
						<span className={`${props.baseClassName}__text--price`}>{formatCurrency(props.price)}</span>
					</div>
					{Flux.Guest.isLoggedIn() && <AlbumButtonBadgeCurrent albumId={props.id} />}
					{Flux.Guest.isLoggedIn() && <AlbumButtonBadgePrevius albumId={props.id} />}
				</div>
				: <div className={`${props.baseClassName}__button--buy -centered-buy-button`} onClick={props.onClick}>
					{props.isVip30 && Flux.Guest.isLoggedIn() && !Flux.Guest.isVip() && !VXConfig.isVXMobilePlatform && <Vip30ButtonBadge />}
					{props.isVip30 && Flux.Guest.isLoggedIn() && VXConfig.isVip && !VXConfig.isVXMobilePlatform && <Vip30ButtonBadge />}
					<div className={`${props.baseClassName}__icon--button icon -icon-cart-full`} />
					<div className={`${props.baseClassName}__text--button`}>{props.isVip30 && VXConfig.isVip ? Translations.get('vip30Buy') : Translations.get('Buy')}</div>
					{props.hasActiveDiscount &&
					<span className="h-text-strike h-color-bg h-ml-5">{formatCurrency(props.crossPrice)}</span>}
					<span className={`${props.baseClassName}__text--price`}>{formatCurrency(props.price)}</span>
					{Flux.Guest.isLoggedIn() && <AlbumButtonBadgeCurrent albumId={props.id} />}
					{Flux.Guest.isLoggedIn() && <AlbumButtonBadgePrevius albumId={props.id} />}
				</div>
			}
		</div>
	);
}

AlbumButtonBuy.propTypes = {
	onClick:           PropTypes.func,
	baseClassName:     PropTypes.string,
	hasActiveDiscount: PropTypes.bool,
	crossPrice:        PropTypes.number,
	price:             PropTypes.number,
	isVip30:           PropTypes.bool,
	id:                PropTypes.number,
};

export default AlbumButtonBuy;
