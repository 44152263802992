import React     from 'react';
import PropTypes from 'prop-types';
import Flux      from '../../../flux/Flux';

function withDailybonusBadge(WrappedComponent) {
	class withDailybonusBadge extends React.Component {
		constructor(props) {
			super(props);
			this.state          = {
				is365Offer:         false,
				is365OfferPrevious: false,
			};
			this.onDailyBonuses = this.onDailyBonuses.bind(this);
		}

		componentDidMount() {
			Flux.Vxql.getDailyBounes(this.onDailyBonuses);
		}

		componentWillUnmount() {
			Flux.DailyBonus.removeLoadedListener(this.onDailyBonuses);
		}

		onDailyBonuses(result) {
			if (!result || !result.data || !result.data.dailyBonus) {
				return;
			}

			const checkId = (data, field, albumId) => data[field] && data[field].content && data[field].content.id === albumId;

			this.setState({
				is365Offer:         checkId(result.data.dailyBonus, 'current', this.props.albumId),
				is365OfferPrevious: checkId(result.data.dailyBonus, 'previous', this.props.albumId),
			});
		}

		render() {
			return <WrappedComponent {...this.state} {...this.props} />;
		}
	}

	withDailybonusBadge.propTypes = {
		albumId: PropTypes.number,
	};

	return withDailybonusBadge;
}

export default withDailybonusBadge;
