import React                 from 'react';
import PropTypes             from 'prop-types';
import WelcomeClipRotator    from '../WelcomeClipRotator/WelcomeClipRotator';

class ProSearchWelcomeClipRotatorTile extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const {className, contentBoxClassModifier, playlist} = this.props;
		let contentBoxClassName                              = 'content-box content-box--welcome-clip-rotator';

		if (contentBoxClassModifier) {
			contentBoxClassName += ' ' + contentBoxClassModifier;
		}

		contentBoxClassName += ' -padding-equal-smooth';

		const boxRationClassName = ' -ratio3_1';

		return (
			<div className={className}>
				<div className={contentBoxClassName}>
					<div className={'box--fixed-ratio' + boxRationClassName}>
						<div className="box--fixed-ratio__content">
							<WelcomeClipRotator initialPlaylist={playlist} />
						</div>
					</div>
				</div>
			</div>
		);

		//		return (
		//			<div className={this.props.className}
		//			     onClick={ProSearchWelcomeClipRotatorTile.onRedeemClick}>
		//				<div className={contentBoxClassName}>
		//					<figure className={"separator-tile -bonus-tile" + inTeasersClassName}>
		//						<div className={"separator-tile__content" + inTeasersClassName}>
		//							{Translations.get('TeaserBonusTileText1')}
		//							<span className={"separator-tile__teaser -bonus-tile" + inTeasersClassName}>
		//								{Translations.get('TeaserBonusTileText2') + ' + ' + Translations.get('TeaserBonusTileText3')}
		//								<div className={"separator-tile__teaser--subline" + inTeasersClassName}>
		//									{Translations.get('TeaserBonusTileText4')}
		//								</div>
		//								{Translations.get('TeaserBonusTileText5')}
		//							</span>
		//							<Button
		//								color="special-invert"
		//								effect="material"
		//								text={Translations.get('RedeemVoucher')}
		//								className={"btn--icon -icon-gift-line teaser__icon-button separator-tile__button" + inTeasersClassName}
		//							/>
		//						</div>
		//					</figure>
		//				</div>
		//			</div>
		//		);
	}

}

ProSearchWelcomeClipRotatorTile.propTypes = {
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	tileSubline:             PropTypes.string,
	isLandscape:             PropTypes.bool,
	playlist:                PropTypes.array,
};

ProSearchWelcomeClipRotatorTile.defaultProps = {
	className:               '',
	contentBoxClassModifier: '',
	tileSubline:             '',
	isLandscape:             false,
};

export default ProSearchWelcomeClipRotatorTile;


