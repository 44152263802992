import React     from 'react';
import PropTypes from 'prop-types';

function VideoPreview(props) {
	const css = {
		'backgroundImage': 'url(' + props.previewImageUrl + ')',
	};

	return (
		<div className="feature-teaser-box -video row">
			<div className="col-xs-12 feature-teaser-box__headline--arrow-full-video" style={css}>
				{props.children}
			</div>
		</div>);
}

VideoPreview.propTypes = {
	previewImageUrl: PropTypes.string.isRequired,
	children:        PropTypes.node,
};

export default VideoPreview;
