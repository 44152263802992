import React            from 'react';
import PropTypes        from 'prop-types';
import IconButton       from './IconButton';
import {seek15SecsBack} from "./Actions";

const Seek15SecsBack = (props) => (
		<IconButton icon="-icon-load-15sec-backward-line" onClick={() => seek15SecsBack(props.video)} />
	);

Seek15SecsBack.propTypes = {
	video: PropTypes.instanceOf(HTMLVideoElement).isRequired,
};

export default Seek15SecsBack;
