import React, {useState}                           from 'react';
import PropTypes                       from 'prop-types';
import VideoRecommendationsGridByAlbum from '../VideoRecommendations/VideoRecommendationsGridByAlbum';
import Translations                    from '../../utils/Translations';

const VideoPlayerRecommendations = (props) => {
	const [numberOfVideos, setNumberOfVideos] = useState(null);

	if (numberOfVideos === 0) {
		return null;
	}

	return <div className={'gallery__video-player__recommendations-container' + (props.isFullscreen ? ' -is-fullscreen' : '')}>
		<div className="gallery__video-player__recommendations-overlay" />
		<div className="gallery__video-player__recommendations-close-btn -icon-close-line" onClick={props.onCloseClick} />
		<div className="h-pos-relative">
			<div className="gallery__video-player__recommendations-headline">{Translations.get('VideoPlayerRecommendationsHeadline')}</div>
			<div className="gallery__video-player__recommendations-subheadline">{Translations.get('VideoPlayerRecommendationsSubheadline')}</div>
			<div className="gallery__video-player__recommendations-tiles-container">
				<VideoRecommendationsGridByAlbum albumId={props.albumId}
				                                 actorId={props.actorId}
				                                 actorName={props.actorName}
				                                 sameActor={true}
				                                 displayCount={3}
				                                 displayTooFewItemsTile={false}
												 videosCallback={setNumberOfVideos}
				/>
			</div>
		</div>
	</div>;
};

VideoPlayerRecommendations.propTypes = {
	actorId:      PropTypes.number,
	actorName:    PropTypes.string,
	albumId:      PropTypes.number,
	isFullscreen: PropTypes.bool,
	onCloseClick: PropTypes.func,
};

VideoPlayerRecommendations.defaultProps = {
	isFullscreen: false,
	onCloseClick: () => {
	},
};

export default VideoPlayerRecommendations;
