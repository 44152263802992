import React                                            from "react";
import PropTypes                                        from 'prop-types';
import Flux                                             from "../../flux/Flux";
import {VXPay}                                          from "../../utils/VXPay";
import Translations                                     from '../../utils/Translations';
import TileBadgeVip                                     from "./Badge/TileBadgeVip";
import ReloadHelper                                     from "../../utils/ReloadHelper";
import {VXButtonLineConfig}                             from "../SimpleElements/VXButton/VXButtonConfig";
import VXButton                                         from "../../components/SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DISABLED} from "../SimpleElements/VXButton/ButtonHelper";

function VipBadge() {
	return <span>
		<TileBadgeVip
			className={'badge--special tile-badge tile-badge-vip h-color-bg h-vertical-align-middle'}
			show={true}
		/>
		<span className={'h-vertical-align-middle'}>BONUS</span>
	</span>;
}

class ProSearchRewardBoxVideoTile extends React.Component {

	static getFormatedNumber(number) {
		return ("0" + number).slice(-2);
	}

	constructor(props) {
		super(props);

		this.state = this.getInitialState(this.props);

		this.init(this.props);

		this.onGetRewardClick          = this.onGetRewardClick.bind(this);
		this.onRedeemClick             = this.onRedeemClick.bind(this);
		this.onRedeemedPromoCodeChange = this.onRedeemedPromoCodeChange.bind(this);
		this.onRewardChange            = this.onRewardChange.bind(this);
		this.setCountDownTime          = this.setCountDownTime.bind(this);
		this.startCountDown            = this.startCountDown.bind(this);
		this.onVideoEnded              = this.onVideoEnded.bind(this);
		this.getExpirationTime         = this.getExpirationTime.bind(this);


		this.videoRef = null;
	}

	getInitialState(props) {
		return {
			reward:             props.reward,
			initialReward:      props.reward,
			videoHasEnded:      false,
			videoIsPlaying:     false,
			countDownHours:     null,
			countDownMinutes:   null,
			countDownSecounds:  null,
			showNextPrizeTimer: !(props.reward && this.getExpirationTime() === props.lastRewardExpTime),
		};
	}

	componentDidMount() {
		Flux.Special.addRewardChangeListener(this.onRewardChange);
		Flux.Promo.addHasNotRedeemedPromoCodeInstanceChangeListener(this.onRedeemedPromoCodeChange);

		if (this.countDownDate) {
			this.startCountDown();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.reward && !this.state.reward) {
			this.setState(this.getInitialState(nextProps), () => {
				if (this.countDownDate) {
					this.startCountDown();
				}
			});
			this.init(nextProps);
		}
	}

	componentWillUnmount() {
		Flux.Special.removeRewardChangeListener(this.onRewardChange);
		Flux.Promo.removeHasNotRedeemedPromoCodeInstanceChangeListener(this.onRedeemedPromoCodeChange);
	}

	init(props) {
		this.countDownDate = props.reward ? this.getExpirationTime(props.qmDate) : null;
		this.countDownId   = null;
		this.qmDate        = props.qmDate;
	}

	getExpirationTime(qmDate = this.props.qmDate) {
		let now = new Date();
		if (qmDate) {
			now = new Date(qmDate * 1000);
		}
		now.setHours(23, 59, 59);
		return now.getTime();
	}

	onRedeemedPromoCodeChange(promoCode, hasNotRedeemedPromoCode) {
		if (this.state.reward && promoCode === this.state.reward.optionCode && !hasNotRedeemedPromoCode) {
			// set reward as redeemed
			const reward     = {...this.state.reward};
			reward.canRedeem = false;

			// check if last reward and stop timer
			const showNextPrizeTimer = !(reward && this.getExpirationTime() === this.props.lastRewardExpTime);
			if (!showNextPrizeTimer && this.countDownId) {
				clearTimeout(this.countDownId);
			}
			this.setState({
				reward: reward,
				showNextPrizeTimer,
			});
		}
	}

	onGetRewardClick() {
		if (!Flux.Guest.isLoggedIn()) {
			VXPay.openSignupOrLogin();
		} else  {
			this.setState({videoIsPlaying: true}, () => {
				this.videoRef.play();
			});
		}
	}

	onVideoEnded() {
		Flux.Special.loadRewardBoxReward(this.props.eventId, this.qmDate);
	}

	onRedeemClick(e) {
		if (this.state.reward) {
			if (this.state.reward.canRedeem) {
				if (this.state.reward.optionCode) {
					if (this.state.reward.type === Flux.Constants.RewardTypes.VIP_ABO) {
						VXPay.openPromoCode({
							pc: this.state.reward.optionCode,
						});
					} else if (this.state.reward.type === Flux.Constants.RewardTypes.MERCH) {
						window.open('https://vstx.de/coupon/' + this.state.reward.optionCode);
					} else {
						VXPay.openPaytour({
							option: this.state.reward.optionCode,
						});
						VXPay.onMoneyChargeSuccess(() => {
							Flux.Promo.hasGuestNotRedeemedOnTopCodeInstanceByName(this.state.reward.optionCode);
						});
					}
				} else if (this.state.reward.type === Flux.Constants.RewardTypes.VIP_BONUS && !Flux.Guest.isVip()) {
					VXPay.openAboPaytour();
				}
			} else {
				switch (this.state.reward.type) {
					case Flux.Constants.RewardTypes.VIDEO:
					case Flux.Constants.RewardTypes.PHOTO_ALBUM:
						ReloadHelper.reload(this.state.reward.album.url, e);
						break;
					case Flux.Constants.RewardTypes.MESSENGER_GIFT:
						ReloadHelper.reload(this.state.reward.rewardLink);
						break;
					case Flux.Constants.RewardTypes.MERCH:
						window.open(this.state.reward.rewardLink, '_blank');
						break;
					// No default
				}
			}
		}
	}

	onRewardChange() {
		const reward = Flux.Special.getReward();

		this.countDownDate = reward && this.getExpirationTime() ? new Date(this.getExpirationTime()).getTime() : null;
		if (this.countDownDate) {
			this.startCountDown();
		}

		this.setState({
			reward:    reward,
		});
	}

	setCountDownTime() {
		let now = new Date().getTime();

		if (this.qmDate) {
			now = new Date(this.qmDate * 1000).getTime();
		}

		const distance = this.countDownDate - now;

		const hours   = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);

		this.setState({
			countDownHours:    ProSearchRewardBoxVideoTile.getFormatedNumber(Math.max(0, hours)),
			countDownMinutes:  ProSearchRewardBoxVideoTile.getFormatedNumber(Math.max(0, minutes)),
			countDownSecounds: ProSearchRewardBoxVideoTile.getFormatedNumber(Math.max(0, seconds)),
		});

		this.qmDate = this.qmDate ? this.qmDate + 1 : this.qmDate; // manually add time to qmDate because unlike the real time it does not change

		return distance >= 0;
	}

	startCountDown() {
		this.setCountDownTime();
		this.countDownId = setInterval(() => {

			const countDownStop = !this.setCountDownTime();

			if (countDownStop) {
				clearInterval(this.countDownId);
				// only reset reward if its not the last reward
				if (this.state.reward && this.getExpirationTime() !== this.props.lastRewardExpTime) {
					this.setState({
						reward:            null,
						countDownHours:    null,
						countDownMinutes:  null,
						countDownSecounds: null,
					});
				}
			}
		}, 1020);
	}

	setRewardRef(ref) {
		if(ref) {
			setTimeout(() => {
			ref.classList.remove("reward-box__container__content--scale-hide");
		}, 500);

		}
	}


	render() {
		let contentBoxClassName = 'content-box';

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		let sublineText      = '';
		let bonusContextText = Translations.get('RewardBoxTileOnYourCharge');
		let buttonRedeemText = Translations.get('RewardBoxTileRedeemBonus');
		let bonusText        = this.state.reward && this.state.reward.bonus;
		let aboveText        = this.state.reward && this.state.reward.additionalInfo === 'isUpTo' ? Translations.get('TeaserBonusTileText3') : '';
		let rewardImage      = null;
		let rewardImageBadge = null;
		let overrideContent  = null;

		if (this.state.reward) {
			switch (this.state.reward.type) {
				case Flux.Constants.RewardTypes.VIP_BONUS: {
					sublineText = <VipBadge />;

					break;
				}
				case Flux.Constants.RewardTypes.MERCH: {
					sublineText      = Translations.get('EasternMerchandiseCode') + ':';
					bonusContextText = this.state.reward.optionCode;

					overrideContent = (
						<div className="reward-box__container__content">
							<div className="reward-box__container__content__merch-bonus">
								{this.state.reward.bonus}
							</div>
							<div className="reward-box__container__content__merch-info">
								auf Merchandise
							</div>

							<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
							          line1={[new VXButtonLineConfig(buttonRedeemText)]}
							          onClick={this.onRedeemClick}
							/>

							<div className={"reward-box__container__content__merch-footer"}>
								mit dem Code: <span className={"h-text-bold"}>{bonusContextText}</span>
							</div>
						</div>
					);


					break;
				}
				case Flux.Constants.RewardTypes.VIDEO: {
					buttonRedeemText = Translations.get('RewardBoxTileWatchVideo');
					bonusContextText = Translations.get('By').toLowerCase() + ' ' + this.state.reward.album.actorName;
					bonusText        = Translations.get('Clip');
					aboveText        = Translations.get('Free');
					sublineText      = this.state.reward.additionalInfo;

					break;
				}
				case Flux.Constants.RewardTypes.PHOTO_ALBUM: {
					buttonRedeemText = Translations.get('RewardBoxTileWatchPhotos');
					bonusContextText = Translations.get('By').toLowerCase() + ' ' + this.state.reward.album.actorName;
					bonusText        = Translations.get('common.photos');
					aboveText        = Translations.get('Free');
					sublineText      = this.state.reward.additionalInfo;

					break;
				}
				case Flux.Constants.RewardTypes.MESSENGER_GIFT: {
					bonusContextText = Translations.get('RewardMessengerGift');
					sublineText      = this.state.reward.additionalInfo;
					buttonRedeemText = Translations.get('DailyBonusControllerGiveAwayGifts');
					rewardImage      = this.state.reward.rewardImage;

					break;
				}
				case Flux.Constants.RewardTypes.ONTOP_GIFT: {
					bonusContextText = Translations.get('RewardMessengerGift');
					sublineText      = this.state.reward.additionalInfo;
					rewardImage      = this.state.reward.rewardImage;
					rewardImageBadge = this.state.reward.rewardImageBadge;
					break;
				}
				case Flux.Constants.RewardTypes.VIP_ABO: {
					bonusContextText = '';
					sublineText      = Translations.get('VIPClub');

					break;
				}
				// No default
			}
		}

		const excludeCanRedeemList = [Flux.Constants.RewardTypes.VIDEO, Flux.Constants.RewardTypes.PHOTO_ALBUM, Flux.Constants.RewardTypes.MESSENGER_GIFT, Flux.Constants.RewardTypes.MERCH];

		return (
			<div className={this.props.className}>
				<div className={contentBoxClassName}>
					<figure className={"reward-box " + (this.props.isMobile ? '-mobile' : '')}>
						<div className="reward-box__container">

							{!this.state.initialReward && <div className={"reward-box__container -background " + this.props.rewardBoxBgModifier} style={{backgroundImage: 'url(' + this.props.rewardBoxStartImgUrl + ')'}} onClick={this.onGetRewardClick}>
								<div className="reward-box__container__content-content">
									<img className="reward-box__container__content-logo" src={this.props.rewardBoxLogoUrl} />
									<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
										          line1={[new VXButtonLineConfig(Translations.get('common.open-and-win'))]}
										          onClick={() => {}}
										/>
								</div>
							</div>}

							{(!this.state.initialReward && Flux.Guest.isLoggedIn()) && <div className={"reward-box__container -background h-cursor-pointer " + (this.state.videoIsPlaying ? '' : '-hide ') + (this.props.rewardBoxBgModifier)}>
								<video className="reward-box__container__content-video" playsInline={true} src={this.props.rewardBoxVideoUrl} ref={ref => this.videoRef = ref} onEnded={this.onVideoEnded}/>
							</div>}

							<div style={{backgroundImage: 'url(' + this.props.rewardBoxImgUrl + ')'}} className={"reward-box__container -bg-opened " + (this.state.reward && (this.state.reward.canRedeem || excludeCanRedeemList.includes(this.state.reward.type)) ? '' : '-hide ') + (this.props.rewardBoxBgModifier)}>
								{<div className="reward-box__container__countdown">{Translations.get('RewardBoxTileValidUntil')}
									<span className="h-text-highlight"> {this.state.countDownHours + ":" + this.state.countDownMinutes + ":" + this.state.countDownSecounds}</span>
								</div>}
								{overrideContent}
								{!overrideContent && this.state.reward &&
									<div className={"reward-box__container__content" + (this.props.withScaleEffect ? " reward-box__container__content--scale-hide" : "")} ref={ref => this.setRewardRef(ref)}>
										{rewardImage && <span>
											<img className={"reward-box__container__content__above-image"} src={rewardImage} />
											{rewardImageBadge && <span className={'reward-box__container__content__above-image__badge badge badge--highlight'}>{rewardImageBadge}</span>}
										</span>}
										{aboveText && <div className="reward-box__container__content__above-text">{aboveText}</div>}
										<div
											className={"reward-box__container__content__bonus " + ((!sublineText && !aboveText) ? '-large' : '')}>{bonusText}</div>
										{sublineText && this.state.reward.type !== Flux.Constants.RewardTypes.VIDEO &&
											<div className="reward-box__container__content__subline">
												{sublineText}
											</div>
										}
										<div className="reward-box__container__content__bonus-context">
											{bonusContextText}
										</div>
										<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
										          line1={[new VXButtonLineConfig(buttonRedeemText)]}
										          onClick={this.onRedeemClick}
										/>
									</div>
								}
							</div>
							<div style={{backgroundImage: 'url(' + this.props.rewardBoxImgUrl + ')'}} className={"reward-box__container -bg-redeemed " + (this.state.reward && !this.state.reward.canRedeem && !excludeCanRedeemList.includes(this.state.reward.type) ? '' : '-hide ') + (this.props.rewardBoxBgModifier)}>
								{this.state.showNextPrizeTimer &&
									<div className="reward-box__container__countdown">{Translations.get('RewardBoxTileNewGiftIn')}
										<span
											className="h-text-highlight"> {this.state.countDownHours + ":" + this.state.countDownMinutes + ":" + this.state.countDownSecounds}</span>
									</div>}
								<div className="reward-box__container__btn-container">
									<VXButton color={COLOR_TYPE_DISABLED}
									          line1={[new VXButtonLineConfig(Translations.get('RewardBoxTileAlreadyRedeemed'))]}
									          disabled={true}
									/>
								</div>
							</div>
						</div>
					</figure>
				</div>
			</div>
		);
	}

}


ProSearchRewardBoxVideoTile.propTypes = {
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	eventId:                 PropTypes.number.isRequired,
	isMobile:                PropTypes.bool,
	lastRewardExpTime:       PropTypes.number.isRequired,
	qmDate:                  PropTypes.number,
	reward:                  PropTypes.object,
	rewardBoxBgModifier:     PropTypes.string.isRequired,
	rewardBoxSpecialImg:     PropTypes.string,
	rewardBoxVideoUrl:       PropTypes.string,
	rewardBoxStartImgUrl:    PropTypes.string,
	rewardBoxImgUrl:         PropTypes.string,
	rewardBoxLogoUrl:        PropTypes.string,
	withScaleEffect:         PropTypes.bool,
};

ProSearchRewardBoxVideoTile.defaultProps = {
	className:               '',
	contentBoxClassModifier: '-padding-equal-smooth',
	isMobile:                false,
	qmDate:                  0,
	reward:                  null,
	withScaleEffect:         false,
};

export default ProSearchRewardBoxVideoTile;
