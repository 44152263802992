import React      from 'react';
import IconButton from './IconButton';
import PropTypes  from 'prop-types';

const LandscapeZoom = (props) => {
	const {	onClick, checked } = props;

	return <IconButton icon={'-icon-fullscreen-weight-player-open-line'} checkedIcon={'-icon-fullscreen-weight-player-close-line'} onClick={onClick} checked={checked} />;
};

LandscapeZoom.propTypes = {
	onClick: PropTypes.func,
	checked: PropTypes.bool,

};

export default LandscapeZoom;
