/* global VXConfig */
import React                       from 'react';
import Modal                       from '../Modal';
import Flux                        from "../../flux/Flux";
import ModalBoxVideosGrid          from "./ModalBoxVideosGrid";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import ReloadHelper                from "../../utils/ReloadHelper";
import UrlBuilder                  from "../../utils/UrlBuilder";
import STYLES                      from "../SimpleElements/VXButton/EnumVXButton";
import Translations                from '../../utils/Translations';

export default class ModalBoxCalendar2022 extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: VXConfig.isVXMobilePlatform,
        };

        this.onClickLink = this.onClickLink.bind(this);
        this.closeModal  = this.closeModal.bind(this);
        this.getLink     = this.getLink.bind(this);

        this.modalRef    = null;
    }

    componentDidMount() {
        if (Flux.Guest.isLoggedIn()) {
            const urlParams = new URL(window.location.href);
            urlParams.searchParams.delete('openModal');
            window.history.replaceState({}, '', urlParams.toString());
        }
    }

    getFilter() {
		const filter = {ids: [2193755, 2193757, 2193759, 2193761], types:[{bought: true}]};

        return filter;
	}

    onClickLink(e) {
        this.closeModal();
        ReloadHelper.reload(this.getLink(), e);
    }

    getLink() {
        return UrlBuilder.buildMyVisitXGiftedVideosRoute();
    }
    
    closeModal() {
        this.modalRef.removeModal();
    }
    
    setModalRef(ref) {
        if (ref) {
            this.modalRef = ref;
        }
    }

    getTranslation(key) {
        return Translations.get(`_event-vxcalendar-2022.${key}`);
    }

    render() {
        if (!Flux.Guest.isLoggedIn()) {
            return null;
        }

        return (
            <Modal className="vxcalendar__modal" modalClassName="vxcalendar__modal-wrapper" ref={(ref) => this.setModalRef(ref)} onClose={this.removeParam}>
                <div className="">
                    <img className="vxcalendar__modal-logo" src="/assets/img/special/vx-calendar/vx-calendar-2022/header-middle-logo.svg" alt="" />
                    <div className="vxcalendar__modal-text">{this.getTranslation('popup_signup+loggedin_desktop_1')}</div>
                    <div className="vxcalendar__modal-headline">{this.state.isMobile ? this.getTranslation('popup_loggedin_mobile_2') : this.getTranslation('popup_loggedin_desktop_2')}</div>
                    <div className="vxcalendar__modal-videos">
                        <ModalBoxVideosGrid filter={this.getFilter()} isMobile={this.state.isMobile} linkVX={this.getLink()} callback={this.onClickLink}/>
                    </div>
                    <div className="vxcalendar__modal-button">
                        <VXButton color={COLOR_TYPE_CALL_TO_ACTION}
						          line1={[new VXButtonLineConfig(this.getTranslation('popup_loggedin_desktop_CTA'))]}
						          icon={"-icon-single-arrow-right-line"}
						          iconPosition={STYLES.ICON_POSITION.RIGHT}
						          onClick={this.onClickLink}
                                  size={this.state.isMobile ? STYLES.SIZE.FULL : STYLES.SIZE.SMALL}
						/>
                    </div>
                    <div onClick={this.closeModal} className="vxcalendar__modal-link">{this.getTranslation('popup_loggedin_desktop_footercloser')}</div>
                </div>                
            </Modal>
        );
    }
}
