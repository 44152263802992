import React        from 'react';
import Flux         from "../../flux/Flux";
import PropTypes    from 'prop-types';
import Modal        from '../Modal';
import Translations from "../../utils/Translations";
import ReloadHelper from "../../utils/ReloadHelper";
import UrlBuilder   from "../../utils/UrlBuilder";

class ModalBoxTagReward extends React.Component {

	constructor(props) {
		super(props);

		this.videoUrl = 'https://vx.vxcdn.org/vxtvdownload/sat_videos/TVMediathek/transcoded/Webvideos/2109/2109_1920_special-visitx_divers_f12_01.1080p.mp4';

		this.state = {
			videoSrc:    Flux.Browser.getPreloadedMediaUrl(this.videoUrl, false),
			showContent: props.isMobile,
		};

		this.videoRef              = null;
		this.hasTimeupdateListener = false;

		this.onTimeupdate           = this.onTimeupdate.bind(this);
		this.onPreloadedMediaChange = this.onPreloadedMediaChange.bind(this);
		this.onRedeemClick          = this.onRedeemClick.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addPreloadedMediaChangeListener(this.onPreloadedMediaChange);

		if (!this.state.videoSrc) {
			Flux.Browser.preloadMedia(this.videoUrl, false);
		}
	}

	componentDidUpdate() {
		if (this.videoRef && !this.hasTimeupdateListener) {
			this.hasTimeupdateListener = true;
			this.videoRef.addEventListener('timeupdate', this.onTimeupdate);
		}
	}

	componentWillUnmount() {
		Flux.Browser.removePreloadedMediaChangeListener(this.onPreloadedMediaChange);
		if (this.videoRef) {
			this.videoRef.removeEventListener('timeupdate', this.onTimeupdate);
		}
	}

	onTimeupdate() {
		if (!this.state.showContent && this.videoRef.currentTime > 1) {
			this.setState({
				showContent: true,
			});
		}
	}

	onRedeemClick(e) {
//		this.props.onRedeemClick();
		ReloadHelper.reload(UrlBuilder.buildMessengerRoute(), e);
		this.props.onClose();
	}

	onPreloadedMediaChange(url) {
		if (url === this.videoUrl) {
			this.setState({
				videoSrc: Flux.Browser.getPreloadedMediaUrl(url),
			});
		}
	}


	render() {
		if (this.state.videoSrc || this.props.isMobile) {
			return (
				<Modal
					className={"-theme-dialog -no-padding h-center tag-reward-modal__modal-container" + (this.props.isMobile ? " -mobile" : '')}
					dynamicSize={true}
					hideCloseButton={false}
					onClose={this.props.onClose}
				>
					{!this.props.isMobile && <div>


						{ <video className={"tag-reward-modal__video " + ' tag-reward-modal__video--width'} ref={ref => this.videoRef = ref} autoPlay muted>
							<source src={this.videoUrl}
							        type={"video/mp4"}
							/>
						</video>
						}

					</div>}
					<div className={"tag-reward-modal__content-container " + (this.state.showContent ? '-show ' : '') + (this.props.isMobile ? " -mobile" : '')}>
						<div className="tag-reward-modal__content-container__headline">{this.props.headline}</div>
						<div className="tag-reward-modal__content-container__subline">{this.props.subline}</div>
						<img className="tag-reward-modal__content-container__bonus-img" src={this.props.imageUrl}/>
						<div className="tag-reward-modal__content-container__bonus-name">{this.props.bonusName}</div>
						<div className="tag-reward-modal__content-container__bonus-subline">{this.props.bonusSubline}</div>
						<button onClick={this.onRedeemClick} className="tag-reward-modal__content-container__btn btn -btn-color-special h-text-transform-none">
							{Translations.get('RewardBoxTileRedeemBonus')}
						</button>
						<div className="tag-reward-modal__content-container__close-text" onClick={this.props.onClose}>{Translations.get('RewardBoxModalCloseText')}
						</div>
					</div>
				</Modal>
			);
		}
		return null;
	}
}

ModalBoxTagReward.defaultProps = {
	onClose:       () => {
	},
};

ModalBoxTagReward.propTypes = {
	onClose:            PropTypes.func,
	headline:           PropTypes.string,
	subline:            PropTypes.string,
	bonusName:          PropTypes.string,
	bonusSubline:       PropTypes.string,
	imageUrl:           PropTypes.string,
	isMobile:           PropTypes.bool,
};

export default ModalBoxTagReward;
