import React               from 'react';
import PropTypes           from 'prop-types';
import IconButton          from './IconButton';
import {seek15SecsForward} from "./Actions";

const Seek15SecsForward = (props) => (
		<IconButton icon="-icon-load-15sec-forward-line" onClick={() => seek15SecsForward(props.video)} />
	);

Seek15SecsForward.propTypes = {
	video: PropTypes.instanceOf(HTMLVideoElement).isRequired,
};

export default Seek15SecsForward;
