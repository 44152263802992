import React, {useState}                                        from "react";
import Flux                                                     from "../../flux/Flux";
import VXButton                                                 from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION}                              from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}                                     from "../SimpleElements/VXButton/VXButtonConfig";
import STYLES                                                   from "../SimpleElements/VXButton/EnumVXButton";
import VXButtonOutline                                          from "../SimpleElements/VXButton/Presets/VXButtonOutline";
import {addPreventScrolling, removePreventScrolling, setCookie} from '../../utils/CommonUtils';
import TermsOfService                                           from "../TermsOfService";
import DataProtection                                           from "../DataProtection/DataProtection";

const onDeny = () => window.location.href = "https://google.es";

export default function ModalboxEsAVS() {
	addPreventScrolling();

	const onConfirm = () => {
		setCookie(Flux.Constants.CookieNames.FORCED_AVS, 1, 30, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
		removePreventScrolling();
		window.location.reload();
	};

	const intro = <React.Fragment><img src={Flux.Browser.isSexole() ? "/assets/img/sexole/sexole_x_visit-x_logo.svg" : "/assets/img/logo2.svg"}
	                                   className="avs-modal__logo" />
		<div className="avs-modal__headline">Verificación de mayoría de edad</div>
		<div className="avs-modal__text">Este sitio web contiene material sexualmente explícito exclusivo para adultos. Si tienes menos de 18 años o eres menor de edad en el país desde el que intentas acceder, no tienes permiso para entrar en este sitio web ni acceder a su contenido.</div>
		<div className="avs-modal__subline">Al pulsar "Entrar", confirmas que eres mayor de edad y aceptas nuestros <span onClick={() => setSite(terms)}>Términos y condiciones</span>, y nuestra <span
			onClick={() => setSite(data)}>Política de privacidad</span>.
		</div>
		<div className="avs-modal__button">
			<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
			          line1={[new VXButtonLineConfig("Entrar")]}
			          onClick={onConfirm}
			          size={STYLES.SIZE.SMALL}
			/>
			<VXButtonOutline
				line1={[new VXButtonLineConfig("Salir")]}
				onClick={onDeny}
				size={STYLES.SIZE.SMALL}
			/>
		</div>
	</React.Fragment>;

	const closeButton = <i onClick={() => setSite(intro)} className="icon -icon-close-full avs-modal__close"></i>;
	const terms       = <div className="avs-modal__scroll-wrapper"> {closeButton} <TermsOfService /></div>;
	const data        = <div className="avs-modal__scroll-wrapper"> {closeButton} <DataProtection /></div>;

	const [site, setSite] = useState(intro);


	return <div className="avs-modal__wrapper">
		<div className="avs-modal">
			{site}
		</div>
	</div>;
}
