/* global VXConfig */
import React               from 'react';
import PropTypes           from 'prop-types';
import Flux                from '../../../flux/Flux';
import {getCompleteDataset} from "../../../utils/CommonUtils";

const CONTAINER_ID = 'video-container';
const CONTENT_ID   = 'video-content';

function withVideos(WrappedComponent) {

	class withVideos extends React.Component {
		constructor(props) {
			super(props);
			this.schemaScript = null;
			this.scrollPosition = Flux.Browser.getLocalItem(this.props.id + 'scrollPosition') || 0;
			this.state = {
				videos: [],
				loading: true,
			};

			this.onVideoDataChange  = this.onVideoDataChange.bind(this);
			this.onVideoDataLoad    = this.onVideoDataLoad.bind(this);
			this.getVideosFromStore = this.getVideosFromStore.bind(this);
			this.loadVideos         = this.loadVideos.bind(this);
			this.setVideos          = this.setVideos.bind(this);
			this.setVideoTotalCount = this.setVideoTotalCount.bind(this);
			this.onScroll           = this.onScroll.bind(this);
		}

		componentDidMount() {
			Flux.VideoV2.addVideoDataChangeListener(this.onVideoDataChange);
			Flux.VideoV2.addVideoDataLoadListener(this.onVideoDataLoad);

			this.loadVideos();

			if (VXConfig.device.isMobile) {
				const container = document.getElementById(CONTAINER_ID);
				container ? container.scrollTop = this.scrollPosition : null;
				Flux.VXMobile.addContainerScrollListener(CONTAINER_ID, CONTENT_ID, this.onScroll);
			}

			const videos = (VXConfig.device.isMobile ? this.getVideos() : this.getVideosFromStore()) || [];
			this.setState({ videos });
		}

		componentDidUpdate(prevProps) {
			if (VXConfig.device.isMobile) {
				const container = document.getElementById(CONTAINER_ID);
				container ? container.scrollTop = this.scrollPosition : null;
			}
			if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
				this.loadVideos();
			}
		}

		componentWillUnmount() {
			if (this.schemaScript) {
				this.schemaScript.remove();
			}

			Flux.VideoV2.removeVideoDataChangeListener(this.onVideoDataChange);
			Flux.VideoV2.removeVideoDataLoadListener(this.onVideoDataLoad);

			if (VXConfig.device.isMobile) {
				Flux.Browser.setLocalItem(this.props.id + 'scrollPosition', this.scrollPosition);
				Flux.VXMobile.removeContainerScrollListener(CONTAINER_ID, CONTENT_ID, this.onScroll);
			}
		}

		onVideoDataLoad() {
			const loading = Flux.VideoV2.isVideoDataLoading(this.props.filter, this.props.order);
			this.setState({
				loading,
			});
		}

		loadVideos() {
			const videos = this.getVideosFromStore();
			const loading = Flux.VideoV2.isVideoDataLoading(this.props.filter, this.props.order);

			if (!loading) {
				if (!videos) {
					Flux.VideoV2.loadVideos(this.props.filter, this.props.order, this.props.count, this.props.offset, this.props.id);
				} else {
					const currentVideos = JSON.parse(JSON.stringify(this.state.videos));
					const newVideos     = [];

					if (this.props.offset === 0  || !this.props.isLoadMore) {
						this.setVideos(videos, loading);
					} else {
						videos.map(video => {
							if (!currentVideos.some(currentVideo => currentVideo.id === video.id)) {
								newVideos.push(video);
							}

						});
						this.setVideos(newVideos, loading);
					}
				}
			}
		}

		onVideoDataChange(id) {
			if (id !== this.props.id) {
				return;
			}

			const videos      = this.getVideosFromStore() || [];
			const loading     = (videos ? false : this.state.loading);
			const currentVideos = JSON.parse(JSON.stringify(this.state.videos));
			const newVideos     = [];

			if (!currentVideos || currentVideos.length === videos.length || this.props.offset === 0) {
				this.setVideos(videos, loading, id);
			} else {
				videos.map(video => {
					if (!currentVideos.some(currentVideo => currentVideo.id === video.id)) {
						newVideos.push(video);
					}
				});

				this.setVideos(newVideos, loading, id);
			}
		}

		setVideos(videos, loading) {
			if (videos) {
				if (this.schemaScript) {
					this.schemaScript.remove();
				}

				const schema = [];
				for (let i = 0; videos.length > i; i++) {
					if (videos[i].schema) {
						const videoSchema = videos[i].schema;
						schema.push(JSON.parse(videoSchema));
					}
				}

				if (schema.length > 0) {
					const script = document.createElement('script');
					script.setAttribute('type', 'application/ld+json');
					script.textContent = JSON.stringify(schema);
					this.schemaScript  = script;
					document.head.appendChild(script);
				}
				if (this.props.isLoadMore && this.state.videos) {
						videos = [ ...this.state.videos, ...videos];
					}
			}
			this.setVideoTotalCount();
			this.setState(() => ({videos, loading}));
		}

		setVideoTotalCount() {
			if (typeof this.props.setVideoTotalCount === 'function') {
				const videoTotalCount = Flux.VideoV2.getVideoTotalCount(this.props.filter, this.props.order);
				this.props.setVideoTotalCount(videoTotalCount);
			}
		}

		getVideosFromStore() {
			const getItems = () => Flux.VideoV2.getVideos(this.props.filter, this.props.order);

			const getTotalCount = () => Flux.VideoV2.getVideoTotalCount(this.props.filter, this.props.order);

			return getCompleteDataset(getItems, getTotalCount, this.props.count, this.props.offset);
		}

		onScroll(pos) {
			this.scrollPosition = pos;
		}

		getVideos() {
			const items = Flux.VideoV2.getVideos(this.props.filter, this.props.order);

			if (items && Object.values(items).length > this.props.count) {
				return Object.values(items);
			}

			return this.getVideosFromStore();
		}

		render() {
			return <WrappedComponent {...this.state} {...this.props} />;
		}
	}

	withVideos.propTypes    = {
		filter: PropTypes.shape({
			modelIds:      PropTypes.arrayOf(
				PropTypes.number,
			),
			actingGroups:  PropTypes.arrayOf(
				PropTypes.oneOf([
					Flux.Constants.Vxql.Videos.ActingGroups.FEMALES,
					Flux.Constants.Vxql.Videos.ActingGroups.MALES,
					Flux.Constants.Vxql.Videos.ActingGroups.COUPLES,
					Flux.Constants.Vxql.Videos.ActingGroups.LESBIANS,
					Flux.Constants.Vxql.Videos.ActingGroups.GAYS,
				])
			),
			ids:           PropTypes.arrayOf(
				PropTypes.number,
			),
			tags:          PropTypes.arrayOf(
				PropTypes.string,
			),
			releasedFrom:  PropTypes.instanceOf(Date),
			releasedUntil: PropTypes.instanceOf(Date),
			types:         PropTypes.arrayOf(PropTypes.shape({
				free:     PropTypes.bool,
				premium:  PropTypes.bool,
				discount: PropTypes.bool,
				vip:      PropTypes.bool,
				bought:   PropTypes.bool,
				trending: PropTypes.bool,
				vxselect: PropTypes.bool,
			})),
		}),
		order:  PropTypes.oneOf([
			Flux.Constants.Vxql.Videos.SortOrders.REDUCED_RANDOM,
			Flux.Constants.Vxql.Videos.SortOrders.NEWEST,
			Flux.Constants.Vxql.Videos.SortOrders.MOST_EXPENSIVE,
			Flux.Constants.Vxql.Videos.SortOrders.CHEAPEST,
			Flux.Constants.Vxql.Videos.SortOrders.LONGEST,
			Flux.Constants.Vxql.Videos.SortOrders.SHORTEST,
			Flux.Constants.Vxql.Videos.SortOrders.BEST_SELLING,
			Flux.Constants.Vxql.Videos.SortOrders.TOP_RATED,
			Flux.Constants.Vxql.Videos.SortOrders.TOP_TRENDING,
		]),
		offset:             PropTypes.number,
		count:              PropTypes.number,
		isLoadMore:         PropTypes.bool,
		id:                 PropTypes.string,
		setVideoTotalCount: PropTypes.func,
	};
	withVideos.defaultProps = {
		filter: {
			modelIds:      null,
			actingGroups:  null,
			ids:           null,
			tags:          null,
			releasedFrom:  null,
			releasedUntil: null,
			types:         null,
		},
		order:  Flux.Constants.Vxql.Videos.SortOrders.NEWEST,
		offset: 0,
		count:  5,
		id:     Math.random().toString(),
	};

	return withVideos;
}

export default withVideos;
