import React               from 'react';
import PropTypes           from 'prop-types';
import ActorPin            from './../Favorite/Actor/Pin';
import Flux                from '../../flux/Flux';
import Translations        from './../../utils/Translations';
import {VXPay}             from './../../utils/VXPay';
import ReloadHelper        from './../../utils/ReloadHelper';
import PictureBoxes        from './PictureBoxes';
import VideochatButton     from '../SimpleElements/VideochatButton';
import {formatCurrency}    from '../../utils/CommonUtils';
import DildocontrolOverlay from "./DildocontrolOverlay";

// To only trigger one rest call and not one in everyTile
let notificationsRetrievalsTotalCount = 0;
let isPopUpNotificationActive         = false;

class FavoriteExtendedTile extends React.Component {

	static getNotificationsSettings() {
		if (notificationsRetrievalsTotalCount === 0) {
			Flux.Guest.getNotificationsSettings(this.onNotificationsSettingsSuccess, this.onNotificationsSettingsFail);
			notificationsRetrievalsTotalCount = 1;
		}
	}

	static openVXPaySettings() {
		VXPay.openSettings({openNotificationsTab: true});
		// wait for settings close event
		VXPay.onSettingsClose(function() {
			notificationsRetrievalsTotalCount = 0;
			isPopUpNotificationActive         = true;
			FavoriteExtendedTile.getNotificationsSettings();
		}.bind(this));
	}

	constructor(props) {
		super(props);

		this.state = {
			isEmailNotificationEnabled:    this.props.isEmailNotificationEnabled,
			isSmsNotificationEnabled:      this.props.isSmsNotificationEnabled,
			showSettingsNotificationPopUp: false,
			notificationsSettings:         Flux.Guest.getNotificationSettings(),
			popUpNotificationDescription:  '',
			isOnline:                      null,
		};

		this.onClick                        = this.onClick.bind(this);
		this.onNewElementClick              = this.onNewElementClick.bind(this);
		this.onToggleEmailNotification      = this.onToggleEmailNotification.bind(this);
		this.onToggleSMSNotification        = this.onToggleSMSNotification.bind(this);
		this.onOpenMessengerClick           = this.onOpenMessengerClick.bind(this);
		this.onNotificationsSettingsSuccess = this.onNotificationsSettingsSuccess.bind(this);
		this.onNotificationsSettingsFail    = this.onNotificationsSettingsFail.bind(this);
		this.hideNotificationPopUp          = this.hideNotificationPopUp.bind(this);
		this.onActorDataChange              = this.onActorDataChange.bind(this);
	}

	componentWillMount() {
		FavoriteExtendedTile.getNotificationsSettings();
	}

	componentDidMount() {
		Flux.Favorite.addActorsChangeListener(this.onActorDataChange);
		Flux.Guest.addNotificationsSettingsReceivedListener(this.onNotificationsSettingsSuccess);
		window.addEventListener('scroll', this.hideNotificationPopUp);
	}

	componentWillUnmount() {
		Flux.Favorite.addActorsChangeListener(this.onActorDataChange);
		Flux.Guest.removeNotificationsSettingsReceivedListener(this.onNotificationsSettingsSuccess);
		window.removeEventListener('scroll', this.hideNotificationPopUp);
	}

	onActorDataChange() {
		const actors = Flux.Favorite.getActors();

		for (let i = 0; i < actors.length; i++) {
			if (actors[i].id === this.props.actorId) {
				this.setState({isOnline: actors[i].isOnline});
			}
		}
	}

	onClick(e) {
		ReloadHelper.reload(this.props.targetLink, e);
	}

	onNewElementClick(e) {
		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.newElementTarget, e);
		}
	}

	onNotificationsSettingsSuccess() {
		this.setState({
			notificationsSettings: Flux.Guest.getNotificationSettings(),
		});
	}

	onNotificationsSettingsFail() {
		this.setState({notificationsSettings: false});
	}

	onToggleEmailNotification() {
		if (this.state.notificationsSettings.isFavoriteOnline) {
			Flux.Favorite.setPinnedActorEmailNotification(this.props.actorId, !this.state.isEmailNotificationEnabled);
			this.setState({
				isEmailNotificationEnabled: !this.state.isEmailNotificationEnabled,
			});
		} else {
			if (!isPopUpNotificationActive) {
				this.setState({
					showSettingsNotificationPopUp: !this.state.showSettingsNotificationPopUp,
					popUpNotificationDescription:  Translations.get('FavoriteActorBoxActivateNotificationsEmailDescription'),
				});
				isPopUpNotificationActive = true;
			}
			if (this.state.showSettingsNotificationPopUp !== Translations.get('FavoriteActorBoxActivateNotificationsEmailDescription')) {
				this.setState({
					popUpNotificationDescription: Translations.get('FavoriteActorBoxActivateNotificationsEmailDescription'),
				});
			}
		}
	}

	onToggleSMSNotification() {
		if (this.state.notificationsSettings.isFavoriteOnline) {
			Flux.Favorite.setPinnedActorSMSNotification(this.props.actorId, !this.state.isSmsNotificationEnabled);
			this.setState({
				isSmsNotificationEnabled: !this.state.isSmsNotificationEnabled,
			});
		} else {
			if (!isPopUpNotificationActive) {
				this.setState({
					showSettingsNotificationPopUp: !this.state.showSettingsNotificationPopUp,
					popUpNotificationDescription:  Translations.get('FavoriteActorBoxActivateNotificationsSMSDescription'),
				});
				isPopUpNotificationActive = true;
			}
			if (this.state.showSettingsNotificationPopUp !== Translations.get('FavoriteActorBoxActivateNotificationsSMSDescription')) {
				this.setState({
					popUpNotificationDescription: Translations.get('FavoriteActorBoxActivateNotificationsSMSDescription'),
				});
			}
		}
	}

	hideNotificationPopUp() {
		this.setState({
			showSettingsNotificationPopUp: false,
		});
		isPopUpNotificationActive = false;
	}

	onOpenMessengerClick(e) {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(this.props.actorId, true);
		} else {
			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  this.props.actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				});
			} else {
				VXPay.openSignupOrLogin();
			}
		}
		if (typeof e === 'object' && e.cancelable) {
			e.preventDefault();
			e.stopPropagation();
		}
		return false;
	}

	render() {
		const responsiveMultiplier = 1;
		const isOnline = this.state.isOnline !== null ? this.state.isOnline : this.props.isOnline;

		const outerClassName = 'context-box--media-box__picture ' + (this.props.useRatio ? ' box--fixed-ratio' : '');
		const innerClassName = this.props.useRatio ? 'box--fixed-ratio__content' : '';
		const pictureBoxes   =
			      <PictureBoxes pictures={this.props.previewPictureUrls}
			                    useIndexForKey={true}
			                    outerClassName={outerClassName}
			                    innerClassName={innerClassName}
			                    responsiveMultiplier={responsiveMultiplier}
			                    setMaxWidth={true}
			                    usePlaceholder={true}
			      />;

		let actorStateText = '';
		if (isOnline) {
			actorStateText = Translations.get('FavoriteLastOnlineTextOnline');
		} else if (this.props.lastOnlineDiffDays < 0.05) {
			actorStateText = Translations.get('FavoriteLastOnlineTextMinutes');
		} else if (this.props.lastOnlineDiffDays < 1) {
			actorStateText = Translations.get('FavoriteLastOnlineTextHours');
		} else if (this.props.lastOnlineDiffDays < 7) {
			actorStateText = Translations.get('FavoriteLastOnlineTextDays');
		} else if (this.props.lastOnlineDiffDays < 14) {
			actorStateText = Translations.get('FavoriteLastOnlineTextWeek');
		} else if (this.props.lastOnlineDiffDays < 21) {
			actorStateText = Translations.get('FavoriteLastOnlineTextWeeks');
		} else {
			actorStateText = Translations.get('FavoriteLastOnlineTextOffline');
		}

		let actorHighlight = null;
		if (this.props.newElements > 0 && this.props.newElementTarget.length > 0 && this.props.showNewContentBanner) {
			const favoriteHighlightClass = this.props.isMobile ? 'favorite__highlight--mobile' : 'favorite__highlight';
			const highlightHeadClass = this.props.isMobile ? 'favorite__highlight__head--mobile' : 'highlight__head';
			
			actorHighlight = 
				<div className={favoriteHighlightClass} onClick={this.onNewElementClick}>
						<span
							className={highlightHeadClass}>{this.props.newElements > 1 ? Translations.get('NewContents') : Translations.get('NewContent')}</span>{!this.props.isMobile && Translations.get('ToProfile')}
				</div>;
		} 

		return (
			<div className={this.props.className}>
				<div>
					{!this.state.notificationsSettings.isFavoriteOnline && this.state.showSettingsNotificationPopUp &&
						<div>
							<div className="favorite__chat--popup">
							<span onScroll={this.hideNotificationPopUp}
							      onClick={this.hideNotificationPopUp}
							      className="icon -icon-close-line favorite__chat-close-icon"
							> </span>
								<div className=" favorite__chat--description -main-description"> {this.state.popUpNotificationDescription}</div>
								<div onClick={FavoriteExtendedTile.openVXPaySettings}
								     className=" favorite__chat--description -link-description"
								>  {Translations.get('FavoriteActorBoxActivateNotificationsC2A')}
								</div>
							</div>
						</div>
					}
				</div>
				<div className="favorite__tile">
					{!this.props.isMobile && actorHighlight}
					<div className={this.props.isMobile ? 'col-xs-5' : 'col-xs-6 favorite__imagecontainer box--fixed-ratio -ratio4_3'}>
						<a href={this.props.targetLink}
						   data-tip={Translations.get('VisitProfile')}
						   data-place="bottom"
						   className="collections_imagelink"
						   onClick={this.onClick}
						>
							<div className="favorite__actorimage content-box -no-padding">
								{pictureBoxes}
								{this.props.children}
								{this.props.isDildocontrolAvailable && isOnline && <DildocontrolOverlay/>}
							</div>
						</a>
					</div>
					<div className={this.props.isMobile ? 'favorite__container col-xs-7' : 'col-xs-6'}>
						{this.props.isMobile && actorHighlight}
						<div className="favorite__actorname">
							{!this.props.isMobile &&
								<span
									className={"badge--" + ((isOnline) ? 'online' : 'offline') + " -size-small"}>&nbsp;</span>}{this.props.actorName} ({this.props.actorAge})
							&nbsp;
							<ActorPin actorId={this.props.actorId}
							          initialIsFavoriteOfGuest={this.props.isModelGuestFavorite}
							/>
							{!this.props.isMobile &&
								<span className="favorite__content">
								{actorStateText}
							</span>}
						</div>

						<div className="favorite__chatbutton">
							{!this.props.isMobile &&
								<div className="favorite__chatbutton--settings">

									{
										<div
											className={'favorite__chatbutton--settings-email h-over-visible btn--icon-detailed  -icon-envelope-full -size-sm ' + (this.state.isEmailNotificationEnabled ? ' active' : '')}
											onClick={this.onToggleEmailNotification}
										>
											<span className="ink animate"/>
											{Translations.get('FavoriteActorBoxNotificationEmail')}
											<span className="btn--icon-detailed__teaser">
											{Translations.get('FavoriteActorBoxNotification')}
										</span>
											<span className="icon -icon-success-rounded-full"/>
										</div>
									}
									<div
										className={' favorite__chatbutton--settings-sms btn--icon-detailed -icon-mobile-send -size-sm' + (this.state.isSmsNotificationEnabled ? ' active' : '')}
										onClick={this.onToggleSMSNotification}
									>
										<span className="ink animate"/>
										{Translations.get('FavoriteActorBoxNotificationSMS')}
										<span className="btn--icon-detailed__teaser">
										{Translations.get('FavoriteActorBoxNotification')}
									</span>
										<span className="icon -icon-success-rounded-full"/>
									</div>
								</div>}
							{!this.props.isMessengerExcluded && <div className="favorite__chatbutton--messenger">
								{(isOnline || (this.props.isOnlineMobileVideocall && !Flux.Guest.isVoicecallActive())) &&
									<VideochatButton
										multiChatPrice={this.props.multiChatPrice}
										actorId={this.props.actorId}
										isActorOnline={isOnline}
										isVoyeurMode={false}
										buttonSubline={Translations.get('ActorGridVideoSubline')}
										buttonText={Translations.get('ActorGridVideoText')}
										additionalClassNames={'-click-material -size-sm'}
										isCurrentlyTicketShow={this.props.isCurrentlyTicketShow}
										guestHasTicket={this.props.guestHasTicket}
										actorLink={this.props.targetLink}
										isOnlineMobileVideocall={this.props.isOnlineMobileVideocall}
									/>}
								{!isOnline && !this.props.isOnlineMobileVideocall &&
									<button className="btn--icon-detailed -btn-color- -icon-chat-text-full -click-material -size-sm"
									        onClick={this.onOpenMessengerClick}
									>
										<span className="ink animate"/>
										{Translations.get('ActorGridMessageText')}
										<span className="btn--icon-detailed__teaser">
									    {this.props.messagePrice === 0 ? Translations.get('ChatButtonFree') : formatCurrency(this.props.messagePrice)}
									</span>
									</button>}
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

FavoriteExtendedTile.propTypes = {
	actorId:                    PropTypes.number.isRequired,
	actorName:                  PropTypes.string.isRequired,
	actorAge:                   PropTypes.number.isRequired,
	children:                   PropTypes.node,
	className:                  PropTypes.string,
	multiChatPrice:             PropTypes.number.isRequired,
	asFavoriteAdded:            PropTypes.string.isRequired,
	isOnline:                   PropTypes.bool.isRequired,
	isDildocontrolAvailable:    PropTypes.bool,
	isMobile:                   PropTypes.bool,
	newElements:                PropTypes.number.isRequired,
	showNewContentBanner:       PropTypes.bool,
	usePjax:                    PropTypes.bool,
	useRatio:                   PropTypes.bool,
	previewPictureUrls:         PropTypes.arrayOf(PropTypes.array),
	targetLink:                 PropTypes.string.isRequired,
	isModelGuestFavorite:       PropTypes.bool.isRequired,
	messagePrice:               PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isEmailNotificationEnabled: PropTypes.bool,
	isSmsNotificationEnabled:   PropTypes.bool,
	lastOnlineDiffDays:         PropTypes.number,
	newElementTarget:           PropTypes.string,
	isCurrentlyTicketShow:      PropTypes.bool,
	guestHasTicket:             PropTypes.bool,
	isMessengerExcluded:        PropTypes.bool,
	isOnlineMobileVideocall:    PropTypes.bool,
};

FavoriteExtendedTile.defaultProps = {
	className:                  'col-lg-6 col-xs-12',
	isEmailNotificationEnabled: false,
	isSmsNotificationEnabled:   false,
	lastOnlineDiffDays:         null,
	isCurrentlyTicketShow:      false,
	guestHasTicket:             false,
	isMessengerExcluded:        false,
	isOnlineMobileVideocall:    false,
};

export default FavoriteExtendedTile;
