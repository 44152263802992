import React                from "react";
import PropTypes            from "prop-types";
import VideoTile            from "../Grid2/Tiles/VideoTile";
import Grid2                from "../Grid2/Grid2";
import withVideos           from "../HigherOrderComponents/Videos/withVideos";
import withStaticLoader     from "../HigherOrderComponents/Utility/withStaticLoader";

class ModalBoxVideosGrid extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.videos) {
			return null;
		}
		
		const tileData    = [...this.props.videos.map(item => {
			const linkVX = this.props.linkVX;
			return {...item, linkVX, callback: this.props.callback};
		})];


		return (
			<div>
				<Grid2 tileData={tileData}
				       defaultTileClass={VideoTile}
				       columns={{xs: 2, md: 4}}
				       defaultTileConfig={{footer: {}, badges: {textBadges: {show: {price: false}}}}}
					   slider={this.props.isMobile}
					   single={this.props.isSingle}
				/>
			</div>
		);
	}
}

ModalBoxVideosGrid.propTypes = {
	videos:    PropTypes.array.isRequired,
	actorId:   PropTypes.number.isRequired,
	actorName: PropTypes.string.isRequired,
	isMobile:  PropTypes.bool,
	linkVX:    PropTypes.string,
	callback:  PropTypes.func,
	isSingle:  PropTypes.bool,
};


export default withVideos(withStaticLoader(ModalBoxVideosGrid));
