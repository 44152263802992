import React, {useState, useEffect} from 'react';
import PropTypes                    from 'prop-types';
import IconButton                   from './IconButton';
import {playPause}                  from "./Actions";

const PlayPause = (props) => {
	const [isPlaying, setIsPlaying] = useState(!props.video.paused);

	useEffect(() => {
		const fn = () => {
			setIsPlaying(!props.video.paused);
		};

		props.video.addEventListener('play', fn);
		props.video.addEventListener('pause', fn);

		return () => {
			props.video.removeEventListener('play', fn);
			props.video.removeEventListener('pause', fn);
		};
	}, [props.video]);

	return (
		<IconButton checked={isPlaying} icon="-icon-single-play-full" checkedIcon="-icon-pause-full" onClick={() => playPause(props.video)} />
	);
};

PlayPause.propTypes = {
	video: PropTypes.instanceOf(HTMLVideoElement).isRequired,
};

export default PlayPause;
