import React             from 'react';
import Flux              from '../flux/Flux';
import PropTypes         from 'prop-types';
import { scrollToParam } from '../utils/CommonUtils';

class LegalTexts extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
		};
	}

	componentDidMount() {
		this.getLegalTexts();
	}

	getLegalTexts() {
		Flux.Legal.getLegalTexts({type: this.props.type, product: 'VX'}, 1, 0, (response) => {
			this.setState({data: response.data.legalTexts.items});
			window.scrollTo(0, 0);
		});
	}

	setContainerRef() {
		scrollToParam();		
	}

	render() {
		if (!this.state.data || this.state.data.length === 0) {
			return null;
		}
		const newest = [...this.state.data][0];

		return <div className="container-block -margin-bottom-lg terms-of-service" ref={ref => this.setContainerRef(ref)}>
                    <div className="content-box -padding-deep-help">
					{newest && <div className='legal-texts' dangerouslySetInnerHTML={{__html: newest.html}}></div>}
					{this.props.download && <a className="legal-texts__download" target="_blank" href={"https://www.visit-x.net/rest/v1/asset/legal-text/download/" + newest.id} >Download</a>}
					{this.props.additionalText && <div className='legal-texts h-mt-20'><p>{this.props.additionalText}</p></div>}
                </div>
        </div>;
	}
}

LegalTexts.propTypes = {
    type:           PropTypes.string.isRequired,
	download:       PropTypes.bool,
	additionalText: PropTypes.string
};

LegalTexts.defaultProps = {
	download:       false,
	additionalText: ''
};

export default LegalTexts;
